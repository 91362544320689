import React from 'react';
import { bindActionCreators } from 'redux';
import { modalClose, modalOpen } from '../../../../shared/reducers/ModalReducer';
import { connect } from 'react-redux';
import { MODAL_EDUCATOR_DASHBOARD } from '../../../ModalContainer/Modals/ModalEducatorDashboard';
import Map from '../../../App/Map/Map';
import DrawArea from '../../../App/DrawArea/DrawArea';
import ScalePopup from '../../../App/ScalePopup/ScalePopup';
import ZoomView from '../../../App/ZoomView/ZoomView';
import ToEducatorDashboard from '../../../App/ToEducatorDashboard';
import { instrumentChange } from '../../../../shared/reducers/instrumentReducer';
import SketchLayers from '../../../App/SketchLayers/SketchLayers';
import Notification from '../../../App/Notification/Notification';
import LayerLabelsFixed from '../../../App/LayerLabelsFixed/LayerLabelsFixed';
import NotesComponent from '../../../App/NotesComponent/NotesComponent';

import './EducatorAppStyles.scss';


class EducatorApp extends React.Component {
    componentWillMount() {
        this.props.modalOpen(MODAL_EDUCATOR_DASHBOARD);
    }

    componentWillUnmount() {
        this.props.modalClose()
    }

    render() {
        const { activeModal } = this.props;
        const modalOpened = !!activeModal;

        return (
            <div className={`educator-app`}>
                <Map />
                {!modalOpened && <ToEducatorDashboard />}
                <DrawArea readonly />
                <ScalePopup />
                <ZoomView />
                {!modalOpened && <SketchLayers />}
                <Notification />
                <LayerLabelsFixed />
                <NotesComponent />
            </div>
        )
    }
}

const mapStateToProps = state => {
    const {
        modal: {
            activeModal
        }
    } = state;

    return {
        activeModal
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            instrumentChange,
            modalOpen,
            modalClose
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EducatorApp);