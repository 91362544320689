import notificationConstants, { NOTIFICATION_DELAY } from '../constants/notificationConstants';

const initState = {
	display: false,
	message: null,
	type: null,
	delay: NOTIFICATION_DELAY
};

export default function notificationReducer(state = initState, action) {
	switch (action.type) {
		case notificationConstants.NOTIFICATION_SHOW: {
			return { ...state, display: true, ...action.payload };
		}
		case notificationConstants.NOTIFICATION_RESET: {
			return { ...initState };
		}
		default: {
			return state;
		}
	}
}
