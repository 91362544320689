import * as React from 'react';
import isEqual from 'lodash.isequal';
import { stopPropagation } from '../../../shared/EventHelper';
import { STRAIGHT_LINE } from '../../../shared/classes/Instruments';
import { renderMoveVertex } from '../../ContextualHelp/Modals/MoveVertex';

export class ShapeControlPoints extends React.Component {
	pointEl = null;

	render() {
		return <g className='control-points'>{this.renderPoints()}</g>;
	}

	renderPoints() {
		let strokeWidth = 1 / (this.props.zoom || 1),
			line = this.props.line,
			pointIndex = this.props.pointIndex;

		return this.props.points.map((point, index) => {
			let classNames = ['point'],
				rectSize = 6 / (this.props.zoom || 1);

			if (line && line.getType() === STRAIGHT_LINE && pointIndex >= 0) {
				if (isEqual(line.getPoints()[pointIndex], point)) {
					classNames.push('active');
				}
			}

			if (this.props.activeIndex === index && !classNames.includes('active')) {
				classNames.push('active');
			}

			if (classNames.includes('active')) {
				rectSize *= 1.2;
			}

			const halfRectSize = rectSize / 2;
			const x = point[0] - halfRectSize;
			const y = point[1] - halfRectSize;

			return (
				<g key={`wrap-${index}`}>
					<rect
						width='100%'
						height='100%'
						style={{ fill: 'transparent' }}
						onTouchStart={event => {
							if (this.props.closed) {
								stopPropagation(event);
								this.props.mouseDown(index, event, this.props.isShape);
							}
						}}
						onMouseDown={event => {
							if (this.props.closed) {
								stopPropagation(event);
								this.props.mouseDown(index, event, this.props.isShape);
							}
						}}
					/>
					<rect
						ref={el => {
							if (index === 0) {
								this.pointEl = el;
							}
						}}
						className={classNames.join(' ')}
						width={rectSize}
						height={rectSize}
						transform={'rotate(45 ' + (x + halfRectSize) + ' ' + (y + halfRectSize) + ')'}
						x={x}
						y={y}
						key={index}
						strokeWidth={strokeWidth}
						onTouchStart={event => {
							if (this.props.closed) {
								stopPropagation(event);
								this.props.mouseDown(index, event, this.props.isShape);
							}
						}}
						onMouseDown={event => {
							if (this.props.closed) {
								stopPropagation(event);
								this.props.mouseDown(index, event, this.props.isShape);
							}
						}}
					/>
				</g>
			);
		});
	}

	componentDidUpdate() {
		if (this.pointEl && this.props.closed && !this.props.helpMoveVertex && renderMoveVertex) {
			const bounds = this.pointEl.getBoundingClientRect();

			renderMoveVertex({
				top: bounds.top,
				left: bounds.left,
				width: bounds.width,
				height: bounds.height
			});
		}
	}
}
