import React, { Component } from 'react';
import { connect } from 'react-redux';
import LineLabel from '../LineLabel/LineLabel';
import CurvedLine from '../../../shared/classes/CurvedLine';
import Line from '../../../shared/classes/Line';
import polygonOverlap from 'polygon-overlap';
import isEqual from 'lodash.isequal';
import { gridSize } from '../CanvasGrid/CanvasGrid';
import { bindActionCreators } from 'redux';

class CanvasPinnedLayers extends Component {
	handleRefs = [];

	state = { hiddenLabels: [] };

	componentDidMount() {
		this.hideOverlaps();
	}

	componentDidUpdate() {
		this.hideOverlaps();
	}

	getPolygonByElement = element => {
		const polygon = [];
		const svg = element.ownerSVGElement;
		const point = svg.createSVGPoint();
		const bbox = element.getBBox();
		const matrix = element.getScreenCTM();

		point.x = bbox.x;
		point.y = bbox.y;
		polygon.push(point.matrixTransform(matrix));
		point.x += bbox.width;
		polygon.push(point.matrixTransform(matrix));
		point.y += bbox.height;
		polygon.push(point.matrixTransform(matrix));
		point.x -= bbox.width;
		polygon.push(point.matrixTransform(matrix));

		return polygon.map(point => [point.x, point.y]);
	};

	hideOverlaps = () => {
		const polygons = [];
		const hiddenLabels = [];
		let lastVisibleIndex = this.handleRefs.length - 1;

		this.handleRefs.forEach((ref, index) => {
			if (ref) {
				const currentPolygon = this.getPolygonByElement(ref);

				if (!polygons.length && currentPolygon) {
					polygons.push(currentPolygon);
				} else {
					let isIntersect = false;

					if (currentPolygon) {
						for (let polygon of polygons) {
							if (polygonOverlap(currentPolygon, polygon)) {
								isIntersect = true;
								break;
							}
						}

						if (isIntersect) {
							hiddenLabels.push(index);
						} else {
							polygons.push(currentPolygon);
							lastVisibleIndex = index;
						}
					}
				}
			}
		});

		if (!isEqual(this.state.hiddenLabels, hiddenLabels)) {
			this.setState({ hiddenLabels });
		}
	};

	render() {
		const layers = this.props.layers.filter(layer => layer.getPin() && !layer.getHidden());

		const zoom = this.props.zoomValue;
		const strokeWidth = this.props.strokeWidth / zoom;

		return (
			<g className='all-layers'>
				<g className='sketch'>
					{layers.map((layer, index) => {
						const isClosed = layer.getSketch().closed;

						const classNames = ['closed', layer.getColor()];
						const lines = layer.getSketch().lines;

						if (!isClosed) {
							classNames.push('open-shape');
						}

						return (
							<g key={index}>
								<path
									d={layer.getSketch().toPath(isClosed)}
									className={classNames.join(' ')}
									strokeWidth={strokeWidth}
								/>
								{lines.map((line, index) => {
									let point;
									let rotateAngle;
									let labelText;
									const isCurvedLine = line instanceof CurvedLine;

									if (isCurvedLine) {
										const curvedLine = new CurvedLine(line.points);

										point = curvedLine.getMiddlePoint();
										rotateAngle = curvedLine.getLabelAngle();
										labelText = `~ ${(curvedLine.getLength(this.props.scale) / gridSize).toFixed(
											2
										)}`;
									} else {
										const newLine = new Line(line.startPoint, line.endPoint);

										point = newLine.getMiddlePoint();
										rotateAngle = newLine.getLabelAngle();
										labelText = (newLine.getLength(this.props.scale) / gridSize).toFixed(2);
									}

									return (
										<LineLabel
											labelRef={el => (this.handleRefs[index] = el)}
											key={index}
											isPinned
											zoom={this.props.zoomValue}
											point={point}
											text={labelText}
											color={layer.getColor()}
											rotate={rotateAngle}
											asTooltip={isCurvedLine}
											hidden={this.state.hiddenLabels.includes(index)}
											measure={this.props.measure}
											lineSize={line.getLength()}
											lineType={line.getType()}
										/>
									);
								})}
							</g>
						);
					})}
				</g>
			</g>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		layers: state.layers.list,
		zoomValue: state.zoom.value,
		scale: state.scale.value,
		measure: state.scale.measure,
		strokeWidth: state.settings.lineWeight,
		isFractions: state.settings.imperial === 'fractions',
		currentIndex: state.layers.currentIndex,
		isMapVisible: state.map.visible
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CanvasPinnedLayers);
