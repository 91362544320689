import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { instrumentChange } from '../../../shared/reducers/instrumentReducer';
import * as zoomActions from '../../../shared/actions/zoomActions';
import * as undoRedoActions from '../../../shared/actions/historyActions';
import * as instrumentsTypes from '../../../shared/classes/Instruments';
import * as SketchTypes from '../../../shared/classes/SketchTypes';
import { stopPropagation } from '../../../shared/EventHelper';
import {
	instrumentMenuSetMouseDown,
	instrumentMenuSetOffsets,
	instrumentMenuToggleExpand
} from '../../../shared/reducers/instrumentMenuReducer';
import { renderSetScale1 } from '../../ContextualHelp/Modals/SetScale1';
import { notificationShow } from '../../../shared/actions/notificationActions';
import { notificationTypes } from '../../../shared/constants/notificationConstants';

export let scaleToolEl;

class InstrumentMenu extends React.Component {
	el = null;
	handleInterval = null;

	availableInstruments = [
		{
			icon: 'icon-drawing_tool',
			type: instrumentsTypes.STRAIGHT_LINE,
			title: 'Line Tool',
			children: [
				{
					icon: 'icon-drawing_tool',
					type: instrumentsTypes.STRAIGHT_LINE,
					text: 'line tool',
					title: 'Line Tool'
				},
				{
					icon: 'icon-drawing_tool_constrained',
					type: instrumentsTypes.CONSTRAINED_LINE,
					text: 'constrained line tool',
					title: 'Constrained Line Tool'
				},
				{
					icon: 'icon-rectangle_tool',
					type: instrumentsTypes.RECTANGLE_TOOL,
					text: 'rectangle tool',
					title: 'Rectangle Tool'
				},
				{
					icon: 'icon-circle_tool',
					type: instrumentsTypes.CIRCLE_TOOL,
					text: 'circle tool',
					title: 'Circle Tool'
				}
			]
		},
		{
			icon: 'icon-magicwand',
			type: instrumentsTypes.MAGIC_WAND,
			title: 'Magic wand',
			children: [
				{
					icon: 'icon-magicwand',
					type: instrumentsTypes.MAGIC_WAND,
					title: 'Magic wand',
					text: 'magic wand'
				},
				{
					icon: 'icon-magicwand-plus',
					type: instrumentsTypes.MAGIC_WAND_ADD,
					title: 'Add to area',
					text: 'add to area'
				},
				{
					icon: 'icon-magicwand-minus',
					type: instrumentsTypes.MAGIC_WAND_SUB,
					title: 'Substract from area',
					text: 'substract from area'
				}
			]
		},
		{
			icon: 'icon-drawing_tool_curves',
			type: instrumentsTypes.CURVED_LINE,
			title: 'Curved Tool'
		},
		{
			icon: 'icon-select_tool',
			type: instrumentsTypes.SELECT_TOOL,
			title: 'Select Tool',
			children: [
				{
					icon: 'icon-select_tool',
					type: instrumentsTypes.SELECT_TOOL,
					text: 'select tool',
					title: 'Select Tool'
				},
				{
					icon: 'icon-angle_tool',
					type: instrumentsTypes.ANGLE_LINE,
					text: 'angle tool',
					title: 'Angle Tool'
				}
			]
		},
		{
			icon: 'icon-zoom_in',
			title: 'Zoom In',
			onMouseDown: this.zoomInMouseDown.bind(this),
			onMouseUp: this.clearInterval.bind(this),
			onMouseOut: this.clearInterval.bind(this),
			alwaysOn: true
		},
		{
			icon: 'icon-zoom_out',
			title: 'Zoom Out',
			onMouseDown: this.zoomOutMouseDown.bind(this),
			onMouseUp: this.clearInterval.bind(this),
			onMouseOut: this.clearInterval.bind(this),
			alwaysOn: true
		},
		{
			icon: 'icon-pan_tool',
			type: instrumentsTypes.PAN_MOVE,
			title: 'Pan Tool'
		},
		{
			icon: 'icon-undo_tool',
			onClick: this.props.historyMoveBack,
			title: 'Undo',
			type: 'UNDO'
		},
		{
			icon: 'icon-redo_tool',
			onClick: this.props.historyMoveForward,
			title: 'Redo',
			type: 'REDO'
		},
		{
			icon: 'icon-comment_tool',
			type: instrumentsTypes.NOTE_TOOL,
			title: 'Annotate'
		}
	];

	imageInstruments = [
		{
			icon: 'icon-scale_tool',
			type: instrumentsTypes.STRAIGHT_LINE,
			title: 'Scale Tool'
		},
		{
			icon: 'icon-zoom_in',
			title: 'Zoom In',
			onMouseDown: this.zoomInMouseDown.bind(this),
			onMouseUp: this.clearInterval.bind(this),
			onMouseOut: this.clearInterval.bind(this)
		},
		{
			icon: 'icon-zoom_out',
			title: 'Zoom Out',
			onMouseDown: this.zoomOutMouseDown.bind(this),
			onMouseUp: this.clearInterval.bind(this),
			onMouseOut: this.clearInterval.bind(this)
		},
		{
			icon: 'icon-pan_tool',
			type: instrumentsTypes.PAN_MOVE,
			title: 'Pan Tool'
		}
	];

	disableOnClose = [
		instrumentsTypes.STRAIGHT_LINE,
		instrumentsTypes.CURVED_LINE,
		instrumentsTypes.RECTANGLE_TOOL,
		instrumentsTypes.MAGIC_WAND,
		instrumentsTypes.MAGIC_WAND_ADD,
		instrumentsTypes.MAGIC_WAND_SUB
	];

	disableOnLinesExists = [
		instrumentsTypes.RECTANGLE_TOOL,
		instrumentsTypes.CIRCLE_TOOL,
		instrumentsTypes.MAGIC_WAND,
		instrumentsTypes.MAGIC_WAND_ADD,
		instrumentsTypes.MAGIC_WAND_SUB
	];

	disableOnShapeExists = [
		instrumentsTypes.STRAIGHT_LINE,
		instrumentsTypes.CURVED_LINE,
		instrumentsTypes.MAGIC_WAND,
		instrumentsTypes.MAGIC_WAND_ADD,
		instrumentsTypes.MAGIC_WAND_SUB
	];

	disableOnOpen = [];

	disabledWithoutImage = [
		instrumentsTypes.MAGIC_WAND,
		instrumentsTypes.MAGIC_WAND_ADD,
		instrumentsTypes.MAGIC_WAND_SUB
	];

	enabledWithMask = [
		instrumentsTypes.MAGIC_WAND,
		instrumentsTypes.MAGIC_WAND_ADD,
		instrumentsTypes.MAGIC_WAND_SUB,
		'UNDO',
		'REDO'
	];

	zoomInMouseDown() {
		this.clearInterval();
		this.props.zoomIn();
		this.handleInterval = window.setInterval(this.props.zoomIn, this.props.mapVisible ? 500 : 100);
	}

	zoomOutMouseDown() {
		this.clearInterval();
		this.props.zoomOut();
		this.handleInterval = window.setInterval(this.props.zoomOut, this.props.mapVisible ? 500 : 100);
	}

	clearInterval() {
		if (this.handleInterval) {
			window.clearInterval(this.handleInterval);
			this.handleInterval = null;
		}
	}

	render() {
		let style = false;

		scaleToolEl = null;

		if (this.props.menu.position) {
			style = {
				top: this.props.menu.position.y,
				left: this.props.menu.position.x
			};
		}

		return (
			<div
				ref={el => (this.el = el)}
				style={style ? style : {}}
				className={`instrument-menu${this.props.menu.expand ? '' : ' minimize'}`}>
				<ul className='instrument-actions'>
					<li className='icon icon-toolbar_handle' onMouseDown={this.mouseDown.bind(this)} />
					<li
						className={`icon turn ${this.props.menu.expand ? 'on' : 'off'}`}
						onClick={this.props.instrumentMenuToggleExpand.bind(this)}
					/>
				</ul>
				{this.props.menu.expand && this.renderItems()}
			</div>
		);
	}

	componentDidUpdate() {
		if (scaleToolEl && renderSetScale1) {
			renderSetScale1();
		}
	}

	mouseDown(event) {
		let bounds = this.el.getBoundingClientRect();
		let offsets = {
			x: event.nativeEvent.clientX - bounds.left,
			y: event.nativeEvent.clientY - bounds.top
		};

		this.props.instrumentMenuSetOffsets(offsets);
		this.props.instrumentMenuSetMouseDown(true);
	}

	renderItems() {
		let sketch = this.props.sketch,
			arrayForRender = this.availableInstruments;

		if (this.props.imageDynamic) {
			arrayForRender = this.imageInstruments;
		}

		return arrayForRender.map((instrument, index) => {
			let disabled =
					((sketch.closed || sketch.angleClosed) && this.disableOnClose.includes(instrument.type)) ||
					(!sketch.closed && !sketch.angleClosed && this.disableOnOpen.includes(instrument.type)),
				children = instrument.children && instrument.children.length,
				active,
				filter,
				icon,
				title;

			if (sketch.getLines().length > 0 && this.disableOnLinesExists.includes(instrument.type)) {
				disabled = true;
			}

			if (
				[instrumentsTypes.STRAIGHT_LINE, instrumentsTypes.CURVED_LINE].includes(instrument.type) &&
				sketch.angleLineExists()
			) {
				disabled = true;
			}

			if (instrument.type === instrumentsTypes.SELECT_TOOL && (sketch.lineExists() || sketch.angleClosed)) {
				children = false;
			}

			if (this.props.image.url === null && this.props.image.data === null) {
				if (this.disabledWithoutImage.indexOf(instrument.type) > 0) {
					disabled = true;
				}
			}

			if (this.props.mask !== null && this.enabledWithMask.indexOf(instrument.type) === -1) {
				disabled = true;
			}

			icon = instrument.icon;
			title = instrument.title;
			active = instrument.type && this.props.instrument === instrument.type;

			if (children) {
				filter = instrument.children.filter(item => item.type === this.props.instrument);

				if (filter.length) {
					icon = filter[0].icon;
					title = filter[0].title;
					active = true;
				}
			}

			if (this.props.showAllLayers) {
				if (
					!instrument.alwaysOn &&
					![instrumentsTypes.PAN_MOVE, instrumentsTypes.SELECT_TOOL].includes(instrument.type)
				) {
					disabled = true;
				}

				if (instrument.type === instrumentsTypes.SELECT_TOOL) {
					children = false;
				}
			}

			return (
				<div
					key={index}
					title={title}
					ref={el => {
						if (icon === 'icon-scale_tool') {
							scaleToolEl = el;
						}
					}}
					className={
						'icon ' +
						icon +
						' ' +
						(active ? 'active' : '') +
						' ' +
						'on-bar' +
						' ' +
						(disabled ? 'disabled' : '') +
						' ' +
						(children ? 'extra' : '')
					}
					onClick={
						disabled
							? () => {
									if (this.disableOnClose.includes(instrument.type)) {
										this.props.notificationShow(
											'Add a new drawing layer with plus (+) symbol in bottom right corner',
											notificationTypes.INFO,
											2000
										);
									} else if (this.disableOnOpen.includes(instrument.type)) {
										this.props.notificationShow(
											'Annotations can only be added to closed sketches.',
											notificationTypes.INFO,
											2000
										);
									}
							  }
							: instrument.onClick
							? instrument.onClick
							: () => {
									if (instrument.type) {
										this.props.instrumentChange(instrument.type);
									}
							  }
					}
					onMouseDown={!disabled && instrument.onMouseDown && instrument.onMouseDown}
					onMouseUp={!disabled && instrument.onMouseUp && instrument.onMouseUp}
					onMouseOut={!disabled && instrument.onMouseOut && instrument.onMouseOut}>
					{children && (
						<ul title=''>
							{instrument.children.map((tool, index) => {
								let subDisabled = false;

								if (sketch.getLines().length > 0 && this.disableOnLinesExists.includes(tool.type)) {
									subDisabled = true;
								}

								if (
									sketch.type === SketchTypes.SHAPE &&
									this.disableOnShapeExists.includes(tool.type)
								) {
									subDisabled = true;
								}

								return (
									<li
										key={index}
										className={subDisabled ? 'disabled' : ''}
										onClick={
											!disabled &&
											!subDisabled &&
											(event => {
												stopPropagation(event);

												this.props.instrumentChange(tool.type);
											})
										}>
										{tool.text}
										<span className={tool.icon} />
									</li>
								);
							})}
						</ul>
					)}
				</div>
			);
		});
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		instrument: state.instrument,
		menu: state.instrumentMenu,
		sketch: state.sketchState.sketch,
		mask: state.sketchState.sketch.mask,
		imageDynamic: state.image.dynamicImage,
		showAllLayers: state.layers.showAllLayers,
		mapVisible: state.map.visible,
		image: state.image
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			instrumentChange,
			instrumentMenuToggleExpand,
			instrumentMenuSetMouseDown,
			instrumentMenuSetOffsets,
			...zoomActions,
			...undoRedoActions,
			notificationShow
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(InstrumentMenu);
