import Line from './Line';

export const DIAMETER_LINE = 'diameter';

export default class DiameterLine extends Line {
	constructor(startPoint = null, endPoint = null, startLatLngPoint = null, endLatLngPoint = null) {
		super();

		this.type = 'diameter';

		this.startPoint = startPoint;
		this.endPoint = endPoint;
		this.startLatLngPoint = startLatLngPoint;
		this.endLatLngPoint = endLatLngPoint;

		this.isConstrained = false;
	}
}
