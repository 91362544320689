import { CIRCLE_TOOL } from './Instruments';
import MathHelper from '../MathHelper';
import * as d3 from 'd3-polygon';
import MapHelper from '../MapHelper';
import DiameterLine from './DiameterLine';

export default class Circle {
	constructor(
		vertexes = [],
		center = null,
		supportPoints = [],
		initialPoints = null,
		latLngPoints = [],
		latLngSupports = []
	) {
		this.type = CIRCLE_TOOL;
		this.vertexes = vertexes.slice(0);
		this.supportPoints = supportPoints;
		this.center = center;
		this.initialPoints = initialPoints;

		this.polyPointsCache = [];

		this.latLngPoints = latLngPoints.slice(0);
		this.latLngSupports = latLngSupports.slice(0);

		this.updatePolyPointsCache();
	}

	start(point) {
		this.vertexes = [];
		this.vertexes.push(point);
	}

	end(point) {
		this.vertexes.push(point);
		this.initialPoints = [0, 1];
		this.supportPoints = [null, null];
		this.updateCenter();
		this.updatePolyPointsCache();
	}

	addPoint(point, index) {
		let ip1 = this.vertexes[this.initialPoints[0]],
			ip2 = this.vertexes[this.initialPoints[1]];

		let points = this.vertexes,
			pointIndex = index + 1,
			newPoints = [...points.slice(0, pointIndex), point, ...points.slice(pointIndex)];

		this.initialPoints = [newPoints.indexOf(ip1), newPoints.indexOf(ip2)];
		this.vertexes = newPoints;
		this.updateCenter();

		let supports = this.supportPoints,
			newSupports = [...supports.slice(0, pointIndex), null, ...supports.slice(pointIndex)];
		this.supportPoints = newSupports;
		this.complementSupportPoints();
		this.updatePolyPointsCache();
	}

	updateVertex(index, point) {
		this.vertexes[index] = point;
		this.updateCenter();
		this.updatePolyPointsCache();
	}

	// alias for updateVertex
	updatePoint(index, point) {
		this.updateVertex(index, point);
	}

	removePoint(index) {
		let ip1 = this.vertexes[this.initialPoints[0]],
			ip2 = this.vertexes[this.initialPoints[1]];

		let newPoints = this.vertexes.slice(0);
		newPoints.splice(index, 1);

		if (newPoints.length === 2) {
			this.initialPoints = [0, 1];
			this.supportPoints = [null, null];
		} else {
			this.initialPoints = [newPoints.indexOf(ip1), newPoints.indexOf(ip2)];
		}

		this.vertexes = newPoints;
		this.updateCenter();
		this.updatePolyPointsCache();
	}

	getVertexes() {
		return this.vertexes;
	}

	getRadius() {
		const points = this.vertexes;
		const centerPoint = this.center;
		let radius = -1;

		for (let i = 0; i < points.length; i++) {
			const currVertex = points[i];
			radius = Math.max(MathHelper.lineLength(currVertex, centerPoint), radius);
		}

		return radius / 2;
	}

	updateCenter() {
		const points = this.vertexes;
		if (points.length === 2) {
			this.center = MathHelper.getCenterPoint(...points);
		} else if (this.containsArc()) {
			this.center = MathHelper.getCenterPoint(...[points[this.initialPoints[0]], points[this.initialPoints[1]]]);
		} else {
			this.center = d3.polygonCentroid(points);
		}
	}

	toChunkedPath() {
		const points = this.vertexes;
		const n = points.length;

		let chunks = [];

		for (let i = 0; i < n; i++) {
			let path = '';
			const currVertex = points[i];
			const nextIndex = i + 1 > n - 1 ? 0 : i + 1;
			const nextVertex = points[nextIndex];

			if (this.areInitialPoints(currVertex, nextVertex)) {
				const radius = MathHelper.lineLength(currVertex, nextVertex) / 2;

				path += 'M' + currVertex.join(',') + ' ';
				path += 'A ' + radius + ',' + radius + ' 0 0 1 ' + nextVertex.join(',') + ' ';
			} else {
				let sup1 = [currVertex[0] + this.supportPoints[i][1][0], currVertex[1] + this.supportPoints[i][1][1]],
					sup2 = [
						nextVertex[0] + this.supportPoints[nextIndex][0][0],
						nextVertex[1] + this.supportPoints[nextIndex][0][1]
					];

				path += 'M' + currVertex.join(',') + ' ';
				path += 'C ' + sup1.join(',') + ' ' + sup2.join(',') + ' ' + nextVertex.join(',');
			}

			chunks.push(path);
		}

		return chunks;
	}

	toPath() {
		const points = this.vertexes;
		const n = points.length;
		let path = '';

		for (let i = 0; i < n; i++) {
			const currVertex = points[i];
			const nextIndex = i + 1 > n - 1 ? 0 : i + 1;
			const nextVertex = points[nextIndex];

			if (i === 0) {
				path += 'M' + currVertex.join(',') + ' ';
			}

			if (this.areInitialPoints(currVertex, nextVertex)) {
				const radius = MathHelper.lineLength(currVertex, nextVertex) / 2;
				path += 'A ' + radius + ',' + radius + ' 0 0 1 ' + nextVertex.join(',') + ' ';
			} else {
				let sup1 = [currVertex[0] + this.supportPoints[i][1][0], currVertex[1] + this.supportPoints[i][1][1]],
					sup2 = [
						nextVertex[0] + this.supportPoints[nextIndex][0][0],
						nextVertex[1] + this.supportPoints[nextIndex][0][1]
					];

				path += 'C ' + sup1.join(',') + ' ' + sup2.join(',') + ' ' + nextVertex.join(',');
			}
		}

		return path;
	}

	complementSupportPoints() {
		const points = this.vertexes;
		const n = points.length;

		for (let i = 0; i < n; i++) {
			let currentPoint = this.supportPoints[i];

			if (currentPoint !== null && currentPoint[0] !== null && currentPoint[1] !== null) {
				continue;
			}

			const currVertex = points[i];
			const prevVertex = points[i - 1] ? points[i - 1] : points[n - 1];
			const nextVertex = points[i + 1] ? points[i + 1] : points[0];
			const curveAmount = this.containsArc() ? 2 * n - 2 : n;
			const prevCurve = this.generateSupportPointsOfCurve(prevVertex, currVertex, curveAmount);
			const nextCurve = this.generateSupportPointsOfCurve(currVertex, nextVertex, curveAmount);

			if (currentPoint === null) {
				this.supportPoints[i] = [null, null];
			}

			if (this.supportPoints[i][0] === null) {
				this.supportPoints[i][0] = prevCurve[1];
			}

			if (this.supportPoints[i][1] === null) {
				this.supportPoints[i][1] = nextCurve[0];
			}
		}
	}

	generateSupportPointsOfCurve(p1, p2, n) {
		const centerPoint = this.center;

		if (this.areInitialPoints(p1, p2)) {
			return [null, null];
		} else {
			const tan = (4 * Math.tan(Math.PI / (2 * n))) / 3;
			const radius = tan * MathHelper.lineLength(p1, centerPoint);

			let vector1 = [p1[0] - centerPoint[0], p1[1] - centerPoint[1]],
				vector2 = [p2[0] - centerPoint[0], p2[1] - centerPoint[1]],
				angle1 = Math.atan2(vector1[0], vector1[1]),
				angle2 = Math.atan2(vector2[0], vector2[1]);

			let sup1 = [-Math.cos(angle1) * radius, Math.sin(angle1) * radius],
				sup2 = [Math.cos(angle2) * radius, -Math.sin(angle2) * radius];

			return [sup1, sup2];
		}
	}

	getPoint(pointIndex) {
		return this.vertexes[pointIndex];
	}

	getSupportPointsOfPoint(pointIndex) {
		return this.supportPoints[pointIndex];
	}

	updateSupportPoint(pointIndex, supportPointIndex, realPoint) {
		const parentPoint = this.vertexes[pointIndex];

		this.supportPoints[pointIndex][supportPointIndex] = [
			realPoint[0] - parentPoint[0],
			realPoint[1] - parentPoint[1]
		];
		this.updatePolyPointsCache();
	}

	areInitialPoints(p1, p2) {
		let ip1 = this.vertexes[this.initialPoints[0]],
			ip2 = this.vertexes[this.initialPoints[1]];

		return (
			ip1 &&
			ip2 &&
			((ip1[0] === p1[0] && ip1[1] === p1[1] && ip2[0] === p2[0] && ip2[1] === p2[1]) ||
				(ip2[0] === p1[0] && ip2[1] === p1[1] && ip1[0] === p2[0] && ip1[1] === p2[1]))
		);
	}

	containsArc() {
		return (
			this.initialPoints[1] - this.initialPoints[0] === 1 ||
			(this.initialPoints[0] === 0 && this.initialPoints[1] === this.vertexes.length - 1)
		);
	}

	toParams() {
		return {
			type: CIRCLE_TOOL,
			vertexes: this.vertexes.map(p => (p ? p.slice(0) : null)),
			center: this.center.slice(0),
			initialPoints: this.initialPoints.slice(0),
			supportPoints: this.supportPoints.map(p => (p ? p.slice(0) : null)),
			latLngPoints: this.latLngPoints.map(p => (p ? p.slice(0) : null)),
			latLngSupports: this.latLngSupports.map(p => (p ? p.slice(0) : null))
		};
	}

	getType() {
		return this.type;
	}

	getPreview(point) {
		return [this.vertexes[0], point];
	}

	getLines() {
		if (this.vertexes.length === 2) {
			return [new DiameterLine(...this.vertexes)];
		}

		return null;
	}

	updatePolyPointsCache() {
		let paths = this.toChunkedPath(),
			resultPoints = [];

		paths.forEach(path => {
			resultPoints = resultPoints.concat(MathHelper.closedBesierToPolygonPoints(path));
		});

		this.polyPointsCache = resultPoints;

		return resultPoints;
	}

	toPolygonPoints() {
		return this.polyPointsCache;
	}

	saveLatLngPoints() {
		this.latLngPoints = this.vertexes.map(vertex => {
			return vertex === null ? null : MapHelper.point2LatLng(vertex);
		});

		this.latLngSupports = this.supportPoints.map((support, index) => {
			return support === null
				? null
				: support.map(point => {
						let parent = this.vertexes[index];
						return !parent || point === null
							? null
							: MapHelper.point2LatLng([parent[0] + point[0], parent[1] + point[1]]);
				  });
		});
	}

	updateFromLatLngPoints() {
		this.vertexes = this.latLngPoints.map(vertex => {
			return vertex === null ? null : MapHelper.latLng2Point(vertex);
		});

		this.supportPoints = this.latLngSupports.map((support, index) => {
			return support === null
				? null
				: support.map(point => {
						if (point === null) {
							return null;
						} else {
							let parent = this.vertexes[index];
							let supPoint = MapHelper.latLng2Point(point);

							return [supPoint[0] - parent[0], supPoint[1] - parent[1]];
						}
				  });
		});
	}
}
