import React from 'react';
import ReactGA from 'react-ga';
import DocumentMeta from 'react-document-meta';

export default class TermsAndConditions extends React.Component {
	render() {
		return (
			<section className='policy'>
				<DocumentMeta title='Terms and Conditions' />
				<div>
					<h2>Terms and Conditions</h2>

					<p>Last updated: August 11, 2020</p>
					<p>
						Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using
						the https://www.sketchandcalc.com website (the "Service") operated by iCalc Inc ("us", "we", or
						"our").
					</p>
					<p>
						Your access to and use of the Service is conditioned upon your acceptance of and compliance with
						these Terms. These Terms apply to all visitors, users and others who wish to access or use the
						Service.
					</p>
					<p>
						By accessing or using the Service you agree to be bound by these Terms. If you disagree with any
						part of the terms then you do not have permission to access the Service.
					</p>

					<h5>Contents</h5>
					<p>
						<a href={'#communications'}>Communications</a>
					</p>
					<p>
						<a href={'#subscriptions'}>Subscriptions</a>
					</p>
					<p>
						<a href={'#licensing'}>Licensing</a>
					</p>
					<p>
						<a href={'#free-trial'}>Free Trial</a>
					</p>
					<p>
						<a href={'#fee-changes'}>Fee Changes</a>
					</p>
					<p>
						<a href={'#refunds'}>Refunds</a>
					</p>
					<p>
						<a href={'#content'}>Content</a>
					</p>
					<p>
						<a href={'#accounts'}>Accounts</a>
					</p>
					<p>
						<a href={'#intellectual-property'}>Intellectual Property</a>
					</p>
					<p>
						<a href={'#links-to-other-web-sites'}>Links To Other Web Sites</a>
					</p>
					<p>
						<a href={'#termination'}>Termination</a>
					</p>
					<p>
						<a href={'#indemnification'}>Indemnification</a>
					</p>
					<p>
						<a href={'#limitation-of-liability'}>Limitation Of Liability</a>
					</p>
					<p>
						<a href={'#disclaimer'}>Disclaimer</a>
					</p>
					<p>
						<a href={'#exclusions'}>Exclusions</a>
					</p>
					<p>
						<a href={'#governing-law'}>Governing Law</a>
					</p>
					<p>
						<a href={'#changes'}>Changes</a>
					</p>
					<p>
						<a href={'#contact-us'}>Contact Us</a>
					</p>

					<h5>
						<a name='communications'>Communications</a>
					</h5>
					<p>
						By creating an Account on our service, you agree to subscribe to newsletters, marketing or
						promotional materials and other information we may send. However, you may opt out of receiving
						any, or all, of these communications from us by following the unsubscribe link or instructions
						provided in any email we send.
					</p>

					<h5>
						<a name='subscriptions'>Subscriptions</a>
					</h5>
					<p>
						Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be
						billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set
						either on a monthly or annual basis, depending on the type of subscription plan you select when
						purchasing a Subscription.
					</p>
					<p>
						At the end of each Billing Cycle, your Subscription will automatically renew under the exact
						same conditions unless you cancel it or iCalc Inc cancels it. You may cancel your Subscription
						renewal either through your online account management page or by contacting iCalc Inc customer
						support team.
					</p>
					<p>
						Metrered billing plans (Pay-Per-Day) are billed on a monthly basis. There are no recurring
						payments other than those related to usage during a given month.
					</p>
					<p>
						A valid payment method, including credit card, is required to process the payment for your
						Subscription. You shall provide iCalc Inc with accurate and complete billing information
						including full name, country or region, postal code, and a valid payment method information. By
						submitting such payment information, you automatically authorize iCalc Inc to charge all
						Subscription fees incurred through your account to any such payment instruments.
					</p>
					<p>
						Should automatic billing fail to occur for any reason, iCalc Inc will issue an electronic
						invoice indicating that you must proceed manually, within a certain deadline date, with the full
						payment corresponding to the billing period as indicated on the invoice.
					</p>

					<h5>
						<a name='licensing'>Licensing</a>
					</h5>
					<p>
						All SketchAndCalc plans are licensed for use on a per-seat basis. This means that access is
						granted to the account holder only, and can not be shared to other individuals or businesses
						unless otherwise indicated by the subscription plan.
					</p>

					<h5>
						<a name='free-trial'>Free Trial</a>
					</h5>
					<p>iCalc Inc offers a free trial for a limited period of time ("Free Trial").</p>
					<p>
						You may be required to enter your name and email address in order to create an account to sign
						up for the Free Trial.
					</p>
					<p>
						At any time and without notice, iCalc Inc reserves the right to (i) modify the terms and
						conditions of the Free Trial offer, or (ii) cancel such Free Trial offer.
					</p>

					<h5>
						<a name='fee-changes'>Fee Changes</a>
					</h5>
					<p>
						iCalc Inc, in its sole discretion and at any time, may modify the Subscription fees for the
						Subscriptions. Any Subscription fee change will become effective at the end of the then-current
						Billing Cycle.
					</p>
					<p>
						iCalc Inc will provide you with a reasonable prior notice of any change in Subscription fees to
						give you an opportunity to terminate your Subscription before such change becomes effective.
					</p>
					<p>
						Your continued use of the Service after the Subscription fee change comes into effect
						constitutes your agreement to pay the modified Subscription fee amount.
					</p>

					<h5>
						<a name='refunds'>Refunds</a>
					</h5>
					<p>
						Refund requests for annual Subscriptions may be considered by iCalc Inc on a case-by-case basis
						and granted in sole discretion of iCalc Inc.
					</p>

					<h5>
						<a name='content'>Content</a>
					</h5>
					<p>
						Our Service allows you to post, link, store, share and otherwise make available certain
						information, text, graphics, videos, or other material ("Content"). You are responsible for the
						Content that you post on or through the Service, including its legality, reliability, and
						appropriateness.
					</p>
					<p>
						By posting Content on or through the Service, You represent and warrant that: (i) the Content is
						yours (you own it) and/or you have the right to use it and the right to grant us the rights and
						license as provided in these Terms, and (ii) that the posting of your Content on or through the
						Service does not violate the privacy rights, publicity rights, copyrights, contract rights or
						any other rights of any person or entity. We reserve the right to terminate the account of
						anyone found to be infringing on a copyright.
					</p>
					<p>
						You retain any and all of your rights to any Content you submit, post or display on or through
						the Service and you are responsible for protecting those rights. We take no responsibility and
						assume no liability for Content you or any third party posts on or through the Service. However,
						by posting Content using the Service you grant us the right and license to use, modify, perform,
						display, reproduce, and distribute such Content on and through the Service.
					</p>
					<p>
						iCalc Inc has the right but not the obligation to monitor and edit all Content provided by
						users.
					</p>
					<p>
						In addition, Content found on or through this Service are the property of iCalc Inc or used with
						permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said
						Content, whether in whole or in part, for commercial purposes or for personal gain, without
						express advance written permission from us.
					</p>

					<h5>
						<a name='accounts'>Accounts</a>
					</h5>
					<p>
						When you create an account with us, you guarantee that you are above the age of 18, and that the
						information you provide us is accurate, complete, and current at all times. Inaccurate,
						incomplete, or obsolete information may result in the immediate termination of your account on
						the Service.
					</p>
					<p>
						You are responsible for maintaining the confidentiality of your account and password, including
						but not limited to the restriction of access to your computer and/or account. You agree to
						accept responsibility for any and all activities or actions that occur under your account and/or
						password, whether your password is with our Service or a third-party service. You must notify us
						immediately upon becoming aware of any breach of security or unauthorized use of your account.
					</p>

					<h5>
						<a name='intellectual-property'>Intellectual Property</a>
					</h5>
					<p>
						The Service and its original content (excluding Content provided by users), features and
						functionality are and will remain the exclusive property of iCalc Inc and its licensors. The
						Service is protected by copyright, trademark, and other laws of both the United States and
						foreign countries. Our trademarks and trade dress may not be used in connection with any product
						or service without the prior written consent of iCalc Inc.
					</p>

					<h5>
						<a name='links-to-other-web-sites'>Links To Other Web Sites</a>
					</h5>
					<p>
						Our Service may contain links to third party web sites or services that are not owned or
						controlled by iCalc Inc
					</p>
					<p>
						iCalc Inc has no control over, and assumes no responsibility for the content, privacy policies,
						or practices of any third party web sites or services. We do not warrant the offerings of any of
						these entities/individuals or their websites.
					</p>
					<p>
						You acknowledge and agree that iCalc Inc shall not be responsible or liable, directly or
						indirectly, for any damage or loss caused or alleged to be caused by or in connection with use
						of or reliance on any such content, goods or services available on or through any such third
						party web sites or services.
					</p>
					<p>
						We strongly advise you to read the terms and conditions and privacy policies of any third party
						web sites or services that you visit.
					</p>

					<h5>
						<a name='termination'>Termination</a>
					</h5>
					<p>
						We may terminate or suspend your account and bar access to the Service immediately, without
						prior notice or liability, under our sole discretion, for any reason whatsoever and without
						limitation, including but not limited to a breach of the Terms.
					</p>
					<p>If you wish to terminate your account, you may simply discontinue using the Service.</p>
					<p>
						All provisions of the Terms which by their nature should survive termination shall survive
						termination, including, without limitation, ownership provisions, warranty disclaimers,
						indemnity and limitations of liability.
					</p>

					<h5>
						<a name='indemnification'>Indemnification</a>
					</h5>
					<p>
						You agree to defend, indemnify and hold harmless iCalc Inc and its licensee and licensors, and
						their employees, contractors, agents, officers and directors, from and against any and all
						claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but
						not limited to attorney's fees), resulting from or arising out of a) your use and access of the
						Service, by you or any person using your account and password; b) a breach of these Terms, or c)
						Content posted on the Service.
					</p>

					<h5>
						<a name='limitation-of-liability'>Limitation Of Liability</a>
					</h5>
					<p>
						In no event shall iCalc Inc, nor its directors, employees, partners, agents, suppliers, or
						affiliates, be liable for any indirect, incidental, special, consequential or punitive damages,
						including without limitation, loss of profits, data, use, goodwill, or other intangible losses,
						resulting from (i) your access to or use of or inability to access or use the Service; (ii) any
						conduct or content of any third party on the Service; (iii) any content obtained from the
						Service; and (iv) unauthorized access, use or alteration of your transmissions or content,
						whether based on warranty, contract, tort (including negligence) or any other legal theory,
						whether or not we have been informed of the possibility of such damage, and even if a remedy set
						forth herein is found to have failed of its essential purpose.
					</p>

					<h5>
						<a name='disclaimer'>Disclaimer</a>
					</h5>
					<p>
						Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS
						AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or
						implied, including, but not limited to, implied warranties of merchantability, fitness for a
						particular purpose, non- infringement or course of performance.
					</p>
					<p>
						iCalc Inc its subsidiaries, affiliates, and its licensors do not warrant that a) the Service
						will function uninterrupted, secure or available at any particular time or location; b) any
						errors or defects will be corrected; c) the Service is free of viruses or other harmful
						components; or d) the results of using the Service will meet your requirements.
					</p>

					<h5>
						<a name='exclusions'>Exclusions</a>
					</h5>
					<p>
						Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or
						limitation of liability for consequential or incidental damages, so the limitations above may
						not apply to you.
					</p>

					<h5>
						<a name='governing-law'>Governing Law</a>
					</h5>
					<p>
						These Terms shall be governed and construed in accordance with the laws of Florida, United
						States, without regard to its conflict of law provisions.
					</p>
					<p>
						Our failure to enforce any right or provision of these Terms will not be considered a waiver of
						those rights. If any provision of these Terms is held to be invalid or unenforceable by a court,
						the remaining provisions of these Terms will remain in effect. These Terms constitute the entire
						agreement between us regarding our Service, and supersede and replace any prior agreements we
						might have had between us regarding the Service.
					</p>

					<h5>
						<a name='changes'>Changes</a>
					</h5>
					<p>
						We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a
						revision is material we will provide at least 30 days notice prior to any new terms taking
						effect. What constitutes a material change will be determined at our sole discretion.
					</p>
					<p>
						By continuing to access or use our Service after any revisions become effective, you agree to be
						bound by the revised terms. If you do not agree to the new terms, you are no longer authorized
						to use the Service.
					</p>

					<h5>
						<a name='contact-us'>Contact Us</a>
					</h5>
					<p>
						If you have any questions about these Terms, please{' '}
						<ReactGA.OutboundLink eventLabel='Mail to support' to='mailto:support@sketchandcalc.com'>
							contact us
						</ReactGA.OutboundLink>
						.
					</p>
				</div>
			</section>
		);
	}
}
