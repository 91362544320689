import React from 'react';
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import CookieNote from '../TopNote/CookiesNote';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { SUBSCRIPTION_PLANS } from '../../shared/constants/plansConstants';
import get from 'lodash/get';

class MainLayout extends React.Component {
	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		const { isEducator, cookiesAccepted, children, activeModal, location } = this.props;
		const educatorHide = !!!activeModal && isEducator && location.pathname==='/';

		return (
			<div className='site-root'>
				{!educatorHide && <Header isEducator={isEducator}/>}
				{!cookiesAccepted && <CookieNote />}
				<main className={`main-content ${educatorHide? 'no-padding' : ''}`}>{children}</main>
				{!educatorHide && <Footer />}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		...ownProps,
		cookiesAccepted: state.settings.cookiesAccepted,
		isEducator: get(state, 'auth.user.subscription.stripe_plan', null) === SUBSCRIPTION_PLANS.educator,
		activeModal: state.modal.activeModal
	};
};

export default withRouter(connect(mapStateToProps, null)(MainLayout));
