import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { notificationReset } from '../../../shared/actions/notificationActions';

let handleTimeout = null;

class Notification extends React.Component {
	render() {
		if (!this.props.notification.display) {
			return null;
		}

		return (
			<div className='notification'>
				<div className={`alert alert-${this.props.notification.type}`}>{this.props.notification.message}</div>
			</div>
		);
	}

	clearTimeout() {
		if (handleTimeout) {
			window.clearTimeout(handleTimeout);
			handleTimeout = null;
		}
	}

	componentDidUpdate() {
		this.clearTimeout();

		if (this.props.notification.display) {
			handleTimeout = window.setTimeout(this.props.notificationReset.bind(this), this.props.notification.delay);
		}
	}

	componentWillUnmount() {
		this.clearTimeout();
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		notification: state.notification
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			notificationReset
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
