import * as types from '../constants/ImageConstants.js';
import store from '../store';
import { modalClose } from '../reducers/ModalReducer';
import { instrumentChange } from '../reducers/instrumentReducer';
import { PAN_MOVE, STRAIGHT_LINE } from '../classes/Instruments';
import { canvasReset, drawAreaSetMinSizes } from './drawAreaActions';
import { zoomSetPanBy } from './zoomActions';
import { mapReset } from './mapActions';
import { scaleReset } from './scaleControlActions';
import { helpActivateScale3 } from './helpActions';
import { notificationShow } from './notificationActions';
import { notificationTypes } from '../constants/notificationConstants';
import ApiHelper from '../ApiHelper';

let startMovePoint = null;

const IMAGE_FOR_ANALYSE_SIZE = {
	width: 1920,
	height: 1080
};

export function imageAdd(image, sizes) {
	return (dispatch, state) => {
		let mapVisible = state().map.visible;

		dispatch(modalClose());
		dispatch(instrumentChange(PAN_MOVE));

		if (mapVisible) {
			dispatch(mapReset());
			dispatch(scaleReset());
		}

		dispatch({
			type: types.IMAGE_ADD,
			payload: { image, sizes }
		});

		dispatch(imageSetRealSizes());
		dispatch(imageSave());
	};
}

export function imageSave() {
	return (dispatch, state) => {
		const file = state().image.file;

		if (file) {
			const formData = new FormData();
			formData.append('file', file);

			dispatch(
				notificationShow(
					'Your actions are limited until the image is uploaded. Waiting...',
					notificationTypes.INFO,
					1000 * 60 * 5
				)
			);
			ApiHelper.upload(formData)
				.then(response => {
					if (response.data.success) {
						dispatch(notificationShow('Image uploaded successfully', notificationTypes.SUCCESS));
						dispatch(imageUpdateUrl(response.data.path));
					}
				})
				.catch(error => {
					let message = 'Unable to upload image, please try again later.';

					if (error.response && error.response.data.message) {
						message = error.response.data.message;
					}

					dispatch(canvasReset());

					dispatch(notificationShow(message, notificationTypes.DANGER));
				});
		}
	};
}

export function imageUpdateUrl(value) {
	return dispatch => {
		dispatch(loadImageData(value));

		dispatch({
			type: types.IMAGE_UPDATE_URL,
			payload: { value }
		});
	};
}

export function imageStartDrag(point) {
	startMovePoint = point;

	return {
		type: types.IMAGE_SET_DRAG,
		payload: {
			dragFlag: true
		}
	};
}

export function imageEndDrag() {
	startMovePoint = null;
	return {
		type: types.IMAGE_SET_DRAG,
		payload: {
			dragFlag: false
		}
	};
}

export function imageMove(point) {
	return dispatch => {
		let imageState = store.getState().image;

		if (!imageState.dragActive) {
			return;
		}

		let imagePosition = imageState.position,
			newPosition = [
				imagePosition[0] + point[0] - startMovePoint[0],
				imagePosition[1] + point[1] - startMovePoint[1]
			];

		startMovePoint = point;

		dispatch({
			type: types.IMAGE_UPDATE_POSITION,
			payload: {
				position: newPosition
			}
		});
	};
}

export function imageSetRealSizes() {
	return dispatch => {
		let state = store.getState(),
			{ originSizes, ratio, position } = state.image,
			stageSizes = state.drawArea.stageSizes,
			stageOffset = 20 * 2,
			{ height, width } = originSizes,
			newPosition = [...position];

		if (width > stageSizes.width) {
			width = stageSizes.width - stageOffset;
			height = width * ratio;
		}

		if (height > stageSizes.height) {
			height = stageSizes.height - stageOffset;
			width = height / ratio;
		}

		newPosition[0] = (stageSizes.width - width) / 2;
		newPosition[1] = (stageSizes.height - height) / 2;

		dispatch({
			type: types.IMAGE_SET_REAL_SIZE,
			payload: { sizes: { width, height }, position: newPosition }
		});
	};
}

export function imageAddToCanvas() {
	return (dispatch, state) => {
		const { image, help, zoom, drawArea } = state();

		if (!help.helpScale3 && help.helpLengthSubmitted) {
			dispatch(helpActivateScale3());
		}

		const pan = { x: 0, y: 0 },
			position = [...image.position],
			stageSizes = drawArea.stageSizes,
			margin = 20;

		if (position[0] < margin) {
			pan.x = -1 * (position[0] < 0 ? Math.abs(position[0]) + margin : margin - position[0]);
			position[0] = margin;
		}

		if (position[1] < margin) {
			pan.y = -1 * (position[1] < 0 ? Math.abs(position[1]) + margin : margin - position[1]);
			position[1] = margin;
		}

		if (position[0] + image.realSizes.width > stageSizes.width - margin) {
			let x = stageSizes.width - margin - image.realSizes.width;
			pan.x = position[0] - x;
			position[0] = x;
		}

		if (position[1] + image.realSizes.height > stageSizes.height - margin) {
			let y = stageSizes.height - margin - image.realSizes.height;
			pan.y = position[1] - y;
			position[1] = y;
		}

		const minSizes = {
			width: position[0] - margin + image.realSizes.width,
			height: position[1] - margin + image.realSizes.height
		};

		pan.x *= zoom.value;
		pan.y *= zoom.value;

		dispatch(instrumentChange(STRAIGHT_LINE));
		dispatch(drawAreaSetMinSizes({ ...minSizes }));
		dispatch({
			type: types.IMAGE_ADD_TO_CANVAS,
			payload: { position }
		});
		dispatch(zoomSetPanBy(pan));
	};
}

export function imageToggleVisibility() {
	return {
		type: types.IMAGE_TOGGLE_VISIBILITY
	};
}

export function imageUpdate(newState) {
	return (dispatch, state) => {
		let oldState = state().image;

		//ToDo: move to separate function and duplicate in load
		if (newState.url !== null && oldState.url !== newState.url) {
			dispatch(loadImageData(newState.url));
		}

		dispatch({
			type: types.IMAGE_UPDATE,
			payload: { state: newState }
		});
	};
}

export function setImageData(data) {
	return {
		type: types.IMAGE_SET_DATA,
		payload: { data }
	};
}

export function imageReset() {
	return {
		type: types.IMAGE_RESET
	};
}

function loadImageData(url) {
	return (dispatch, state) => {
		let img = new Image();

		img.crossOrigin = 'Anonymous';

		img.onload = () => {
			let tempCanvas = document.createElement('canvas'),
				tempCtx = tempCanvas.getContext('2d'),
				sizes = getSizesOfImageData();

			tempCtx.canvas.width = sizes.width;
			tempCtx.canvas.height = sizes.height;
			tempCtx.fillRect(0, 0, 100, 100);
			tempCtx.drawImage(img, 0, 0, sizes.width, sizes.height);

			try {
				let imageData = tempCtx.getImageData(0, 0, sizes.width, sizes.height);
				console.log(tempCtx.canvas.toDataURL());
				imageData.bytes = 4;
				dispatch(setImageData(imageData));
			} catch (e) {}
		};

		img.src = url;
	};
}

function getSizesOfImageData() {
	let state = store.getState(),
		{ originSizes, ratio } = state.image,
		stageSizes = state.drawArea.stageSizes,
		{ height, width } = originSizes;

	if (stageSizes.width >= IMAGE_FOR_ANALYSE_SIZE.width) {
		if (width > stageSizes.width) {
			width = stageSizes.width;
			height = width * ratio;
		}
	} else if (width > IMAGE_FOR_ANALYSE_SIZE.width) {
		width = IMAGE_FOR_ANALYSE_SIZE.width;
		height = width * ratio;
	}

	if (stageSizes.height >= IMAGE_FOR_ANALYSE_SIZE.height) {
		if (height > stageSizes.height) {
			height = stageSizes.height;
			width = Math.ceil(height / ratio);
		}
	} else if (height > IMAGE_FOR_ANALYSE_SIZE.height) {
		height = IMAGE_FOR_ANALYSE_SIZE.height;
		width = Math.ceil(height / ratio);
	}

	return {
		width,
		height
	};
}
