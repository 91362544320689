import isEqual from 'lodash.isequal';
import * as d3 from 'd3-polygon';
import Fraction from 'fraction.js';

export default class MathHelper {
	// Calculate Line length
	static lineLength(startPoint, endPoint) {
		return this.round(
			Math.sqrt(Math.pow(endPoint[0] - startPoint[0], 2) + Math.pow(endPoint[1] - startPoint[1], 2))
		);
	}

	static radians(degrees) {
		return (degrees * Math.PI) / 180;
	}

	static degrees(radians) {
		return (radians * 180) / Math.PI;
	}

	static lineAngle(startPoint, endPoint) {
		let x = endPoint[0] - startPoint[0];
		let y = endPoint[1] - startPoint[1];
		let theta = Math.atan2(y, x);

		return this.degrees(theta);
	}

	static getDegreeSnap(angle, plain) {
		return Math.round(angle / plain) * plain;
	}

	static convertLineToConstrainedLine(startPoint, endPoint) {
		let length = this.lineLength(startPoint, endPoint),
			newEndPoint = endPoint,
			angle;

		if (length > 0) {
			angle = this.lineAngle(startPoint, endPoint);
			angle = this.getDegreeSnap(angle, 15);
			angle = this.radians(angle);
			newEndPoint = [
				this.round(startPoint[0] + Math.cos(angle) * length),
				this.round(startPoint[1] + Math.sin(angle) * length)
			];
		}

		return newEndPoint;
	}

	static getCenterPoint(...points) {
		let Xs = points.map(point => point[0]),
			Ys = points.map(point => point[1]),
			minX = Math.min(...Xs),
			maxX = Math.max(...Xs),
			minY = Math.min(...Ys),
			maxY = Math.max(...Ys);

		return [(maxX - minX) / 2 + minX, (maxY - minY) / 2 + minY];
	}

	static getAnglePosition(firstPoint, secondPoint, thirdPoint, distance) {
		let middlePoint = [(firstPoint[0] + thirdPoint[0]) / 2, (firstPoint[1] + thirdPoint[1]) / 2];
		let lineLengthBetweenPoints = this.lineLength(middlePoint, secondPoint);

		let x = secondPoint[0] - ((secondPoint[0] - middlePoint[0]) * distance) / lineLengthBetweenPoints;
		let y = secondPoint[1] - ((secondPoint[1] - middlePoint[1]) * distance) / lineLengthBetweenPoints;

		return [x, y];
	}

	static getAngleBetweenTwoLines(startLinePoints, endLinePoints) {
		let intersectionPoint = this.lineIntersection(
			startLinePoints[0],
			startLinePoints[1],
			endLinePoints[0],
			endLinePoints[1],
			false,
			false
		);

		if (intersectionPoint === null) {
			return 180;
		}

		return this.getAngleBetweenThreePoints(startLinePoints[0], intersectionPoint, endLinePoints[0]);
	}

	static getAngleBetweenThreePoints(A, B, C) {
		let AB = Math.sqrt(Math.pow(B[0] - A[0], 2) + Math.pow(B[1] - A[1], 2)),
			BC = Math.sqrt(Math.pow(B[0] - C[0], 2) + Math.pow(B[1] - C[1], 2)),
			AC = Math.sqrt(Math.pow(C[0] - A[0], 2) + Math.pow(C[1] - A[1], 2));
		return ((Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB)) * 180) / Math.PI).toFixed();
	}

	// Make angle human friendly
	static prettifyAngle(angle) {
		if (angle > 75 || angle < -90) {
			angle += 180;
		}

		return angle;
	}

	// Calculate Middle point of the Line
	static lineMiddlePoint(startPoint, endPoint) {
		return [(startPoint[0] + endPoint[0]) / 2, (startPoint[1] + endPoint[1]) / 2];
	}

	// Round numbers x.xx
	static round(value) {
		return Math.round(value * 100) / 100;
	}

	static roundLineLength(array, scale, gridSize) {
		return MathHelper.round(MathHelper.round((d3.polygonLength([array[0], array[1]]) * scale) / 2) / gridSize);
	}

	static getPerimeter(array, scale, gridSize) {
		return MathHelper.round(
			array.reduce((sum, item, key, array) => {
				let arrKey = array[key + 1] !== undefined ? key + 1 : 0;
				return sum + this.roundLineLength([item, array[arrKey]], scale, gridSize);
			}, 0)
		);
	}

	// Check if Path intersect
	static isPathIntersect(path) {
		let pathLength = path.length;

		if (pathLength > 3) {
			for (let i = 0; i < pathLength; i++) {
				if (i + 1 < pathLength) {
					let start = path[i],
						end = path[i + 1],
						points = path.slice(i + 1),
						pointsLength = points.length;

					if (pointsLength > 0) {
						for (let y = 0; y < pointsLength; y++) {
							if (y + 1 < pointsLength) {
								let pStart = points[y],
									pEnd = points[y + 1];

								if (
									!(
										isEqual(start, pStart) ||
										isEqual(start, pEnd) ||
										isEqual(end, pStart) ||
										isEqual(end, pEnd)
									)
								) {
									if (this.lineIntersection(start, end, pStart, pEnd)) {
										return true;
									}
								}
							}
						}
					} else {
						return false;
					}
				}
			}
		}

		return false;
	}

	// Check if Line intersect Path
	static isLineIntersectsWithPath(lineStart, lineEnd, path) {
		return this.lineIntersectionsWithPath(lineStart, lineEnd, path).length;
	}

	// Get Line with Path intersections
	static lineIntersectionsWithPath(lineStart, lineEnd, path) {
		let intersections = [];

		for (let index = path.length - 1; index > 1; index--) {
			let intersection = MathHelper.lineIntersection(lineStart, lineEnd, path[index - 1], path[index - 2]);
			if (intersection) {
				intersections.push(intersection);
			}
		}

		return intersections;
	}

	// Get Line with Line intersection
	static lineIntersection(A, B, E, F, ABasSeg = true, EFasSeg = true) {
		let ip,
			a1 = B[1] - A[1],
			b1 = A[0] - B[0],
			c1 = B[0] * A[1] - A[0] * B[1],
			a2 = F[1] - E[1],
			b2 = E[0] - F[0],
			c2 = F[0] * E[1] - E[0] * F[1],
			denom = a1 * b2 - a2 * b1;

		if (denom === 0) {
			return null;
		}

		ip = [(b1 * c2 - b2 * c1) / denom, (a2 * c1 - a1 * c2) / denom];

		if (A[0] === B[0]) ip[0] = A[0];
		else if (E[0] === F[0]) ip[0] = E[0];
		if (A[1] === B[1]) ip[1] = A[1];
		else if (E[1] === F[1]) ip[1] = E[1];

		//	Constrain to segment.

		if (ABasSeg) {
			if (A[0] < B[0] ? ip[0] < A[0] || ip[0] > B[0] : ip[0] > A[0] || ip[0] < B[0]) return null;
			if (A[1] < B[1] ? ip[1] < A[1] || ip[1] > B[1] : ip[1] > A[1] || ip[1] < B[1]) return null;
		}

		if (EFasSeg) {
			if (E[0] < F[0] ? ip[0] < E[0] || ip[0] > F[0] : ip[0] > E[0] || ip[0] < F[0]) return null;
			if (E[1] < F[1] ? ip[1] < E[1] || ip[1] > F[1] : ip[1] > E[1] || ip[1] < F[1]) return null;
		}
		return ip;
	}

	static closedBesierToPolygonPoints(d, threshold) {
		if (!threshold) threshold = 10;
		let doc = document;
		let path = doc.createElementNS('http://www.w3.org/2000/svg', 'path');
		path.setAttribute('d', d);
		const length = path.getTotalLength();
		let t = 0;
		let polyPoints = [];

		while (t + threshold < length) {
			const point = path.getPointAtLength(t);
			polyPoints.push([point.x, point.y]);
			t += threshold;
		}

		return polyPoints;
	}

	static coefficient(measure) {
		if (measure === 'yd') {
			return 3;
		}

		if (measure === 'ft') {
			return 12;
		}

		return 1;
	}

	static convert(value, measure, area = false) {
		let coefficient = MathHelper.coefficient(measure),
			k = area ? Math.pow(coefficient, 2) : coefficient,
			number = parseFloat(value) || 0,
			main = ~~number, // TODO: Math.trunc not work on print
			item = new Fraction(MathHelper.round((number % 1) * k)).toFraction(true);

		if (/^\d+$/.test(item)) {
			let a = ~~(parseInt(item) / k); // TODO: Math.trunc not work on print
			main += a;
			item = parseInt(item) - a * k;
		}

		return `'${main} "${item}`;
	}

	static yardsFeet(value, area = false) {
		return MathHelper.convert(value, 'yd', area);
	}

	static feetInches(value, area = false) {
		return MathHelper.convert(value, 'ft', area);
	}
}
