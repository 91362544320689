import settingsConstants from '../constants/settingsConstants';
import store from '../store';
import CookieHelper from '../CookieHelper';

export function settingsToggleDisplayLineLength() {
	return { type: settingsConstants.SETTINGS_TOGGLE_DISPLAY_LINE_LENGTH };
}

export function settingsSetDisplayAngles(value) {
	return {
		type: settingsConstants.SETTINGS_SET_DISPLAY_ANGLES,
		payload: { value }
	};
}

export function settingsSetGridTransparency(value) {
	return {
		type: settingsConstants.SETTINGS_SET_GRID_TRANSPARENCY,
		payload: { value }
	};
}

export function settingsSetDrawingColor(value) {
	return {
		type: settingsConstants.SETTINGS_SET_DRAWING_COLOR,
		payload: { value }
	};
}

export function settingsSetLineWeight(value) {
	return {
		type: settingsConstants.SETTINGS_SET_LINE_WEIGHT,
		payload: { value }
	};
}

export function settingsSetMeasurementSystem(value) {
	return {
		type: settingsConstants.SETTINGS_SET_MEASUREMENT_SYSTEM,
		payload: { value }
	};
}

export function settingsSetImperial(value) {
	return {
		type: settingsConstants.SETTINGS_SET_IMPERIAL,
		payload: { value }
	};
}

export function settingsSetMarketingCookies(value) {
	const { settings } = store.getState();

	if (settings.cookiesAccepted) {
		settings.marketingCookiesOn ? CookieHelper.enableMarketingCookies() : CookieHelper.disableMarketingCookies();
	}

	return {
		type: settingsConstants.SETTINGS_SET_MARKETING_COOKIES,
		payload: { value }
	};
}

export function settingsAcceptCookies() {
	const { settings } = store.getState();

	return dispatch => {
		CookieHelper.acceptCookies(settings.marketingCookiesOn);
		dispatch({
			type: settingsConstants.ACCEPT_COOKIES
		});
	};
}

export function settingsUpdate(state) {
	return {
		type: settingsConstants.SETTINGS_UPDATE,
		payload: { state }
	};
}
