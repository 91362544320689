import React from 'react';
import bindActionCreators from 'redux/es/bindActionCreators';
import { connect } from 'react-redux';
import {
	sketchSetMagicWandTolerance,
	sketchSetMagicWandBlur,
	sketchResetCurrentMask,
	sketchConfirmCurrentMask,
	sketchSetMagicWandCompoundShapes
} from '../../../shared/actions/SketchActions';
import { modalOpen } from '../../../shared/reducers/ModalReducer';
import { MODAL_CONFIRM_WAND } from '../../ModalContainer/Modals/ModalConfirmWand/ModalConfirmWand';
import _ from 'lodash';

class MagicWandPopUp extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			sketch: props.sketch,
			tolerance: 15,
			blur: 0
		};
	}

	handleToleranceChange = e => this.setState({ tolerance: e.target.value });
	handleToleranceMouseUp = _.debounce(this.props.sketchSetMagicWandTolerance, 300);

	handleBlurChange = e => this.setState({ blur: e.target.value });
	handleBlurMouseUp = _.debounce(this.props.sketchSetMagicWandBlur, 300);

	componentDidUpdate() {
		if (this.props.sketch.mask && this.state.sketch !== this.props.sketch) {
			this.setState({
				sketch: this.props.sketch,
				tolerance: this.props.sketch.mask.tolerance,
				blur: this.props.sketch.mask.blur
			});
		}
	}

	render() {
		let mask = this.props.sketch.mask;

		if (!mask) {
			return null;
		}

		return (
			<div className='magicwand-popup'>
				<div className='icon icon-annotate_delete' onClick={this.props.sketchResetCurrentMask} />
				tolerance:
				<input
					id='range'
					type='range'
					className='range'
					step='1'
					value={this.state.tolerance}
					min='0'
					max='255'
					onChange={e => this.handleToleranceChange(e)}
					onMouseUp={() => this.handleToleranceMouseUp(this.state.tolerance)}
				/>
				<span>{this.state.tolerance}</span>
				<span id='divider' />
				outline:
				<input
					id='range'
					type='range'
					className='range'
					step='1'
					value={this.state.blur}
					min='0'
					max='100'
					onChange={e => this.handleBlurChange(e)}
					onMouseUp={() => this.handleBlurMouseUp(this.state.blur)}
				/>
				<span>{this.state.blur}</span>
				<span id='divider' />
				<div className='checkbox-custom'>
					compound shapes:
					<input
						type='checkbox'
						className='checkbox-field'
						onClick={event => {
							this.props.sketchSetMagicWandCompoundShapes(event.target.checked);
						}}
						checked={mask.getCompound()}
					/>
					<span className='flag' />
				</div>
				<div
					className='icon icon-checkmark'
					onClick={() => {
						if (mask.getCompound() && mask.getResultPolygonsCount() > 1) {
							this.props.modalOpen(MODAL_CONFIRM_WAND);
						} else {
							this.props.sketchConfirmCurrentMask();
						}
					}}
				/>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		sketch: state.sketchState.sketch
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			sketchSetMagicWandBlur,
			sketchSetMagicWandTolerance,
			sketchResetCurrentMask,
			sketchConfirmCurrentMask,
			sketchSetMagicWandCompoundShapes,
			modalOpen
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(MagicWandPopUp);
