import React from 'react';

const Art2 = ({color="#fff"}) => {

    return (
        <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 200 200">
            <path className="cls-1"
                  style={{ fill: color }}
                  d="M52.44,83l.42,4.86.11,0a12.08,12.08,0,0,1,4.42-4.15,12.83,12.83,0,0,1,6.25-1.45,11.73,11.73,0,0,1,6.15,1.52,9.56,9.56,0,0,1,3.86,4.75,12.82,12.82,0,0,1,4.51-4.67,12.6,12.6,0,0,1,6.48-1.6q5.67,0,8.81,3.75t3.13,11.86v23.21H84.64V97.87c0-2.51-.37-4.22-1.11-5.11a4.27,4.27,0,0,0-3.47-1.34A7.23,7.23,0,0,0,77,92a6.14,6.14,0,0,0-2.22,1.73q0,.6,0,1c0,.28,0,.57,0,.88v25.43H62.94V97.87c0-2.51-.37-4.22-1.11-5.11a4.31,4.31,0,0,0-3.51-1.34,6.89,6.89,0,0,0-2.94.6,6.57,6.57,0,0,0-2.24,1.73v27.33H41.24V83Z"/>
            <path className="cls-1"
                  style={{ fill: color }}
                  d="M114.58,83l.43,4.86.1,0a12.16,12.16,0,0,1,4.42-4.15,12.87,12.87,0,0,1,6.26-1.45,11.73,11.73,0,0,1,6.15,1.52,9.6,9.6,0,0,1,3.85,4.75,12.82,12.82,0,0,1,4.51-4.67,12.6,12.6,0,0,1,6.48-1.6q5.68,0,8.81,3.75t3.14,11.86v23.21H146.78V97.87c0-2.51-.37-4.22-1.11-5.11a4.24,4.24,0,0,0-3.46-1.34,7.28,7.28,0,0,0-3.07.6,6.14,6.14,0,0,0-2.22,1.73,10,10,0,0,0,0,1c0,.28,0,.57,0,.88v25.43H125.08V97.87c0-2.51-.37-4.22-1.11-5.11a4.29,4.29,0,0,0-3.5-1.34,6.83,6.83,0,0,0-2.94.6,6.47,6.47,0,0,0-2.24,1.73v27.33H103.38V83Z"/>
            <rect className="cls-1" style={{ fill: color }} width="28.5" height="28.5"/>
        </svg>
    )
}

export default Art2