import * as historyActions from '../constants/historyConstants';
import isEqual from 'lodash.isequal';

const initState = {
	currentPosition: 0,
	history: [{}]
};

export default function historyReducer(state = initState, action) {
	switch (action.type) {
		case historyActions.HISTORY_UPDATE_POSITION: {
			return { ...state, currentPosition: action.payload.position };
		}
		case historyActions.HISTORY_UPDATE: {
			return { ...action.payload.history };
		}
		case historyActions.HISTORY_PUSH: {
			if (isEqual(state.history[state.currentPosition], action.payload.item)) {
				return state;
			}

			let history = [...state.history.slice(0, state.currentPosition + 1), action.payload.item];
			const startIndex = history.length < 20 ? 0 : history.length - 20;
			history = history.slice(startIndex, history.length);

			return {
				history,
				currentPosition: history.length - 1
			};
		}
		case historyActions.HISTORY_RESET: {
			return { ...initState };
		}
		default: {
			return state;
		}
	}
}
