import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEqual from 'lodash.isequal';
import Slider from 'rc-slider/lib/Slider';

import {
	settingsSetDisplayAngles,
	settingsToggleDisplayLineLength,
	settingsSetDrawingColor,
	settingsSetGridTransparency,
	settingsSetLineWeight,
	settingsSetMeasurementSystem,
	settingsSetImperial
} from '../../../../shared/actions/settingsActions';
import { scaleSetMeasure, measuresChanged } from '../../../../shared/actions/scaleControlActions';
import { drawingColorValues, measurementSystemValues } from '../../../../shared/constants/settingsConstants';
import ApiHelper from '../../../../shared/ApiHelper';

const transformData = (obj, value) => {
	if (typeof value === 'number') {
		for (const [key, val] of Object.entries(obj)) {
			if (val === value) return key;
		}
	}

	return obj[value];
};

class ApplicationSettings extends React.Component {
	componentDidUpdate(prevProps) {
		const settings = this.props.settings;

		if (!isEqual(prevProps.settings, settings)) {
			ApiHelper.updateSettings({ settings });
		}

		if (settings.measurementSystem !== prevProps.settings.measurementSystem) {
			switch (settings.measurementSystem) {
				case measurementSystemValues.MEASUREMENT_SYSTEM_IMPERIAL: {
					this.props.measuresChanged('mi');
					window.defaults.measure = 'ft';
					break;
				}
				default: {
					this.props.measuresChanged('km');
					window.defaults.measure = 'cm';
				}
			}
		}
	}

	mapping = {
		labels: {
			true: 'display',
			false: 'hide'
		},
		line: {
			1: 'light',
			2: 'regular',
			3: 'medium',
			4: 'heavy'
		},
		grid: {
			Full: 'full',
			'25%': '25%',
			'50%': '50%',
			'75%': '75%'
		},
		angles: {
			Off: 'off',
			small: 'small',
			med: 'medium',
			large: 'large'
		},
		system: {
			Metric: 'cm',
			Imperial: 'ft'
		},
		imperial: {
			decimals: 'decimals',
			fractions: 'fractions'
		}
	};

	transformGridData = gridValue => transformData({ Full: 1, '75%': 2, '50%': 3, '25%': 4 }, gridValue);

	transformAnglesData = angleValue => transformData({ Off: 1, small: 2, med: 3, large: 4 }, angleValue);

	transformSystemData = systemValue => transformData({ true: 'Metric', false: 'Imperial' }, systemValue);

	transformImperialData = imperialValue => transformData({ true: 'decimals', false: 'fractions' }, imperialValue);

	handleChangeImperial = event => {
		this.props.settingsSetImperial(this.transformImperialData(event.target.checked));
	};

	handleChangeSystem = event => {
		this.props.settingsSetMeasurementSystem(this.transformSystemData(event.target.checked));
	};

	isImperialToggleDisabled = () => {
		const mapping = {
			nm: 'nm',
			'μm': 'μm',
			mm: 'mm',
			cm: 'cm',
			m: 'm',
			km: 'km'
		};

		return Boolean(mapping[this.props.measure]);
	};

	render() {
		return (
			<div className='application-settings'>
				<ul className='items'>
					<li className='item'>
						<span className='label'>Labels</span>
						<div className='fields'>
							<span className='state'>{this.mapping.labels[this.props.settings.displayLineLength]}</span>
							<label className='switch'>
								<input
									type='checkbox'
									onChange={this.props.settingsToggleDisplayLineLength.bind(this)}
									defaultChecked={this.props.settings.displayLineLength}
								/>
								<span className='slider' />
							</label>
						</div>
					</li>
					<li className='item line-weight'>
						<span className='label'>Line</span>
						<div className='fields'>
							<span className='state'>{this.mapping.line[this.props.settings.lineWeight]}</span>
							<Slider
								min={1}
								max={4}
								step={1}
								defaultValue={this.props.settings.lineWeight}
								onChange={this.props.settingsSetLineWeight}
							/>
						</div>
					</li>
					<li className='item line-weight'>
						<span className='label'>Grid</span>
						<div className='fields'>
							<span className='state'>{this.mapping.grid[this.props.settings.gridTransparency]}</span>
							<Slider
								min={1}
								max={4}
								step={1}
								defaultValue={this.transformGridData(this.props.settings.gridTransparency)}
								onChange={event =>
									this.props.settingsSetGridTransparency(this.transformGridData(event))
								}
							/>
						</div>
					</li>
					<li className='item line-weight'>
						<span className='label'>Angles</span>
						<div className='fields'>
							<span className='state'>{this.mapping.angles[this.props.settings.displayAngles]}</span>
							<Slider
								min={1}
								max={4}
								step={1}
								defaultValue={this.transformAnglesData(this.props.settings.displayAngles)}
								onChange={event => this.props.settingsSetDisplayAngles(this.transformAnglesData(event))}
							/>
						</div>
					</li>
					<li className='item '>
						<span className='label'>Default</span>
						<div className='fields'>
							<span className='state'>{this.mapping.system[this.props.settings.measurementSystem]}</span>
							<label className='switch'>
								<input
									type='checkbox'
									onChange={this.handleChangeSystem}
									defaultChecked={this.props.settings.measurementSystem === 'Metric'}
								/>
								<span className='slider' />
							</label>
						</div>
					</li>
					<li className='item'>
						<span className='label'>Imperial</span>
						<div className='fields'>
							<span className='state'>{this.mapping.imperial[this.props.settings.imperial]}</span>
							<label className='switch'>
								<input
									type='checkbox'
									onChange={this.handleChangeImperial}
									defaultChecked={this.props.settings.imperial === 'decimals'}
									disabled={this.isImperialToggleDisabled()}
								/>
								<span className='slider' />
							</label>
						</div>
					</li>
					<li className='item'>
						<span className='label'>Color</span>
						<div className='fields'>
							<span className='state' />
							<div className='colors'>
								{Object.values(drawingColorValues).map((color, index) => {
									return (
										<label key={index} className={`color ${color}`}>
											<input
												name='drawingColor'
												type='radio'
												checked={this.props.settings.drawingColor === color}
												onChange={() => {
													this.props.settingsSetDrawingColor(color);
												}}
											/>
											<span className='slider' />
										</label>
									);
								})}
							</div>
						</div>
					</li>
				</ul>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		settings: state.settings,
		measure: state.scale.measure
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			settingsToggleDisplayLineLength,
			settingsSetDisplayAngles,
			settingsSetGridTransparency,
			settingsSetDrawingColor,
			settingsSetLineWeight,
			settingsSetMeasurementSystem,
			measuresChanged,
			settingsSetImperial,
			scaleSetMeasure
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationSettings);
