import React from 'react';

export const MODAL_LOADER = 'MODAL_LOADER';

export default class ModalLoader extends React.Component {
	handleInterval = null;

	constructor(props) {
		super(props);

		this.state = {
			step: 1,
			minStep: 1,
			maxStep: 9
		};
	}

	handleStep() {
		if (this.state.step < this.state.maxStep) {
			this.setState({ step: this.state.step + 1 });
		} else {
			this.setState({ step: this.state.minStep });
		}
	}

	componentDidMount() {
		this.handleInterval = window.setInterval(this.handleStep.bind(this), 500);
	}

	componentWillUnmount() {
		window.clearInterval(this.handleInterval);
	}

	render() {
		return <div className={`modal-loader step-${this.state.step}`} />;
	}
}
