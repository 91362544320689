export const NOTE_ADD = 'NOTE_ADD';
export const NOTE_UPDATE = 'NOTE_UPDATE';
export const NOTE_UPDATE_LIST = 'NOTE_UPDATE_LIST';
export const NOTE_DELETE = 'NOTE_DELETE';
export const NOTE_ADJUST_ZOOM = 'NOTE_ADJUST_ZOOM';
export const NOTE_START_MOVE = 'NOTE_START_MOVE';
export const NOTE_MOVE = 'NOTE_MOVE';
export const NOTE_END_MOVE = 'NOTE_END_MOVE';
export const NOTE_OVER = 'NOTE_OVER';
export const NOTE_MOUSE_OUT = 'NOTE_MOUSE_OUT';
export const NOTE_START_EDIT = 'NOTE_START_EDIT';
export const NOTE_END_EDIT = 'NOTE_END_EDIT';
export const NOTE_RESET = 'NOTE_RESET';
