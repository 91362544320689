import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { noteEndEdit, noteUpdateText } from '../../../shared/actions/NoteActions';

class NotesComponent extends React.Component {
	target = [];

	render() {
		if (!this.props.notes.length && !this.props.layers.showAllLayers) {
			return null;
		}

		return (
			<div className='notes-container'>
				{!this.props.layers.showAllLayers && this.renderNotes()}
				{this.props.layers.showAllLayers && this.renderLayerNotes()}
			</div>
		);
	}

	componentDidUpdate() {
		if (this.target[this.props.editableNote]) {
			this.target[this.props.editableNote].focus();
		}
	}

	renderLayerNotes() {
		const notes = [];

		this.props.layers.list.forEach(layer => {
			if (layer.isVisible() && layer.sketch.closed) {
				notes.push(...layer.getNotes());
			}
		});

		return notes.map((note, index) => {
			let textPosition = note.getTextPosition(this.props.offset);

			return (
				<div
					className='note text'
					key={index}
					style={{
						height: note.height,
						width: note.width,
						left: textPosition.x + 'px',
						top: textPosition.y + 'px'
					}}>
					<textarea autoCorrect='false' spellCheck='false' value={note.text} disabled={true} />
				</div>
			);
		});
	}

	renderNotes() {
		return this.props.notes.map((note, index) => {
			let isEditableNote = index === this.props.editableNote,
				textPosition = note.getTextPosition(this.props.offset);
			const classNames = ['note'];

			if (isEditableNote) {
				classNames.push('editable');
			}
			if (this.props.hoveredNote === index) {
				classNames.push('over');
			}

			return (
				<div
					className={classNames.join(' ')}
					key={index}
					style={{
						height: note.height,
						width: note.width,
						left: textPosition.x + 'px',
						top: textPosition.y + 'px'
					}}>
					<textarea
						autoCorrect='false'
						spellCheck='false'
						value={note.text}
						disabled={!isEditableNote}
						onChange={event => this.props.noteUpdateText(event, index)}
						onKeyDown={this.checkEditEnd.bind(this)}
						onKeyUp={this.checkEscape.bind(this)}
						ref={element => {
							this.target[index] = element;
						}}
					/>
				</div>
			);
		});
	}

	checkEscape(event) {
		const keyCode = event.keyCode || event.which;

		if (keyCode === 27) {
			event.preventDefault();
			this.props.noteEndEdit();
		}
	}

	checkEditEnd(event) {
		const keyCode = event.keyCode || event.which;

		if (keyCode === 13) {
			event.preventDefault();
			this.props.noteEndEdit();
		}
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		editableNote: state.notes.editableNote,
		notes: state.notes.list,
		offset: state.offset,
		hoveredNote: state.notes.hoveredNote,
		layers: state.layers
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ noteUpdateText, noteEndEdit }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesComponent);
