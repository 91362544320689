import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { helpActivateResults } from '../../../shared/actions/helpActions';
import { sketchResultEl } from '../../App/SketchResults/SketchResults';

export let renderResults;

class Results extends React.Component {
	componentWillMount() {
		renderResults = this.forceRender.bind(this);
	}

	componentWillUnmount() {
		renderResults = null;
	}

	forceRender() {
		this.forceUpdate();
	}

	render() {
		if (!sketchResultEl) {
			return null;
		}

		const bounds = sketchResultEl.getBoundingClientRect(),
			style = {
				bottom: bounds.bottom - bounds.top + 20,
				left: bounds.left + bounds.width / 2 - 135
			};

		return (
			<div style={style} className='contextual-modal arrow-bottom'>
				<h3 className='title'>Area & Perimeter Results</h3>
				<div className='content'>
					Area <span className='icon icon-results_area' /> and Perimeter{' '}
					<span className='icon icon-results_perim' />
					<br />
					results are displayed alongside
					<br />
					controls to create new drawing
					<br />
					layers <span className='icon icon-add_new_layer' /> and sum{' '}
					<span className='icon icon-sum_all_layers' /> the areas
					<br />
					of all drawing layers together
				</div>
				<div className='actions'>
					<button
						onClick={() => {
							this.props.helpActivateResults();
						}}
						className='btn btn-sm btn-modal'>
						ok
					</button>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		stageSizes: state.drawArea.stageSizes
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			helpActivateResults
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Results);
