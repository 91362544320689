import React from 'react';
import UserRegister from '../UserRegister';
import TextView from '../../components/Util/TextView';
import Dropdown from '../../components/Util/Dropdown/Dropdown';
import { countryList } from '../../shared/constants/plansConstants';

import './EducatorRegister.scss';


class EducatorRegister extends React.Component {
    getInputProps = fieldName => {
        const { showErrors, handleFieldChanged, validationErrors } = this.props;

        return {
            showError: showErrors,
            errorText: validationErrors[fieldName],
            text: this.props[fieldName],
            onFieldChanged: handleFieldChanged(fieldName)
        };
    }

    render () {
        const { handleFieldChanged, country, validationErrors, address, showErrors, withUserFields=true } = this.props;

        return (
                <div className={'educator-register'}>
                    {withUserFields && <UserRegister { ...this.props }/>}
                    <div className='form-block mb-4'>
                        <div className='form-group'>
                            <TextView
                                {...this.getInputProps('nameOfSchool')}
                                className={'form-input'}
                                placeholder='Name of school'
                                autoComplete='off'
                                type='text'
                            />
                        </div>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <TextView
                                        {...this.getInputProps('city')}
                                        className={'form-input'}
                                        placeholder='City'
                                    />
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <Dropdown
                                        value={country}
                                        options={countryList}
                                        label={'Country'}
                                        onChange={handleFieldChanged('country')}
                                        errorText={showErrors && validationErrors['country']}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <TextView
                                {...this.getInputProps('phoneNumber')}
                                className={'form-input'}
                                placeholder='Telephone number (+001234567890)'
                            />
                        </div>
                        <div className='form-group'>
                            <TextView
                                {...this.getInputProps('usage')}
                                className={'form-input'}
                                rows={4}
                                placeholder='What subject/s do you teach, and how will you use SketchAndCalc?'
                                type='textarea'
                            />
                        </div>
                    </div>
                    <div className={'text-center'}>
                        Add our badge to your school website so we can verify it's you.
                        <br/>
                        Nominate a web page where you'll place our badge and we'll
                        <br/>
                        email you the code and instructions
                    </div>
                    <div className='location-block mt-4'>
                        <div className={'prefix'}>
                            https://
                        </div>
                        <TextView
                            onFieldChanged={handleFieldChanged('address')}
                            showError={showErrors}
                            errorText={validationErrors['address']}
                            text={address}
                            className={'form-input'}
                            placeholder='www.school-homepage.edu/page1'
                            type='text'
                        />
                    </div>
                    <div className={'badge-section'}>
                        <img
                            src={window.location.origin + '/static/media/Grey33.png'}
                            alt={'Badge example'}
                        />
                        <div className={'subtitle'}>Example badge</div>
                    </div>
                </div>
        );
    }
}

export default EducatorRegister;