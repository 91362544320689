import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import TextView from '../../../Util/TextView';
import TextAreaView from '../../../Util/TextAreaView';
import { ruleRunner, run } from '../../../Validation/ruleRunner';
import { isValidEmail, required } from '../../../Validation/rules';
import ApiHelper from '../../../../shared/ApiHelper';
import { modalClose } from '../../../../shared/reducers/ModalReducer';
import { notificationShow } from '../../../../shared/actions/notificationActions';
import { notificationTypes } from '../../../../shared/constants/notificationConstants';

export const MODAL_LISTENING = 'MODAL_LISTENING';

const fieldValidations = [
	ruleRunner('email', 'Email Address', required, isValidEmail),
	ruleRunner('message', 'Message', required)
];

class ModalListening extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			message: '',
			disabled: false,
			showErrors: false,
			validationErrors: {}
		};
	}

	componentWillMount() {
		this.setState({ validationErrors: run(this.state, fieldValidations) });
	}

	errorFor(field) {
		return this.state.validationErrors[field] || '';
	}

	handleFieldChanged(field) {
		return e => {
			let newState = update(this.state, {
				[field]: { $set: e.target.value }
			});

			newState.validationErrors = run(newState, fieldValidations);
			this.setState(newState);
		};
	}

	handleSubmit() {
		this.setState({ showErrors: true });

		if (Object.keys(this.state.validationErrors).length) return;

		const data = {
			email: this.state.email.trim(),
			message: this.state.message.trim()
		};

		this.setState({
			showErrors: false,
			disabled: true
		});

		ApiHelper.listening(data)
			.then(response => {
				this.props.modalClose();

				if (response.data.success) {
					this.props.notificationShow('Thank you for your feedback!', notificationTypes.SUCCESS, 3000);
				} else {
					this.props.notificationShow(
						'There was an error sending your feedback. Please try again.',
						notificationTypes.WARNING,
						3000
					);
				}
			})
			.catch(error => {
				const state = {
					disabled: false
				};
				if (error.response.data.message) {
					state.showErrors = true;
					state.validationErrors = {
						email: error.response.data.message
					};
				}

				this.setState(state);
			});
	}

	render() {
		return (
			<div className='modal-listening'>
				<h2 className='text-center'>Feedback</h2>
				<p className='description'>We're listening</p>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col'>
							<TextView
								placeholder='Email'
								showError={this.state.showErrors}
								text={this.state.email}
								onFieldChanged={this.handleFieldChanged('email')}
								errorText={this.errorFor('email')}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='col'>
							<TextAreaView
								placeholder='How can we improve?'
								showError={this.state.showErrors}
								text={this.state.message}
								onFieldChanged={this.handleFieldChanged('message')}
								errorText={this.errorFor('message')}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='col'>
							<button
								onClick={this.handleSubmit.bind(this)}
								className='btn btn-primary'
								disabled={this.state.disabled}>
								Send
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			modalClose,
			notificationShow
		},
		dispatch
	);
}
export default connect(null, mapDispatchToProps)(ModalListening);
