import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {SUBSCRIPTION_PLANS_SETTINGS} from '../../../../../shared/constants/plansConstants';
import { modalClose } from '../../../../../shared/reducers/ModalReducer';

class AccountPaymentField extends React.Component {
	isUnSubscribe() {
		return this.props.subscription && this.props.subscription.unsubscribe;
	}

	render() {
		const { subscription } = this.props;
		const subscriptionPlanName = SUBSCRIPTION_PLANS_SETTINGS[subscription.stripe_plan] || subscription.stripe_plan;
		const subscriptionEndTime = subscription.stripe_plan === 'PayPerDay'
			? 'Active Plan'
			: moment(subscription.ends_at).format('l');
		let button = null;

		if (this.isUnSubscribe()) {
			button = (
				<a className='btn btn-modal' target='_blank' href="/subscription">
					Visit Subscription Page
				</a>
			);
		} else {
			button = (
				<button onClick={this.props.onClick} className='btn btn-modal'>
					update payment method
				</button>
			);
		}

		return (
			<div className='field'>
				<span className='subscription-end'>{subscriptionPlanName}, {subscriptionEndTime}</span>
				{button}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			modalClose
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(AccountPaymentField);
