import * as React from 'react';
import { connect } from 'react-redux';
import LayerLabels from '../LayerLabels/LayerLabels';

class CanvasLayers extends React.Component {
	render() {
		const layers = this.props.layers.filter(layer => {
				return layer.isVisible();
			}),
			zoom = this.props.zoomValue,
			strokeWidth = this.props.strokeWidth / zoom;

		return (
			<g className='all-layers'>
				<g className='sketch'>
					{layers.map((layer, index) => {
						const isClosed = layer.getSketch().closed;
						const classNames = [];

						if (layer.getColor()) {
							classNames.push(layer.getColor());
						}

						if (!isClosed) {
							classNames.push('open-shape');
						} else {
							classNames.push('closed');
						}

						return (
							<path
								d={layer.getSketch().toPath(isClosed)}
								key={index}
								className={classNames.join(' ')}
								strokeWidth={strokeWidth}
							/>
						);
					})}
					{this.props.showSumLabels &&
						<LayerLabels
							layers={layers}
							scale={this.props.scale}
							zoom={zoom}
							measure={this.props.measure}
							key={this.props.fileName}
						/>
					}
				</g>
			</g>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		layers: state.layers.list,
		zoomValue: state.zoom.value,
		scale: state.scale.value,
		measure: state.scale.measure,
		strokeWidth: state.settings.lineWeight,
		isFractions: state.settings.imperial === 'fractions',
		showSumLabels: state.label.showSumLabels,
		fileName: state.fileName.fileName
	};
}

export default connect(mapStateToProps)(CanvasLayers);
