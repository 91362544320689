export default class LineInterface {
	toPath(first = false) {}

	getType() {}

	getPoints() {}

	getLatLngPoints() {}

	updatePoint(pointIndex, newPoint) {}

	updateLatLngPoint(latLngIndex, latLng) {}

	getLength() {}

	getMiddlePoint() {}

	getLabelAngle() {}

	isValidLineIfEndAt(endPoint, zoomValue) {}
}
