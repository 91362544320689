import React from 'react';
import cookie from 'js-cookie';

import { authLogin, loadUserData } from '../../../shared/actions/authActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import TextView from '../../Util/TextView.js';
import update from 'immutability-helper';
import { run, ruleRunner } from '../../Validation/ruleRunner.js';
import { required, isValidEmail } from '../../Validation/rules.js';
import { Link } from 'react-router-dom';
import ApiHelper from '../../../shared/ApiHelper';
import DocumentMeta from 'react-document-meta';
import { mobileDetect } from '../../../shared/store';

import { ppdIgnoreWelcome } from '../../../shared/reducers/ppdReducer';
import { shortHistory } from '../../../Routes';
import Loader from '../../Loader/Loader';
import { authTypes } from '../../../shared/constants/authConstants';
import Switch from '../../inputs/Switch';
import { resendBadgeEmail } from '../../../shared/actions/educatorActions';
import AuthPolicyWarn from '../../App/AuthPolicyWarn';
import Eye from '../../icons/Eye';

const fieldValidationsUser = [
	ruleRunner('email', 'Email Address', required, isValidEmail),
	ruleRunner('password', 'Password', required)
];

const fieldValidationsSession = [
	ruleRunner('session', 'Session', required),
	ruleRunner('password', 'Password', required)
];

class LogInPage extends React.Component {
	handleTimeout = null;
	rememberMeEl = null;
	forgotEl = null;

	constructor(props) {
		super(props);
		this.handleFieldChanged = this.handleFieldChanged.bind(this);
		this.errorFor = this.errorFor.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			session:'',
			errorReason: null,
			showErrors: false,
			validationErrors: {},
			globalError: false,
			globalSuccess: false,
			isLoggedIn: false,
			loading: false,
			authType: authTypes.user,
			showPassword: false,
			authVars: {
				title: 'Log in',
				registerTitle: 'User Account',
				loginPlaceholder: 'Email',
				loginName: 'email',
				validators: fieldValidationsUser
			},
			loginBtn: {
				text: 'Log in',
				classNames: 'primary-btn',
				onClick: this.handleSubmit
			}
		};
	}

	switchAuthType = () => {
		const { authType } = this.state;

		const common = {
			globalError: false,
			globalSuccess: false,
			email:null,
			password:null,
			session:null,
		}

		if (authType === authTypes.user) {
			this.setState({
				...common,
				authType: authTypes.student,
				authVars: {
					title: 'Student Log in',
					registerTitle: 'Educator Account',
					loginPlaceholder: 'Session',
					loginName: 'session',
					validators: fieldValidationsSession,
					autoComplete: 'new-password'
				},
				validationErrors: run(this.state, fieldValidationsSession)
			})
		} else {
			this.setState({
				...common,
				authType: authTypes.user,
				authVars: {
					title: 'Log in',
					registerTitle: 'User Account',
					loginPlaceholder: 'Email',
					loginName: 'email',
					validators: fieldValidationsUser,
					autoComplete: 'on'
				},
				validationErrors: run(this.state, fieldValidationsUser)
			})
		}
	}

	resetGlobalError() {
		this.setState({ globalError: false });
	}

	clearTimeout() {
		if (this.handleTimeout) {
			window.clearTimeout(this.handleTimeout);
			this.handleTimeout = null;
		}
	}

	componentWillMount() {
		if (this.props.isAuthenticated) {
			if (shortHistory.prevPage) {
				let path = shortHistory.prevPage.split('/'),
					last = path[path.length - 1];

				if (['help', 'blog'].indexOf(last) !== -1) {
					this.props.ppdIgnoreWelcome(true);
				}
			}

			this.props.history.push('/');
		}

		if (mobileDetect.mobile()) {
			this.props.history.push('/download');
		}

		this.setState({ validationErrors: run(this.state, this.state.authVars.validators) });
	}

	errorFor(field) {
		return this.state.validationErrors[field] || '';
	}

	handleFieldChanged(field) {
		return e => {
			let newState = update(this.state, {
				[field]: { $set: e.target.value }
			});
			newState.globalSuccess = false;
			newState.validationErrors = run(newState, this.state.authVars.validators);
			this.setState(newState);
		};
	}

	handleSubmit = async (event, forceUpdate = false) => {
		event.preventDefault();
		this.resetGlobalError()
		const { authVars, validationErrors, authType } = this.state;

		if (this.forgotEl && this.forgotEl.checked) {
			if (this.state.validationErrors.email) {
				this.setState({
					showErrors: true,
					globalError: false,
					globalSuccess: false,
					validationErrors: {
						email: this.state.validationErrors.email
					}
				});
			} else {
				const email = this.state.email.trim();

				ApiHelper.resetPassword({ email })
					.then(response => {
						if (response.data.success) {
							this.clearTimeout();
							this.setState({
								showErrors: false,
								globalError: false,
								globalSuccess: 'A new password has been sent to your email address.'
							});
							this.forgotEl.checked = false;
						} else if (response.data.message) {
							this.clearTimeout();
							this.setState({
								globalError: response.data.message,
								globalSuccess: false
							});
							this.handleTimeout = window.setTimeout(this.resetGlobalError.bind(this), 2000);
						}
					})
					.catch(error => {
						if (error.response.data.message) {
							this.clearTimeout();
							this.setState({
								globalError: error.response.data.message,
								globalSuccess: false
							});
							this.handleTimeout = window.setTimeout(this.resetGlobalError.bind(this), 2000);
						}
					});
			}

			return;
		}

		this.setState({ showErrors: true });

		if (Object.keys(validationErrors).length) return;

		this.setState({ showErrors: false });

		const credentials = {
			[authVars.loginName]: this.state[authVars.loginName].trim(),
			password: this.state.password.trim()
		};

		this.setState({ loading: true });
		try {
			const response = authType === authTypes.user ?
				await ApiHelper.login(credentials, forceUpdate)
				:
				await ApiHelper.sessionLogin(credentials)

			if (response.data.success) {
				const { accessToken } = response.data
				if (this.rememberMeEl && this.rememberMeEl.checked) {
					cookie.set('accessToken', accessToken, {
						expires: 365
					});
				} else {
					cookie.set('accessToken', accessToken, {
						expires: 1
					});
				}

				ApiHelper.updateAccessToken(accessToken);
				this.props.authLogin();
				this.props.loadUserData();
				window.sessionStorage.removeItem('shouldShowMessage');
				this.props.history.push('/');
			}
		} catch (error) {
			if (!error.response.data.success) {
				this.clearTimeout();
				let loginBtn = {
					text: 'Log in',
					classNames: 'primary-btn',
					onClick: this.handleSubmit
				}
				switch (error.response.data.reason) {
					case 'failed review':
						loginBtn = {
							text: 'Re-Register Account',
							classNames: 'error-btn',
							onClick: this.reRegister
						}
						break;
					case 'failed badge':
						loginBtn = {
							text: 'Resend Verification Badge',
							classNames: 'error-btn',
							onClick: this.resendBadge
						}
						break;
				}
				this.setState({
					loginBtn,
					globalError: error.response.data.message,
					isLoggedIn: error.response.data.isLoggedIn,
					globalSuccess: false
				});
			}
		} finally {
			this.setState({ loading: false });
		}
	}

	reRegister = async (e) => {
		e.preventDefault()
		this.resetGlobalError()
		this.props.history.push(`/register?type=${authTypes.educator}`)
	}

	resendBadge = async (e) => {
		e.preventDefault()
		this.setState({loading: true})
		this.resetGlobalError()
		const { resendBadgeEmail } = this.props;
		const { password, email } = this.state;
		const credentials = {
			email: email.trim(),
			password: password.trim()
		};
		try {
			await resendBadgeEmail({...credentials})
			this.setState({
				globalSuccess: 'We will be sending you an email shortly',
				loginBtn: {
					text: 'Log in',
					classNames: 'primary-btn',
					onClick: this.handleSubmit
				}
			})
		} catch (e) {
			this.setState({
				globalSuccess: false
			})
		}
		finally {
			this.setState({loading: false})
		}
	}

	togglePassword = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		this.setState({showPassword: !this.state.showPassword})
	}

	render() {
		const { loading, authVars, authType, loginBtn } = this.state;
		const redirectType = authType === authTypes.user? authType : authTypes.educator;

		return (
			<div className='form-wrapper login-form'>
				<DocumentMeta
					title='Login | Irregular Area Calculator | Calculate Irregular Shape Area'
					description='Login to our software to calculate the area of irregular shapes.'
				/>
				{ loading && <Loader size={150} color={'white'} modes={'fixed dark-fade'}/> }
				<form onSubmit={this.handleSubmit.bind(this)} key={authType}>
					<h2>{authVars.title}</h2>
					<div className={'d-flex align-items-center'}>
						<h5 className={'text-nowrap pl-4 m-0'}>
							Create an <Link to={`/register?type=${redirectType}`}>{authVars.registerTitle}</Link>
						</h5>
						<div className={'switchbox d-flex justify-content-lg-end justify-content-center align-items-center w-100'}>
							<div>User</div>
							<Switch
								onChange={this.switchAuthType}
								defaultChecked={authType===authTypes.user}
							/>
							<div>Student</div>
						</div>
					</div>
					<div className={'notify-block'}>
						{(this.state.globalError || this.props.shouldShowForceLoginMessage || this.state.isLoggedIn) && (
							<p className='global-error text-center text-danger login-error'>
								<b>{this.state.globalError}</b>
							</p>
						)}
						{this.state.globalSuccess && <p className='text-center text-success'><b>{this.state.globalSuccess}</b></p>}
					</div>
					<div className='form-group'>
						<TextView
							placeholder={authVars.loginPlaceholder}
							showError={this.state.showErrors}
							text={this.state[authVars.loginName]}
							onFieldChanged={this.handleFieldChanged(authVars.loginName)}
							errorText={this.errorFor(authVars.loginName)}
							autoComplete={authVars.autoComplete}
						/>
					</div>
					<div className='form-group'>
						<TextView
							placeholder='Password'
							showError={this.state.showErrors}
							type={this.state.showPassword? 'text':'password'}
							text={this.state.password}
							onFieldChanged={this.handleFieldChanged('password')}
							errorText={this.errorFor('password')}
							autoComplete={authVars.autoComplete}
							suffixIcon={<Eye size={24}/>}
							onSuffixClick={this.togglePassword}
							wrapperClass={`relative ${this.state.showPassword? 'active-suffix' : ''}`}
						/>
					</div>
					{ authType === authTypes.user ?
						<div className='form-group row no-gutters'>
							<div className='col-6'>
								<label htmlFor='remember' className='custom-control custom-checkbox'>
									<input
										id='remember'
										name='remember'
										type='checkbox'
										className='custom-control-input'
										ref={el => (this.rememberMeEl = el)}
									/>
									<span className='custom-control-indicator'/>
									<span className='custom-control-description'>Remember Me</span>
								</label>
							</div>
							<div className='col-6 text-right'>
								<label htmlFor='forgot' className='custom-control custom-checkbox revert'>
									<input
										id='forgot'
										name='forgot'
										type='checkbox'
										className='custom-control-input'
										ref={el => (this.forgotEl = el)}
									/>
									<span className='custom-control-indicator'/>
									<span className='custom-control-description'>Forgot Password</span>
								</label>
							</div>
						</div>
						:
						<div className={'empty-checkboxes'}/>
					}

					<button
						className={loginBtn.classNames}
						onClick={loginBtn.onClick}>
						{loginBtn.text}
					</button>
					<AuthPolicyWarn text={'By clicking "Log in" you agree to our '}/>
				</form>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		isAuthenticated: state.auth.isAuthenticated,
		shouldShowForceLoginMessage: state.auth.isForceLogin,
		userPending: state.auth.pending
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			authLogin,
			loadUserData,
			ppdIgnoreWelcome,
			resendBadgeEmail,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(LogInPage);
