import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { modalClose } from '../../../../shared/reducers/ModalReducer';
import { layerDeleteCurrentSketch } from '../../../../shared/actions/layerActions';

export const MODAL_DELETE_LAYER = 'MODAL_DELETE_LAYER';

class ModalDeleteLayer extends React.Component {
	render() {
		return (
			<div className='modal-delete-layer modal-inner-wrapper'>
				<h2 className='text-center'>delete layer</h2>
				<p className='text-center'>the calculation on this layer will be lost</p>
				<div className='text-center'>
					<button className='btn btn-modal' onClick={this.props.modalClose}>
						cancel
					</button>
					<button
						className='btn btn-modal'
						onClick={() => {
							this.props.layerDeleteCurrentSketch();
							this.props.modalClose();
						}}>
						delete
					</button>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			layerDeleteCurrentSketch,
			modalClose
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(ModalDeleteLayer);
