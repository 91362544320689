const LABEL_SET_LINE_INDEX = 'LABEL_SET_LINE_INDEX';
const LABEL_TOGGLE_POINT_INDEX = 'LABEL_TOGGLE_POINT_INDEX';
const LABEL_RESET = 'LABEL_RESET';
const TOGGLE_SUM_LABEL = 'TOGGLE_SUM_LABEL';


export const labelTypes = {
	LABEL_SET_LINE_INDEX,
	LABEL_TOGGLE_POINT_INDEX,
	LABEL_RESET,
	TOGGLE_SUM_LABEL
};

export function labelSetLineIndex(index) {
	return {
		type: labelTypes.LABEL_SET_LINE_INDEX,
		payload: { index }
	};
}

export function labelTogglePointIndex() {
	return { type: labelTypes.LABEL_TOGGLE_POINT_INDEX };
}

export function labelReset() {
	return { type: labelTypes.LABEL_RESET };
}

export function toggleSumLabel(val) {
	return { type: labelTypes.TOGGLE_SUM_LABEL, payload: val };
}

const initState = {
	editableLineIndex: null,
	editablePointIndex: null,
	showSumLabels: true,
};

export default function(state = { ...initState }, action) {
	switch (action.type) {
		case labelTypes.LABEL_SET_LINE_INDEX: {
			return {
				...state,
				editableLineIndex: action.payload.index,
				editablePointIndex: 0
			};
		}
		case labelTypes.LABEL_TOGGLE_POINT_INDEX: {
			let pointIndex = state.editablePointIndex ? 0 : 1;

			return { ...state, editablePointIndex: pointIndex };
		}
		case labelTypes.LABEL_RESET: {
			return { ...initState };
		}
		case labelTypes.TOGGLE_SUM_LABEL: {
			return { ...state, showSumLabels: action.payload };
		}
		default: {
			return state;
		}
	}
}
