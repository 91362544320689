import {
	layerChangeIndex,
	layerRestoreCurrentSketch,
	layerSaveCurrentSketch,
	layerSetListLayers,
	layerSetShowAllLayers
} from './layerActions';
import store from '../store';
import { svgPan } from '../../components/App/DrawArea/DrawArea';
import { canvasReset, drawAreaUpdate } from './drawAreaActions';
import { instrumentChange } from '../reducers/instrumentReducer';
import { scaleUpdate } from './scaleControlActions';
import { zoomUpdate } from './zoomActions';
import { mapUpdate } from './mapActions';
import Layer from '../classes/Layer';
import { imageUpdate } from './ImageActions';
import { labelReset, toggleSumLabel } from '../reducers/LabelReducer';
import { modalClose } from '../reducers/ModalReducer';
import { notificationReset } from './notificationActions';
import { pdfReset } from '../reducers/PdfReducer';
import { previewHide } from './previewActions';
import { ppdReset } from '../reducers/ppdReducer';
import { updateFileState } from './fileStateAction';
import { saveFileName } from './fileNameActions';

export function stateToObject() {
	return dispatch => {
		dispatch(layerSaveCurrentSketch());

		return new Promise(resolve => {
			const state = {},
				{ drawArea, image, instrument, layers, map, scale, zoom, offset } = store.getState();

			state.drawArea = {
				visible: drawArea.visible,
				minStageSizes: { ...drawArea.minStageSizes }
			};

			if (image.url) {
				state.image = {
					display: image.display,
					originSizes: { ...image.originSizes },
					position: [...image.position],
					ratio: image.ratio,
					realSizes: { ...image.realSizes },
					scale: image.scale,
					url: image.url
				};
			}

			state.instrument = instrument;

			state.layers = {
				showAllLayers: layers.showAllLayers,
				currentIndex: layers.currentIndex,
				list: layers.list.map(layer => {
					return layer.toObject();
				})
			};

			if (map.visible) {
				state.map = {
					center: { ...map.center },
					type: map.type,
					visible: map.visible,
					zoom: map.zoom
				};
			}

			state.scale = {
				value: scale.value,
				measure: scale.measure
			};

			state.zoom = { ...zoom };

			state.offset = { ...offset };

			resolve(state);
		});
	};
}

export function stateToSaveObject() {
	return dispatch => {
		dispatch(layerSaveCurrentSketch());

		return new Promise(resolve => {
			const state = {},
				{ drawArea, image, instrument, layers, map, scale, zoom, offset } = store.getState();

			state.drawArea = {
				visible: drawArea.visible,
				stageSizes: { ...drawArea.stageSizes },
				minStageSizes: { ...drawArea.minStageSizes }
			};

			if (image.url) {
				state.image = {
					display: image.display,
					originSizes: { ...image.originSizes },
					position: [...image.position],
					ratio: image.ratio,
					realSizes: { ...image.realSizes },
					scale: image.scale,
					url: image.external_url || image.url
				};
			}

			state.instrument = instrument;

			state.layers = {
				showAllLayers: layers.showAllLayers,
				currentIndex: layers.currentIndex,
				list: layers.list.map(layer => {
					return layer.toSaveObject();
				})
			};

			if (map.visible) {
				state.map = {
					center: { ...map.center },
					type: map.type,
					visible: map.visible,
					zoom: map.zoom
				};
			}

			state.scale = {
				value: scale.value,
				measure: scale.measure
			};

			state.zoom = { ...zoom };

			state.offset = { ...offset };

			resolve(state);
		});
	};
}

export function stateRestoreFromObject(object) {
	return dispatch => {
		const state = JSON.parse(object.state);

		dispatch(canvasReset());

		if (state.drawArea) {
			dispatch(drawAreaUpdate(state.drawArea));
		}

		if (state.instrument) {
			dispatch(instrumentChange(state.instrument));
		}

		if (state.map) {
			dispatch(mapUpdate(state.map));
		}

		if (state.scale) {
			dispatch(scaleUpdate(state.scale));
		}

		if (state.zoom) {
			dispatch(zoomUpdate(state.zoom));
		}

		if (state.offset) {
			svgPan.pan(state.offset);
		}

		if (state.image) {
			dispatch(imageUpdate(state.image));
		}

		if (state.label) {
			dispatch(toggleSumLabel(!!state.label.showSumLabels));
		}

		if (state.layers) {
			if (state.layers.currentIndex >= 0) {
				dispatch(layerChangeIndex(state.layers.currentIndex));
			}

			if (state.layers.list && state.layers.list.length) {
				dispatch(layerSetListLayers(state.layers.list.map(item => Layer.fromObject(item))));
			}

			dispatch(layerRestoreCurrentSketch());

			dispatch(layerSetShowAllLayers(!!state.layers.showAllLayers));
		}
	};
}

export function stateReset() {
	return dispatch => {
		dispatch(labelReset());
		dispatch(modalClose());
		dispatch(notificationReset());
		dispatch(pdfReset());
		dispatch(previewHide());
		dispatch(ppdReset());
		dispatch(updateFileState(false));
		dispatch(saveFileName(''));
	};
}
