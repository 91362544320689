import React from 'react';
import AccountSettings from './AccountSettings';
import ApplicationSettings from './ApplicationSettings';
import get from 'lodash/get';
import { SUBSCRIPTION_PLANS } from '../../../../shared/constants/plansConstants';
import { connect } from 'react-redux';

export const MODAL_SETTINGS = 'MODAL_SETTINGS';

class ModalSettings extends React.Component {
	ACCOUNT_TAB = 'Account';
	APPLICATION_TAB = 'Application';

	constructor(props) {
		super(props);

		this.state = {
			activeTab: this.APPLICATION_TAB
		};
	}

	isActiveTab(tab) {
		return this.state.activeTab === tab;
	}

	changeTab(tab) {
		if (!this.isActiveTab(tab)) {
			this.setState({ activeTab: tab });
		}
	}

	render() {
		const plan = get(this, 'props.user.subscription.stripe_plan', '')
		const isEducator = plan === SUBSCRIPTION_PLANS.educator

		return (
			<div className='modal-settings modal-inner-wrapper'>
				<h2 className='text-center'>Settings</h2>
				{isEducator?
					<div className='tabs' />
					:
					<div className='tabs'>
						<div
							className={`tab${this.isActiveTab(this.ACCOUNT_TAB) ? ' active' : ''}`}
							onClick={() => {
								this.changeTab(this.ACCOUNT_TAB);
							}}>
							{this.ACCOUNT_TAB}
						</div>
						<div
							className={`tab${this.isActiveTab(this.APPLICATION_TAB) ? ' active' : ''}`}
							onClick={() => {
								this.changeTab(this.APPLICATION_TAB);
							}}>
							{this.APPLICATION_TAB}
						</div>
					</div>
				}
				{this.isActiveTab(this.ACCOUNT_TAB) && <AccountSettings />}
				{this.isActiveTab(this.APPLICATION_TAB) &&
					<ApplicationSettings />
				}
			</div>
		);
	}
}

const mapStateToProps = state => {
	const {
		auth: {
			user
		}
	} = state

	return {
		user
	}
}

export default connect(mapStateToProps, null)(ModalSettings)