import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import keyboardArrows from '../../../assets/img/help/keyboard-arrows.png';
import { helpActivateMoveVertex } from '../../../shared/actions/helpActions';
import { svgEl } from '../../App/DrawArea/DrawArea';

export let renderMoveVertex;

class MoveVertex extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			bounds: null
		};
	}

	componentWillMount() {
		renderMoveVertex = this.forceRender.bind(this);
	}

	componentWillUnmount() {
		renderMoveVertex = null;
	}

	forceRender(bounds) {
		this.setState({ bounds });
	}

	render() {
		if (!this.state.bounds || !svgEl) {
			return null;
		}

		const svgBounds = svgEl.getBoundingClientRect(),
			point = {
				x: this.state.bounds.left + this.state.bounds.width / 2,
				y: this.state.bounds.top + this.state.bounds.height / 2
			},
			style = { left: point.x - 275 / 2 };

		let arrow = 'arrow-bottom';

		if (point.y - svgBounds.top < 240) {
			style.top = point.y - svgBounds.top + 30;
			arrow = 'arrow-top';
		} else {
			style.top = point.y - svgBounds.top - 240;
		}

		return (
			<div style={style} className={`contextual-modal ${arrow}`}>
				<h3 className='title'>
					Adjust or Delete
					<i className='icon icon-mobile_clear' />
				</h3>
				<div className='content'>
					Drag or use cursor keys
					<img src={keyboardArrows} className='keyboard-arrows' alt='Keyboard Arrows' />
				</div>
				<div className='actions'>
					<button
						onClick={() => {
							this.props.helpActivateMoveVertex();
						}}
						className='btn btn-sm btn-modal'>
						ok
					</button>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			helpActivateMoveVertex
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(MoveVertex);
