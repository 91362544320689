import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { canvasReset } from '../../shared/actions/drawAreaActions';
import { modalClose, modalOpen } from '../../shared/reducers/ModalReducer';
import { MODAL_IMAGE_UPLOAD } from '../ModalContainer/Modals/ModalImageUpload/ModalImageUpload';
import { MODAL_TOGGLE_MAP } from '../ModalContainer/Modals/ModalToggleMap/ModalToggleMap';
import { layerIsEmptyAllLayers } from '../../shared/actions/layerActions';
import { mapToggleVisibility } from '../../shared/actions/mapActions';
import { MODAL_IMAGE_UPLOAD_CANVAS_RESET } from '../ModalContainer/Modals/ModalImageUpload/ModalImageUploadCanvasReset';
import { MODAL_SETTINGS } from '../ModalContainer/Modals/ModalSettings/ModalSettings';
import { MODAL_SAVE_OPEN } from '../ModalContainer/Modals/ModalSaveOpen/ModalSaveOpen';
import { MODAL_CLEAR_CANVAS } from '../ModalContainer/Modals/ModalClearCanvas/ModalClearCanvas';
import { MODAL_EXPORT } from '../ModalContainer/Modals/ModalExport/ModalExport';
import { authLogout } from '../../shared/actions/authActions';
import { saveFileName } from '../../shared/actions/fileNameActions';
import {
	sketchDeleteActivePoint,
	sketchMoveActivePoint,
	sketchMoveEndActivePoint
} from '../../shared/actions/SketchActions';
import { updateFileState } from '../../shared/actions/fileStateAction';
import { printActivate } from '../../shared/reducers/printReducer';
import { MODAL_LOADER } from '../ModalContainer/Modals/ModalLoader/ModalLoader';
import ClipBoardImport from '../App/ClipBoardImport/ClipBoardImport';
import ApiHelper from '../../shared/ApiHelper';
import { MODAL_SAVE_OPEN_STUDENT } from '../ModalContainer/Modals/ModalSaveOpenStudent/ModalSaveOpenStudent';
import get from 'lodash/get';

class AppMenu extends React.Component {
	constructor(props) {
		super(props);

		this.handleKeyUp = this.handleKeyUp.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
	}

	render() {
		const isSessionUser = get(this.props, 'isSessionUser', false);

		return (
			<div className='app-menu'>
				<ClipBoardImport />
				<span className='icon icon-action_menu hover-pointer' />
				<nav className=''>
					<Link to='#clear-canvas' onClick={this.clearCanvas.bind(this)}>
						clear canvas [ESC]
					</Link>
					<Link to='#save-open' onClick={this.saveOpen.bind(this)}>
						save [S] | open [O]
					</Link>
					<Link to='#export' onClick={this.export.bind(this)}>
						export [E]
					</Link>
					<Link to='#print' onClick={this.print.bind(this)}>
						print [P]
					</Link>
					<Link to='#import-image' onClick={this.importImage.bind(this)}>
						import image/pdf [I]
					</Link>
					<Link to='#show-maps' onClick={this.showMaps.bind(this)}>
						{this.props.mapVisible ? 'hide' : 'show'} maps [M]
					</Link>
					{!isSessionUser &&
						<Link
							to='#settings'
							onClick={event => {
								event.preventDefault();
								this.props.modalOpen(MODAL_SETTINGS);
							}}>
							settings
						</Link>
					}
					<Link to='/help' target='_blank'>
						help
					</Link>
					<Link
						to='#logout'
						onClick={event => {
							event.preventDefault();
							if (this.props.needSave) {
								this.saveFileBeforeLogout();
							} else {
								this.props.authLogout(this.props.userEmail);
							}
						}}>
						logout
					</Link>
				</nav>
			</div>
		);
	}

	handleKeyUp(event) {
		const target = event.target || event.srcElement;

		if (target.tagName.toUpperCase() !== 'BODY') {
			return;
		}

		const keyCode = event.keyCode || event.which,
			code = `${event.code}`.toLowerCase(),
			key = `${event.key}`.toLowerCase();

		if (keyCode === 27 || code === 'escape') {
			if (this.props.activeModal) {
				if (this.props.activeModal !== MODAL_LOADER) {
					this.props.modalClose();
				}
			} else {
				this.clearCanvas();
			}
		} else {
			if (!this.props.activeModal) {
				if (keyCode === 77 || key === 'm') {
					this.showMaps();
				} else if (keyCode === 83 || key === 's' || keyCode === 79 || key === 'o') {
					this.saveOpen();
				} else if (keyCode === 73 || key === 'i') {
					this.importImage();
				} else if (keyCode === 80 || key === 'p') {
					this.print();
				} else if (keyCode === 69 || key === 'e') {
					this.export();
				} else if (keyCode === 46 || code === 'delete' || keyCode === 8 || code === 'backspace') {
					this.props.sketchDeleteActivePoint();
				} else if (
					keyCode === 37 ||
					code === 'arrowleft' ||
					keyCode === 38 || code === 'arrowup' ||
					keyCode === 39 || code === 'arrowright' ||
					keyCode === 40 || code === 'arrowdown'
				) {
					this.props.sketchMoveEndActivePoint();
				}
			}
		}
	}

	handleKeyDown(event) {
		const target = event.target || event.srcElement;

		if (target.tagName.toUpperCase() !== 'BODY') {
			return;
		}

		const keyCode = event.keyCode || event.which,
			code = `${event.code}`.toLowerCase();

		if (!this.props.activeModal) {
			if (keyCode === 37 || code === 'arrowleft') {
				this.props.sketchMoveActivePoint('left');
			} else if (keyCode === 38 || code === 'arrowup') {
				this.props.sketchMoveActivePoint('up');
			} else if (keyCode === 39 || code === 'arrowright') {
				this.props.sketchMoveActivePoint('right');
			} else if (keyCode === 40 || code === 'arrowdown') {
				this.props.sketchMoveActivePoint('down');
			}
		}
	}

	componentDidMount() {
		window.addEventListener('keyup', this.handleKeyUp);
		window.addEventListener('keydown', this.handleKeyDown);
	}

	componentWillUnmount() {
		window.removeEventListener('keyup', this.handleKeyUp);
		window.removeEventListener('keydown', this.handleKeyDown);
	}

	saveOpen(event) {
		event && event.preventDefault();
		const { isSessionUser, modalOpen } = this.props;

		if (isSessionUser){
			modalOpen(MODAL_SAVE_OPEN_STUDENT);
		} else {
			modalOpen(MODAL_SAVE_OPEN);
		}
	}

	saveFileBeforeLogout = () => {
		const { isSessionUser, modalOpen } = this.props;

		if (isSessionUser){
			modalOpen(MODAL_SAVE_OPEN_STUDENT, {action: 'logout'});
		} else {
			modalOpen(MODAL_SAVE_OPEN, {action: 'logout'});
		}
	}

	showMaps(event) {
		event && event.preventDefault();
		this.props.layerIsEmptyAllLayers().then(response => {
			if (response) {
				this.props.mapToggleVisibility();
			} else {
				this.props.modalOpen(MODAL_TOGGLE_MAP);
			}
		});
	}

	importImage(event) {
		event && event.preventDefault();
		this.props.layerIsEmptyAllLayers().then(response => {
			if (response) {
				this.props.modalOpen(MODAL_IMAGE_UPLOAD);
			} else {
				this.props.modalOpen(MODAL_IMAGE_UPLOAD_CANVAS_RESET);
			}
		});
	}

	clearCanvas(event) {
		event && event.preventDefault();
		this.props.layerIsEmptyAllLayers(false).then(response => {
			if (response) {
				ApiHelper.onClearCanvas();
				this.props.canvasReset();
				this.props.updateFileState(false);
				this.props.saveFileName('');
			} else {
				if (this.props.mapVisible || this.props.image.url || this.props.image.file) {
					this.props.modalOpen(MODAL_CLEAR_CANVAS);
				} else {
					ApiHelper.onClearCanvas();
					this.props.canvasReset();
					this.props.updateFileState(false);
					this.props.saveFileName('');
				}
			}
		});
	}

	print(event) {
		event && event.preventDefault();
		this.props.printActivate();
	}

	export(event) {
		event && event.preventDefault();
		this.props.modalOpen(MODAL_EXPORT);
	}
}

function mapStateToProps(state, ownProps) {
	const {
		auth:{
			user
		}
	} = state;

	return {
		...ownProps,
		mapVisible: state.map.visible,
		image: state.image,
		activeModal: state.modal.activeModal,
		userEmail: user && user.email,
		isSessionUser: user && user.is_session_user,
		needSave: state.fileState.needSave
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			mapToggleVisibility,
			layerIsEmptyAllLayers,
			sketchDeleteActivePoint,
			sketchMoveActivePoint,
			sketchMoveEndActivePoint,
			canvasReset,
			modalOpen,
			modalClose,
			printActivate,
			authLogout,
			updateFileState,
			saveFileName
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(AppMenu);
