import socketConstants from '../constants/socketConstants';

const initState = {
	history: [],
	status: socketConstants.SOCKET_OFFLINE
};

export default function socketReducer(state = initState, action) {
	const { type, payload, meta } = action
	switch (action.type) {
		case socketConstants.SOCKET_EVENT_LOGOUT:
		case socketConstants.SOCKET_SUBSCRIPTION_PLAN_CHANGED: {
			return {
				...state,
				history: [
					...state.history,
					{ type, payload, meta }
				]
			};
		}
		case socketConstants.SET_WEBSOCKET_STATUS: {
			return {
				...state,
				status: payload.status
			};
		}
		default:
			return state;
	}
}
