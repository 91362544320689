import React from 'react';
import { connect } from 'react-redux';
import ActionMenu from './Modals/ActionMenu';
import MapControls from './Modals/MapControls';
import Results from './Modals/Results';
import SetScale1 from './Modals/SetScale1';
import SetScale2 from './Modals/SetScale2';
import SetScale3 from './Modals/SetScale3';
import EditLineLength from './Modals/EditLineLength';
import MoveVertex from './Modals/MoveVertex';
import LineLengthsLabels from './Modals/LineLengthsLabels';

class ContextualHelp extends React.Component {
	render() {
		if (
			!this.props.user ||
			this.props.activeModal ||
			(this.props.help.helpMenu &&
				this.props.help.helpScale1 &&
				this.props.help.helpScale2 &&
				this.props.help.helpScale3 &&
				this.props.help.helpLengthSubmitted &&
				this.props.help.helpResults &&
				this.props.help.helpMap &&
				this.props.help.helpEditLineLength &&
				this.props.help.helpMoveVertex &&
				this.props.help.helpHideLineLengthLabels)
		) {
			return null;
		}

		return (
			<div className='contextual-help'>
				{!this.props.help.helpMenu && !this.props.imageDynamic && <ActionMenu />}
				{!this.props.help.helpMap && this.props.mapVisible && <MapControls />}
				{!this.props.help.helpResults &&
					!this.props.showAllLayers &&
					this.props.help.helpHideLineLengthLabels &&
					this.props.sketch.closed && <Results />}
				{!this.props.help.helpScale1 && this.props.imageDynamic && <SetScale1 />}
				{!this.props.help.helpScale2 && this.props.help.helpScale1 && this.props.scaleLineReady && (
					<SetScale2 />
				)}
				{!this.props.help.helpScale3 &&
					this.props.help.helpScale2 &&
					this.props.imageDynamic &&
					this.props.help.helpLengthSubmitted && <SetScale3 />}
				{!this.props.help.helpEditLineLength &&
					this.props.help.helpResults &&
					!this.props.showAllLayers &&
					this.props.sketch.closed &&
					this.props.sketch.labelsVisible && <EditLineLength />}
				{!this.props.help.helpMoveVertex &&
					this.props.help.helpEditLineLength &&
					!this.props.showAllLayers &&
					this.props.sketch.closed && <MoveVertex />}
				{!this.props.help.helpHideLineLengthLabels &&
					!this.props.showAllLayers &&
					(this.props.sketch.controlPoints.length > 3 || this.props.sketch.closed) && <LineLengthsLabels />}
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		user: state.auth.user,
		help: state.help,
		mapVisible: state.map.visible,
		sketch: state.sketchState.sketch,
		activeModal: state.modal.activeModal,
		imageDynamic: state.image.dynamicImage,
		expandMenu: state.instrumentMenu.expand,
		scaleLineReady: state.scale.lineReady,
		showAllLayers: state.layers.showAllLayers
	};
}

export default connect(mapStateToProps)(ContextualHelp);
