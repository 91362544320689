import * as React from 'react';
import PreviewLine from '../PreviewLine/PreviewLine';

export default class CanvasImage extends React.Component {
	componentDidMount() {
		if (window.isPuppeteer) {
			const image = new Image();

			image.onload = image.onerror = image.onabort = this.props.printActivateReady;

			image.src = this.props.image.url;
		}
	}

	render() {
		if (!this.props.image || !this.props.image.url || !this.props.image.display) {
			return null;
		}

		let { image, strokeWidth, zoomValue, scaleLineReady, scaleLine, preview } = this.props;
		image.src = image.external_url || image.url;
		strokeWidth = strokeWidth / zoomValue;

		return (
			<g className='image'>
				<image
					xlinkHref={image.src}
					x={image.position[0]}
					y={image.position[1]}
					width={image.realSizes.width}
					height={image.realSizes.height}
				/>
				<rect
					x={image.position[0]}
					y={image.position[1]}
					width={image.realSizes.width}
					height={image.realSizes.height}
				/>

				{scaleLineReady && image.dynamicImage && <path strokeWidth={strokeWidth} d={scaleLine.toPath(true)} />}
				<PreviewLine preview={preview} showLabel={false} zoom={zoomValue} strokeWidth={strokeWidth} />
			</g>
		);
	}
}
