import { helpTypes } from '../constants/helpConstants';

const initState = {
	helpIntro: false,
	helpMenu: false,
	helpScale1: false,
	helpScale2: false,
	helpScale3: false,
	helpLengthSubmitted: false,
	helpResults: false,
	helpMap: false,
	helpEditLineLength: false,
	helpMoveVertex: false,
	helpHideLineLengthLabels: false
};

export default function helpReducer(state = initState, action) {
	switch (action.type) {
		case helpTypes.HELP_ACTIVATE_INTRO: {
			return { ...state, helpIntro: true };
		}
		case helpTypes.HELP_ACTIVATE_MENU: {
			return { ...state, helpMenu: true };
		}
		case helpTypes.HELP_ACTIVATE_SCALE_1: {
			return { ...state, helpScale1: true };
		}
		case helpTypes.HELP_ACTIVATE_SCALE_2: {
			return { ...state, helpScale2: true };
		}
		case helpTypes.HELP_ACTIVATE_SCALE_3: {
			return { ...state, helpScale3: true };
		}
		case helpTypes.HELP_ACTIVATE_LENGTH_SUBMITTED: {
			return { ...state, helpLengthSubmitted: true };
		}
		case helpTypes.HELP_ACTIVATE_RESULTS: {
			return { ...state, helpResults: true };
		}
		case helpTypes.HELP_ACTIVATE_MAP: {
			return { ...state, helpMap: true };
		}
		case helpTypes.HELP_ACTIVATE_EDIT_LINE_LENGTH: {
			return { ...state, helpEditLineLength: true };
		}
		case helpTypes.HELP_ACTIVATE_MOVE_VERTEX: {
			return { ...state, helpMoveVertex: true };
		}
		case helpTypes.HELP_HIDE_LINE_LENGTH_LABELS: {
			return { ...state, helpHideLineLengthLabels: true };
		}
		case helpTypes.HELP_UPDATE: {
			return { ...state, ...action.payload.state };
		}
		default: {
			return state;
		}
	}
}
