import React from 'react';
import DocumentMeta from 'react-document-meta';
import FuzzySearch from 'react-fuzzy';
import uniqid from 'uniqid';
import _ from 'lodash';
import scrollToElement from 'scroll-to-element';
import Fuse from 'fuse.js';
import ApiHelper from '../../../shared/ApiHelper';
import { getUserID, saveUserID } from '../../../shared/StorageHelper';

export default class Help extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			title: 'Help | Irregular Area Calculator | Calculate Irregular Shape Area',
			description:
				'Find out what you can do with our irregular area calculator software. Our program easily calculates the area to any irregular shape.',
			list: [],
			inputValue: '',
			isFocus: false,
			helpedArticle: null,
			listForSearch: [],
			filteredList: [],
			userRequestData: {
				savedTopic: '',
				savedText: ''
			},
			savedUserRequests: [],
			userIdentity: null
		};
	}

	componentWillMount() {
		ApiHelper.help().then(response => {
			if (response.data.success) {
				const list = response.data.posts.map(post => {
					let expand = false;

					if (this.props.location.hash) {
						if (this.props.location.hash.substring(1) === post.slug) {
							expand = true;
						}
					}

					return {
						title: post.title,
						content: post.content,
						slug: post.slug,
						expand: expand
					};
				});
				const helpedArticle = list.find(({title}) => title === 'Can\'t find an answer. How can I get support?');
				const listForSearch = list.filter(({title}) => title !== helpedArticle.title);
				this.setState({
					list,
					helpedArticle,
					listForSearch
				});
			}
		});
		let userIdentity = getUserID();
		if (!userIdentity) {
			userIdentity = uniqid();
			saveUserID(userIdentity);
		}
		this.setState({userIdentity});
	}

	goToQuestion = (event) => {
		let href = event.target.getAttribute('href');

		if (href.indexOf('#') === 0) {
			const index = this.state.list.findIndex(item => item.slug === href.substring(1));

			if (index !== -1 && !this.state.list[index].expand) {
				this.toggleExpand(index);
			}
		}
	}

	saveUserRequest = _.debounce(() => {
		const { userRequestData: {savedTopic, savedText}, savedUserRequests, userIdentity } = this.state;
		const isAlreadySent = savedUserRequests.find(item => item.savedTopic === savedTopic && item.savedText === savedText);
		if (!isAlreadySent && savedText) {
			ApiHelper.saveSearchText({text: savedText, selected_topic: savedTopic, userIdentity});
			this.setState({
				savedUserRequests: [...savedUserRequests, {savedTopic, savedText}]
			});
		}
		this.setState({
			userRequestData: {
				savedTopic: '',
				savedText: ''
			}
		});
	}, 1000);

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.list.length && this.state.list.length) {
			Array.from(document.querySelectorAll('.answer a')).forEach(el => {
				el.addEventListener('click', this.goToQuestion);
			});

			if (this.props.location.hash) {
				scrollToElement(this.props.location.hash);
			}
		}
	}

	toggleExpand(index) {
		this.setState({
			list: [
				...this.state.list.slice(0, index),
				{
					...this.state.list[index],
					expand: !this.state.list[index].expand
				},
				...this.state.list.slice(index + 1)
			]
		});
	}

	onChange = (event) => {
		const inputValue = event.target.value;
		this.setState({inputValue});
		const options = {
			includeScore: true,
			threshold: 0.32,
			distance: 320,
			keys: ['title', 'content']
		};
		const fuse = new Fuse(this.state.listForSearch, options);
		const result = fuse.search(inputValue);
		this.setState({filteredList: result.map(({item}) => item)});
	}

	onFocus = () => {
		this.setState({isFocus: true});
	}

	onBlur = () => {
		const { userRequestData, inputValue } = this.state;

		this.setState({
			userRequestData: {
				...userRequestData,
				savedText: inputValue
			}
		});
		this.saveUserRequest();
		setTimeout(() => this.setState({isFocus: false}), 150);
	}

	clearInput = () => {
		this.setState({inputValue: '' });
		if (this.input) {
			this.input.state.value = '';
			this.input.state.results = [];
		}
	}

	onSelect = (selectedItem, isHelped) => {
		const { list, inputValue } = this.state;
		this.setState({
			userRequestData: {
				savedTopic: selectedItem.title,
				savedText: inputValue
			}
		});
		this.saveUserRequest();
		if (isHelped) {
			if (this.input) {
				this.input.state.value = selectedItem.title;
				this.input.state.results = [];
			}
		}
		const element = document.getElementById(selectedItem.title);
		const index = list.findIndex(item => item.title === selectedItem.title);

		if (index !== -1 && !list[index].expand) {
			this.toggleExpand(index);
		}
		setTimeout(() => {
			element.scrollIntoView({ behavior: 'smooth' }, true);
		}, 10);
	}

	render() {
		const {list, title, description, inputValue, isFocus, helpedArticle, listForSearch, filteredList} = this.state;

		const style = {
			inputWrapperStyle: {
				boxShadow: 'none',
				marginBottom: '25px',
				height: '50px',
				padding: '0',
			},
			inputStyle: {
				height: '50px',
				padding: '7px 35px 7px 40px',
				borderRadius: '5px',
				border: `1px solid ${isFocus ? 'blue' : ''}`
			},
			listWrapperStyle: {
				position: 'absolute',
				top: '50px',
				zIndex: 2
			},
		};

		return (
			<section className='help'>
				{list.length > 0 && (
					<article className='faq'>
						{title && <h2 className='text-center'>Help</h2>}

						<div className='input-wrapper'>
							<div className="btn input-icon left icon-search_map"/>
							<FuzzySearch
								ref={(ref) => this.input = ref}
								list={listForSearch}
								keys={['title', 'content']}
								width='100%'
								maxResults={100}
								distance={320}
								inputProps={{
									value: inputValue,
									onChange: this.onChange,
									onFocus: this.onFocus,
									onBlur: this.onBlur
								}}
								shouldShowDropdownAtStart={!isFocus && !!inputValue}
								threshold={0.32}
								{...style}
								onSelect={this.onSelect}
								resultsTemplate={(props, state, styles, clickHandler) => {
									if (!isFocus || !filteredList.length) {
										return null;
									}
									return state.results.map((val, i) => {
										const style = state.selectedIndex === i ? styles.selectedResultStyle : styles.resultsStyle;
										return (
											<div
												key={i}
												style={style}
												onClick={() => clickHandler(i)}
											>
												{val.title}
											</div>
										);
									});
								}}
							/>
							{!!(isFocus && inputValue.trim() && !filteredList.length) &&
								<div className='list-options-wrapper'>
									<div
										className='list-options-item'
										onClick={() => this.onSelect(helpedArticle, true)}
									>
										{helpedArticle.title}
									</div>
								</div>
							}
							{inputValue && <i className="icon input-icon right icon-close_icn" onClick={this.clearInput}/>}
						</div>
						<ul>
							{list.map((post, index) => {
								const classNames = ['question'];

								if (post.expand) {
									classNames.push('expand');
								}

								return (
									<li key={index} id={post.slug} className={classNames.join(' ')}>
										<p
											id={post.title}
											className='title'
											onClick={() => {
												this.toggleExpand(index);
											}}>
											{post.title}
										</p>
										<div
											className='answer'
											dangerouslySetInnerHTML={{
												__html: post.content
											}}
										/>
									</li>
								);
							})}
						</ul>
					</article>
				)}
				{title && <DocumentMeta title={title} description={description} />}
			</section>
		);
	}
}
