import React from 'react';
import ReactGA from 'react-ga';
import DocumentMeta from 'react-document-meta';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { settingsSetMarketingCookies, settingsAcceptCookies } from '../../../shared/actions/settingsActions';

class CookiePolicyPage extends React.Component {
	render() {
		return (
			<section className='policy'>
				<DocumentMeta title='Cookie Policy' />
				<div>
					<h2>Cookies Policy</h2>

					<p>Last updated: July 06, 2018</p>
					<p>
						iCalc Inc ("us", "we", or "our") uses cookies on https://www.sketchandcalc.com (the "Service").
						By using SketchAndCalc, you consent to the use of cookies.
					</p>
					<p>
						Our Cookies Policy explains what cookies are, how we use cookies, how third-parties we may
						partner with may use cookies on the Service, your choices regarding cookies and further
						information about cookies.
					</p>
					<h5>What are cookies</h5>
					<p>
						Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file
						is stored in your web browser and allows the Service or a third-party to recognize you and make
						your next visit easier and the Service more useful to you.
					</p>

					<p>Cookies can be "persistent" or "session" cookies.</p>

					<h5>How iCalc Inc uses cookies</h5>

					<p>
						When you use and access SketchAndCalc, we may place a number of cookie files in your web
						browser.
					</p>

					<p>
						We use cookies for the following purposes: to enable certain functions of the Service, to
						provide analytics, and to store your preferences.
					</p>

					<p>
						We use both session and persistent cookies on SketchAndCalc and we use different types of
						cookies to run the Service:
					</p>

					<div className='solid-block'>
						<h5>Essential cookies</h5>
						<p>
							These cookies are necessary for SketchAndCalc to function and cannot be switched off in our
							systems. They are usually only set in response to actions made by you which amount to a
							request for services, such as setting your preferences and authenticating. You can set your
							browser to block or alert you about these cookies, but some parts of the site may cease to
							function.
						</p>
					</div>

					<div className='solid-block'>
						<div className='row'>
							<div className='col col-auto'>
								<h5>Marketing cookies</h5>
							</div>
							<div className='col row no-gutters justify-content-end'>
								<span>On</span>
								<label className='switch'>
									<span className='slider' />
									<input
										type='checkbox'
										onChange={event => {
											this.props.settingsSetMarketingCookies(event.target.checked);
										}}
										defaultChecked={this.props.settings.marketingCookiesOn}
									/>
									<span className='slider' />
								</label>
								<span>Off</span>
							</div>
						</div>
						<p>
							These cookies allow us to count visits and traffic sources, so we can measure and improve
							the performance of SketchAndCalc. They help us know which pages are the most popular and see
							how visitors move around. All information these cookies collect is aggregated and therefore
							anonymous. If you do not allow these cookies we will not know when you have visited.
						</p>
					</div>

					<h5>What are your choices regarding cookies</h5>

					<p>
						If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please
						visit the help pages of your web browser.
					</p>

					<p>
						Please note, however, that if you delete cookies or refuse to accept them, you might not be able
						to use all of the features we offer, you may not be able to store your preferences, and some of
						our pages might not display properly.
					</p>

					<h5>Where can your find more information about cookies</h5>
					<p>You can learn more about cookies and the following third-party websites:</p>
					<p>
						* AllAboutCookies:{' '}
						<ReactGA.OutboundLink
							target='_blank'
							eventLabel='All About Cookies'
							to='http://www.allaboutcookies.org'>
							http://www.allaboutcookies.org
						</ReactGA.OutboundLink>
						<br />* Network Advertising Initiative:{' '}
						<ReactGA.OutboundLink
							target='_blank'
							eventLabel='Network Advertising'
							to='http://www.networkadvertising.org'>
							http://www.networkadvertising.org
						</ReactGA.OutboundLink>
					</p>

					<h5 className='confirmation'>
						Click the 'I Accept' button below to indicate you have read and agree to the terms of this
						policy.
					</h5>

					<button
						className='btn'
						onClick={() => {
							this.props.settingsAcceptCookies();
							this.props.history.push('/');
						}}>
						I Accept
					</button>
				</div>
			</section>
		);
	}
}

const mapStateToPros = (state, ownProps) => {
	return {
		...ownProps,
		user: state.auth.user,
		settings: state.settings
	};
};

const mapDispatchToPros = dispatch => {
	return bindActionCreators(
		{
			settingsSetMarketingCookies,
			settingsAcceptCookies
		},
		dispatch
	);
};

export default connect(mapStateToPros, mapDispatchToPros)(CookiePolicyPage);
