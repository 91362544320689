const HELP_ACTIVATE_INTRO = 'HELP_ACTIVATE_INTRO';
const HELP_ACTIVATE_MENU = 'HELP_ACTIVATE_MENU';
const HELP_ACTIVATE_SCALE_1 = 'HELP_ACTIVATE_SCALE_1';
const HELP_ACTIVATE_SCALE_2 = 'HELP_ACTIVATE_SCALE_2';
const HELP_ACTIVATE_SCALE_3 = 'HELP_ACTIVATE_SCALE_3';
const HELP_ACTIVATE_LENGTH_SUBMITTED = 'HELP_ACTIVATE_LENGTH_SUBMITTED';
const HELP_ACTIVATE_RESULTS = 'HELP_ACTIVATE_RESULTS';
const HELP_ACTIVATE_MAP = 'HELP_ACTIVATE_MAP';
const HELP_ACTIVATE_EDIT_LINE_LENGTH = 'HELP_ACTIVATE_EDIT_LINE_LENGTH';
const HELP_ACTIVATE_MOVE_VERTEX = 'HELP_ACTIVATE_MOVE_VERTEX';
const HELP_HIDE_LINE_LENGTH_LABELS = 'HELP_HIDE_LINE_LENGTHS_LABELS';
const HELP_UPDATE = 'HELP_UPDATE';

export const helpTypes = {
	HELP_ACTIVATE_INTRO,
	HELP_ACTIVATE_MENU,
	HELP_ACTIVATE_SCALE_1,
	HELP_ACTIVATE_SCALE_2,
	HELP_ACTIVATE_SCALE_3,
	HELP_ACTIVATE_LENGTH_SUBMITTED,
	HELP_ACTIVATE_RESULTS,
	HELP_ACTIVATE_MAP,
	HELP_ACTIVATE_EDIT_LINE_LENGTH,
	HELP_ACTIVATE_MOVE_VERTEX,
	HELP_HIDE_LINE_LENGTH_LABELS,
	HELP_UPDATE
};
