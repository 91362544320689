import * as React from 'react';
import { stopPropagation } from '../../../shared/EventHelper';

export class SupportPoints extends React.Component {
	render() {
		if (this.props.activeIndex === null) {
			return null;
		}

		return (
			<g className='support-points'>
				{this.getSupports()}
				{this.getSupportControls()}
			</g>
		);
	}

	getSupports() {
		let strokeWidth = 1 / (this.props.zoom || 1),
			activeIndex = this.props.activeIndex,
			point = this.props.sketch.getControlPoints()[activeIndex],
			supportPoints = this.props.sketch.getSupportPoints(activeIndex);

		return (
			supportPoints &&
			supportPoints.map((sp, index) => {
				if (sp) {
					let displayPoint = [sp[0] + point[0], sp[1] + point[1]];

					return (
						<path
							key={index}
							d={'M ' + point.join(',') + ' L ' + displayPoint.join(',') + ' Z'}
							strokeWidth={strokeWidth}
						/>
					);
				}
			})
		);
	}

	getSupportControls() {
		let strokeWidth = 1 / (this.props.zoom || 1),
			activeIndex = this.props.activeIndex,
			point = this.props.sketch.getControlPoints()[activeIndex],
			supportPoints = this.props.sketch.getSupportPoints(activeIndex),
			dragSupportPoint = this.props.dragSupportPoint,
			radius = 4 / (this.props.zoom || 1);

		return (
			supportPoints &&
			supportPoints.map((sp, spIndex) => {
				if (sp) {
					let displayPoint = [sp[0] + point[0], sp[1] + point[1]];
					let classes = ['point'];

					if (
						dragSupportPoint &&
						dragSupportPoint.pointIndex === activeIndex &&
						dragSupportPoint.supportIndex === spIndex
					) {
						classes.push('active');
					}

					return (
						<circle
							className={classes.join(' ')}
							cx={displayPoint[0]}
							cy={displayPoint[1]}
							r={radius}
							key={this.props.activeIndex + '-' + spIndex}
							strokeWidth={strokeWidth}
							onMouseDown={event => {
								stopPropagation(event);
								this.props.mouseDown(this.props.activeIndex, spIndex);
							}}
						/>
					);
				}
			})
		);
	}
}
