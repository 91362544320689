import React from 'react';

const Art1 = ({color="#fff"}) => {

    return (
        <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 200 200">
            <path
                style={{ fill: color }}
                className="cls-1"
                d="M63,83l.46,5.18.11,0a13.36,13.36,0,0,1,4.52-4.37,12.09,12.09,0,0,1,6.11-1.55q5.72,0,8.81,3.56t3.1,11.63v23.63H74.19V97.41c0-2.23-.44-3.79-1.32-4.67a5.48,5.48,0,0,0-4-1.32,7.17,7.17,0,0,0-3,.58,6.24,6.24,0,0,0-2.18,1.68v27.4H51.82V83Z"/>
            <path
                style={{ fill: color }}
                className="cls-1"
                d="M103.79,83l.42,4.86.11,0a12,12,0,0,1,4.42-4.15A12.83,12.83,0,0,1,115,82.26a11.73,11.73,0,0,1,6.15,1.52A9.61,9.61,0,0,1,125,88.53a12.74,12.74,0,0,1,4.51-4.67,12.55,12.55,0,0,1,6.48-1.6q5.67,0,8.8,3.75t3.14,11.86v23.21H136V97.87c0-2.51-.37-4.22-1.11-5.11a4.27,4.27,0,0,0-3.47-1.34,7.28,7.28,0,0,0-3.07.6,6.14,6.14,0,0,0-2.22,1.73,10,10,0,0,0,0,1c0,.28,0,.57,0,.88v25.43h-11.9V97.87c0-2.51-.37-4.22-1.11-5.11a4.31,4.31,0,0,0-3.51-1.34,6.89,6.89,0,0,0-2.94.6,6.57,6.57,0,0,0-2.24,1.73v27.33H92.58V83Z"/>
        </svg>
    )
}

export default Art1