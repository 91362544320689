export const currencyOptions =[
    {value:'AUD', label:'AUD'},
    {value:'BRL', label:'BRL'},
    {value:'CAD', label:'CAD'},
    {value:'CNY', label:'CNY'},
    {value:'DKK', label:'DKK'},
    {value:'EUR', label:'EUR'},
    {value:'GBP', label:'GBP'},
    {value:'HKD', label:'HKD'},
    {value:'ILS', label:'ILS'},
    {value:'INR', label:'INR'},
    {value:'JPY', label:'JPY'},
    {value:'MXN', label:'MXN'},
    {value:'MYR', label:'MYR'},
    {value:'NOK', label:'NOK'},
    {value:'NZD', label:'NZD'},
    {value:'SEK', label:'SEK'},
    {value:'SGD', label:'SGD'},
    {value:'THB', label:'THB'},
    {value:'TRY', label:'TRY'},
    {value:'USD', label:'USD'},
    {value:'ZAR', label:'ZAR'}
]