import * as types from '../constants/scaleControlConstants';
import { convertDownToCm, convertFromCmToUnit, correctScale } from '../MeasureHelper';
import store from '../store';
import { gridSize } from '../../components/App/CanvasGrid/CanvasGrid';
import { measurementSystemValues } from '../constants/settingsConstants';
import { notificationShow } from './notificationActions';
import { notificationTypes } from '../constants/notificationConstants';
import {updateFileState} from './fileStateAction';

export function measuresChanged(measure) {
	return dispatch => {
		let { scale, image } = store.getState();

		if (!image.dynamicImage) {
			let newDrawingScale = convertFromCmToUnit(convertDownToCm(scale.value, scale.measure), measure);
			let correctScaleAndUnits = correctScale(newDrawingScale, measure);

			dispatch(scaleSetValue(correctScaleAndUnits.scale));
			dispatch(scaleSetTextValue(correctScaleAndUnits.scale));
			dispatch({
				type: types.SCALE_MEASURES_CHANGED,
				payload: { measure: correctScaleAndUnits.unit }
			});
		} else {
			dispatch({
				type: types.SCALE_MEASURES_CHANGED,
				payload: { measure }
			});
		}
	};
}

export function incraseScaleValue(value) {
	return dispatch => {
		dispatch({type: types.SCALE_INCREASE});
		dispatch(updateFileState(true));
	};
}

export function decraseScaleValue(value) {
	return dispatch => {
		dispatch({type: types.SCALE_DECREASE});
		dispatch(updateFileState(true));
	};
}

export function scaleCalcFromUserInputValue(value, measure) {
	return dispatch => {
		let scaleState = store.getState().scale;

		let newScale = value / scaleState.line.getLength();

		const corrected = correctScale(newScale * gridSize, measure);

		dispatch(scaleLineReset());

		if (measure !== corrected.unit) {
			dispatch(measuresChanged(corrected.unit));
		}

		dispatch(scaleSetValue(corrected.scale));

		dispatch(notificationShow('Scale set, now Add to Canvas', notificationTypes.SUCCESS, 3000));
	};
}

export function scaleSetDefaultMeasure() {
	return (dispatch, state) => {
		const measurementSystem = state().settings.measurementSystem;

		switch (measurementSystem) {
			case measurementSystemValues.MEASUREMENT_SYSTEM_IMPERIAL: {
				dispatch(scaleSetMeasure('ft'));
				window.defaults.measure = 'ft';
				break;
			}
			default: {
				dispatch(scaleSetMeasure('cm'));
				window.defaults.measure = 'cm';
			}
		}
	};
}

export function scaleSetTextValue(text) {
	return (dispatch) => {
		dispatch({type: types.SCALE_SET_TEXT_VALUE, payload: {text}});
		dispatch(updateFileState(true));
	};
}
export function scaleSetValue(scale) {
	return (dispatch) => {
		dispatch({type: types.SCALE_SET_VALUE, payload: {value: scale}});
		dispatch(updateFileState(true));
	};
}
export function scaleSetMeasure(measure) {
	return { type: types.SCALE_SET_MEASURE, payload: { measure } };
}
export function scaleLineStart(point) {
	return { type: types.SCALE_LINE_START, payload: { point } };
}

export function scaleLineEnd(point) {
	return { type: types.SCALE_LINE_END, payload: { point } };
}

export function scaleLineReset() {
	return { type: types.SCALE_LINE_RESET };
}

export function scaleUpdate(state) {
	return { type: types.SCALE_UPDATE, payload: { state } };
}

export function scaleReset(props={}) {
	const { manualValues = {} } = props;

	return { type: types.SCALE_RESET, payload: { manualValues } };
}
