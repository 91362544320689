import React from 'react';
import TextView from '../../components/Util/TextView';

class UserRegister extends React.Component {
    render () {
        const { showErrors, firstName, lastName, email, handleFieldChanged, errorFor, password } = this.props

        return (
                <div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <TextView
                                    placeholder='First Name'
                                    showError={showErrors}
                                    text={firstName}
                                    onFieldChanged={handleFieldChanged('firstName')}
                                    errorText={errorFor('firstName')}
                                    autoComplete='off'
                                />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <TextView
                                    placeholder='Last Name'
                                    showError={showErrors}
                                    text={lastName}
                                    onFieldChanged={handleFieldChanged('lastName')}
                                    errorText={errorFor('lastName')}
                                    autoComplete='off'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <TextView
                            placeholder='Email'
                            showError={showErrors}
                            text={email}
                            onFieldChanged={handleFieldChanged('email')}
                            errorText={errorFor('email')}
                            autoComplete='off'
                            type='email'
                        />
                    </div>
                    <div className='form-group'>
                        <TextView
                            placeholder='Password'
                            showError={showErrors}
                            type='password'
                            text={password}
                            onFieldChanged={handleFieldChanged('password')}
                            errorText={errorFor('password')}
                            autoComplete='new-password'
                        />
                    </div>
                </div>
        )
    }
}

export default UserRegister;