import React from 'react';
import ApiHelper from '../../shared/ApiHelper';

export default class TopNote extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			message: null
		};
	}

	componentWillMount() {
		ApiHelper.getNotification().then(response => {
			if (response.data.success && response.data.notification) {
				if (response.data.notification.message) {
					this.setState({
						show: true,
						message: response.data.notification.message
					});
				}
			}
		});
	}

	hideNote() {
		this.setState({ show: false });
	}

	render() {
		if (!this.state.show) {
			return null;
		}

		return (
			<div className='top-note-component'>
				<i className='icon icon-close_icn close-note' onClick={this.hideNote.bind(this)} />
				<div className='content' dangerouslySetInnerHTML={{ __html: this.state.message }} />
			</div>
		);
	}
}
