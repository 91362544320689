import notificationConstants, { NOTIFICATION_DELAY, notificationTypes } from '../constants/notificationConstants';

export function notificationShow(message, type = notificationTypes.SUCCESS, delay = NOTIFICATION_DELAY) {
	return {
		type: notificationConstants.NOTIFICATION_SHOW,
		payload: { message, type, delay }
	};
}

export function notificationReset() {
	return { type: notificationConstants.NOTIFICATION_RESET };
}
