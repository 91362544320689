import * as ErrorMessages from './errorMessages.js';
import * as EmailValidator from 'email-validator';
import { notValidField } from './errorMessages.js';

export const required = text => {
	if (text) {
		return null;
	} else {
		return ErrorMessages.isRequired;
	}
};

export const isValidEmail = text => {
	const email = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

	if (!email.test(text)) {
		return ErrorMessages.isValidEmail;
	}

	if (EmailValidator.validate(text)) {
		return null;
	} else {
		return ErrorMessages.isValidEmail;
	}
};

export const minLength = length => {
	return text => {
		return text.length >= length ? null : ErrorMessages.minLength(length);
	};
};

export const site = text => {
	const site = /^(https:\/\/|http:\/\/|www\.)*[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gi;

	if (!site.test(text)) {
		return ErrorMessages.notValidField;
	}

	return null;
};

export const phone = phoneNum => {
	const myPhoneRegex = /^\+?(\d{6,})$/g;
	if (!myPhoneRegex.test(phoneNum)) {
		return ErrorMessages.notValidField;
	}
};