import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { layerAdd, layerChange, layerSetShowAllLayers, updateLayer } from '../../../shared/actions/layerActions';
import SketchResults from '../SketchResults/SketchResults';
import get from 'lodash/get';
import { SUBSCRIPTION_PLANS } from '../../../shared/constants/plansConstants';

class SketchLayers extends React.Component {
	layersWrapperEl = null;
	layersEl = null;

	constructor(props) {
		super(props);

		this.state = {
			showArrows: false,
			offset: 0,
			isNewLayer: false,
			disableLeftMove: false,
			disableRightMove: false,
			isPinned: false
		};
	}

	render() {
		const { showArrows } = this.state;
		const { isEducator } = this.props;
		if (!(this.props.sizes.width > 0 && !this.props.image)) {
			return null;
		}

		let showAllLayers = false;

		if (this.props.layers.list.length > 1) {
			showAllLayers = true;
		}

		const style = {
			gridTemplateColumns: `${showArrows ? '50px' : ''}${showAllLayers ? ' auto' : ''} 1fr${showArrows ? ' 50px' : ''}${!isEducator ? ' 50px' : ''}`
		};
		return (
			<div className='sketch-layers-container' style={style}>
				{this.state.showArrows && (
					<div
						className={`action-item icon icon-layer_left${this.state.disableLeftMove ? ' disabled' : ''}`}
						onClick={!this.state.disableLeftMove && this.leftMove.bind(this)}
					/>
				)}
				{showAllLayers && (
					<div
						title={this.props.layers.showAllLayers ? '' : 'Sum of All Layers'}
						className={`action-item${
							this.props.layers.showAllLayers ? ' show-all-layers' : ' icon icon-sum_all_layers'
						}`}
						onClick={() => {
							if (!this.props.sketch.mask) {
								if (!this.props.layers.showAllLayers) {
									this.props.layerSetShowAllLayers(true);
								}
							}
						}}>
						{this.props.layers.showAllLayers && <SketchResults showAllLayers={true} />}
					</div>
				)}
				<div ref={el => (this.layersWrapperEl = el)} className='sketch-layers-wrapper'>
					<ul
						ref={el => (this.layersEl = el)}
						className='sketch-layers'
						style={{
							marginLeft: `-${this.state.offset}px`
						}}>
						{this.props.layers.list.map((layer, index) => {
							const classNames = ['layer'];

							if (layer.getColor()) {
								classNames.push(layer.getColor());
							}

							if (this.props.layers.currentIndex === index && !this.props.layers.showAllLayers) {
								classNames.push('active');
								return (
									<li key={index} className={classNames.join(' ')}>
										<SketchResults index={layer.getId()} />
									</li>
								);
							} else {
								if (layer.getPin() && !classNames.includes('active')) {
									classNames.push('pinned');
								}

								return (
									<li
										title='Go to Layer'
										key={index}
										onClick={() => {
											this.changeLayer(index);
											this.props.updateLayer(index);
										}}
										className={classNames.join(' ')}>
										{classNames.includes('pinned') && (
											<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 68.57 100'>
												<path
													d='M84.29,33.81A33.81,33.81,0,0,1,50.47,67.62,33.39,33.39,0,0,1,
													40.53,66L24.38,100l-8.67-4.11L31.8,62A33.81,33.81,0,1,1,84.29,
													33.81Z'
													transform='translate(-15.71)'
												/>
											</svg>
										)}
										{layer.getId()}
									</li>
								);
							}
						})}
					</ul>
				</div>
				{this.state.showArrows && (
					<div
						className={`action-item icon icon-layer_right${this.state.disableRightMove ? ' disabled' : ''}`}
						onClick={!this.state.disableRightMove && this.rightMove.bind(this)}
					/>
				)}
				{!this.props.isEducator &&
					<div
						title='Add a New Drawing Layer'
						onClick={this.addLayer.bind(this)}
						className='action-item icon icon-add_new_layer'
					/>
				}
			</div>
		);
	}

	changeLayer(index) {
		if (this.props.layers.currentIndex !== index || this.props.layers.showAllLayers) {
			this.props.layerChange(index);
		}
	}

	addLayer() {
		this.props.layerAdd();
		this.setState({ isNewLayer: true });
	}

	leftMove() {
		let offset = this.state.offset - 200;
		if (offset < 0) {
			offset = 0;
		}
		this.setState({ offset: offset });
	}

	rightMove() {
		if (!this.layersEl || !this.layersWrapperEl) {
			return;
		}

		let layersBounds = this.layersEl.getBoundingClientRect(),
			layersWrapperBounds = this.layersWrapperEl.getBoundingClientRect(),
			maxOffset = layersBounds.width - layersWrapperBounds.width,
			offset = 0;

		if (maxOffset > 0) {
			offset = this.state.offset + 200;

			if (offset > maxOffset) {
				offset = maxOffset;
			}
		}

		this.setState({ offset: offset });
	}

	componentDidUpdate(prevProps) {
		if (!this.layersEl || !this.layersWrapperEl) {
			return;
		}

		if (prevProps.sizes.width !== this.props.sizes.width) {
			if (this.state.offset) {
				this.setState({ offset: 0 });
			}
		}

		let layersBounds = this.layersEl.getBoundingClientRect(),
			layersWrapperBounds = this.layersWrapperEl.getBoundingClientRect();

		if (layersBounds.width > layersWrapperBounds.width) {
			if (this.state.offset === 0 && !this.state.disableLeftMove) {
				this.setState({ disableLeftMove: true });
			} else if (this.state.offset > 0 && this.state.disableLeftMove) {
				this.setState({ disableLeftMove: false });
			}

			if (layersWrapperBounds.right + 10 > layersBounds.right) {
				if (!this.state.disableRightMove) {
					this.setState({ disableRightMove: true });
				}
			} else {
				if (this.state.disableRightMove) {
					this.setState({ disableRightMove: false });
				}
			}

			if (this.state.showArrows === false) {
				this.setState({ showArrows: true });
			} else if (this.state.isNewLayer) {
				let offset = layersBounds.width - layersWrapperBounds.width;
				this.setState({
					offset: offset,
					isNewLayer: false
				});
			}
		} else if (this.state.showArrows === true) {
			this.setState({ showArrows: false });
		}
	}
}

function mapStateToProps(state, ownProps) {
	const plan = get(state, 'auth.user.subscription.stripe_plan', '');
	const isEducator = plan === SUBSCRIPTION_PLANS.educator;

	return {
		...ownProps,
		layers: state.layers,
		sizes: state.drawArea.stageSizes,
		image: state.image.dynamicImage,
		sketch: state.sketchState.sketch,
		isEducator
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			layerAdd,
			layerChange,
			layerSetShowAllLayers,
			updateLayer
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(SketchLayers);
