import React from 'react';
import TextView from '../../../Util/TextView';
import TextAreaView from '../../../Util/TextAreaView';
import update from 'immutability-helper';
import { ruleRunner, run } from '../../../Validation/ruleRunner';
import { isValidEmail, required, site } from '../../../Validation/rules';
import ApiHelper from '../../../../shared/ApiHelper';
import DocumentMeta from 'react-document-meta';
import { withRouter } from 'react-router-dom';

export const MODAL_REGISTRATION = 'MODAL_REGISTRATION';

const fieldValidations = [
	ruleRunner('name', 'Name', required),
	ruleRunner('email', 'Email Address', required, isValidEmail),
	ruleRunner('plan', 'Plan', required),
	ruleRunner('website', 'Website', required, site)
];

class ModalRegistration extends React.Component {
	constructor(props) {
		super(props);
		this.handleFieldChanged = this.handleFieldChanged.bind(this);
		this.errorFor = this.errorFor.bind(this);
		this.state = {
			name: null,
			email: null,
			plan: null,
			website: null,
			comment: '',

			success: false,
			showErrors: false,
			validationErrors: {},
			globalError: false
		};
	}

	resetGlobalError() {
		this.setState({ globalError: false });
	}

	componentWillMount() {
		this.setState({ validationErrors: run(this.state, fieldValidations) });
	}

	errorFor(field) {
		return this.state.validationErrors[field] || '';
	}

	handleFieldChanged(field) {
		return e => {
			let newState = update(this.state, {
				[field]: { $set: e.target.value }
			});
			newState.validationErrors = run(newState, fieldValidations);
			this.validate(newState, field);
		};
	}

	validate(state, field) {
		return this.setState(state);
	}

	handleSubmit(event) {
		event.preventDefault();

		this.resetGlobalError();

		this.setState({ showErrors: true });

		if (Object.keys(this.state.validationErrors).length) return;

		this.setState({ showErrors: false });

		const user = {
			name: this.state.name.trim(),
			email: this.state.email.trim(),
			website: this.state.website.trim(),
			plan: this.state.plan.trim(),
			comment: this.state.comment.trim()
		};

		ApiHelper.registerApi(user)
			.then(response => {
				if (response.data.success) {
					this.setState({ success: true });
				}
			})
			.catch(error => {
				if (error.response && error.response.data && error.response.data.message) {
					this.setState({ globalError: error.response.data.message });
				}
			});
	}

	render() {
		const success = (
			<div className='success'>
				<h2>Thank You</h2>
				<p>
					for your interest in our API <br />
					we'll let you know when trials are open
				</p>
				<button
					className='btn'
					onClick={() => {
						this.props.modalClose();
						this.props.history.push('/');
					}}>
					homepage
				</button>
			</div>
		);

		const form = (
			<form onSubmit={this.handleSubmit.bind(this)} autoComplete='off'>
				<h2>register</h2>
				<h5>Be notified when the API becomes available</h5>
				{this.state.globalError && (
					<p className='global-error text-center text-danger'>{this.state.globalError}</p>
				)}
				<div className='row'>
					<div className='col-12 mb-3 mt-3'>
						<TextView
							placeholder='Name'
							showError={this.state.showErrors}
							text={this.props.name}
							onFieldChanged={this.handleFieldChanged('name')}
							errorText={this.errorFor('name')}
							autoComplete='off'
						/>
					</div>
					<div className='col-12 mb-3 mt-3'>
						<TextView
							placeholder='Email'
							showError={this.state.showErrors}
							text={this.props.email}
							onFieldChanged={this.handleFieldChanged('email')}
							errorText={this.errorFor('email')}
							autoComplete='off'
						/>
					</div>
					<div className='col-12 mb-3 mt-3'>
						<TextView
							placeholder='Website'
							showError={this.state.showErrors}
							text={this.props.website}
							onFieldChanged={this.handleFieldChanged('website')}
							errorText={this.errorFor('website')}
							autoComplete='off'
						/>
					</div>
					<div className='col-12 mb-3 mt-3'>
						<div className='radio-row'>
							<label>Plan</label>
							<div className={'radio-wrapper'}>
								<label>Maps</label>
								<TextView
									name={'plan'}
									type={'radio'}
									showError={false}
									text={'maps'}
									onFieldChanged={this.handleFieldChanged('plan')}
									autoComplete='off'
								/>
							</div>
							<div className={'radio-wrapper'}>
								<label>
									Maps <br /> Images
								</label>
								<TextView
									name={'plan'}
									type={'radio'}
									showError={false}
									text={'maps images'}
									onFieldChanged={this.handleFieldChanged('plan')}
									autoComplete='off'
								/>
							</div>
							<div className={'radio-wrapper'}>
								<label>
									Maps <br /> Images <br /> Pro
								</label>
								<TextView
									name={'plan'}
									type={'radio'}
									showError={false}
									text={'maps images pro'}
									onFieldChanged={this.handleFieldChanged('plan')}
									autoComplete='off'
								/>
							</div>
						</div>
						{this.state.showErrors && this.errorFor('plan') !== '' ? (
							<div className='validation-error'>
								<span className='text'>{this.errorFor('plan')}</span>
							</div>
						) : null}
					</div>
					<div className='col-12 mb-3 mt-3'>
						<TextAreaView
							placeholder='Comments'
							showError={this.state.showErrors}
							text={this.state.comment}
							onFieldChanged={this.handleFieldChanged('comment')}
							errorText={this.errorFor('comment')}
						/>
					</div>

					<div className='col-12 mb-3 mt-3'>
						<button type='submit'>register</button>
					</div>
				</div>
			</form>
		);

		return (
			<div className='modal-registration'>
				<div className='scroll-wrapper'>
					<DocumentMeta title='Api' />
					{this.state.success ? success : form}
				</div>
			</div>
		);
	}
}

export default withRouter(ModalRegistration);
