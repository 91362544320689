import React from 'react';

export default class PayPerDayEnd extends React.Component {
	render() {
		return (
			<span className='trial-end'>
				{this.props.text && <span>{this.props.text}</span>}
				{this.props.payPerDay.timeLeft.format('HH:mm', { trim: false })}
			</span>
		);
	}
}
