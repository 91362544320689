import React from 'react';

const File = ({size=40, color='#fff'}) => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px"
         viewBox="0 0 75.48 96.89"
         height={size}
         width={size}
         className={'file-icon'}
    >
        <path fill={color} d="M3.39,93.46C1.13,91.18,0,87.76,0,83.21V13.68C0,9.15,1.14,5.74,3.41,3.45C5.68,1.15,9.06,0,13.54,0h21.69
            c1.55,0,2.9,0.11,4.06,0.32s2.23,0.6,3.22,1.16s1.99,1.37,3.02,2.43l26.05,26.4c1.08,1.1,1.9,2.14,2.46,3.13s0.94,2.08,1.14,3.29
            c0.2,1.21,0.3,2.68,0.3,4.41v42.08c0,4.52-1.13,7.93-3.39,10.23c-2.26,2.3-5.64,3.45-10.14,3.45H13.54
            C9.04,96.89,5.65,95.75,3.39,93.46z M61.59,90.67c2.53,0,4.44-0.65,5.73-1.95s1.93-3.18,1.93-5.64V41.13H42.68
            c-2.62,0-4.61-0.66-5.96-1.97c-1.35-1.31-2.02-3.32-2.02-6.01V6.22H13.89c-2.53,0-4.44,0.66-5.73,1.99
            c-1.29,1.32-1.93,3.19-1.93,5.61v69.26c0,2.46,0.64,4.34,1.93,5.64s3.19,1.95,5.7,1.95H61.59z M68.03,35.26L40.57,7.45v25.03
            c0,0.98,0.22,1.69,0.65,2.13c0.43,0.43,1.14,0.65,2.13,0.65H68.03z"
        />
    </svg>
);

export default File;