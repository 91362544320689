import React from 'react'
import { Route } from 'react-router'
import AppPage from '../../Pages/App/AppPage'
import { AppLayout } from '../../Layout/AppLayout'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { SUBSCRIPTION_PLANS } from '../../../shared/constants/plansConstants';
import MainLayout from '../../Layout/MainLayout';
import EducatorApp from '../../Pages/App/EducatorApp/EducatorApp';

class AppPageSwitcher extends React.Component {
    render(){
        const isEducator =
            get(this, 'props.user.subscription.stripe_plan', '') === SUBSCRIPTION_PLANS.educator

        if (isEducator) {
            return (
                <MainLayout>
                    <Route name='Application' exact path='/' component={EducatorApp} />
                </MainLayout>
            )
        }

        return (
            <AppLayout>
                <Route name='Application' exact path='/' component={AppPage} />
            </AppLayout>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        auth: {
            user
        }
    } = state;

    return { user };
}

export default connect(mapStateToProps, null)(AppPageSwitcher)