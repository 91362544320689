export const SKETCH_STOP_DRAG = 'SKETCH_STOP_DRAG';
export const SKETCH_RESET_CURRENT_LINE = 'SKETCH_RESET_CURRENT_LINE';
export const SKETCH_RESET_CURRENT_SHAPE = 'SKETCH_RESET_CURRENT_SHAPE';
export const SKETCH_SET_CURRENT_LINE = 'SKETCH_SET_CURRENT_LINE';
export const SKETCH_SET_CURRENT_SHAPE = 'SKETCH_SET_CURRENT_SHAPE';
export const SKETCH_UPDATE_SKETCH = 'SKETCH_UPDATE_SKETCH';
export const SKETCH_POINT_MOUSE_DOWN = 'SKETCH_POINT_MOUSE_DOWN';
export const SKETCH_RESET_ACTIVE_POINT = 'SKETCH_RESET_ACTIVE_POINT';
export const SKETCH_RESET_SUPPORT_POINT = 'SKETCH_RESET_SUPPORT_POINT';
export const SKETCH_SUPPORT_POINT_MOUSE_DOWN = 'SKETCH_SUPPORT_POINT_MOUSE_DOWN';
export const SKETCH_ANGLE_MOUSE_DOWN = 'SKETCH_ANGLE_MOUSE_DOWN';
export const SKETCH_UPDATE_POINT = 'SKETCH_UPDATE_POINT';
export const SKETCH_UPDATE_LAT_LNG = 'SKETCH_UPDATE_LAT_LNG';
export const SKETCH_CURVED_LINE_PUSH_POINT = 'SKETCH_CURVED_LINE_PUSH_POINT';
export const SKETCH_SET_WILL_CLOSE = 'SKETCH_SET_WILL_CLOSE';
export const SKETCH_RESET = 'SKETCH_RESET';
export const SKETCH_SET_CURRENT_MASK = 'SKETCH_SET_CURRENT_MASK';
export const SKETCH_SET_MAGIC_WAND_TOLERANCE = 'SKETCH_SET_MAGIC_WAND_TOLERANCE';
export const SKETCH_SET_MAGIC_WAND_BLUR = 'SKETCH_SET_MAGIC_WAND_BLUR';
export const SKETCH_SET_MAGIC_WAND_COMPOUND = 'SKETCH_SET_MAGIC_WAND_COMPOUND';
export const RESET_CURRENT_MASK = 'RESET_CURRENT_MASK';
export const CONFIRM_CURRENT_MASK = 'CONFIRM_CURRENT_MASK';
export const SKETCH_ADD_TO_MASK = 'SKETCH_ADD_TO_MASK';
export const SKETCH_SUB_FROM_MASK = 'SKETCH_SUB_FROM_MASK';
export const SKETCH_SET_DRAG_INDEX = 'SKETCH_SET_DRAG_INDEX';
