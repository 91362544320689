import React from 'react';

const Archive = ({size=40, color='#fff'}) => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px"
         viewBox="0 0 1024 1024"
         height={size}
         width={size}
         className={'file-icon'}
    >
        <path fill={color} d="M896 938.666667H128c-25.6 0-42.666667-17.066667-42.666667-42.666667V341.333333c0-25.6
            17.066667-42.666667 42.666667-42.666666s42.666667 17.066667 42.666667 42.666666v512h682.666666V341.333333c0-25.6
            17.066667-42.666667 42.666667-42.666666s42.666667 17.066667 42.666667 42.666666v554.666667c0 25.6-17.066667 42.666667-42.666667 42.666667z"
        />
        <path fill={color} d="M981.333333 384H42.666667c-25.6 0-42.666667-17.066667-42.666667-42.666667V128c0-25.6
            17.066667-42.666667 42.666667-42.666667h938.666666c25.6 0 42.666667 17.066667 42.666667 42.666667v213.333333c0 25.6-17.066667
            42.666667-42.666667 42.666667zM85.333333 298.666667h853.333334V170.666667H85.333333v128zM597.333333 554.666667h-170.666666c-25.6
            0-42.666667-17.066667-42.666667-42.666667s17.066667-42.666667 42.666667-42.666667h170.666666c25.6 0 42.666667 17.066667 42.666667
            42.666667s-17.066667 42.666667-42.666667 42.666667z"
        />
    </svg>
);

export default Archive;