import React from 'react';
import Art1 from './steps/Art1';
import Art2 from './steps/Art2';
import Art3 from './steps/Art3';
import Art4 from './steps/Art4';
import Art5 from './steps/Art5';
import Art6 from './steps/Art6';
import Art7 from './steps/Art7';
import Art8 from './steps/Art8';
import Art9 from './steps/Art9';
import './LoaderStyle.scss';

class Loader extends React.Component {
    handleTimeout = null;

    constructor(props) {
        super(props);

        const loaderDefaultStyles = {
            height: `${props.size || 100}px`,
            width: `${props.size || 100}px`,
            color: '#e200bc'
        }
        const styles = { ...loaderDefaultStyles, ...props };
        delete styles.modes

        this.state = {
            artIndex:1,
            styles
        }
    }

    setArt = () => {
        const { artIndex } = this.state;
        let newIndex = 1;

        if (artIndex<9) {
            newIndex = artIndex + 1;
        }

        this.setState({artIndex: newIndex})
    }

    componentDidMount() {
        this.handleTimeout = window.setInterval(this.setArt, 400);
    }

    clearTimeout() {
        if (this.handleTimeout) {
            window.clearInterval(this.handleTimeout);
            this.handleTimeout = null;
        }
    }

    componentWillUnmount() {
        this.clearTimeout()
    }

    getArt = () => {
        const { artIndex } = this.state;

        switch (artIndex) {
            case 1: return Art1
            case 2: return Art2
            case 3: return Art3
            case 4: return Art4
            case 5: return Art5
            case 6: return Art6
            case 7: return Art7
            case 8: return Art8
            case 9: return Art9
            default: return Art1
        }
    }

    render() {
        const { styles } = this.state;
        const Art = this.getArt();

        return (
            <div className={`loader-block ${this.props.modes}`} onClick={e => {e.stopPropagation(); e.preventDefault()}}>
                <div style={styles}>
                    <Art {...styles}/>
                </div>
            </div>
        )
    }
}

export default Loader