import React from 'react';

const Art3 = ({color="#fff"}) => {

        return (
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                <path
                    style={{ fill: color }}
                    className="cls-1"
                    d="M69.67,112.63a5.33,5.33,0,0,0,3.86-1.32,5.11,5.11,0,0,0,1.35-3.82H86.05a13.06,13.06,0,0,1-4.51,10.41A17.13,17.13,0,0,1,70,121.79q-8.81,0-13.33-5.27t-4.53-14.07v-.81q0-8.77,4.51-14.08t13.28-5.3q7.4,0,11.78,4t4.39,11.27H74.88a7,7,0,0,0-1.32-4.44,4.73,4.73,0,0,0-4-1.69c-2.31,0-3.82.88-4.55,2.64A20.61,20.61,0,0,0,64,101.64v.81a20.1,20.1,0,0,0,1.11,7.63Q66.18,112.63,69.67,112.63Z"/>
                <path
                    style={{ fill: color }}
                    className="cls-1"
                    d="M101.94,83l.42,4.86.11,0a12,12,0,0,1,4.42-4.15,12.83,12.83,0,0,1,6.25-1.45,11.73,11.73,0,0,1,6.15,1.52,9.61,9.61,0,0,1,3.86,4.75,12.74,12.74,0,0,1,4.51-4.67,12.55,12.55,0,0,1,6.48-1.6q5.67,0,8.8,3.75t3.14,11.86v23.21H134.14V97.87c0-2.51-.37-4.22-1.11-5.11a4.27,4.27,0,0,0-3.47-1.34,7.28,7.28,0,0,0-3.07.6,6.14,6.14,0,0,0-2.22,1.73,10,10,0,0,0,0,1c0,.28,0,.57,0,.88v25.43h-11.9V97.87c0-2.51-.37-4.22-1.11-5.11a4.31,4.31,0,0,0-3.51-1.34,6.89,6.89,0,0,0-2.94.6,6.57,6.57,0,0,0-2.24,1.73v27.33H90.73V83Z"/>
                <rect style={{ fill: color }} className="cls-1" width="28.5" height="28.5"/>
                <line style={{ stroke: color, strokeMiterlimit:10, strokeWidth: '5px' }} className="cls-2" x1="28.5" y1="14.25" x2="171.5" y2="14.25"/>
            </svg>
        )
}

export default Art3