import React from 'react';
import DocumentMeta from 'react-document-meta';
import cookie from 'js-cookie';
import ApiHelper from '../../../shared/ApiHelper';
import get from 'lodash/get';

export default class Verify extends React.Component {
	componentWillMount() {
		ApiHelper.verify(this.props.match.params.token)
			.then(response => {
				if (response.data.success && response.data.accessToken) {
					cookie.set('accessToken', response.data.accessToken, {
						expires: 1
					});
					ApiHelper.updateAccessToken(response.data.accessToken);
					const queryStr = get(this, 'props.history.location.search', '');
					this.props.history.push(`/register-complete${queryStr}`);
				} else {
					this.props.history.push('/login');
				}
			})
			.catch(() => {
				this.props.history.push('/login');
			});
	}

	render() {
		return (
			<div className="form-wrapper">
				<DocumentMeta title="Verify Account" />
				<div className="verify">
					<h2>Nearly there...</h2>
					<p>The account verification process is in progress...</p>
				</div>
			</div>
		);
	}
}
