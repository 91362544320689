import layerTypes from '../constants/layerConstants';
import * as instrumentTypes from '../classes/Instruments';
import { sketchRefreshPoints, sketchUpdateSketch } from './SketchActions';
import { noteRefreshPoints, noteUpdateList } from './NoteActions';
import { historySetLabelsVisible, historyUpdate, updateInstrumentBasedOnLastLine } from './historyActions';
import { instrumentChange } from '../reducers/instrumentReducer';
import { mapRefreshZoomView } from './mapActions';
import { updateFileState } from './fileStateAction';
import Sketch from '../classes/Sketch';
import { setShouldDragLayer } from './drawAreaActions';

export function layerUpdate(layer, index) {
	return {
		type: layerTypes.LAYER_UPDATE,
		payload: { layer, index }
	};
}

export function layerSaveCurrentSketch() {
	return (dispatch, state) => {
		let { layers, sketchState, notes, history } = state(),
			layer = layers.list[layers.currentIndex];

		if (layer) {
			layer = layer.clone();

			layer.setSketch(sketchState.sketch);
			layer.setNotes(notes.list);
			layer.setHistory(history);

			dispatch(layerUpdate(layer, layers.currentIndex));
		}
	};
}

export function layerSetLabelsVisible(value) {
	return (dispatch, state) => {
		const { layers, sketchState } = state(),
			newSketch = Sketch.restoreFromDump(sketchState.sketch.convertForSave());

		newSketch.labelsVisible = value;

		dispatch(sketchUpdateSketch(newSketch));
		dispatch(historySetLabelsVisible(value));
		dispatch(
			layerSetListLayers(
				layers.list.map(layer => {
					const newLayer = layer.clone();

					newLayer.history.history = newLayer.history.history.map(item => {
						return { ...item, labelsVisible: value };
					});

					newLayer.sketch.labelsVisible = value;

					return newLayer;
				})
			)
		);
		dispatch(updateFileState(true));
	};
}

export function layerRestoreCurrentSketch() {
	return (dispatch, state) => {
		let { layers, map } = state(),
			layer = layers.list[layers.currentIndex];

		if (layer) {
			let sketch = layer.getSketch(),
				notes = layer.getNotes();

			if (notes.length) {
				notes.forEach(note => {
					note.point = note.adjustScale();
				});
			}

			dispatch(sketchUpdateSketch(sketch));
			dispatch(noteUpdateList(notes));
			dispatch(historyUpdate(layer.getHistory()));

			if (sketch.closed || sketch.angleClosed) {
				dispatch(instrumentChange(instrumentTypes.SELECT_TOOL));
			} else {
				updateInstrumentBasedOnLastLine(dispatch, sketch);
			}

			if (map.visible) {
				dispatch(sketchRefreshPoints());
				dispatch(noteRefreshPoints());
				dispatch(mapRefreshZoomView());
			}
		}
	};
}

export function layerDeleteCurrentSketch() {
	return (dispatch, state) => {
		let layers = state().layers,
			index = layers.currentIndex;

		if (layers.list.length <= 1) {
			dispatch(layerReset());
			dispatch(layerRestoreCurrentSketch());
		} else {
			dispatch(layerDelete(index));

			layers = state().layers;

			if (layers.showAllLayers) {
				dispatch(layerSetShowAllLayers(false));
			}

			if (index >= layers.list.length) {
				index = layers.list.length - 1;
				layers.list[index].setHidden(true);
				dispatch({
					type: layerTypes.LAYER_CHANGE,
					payload: { index }
				});
			}

			dispatch(layerRestoreCurrentSketch());
			dispatch(updateFileState(true));
		}
	};
}

export function layerIsEmptyAllLayers(withImage = true) {
	return (dispatch, state) => {
		const { image, notes, sketchState, layers } = state();

		return new Promise(resolve => {
			let isEmpty = true;

			if (withImage && (image.url || image.file)) {
				isEmpty = false;
			}

			if (
				notes.list.length ||
				sketchState.sketch.lineExists() ||
				sketchState.sketch.angleLineExists() ||
				sketchState.sketch.isCircle()
			) {
				isEmpty = false;
			}

			for (const layer of layers.list) {
				if (
					layer.sketch.lineExists() ||
					layer.sketch.angleLineExists() ||
					layer.notes.length ||
					layer.sketch.isCircle()
				) {
					isEmpty = false;
					break;
				}
			}

			resolve(isEmpty);
		});
	};
}

export function layerDelete(index) {
	return {
		type: layerTypes.LAYER_DELETE,
		payload: { index }
	};
}

export function layerChange(index) {
	return (dispatch, state) => {
		if (state().sketchState.sketch.mask) {
			//ToDo: show some error
		} else {
			dispatch(layerSetShowAllLayers(false));
			dispatch(layerSaveCurrentSketch());
			dispatch(layerChangeIndex(index));
			dispatch(layerRestoreCurrentSketch());
		}
	};
}

export function layerChangeIndex(index) {
	return {
		type: layerTypes.LAYER_CHANGE,
		payload: { index }
	};
}

export function layerSetShowAllLayers(value) {
	return (dispatch, state) => {
		let { instrument, map } = state();

		if (value) {
			if (![instrumentTypes.SELECT_TOOL, instrumentTypes.PAN_MOVE].includes(instrument)) {
				dispatch(instrumentChange(instrumentTypes.SELECT_TOOL));
			}

			dispatch(layerSaveCurrentSketch());

			if (map.visible) {
				dispatch(layerRefreshLayers());
			}
		}

		dispatch({
			type: layerTypes.LAYER_SET_SHOW_ALL_LAYERS,
			payload: { value }
		});
		dispatch(updateFileState(true));
	};
}

export function layerAdjustScaleNotes() {
	return (dispatch, state) => {
		let layers = state().layers.list;

		if (layers.length) {
			layers = layers.map(layer => {
				const newLayer = layer.clone(),
					notes = newLayer.notes;

				if (notes.length) {
					notes.forEach(note => {
						note.point = note.adjustScale();
					});
				}

				newLayer.notes = notes;

				return newLayer;
			});
			dispatch(layerSetListLayers(layers));
		}
	};
}

export function layerUpdateLayersByPan(pan) {
	return (dispatch, state) => {
		let layers = state().layers.list;

		if (layers.length) {
			layers = layers.map(layer => {
				const newLayer = layer.clone(),
					sketch = newLayer.sketch,
					notes = newLayer.notes;

				sketch.updatePointsByPan(pan);

				if (notes.length) {
					notes.forEach(note => note.updatePointsByPan(pan));
				}

				newLayer.sketch = sketch;
				newLayer.notes = notes;

				return newLayer;
			});

			dispatch(layerSetListLayers(layers));
		}
	};
}

export function layerRefreshLayers() {
	return (dispatch, state) => {
		let layers = state().layers.list;
		const currentLayerId = state().layers.currentIndex + 1;

		if (layers.length) {
			layers = layers.map(layer => {
				const newLayer = layer.clone(),
					sketch = newLayer.sketch,
					notes = newLayer.notes,
					pinned = newLayer.getPin();

				sketch.refreshPoints();

				if (notes.length) {
					notes.forEach(note => note.refreshPoints());
				}

				if (pinned && currentLayerId === newLayer.getId()) {
					newLayer.setHidden(true);
				}

				newLayer.sketch = sketch;
				newLayer.notes = notes;

				return newLayer;
			});

			dispatch(layerSetListLayers(layers));
		}
	};
}

export function layerSetColor(color) {
	return (dispatch, state) => {
		let { layers } = state(),
			layer = layers.list[layers.currentIndex];

		if (layer) {
			layer = layer.clone();

			layer.setColor(color);

			dispatch(layerUpdate(layer, layers.currentIndex));
			dispatch(updateFileState(true));
		}
	};
}

export function layerToggleVisibility(index) {
	return (dispatch, state) => {
		let { layers } = state(),
			layer = layers.list[index];

		if (layer) {
			layer = layer.clone();

			layer.setVisibility(!layer.isVisible());

			dispatch(layerUpdate(layer, index));
			dispatch(updateFileState(true));
		}
	};
}

export function layerSetListLayers(list) {
	return {
		type: layerTypes.LAYER_SET_LIST_LAYERS,
		payload: { list }
	};
}

export function layerSelectLastLayer() {
	return (dispatch, state) => {
		dispatch(layerChange(state().layers.list.length - 1));
	};
}

export function layerAdd() {
	return (dispatch, state) => {
		if (state().sketchState.sketch.mask) {
			//ToDo: show some error
		} else {
			dispatch({ type: layerTypes.LAYER_ADD });
			dispatch(layerSelectLastLayer());
			dispatch(updateFileState(true));
		}
	};
}

export function layerDuplicate() {
	return (dispatch, state) => {
		if (state().sketchState.sketch.mask) {
			//ToDo: show some error
		} else {
			dispatch({ type: layerTypes.LAYER_DUPLICATE });
			//dispatch(layerSelectLastLayer());
			dispatch(updateFileState(true));
		}
	};
}

export function layerReset() {
	return { type: layerTypes.LAYER_RESET };
}

export function togglePinLayer(layerId) {
	return (dispatch) => {
		dispatch({
			type: layerTypes.TOGGLE_PIN_LAYER,
			payload: { layerId }
		});
		dispatch(updateFileState(true));
	};
}

export function updateLayer(index) {
	return (dispatch, state) => {
		let layers = state().layers.list;

		layers[index].setHidden(true);

		dispatch(layerSetListLayers(layers));
	};
}
