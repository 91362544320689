import React from 'react';
import moment from 'moment';
import DocumentMeta from 'react-document-meta';
import ApiHelper from '../../../shared/ApiHelper';

export default class Post extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			post: null,
			meta: null
		};
	}

	componentWillMount() {
		ApiHelper.post(this.props.match.params.slug)
			.then(response => {
				if (response.data.success) {
					const post = response.data.post;

					if (post) {
						let meta = {
							title: post.title
						};

						if (post.meta) {
							if (post.meta.title) {
								meta.title = post.meta.title;
							}
							if (post.meta.canonical) {
								meta.canonical = post.meta.canonical;
							}
							if (post.meta.metadesc) {
								meta.description = post.meta.metadesc;
							}
							if (post.meta.metakeywords || post.meta.focuskw) {
								meta.meta = {
									name: {
										keywords: post.meta.metakeywords || post.meta.focuskw
									}
								};
							}
						}

						this.setState({ post, meta });
					} else {
						this.props.history.push('/blog');
					}
				} else {
					this.props.history.push('/blog');
				}
			})
			.catch(() => {
				this.props.history.push('/blog');
			});
	}

	render() {
		return (
			<section className='blog'>
				{this.state.post && (
					<article className='post details'>
						<h2 className='title'>{this.state.post.title}</h2>
						<div className='desc'>
							<div className='date'>
								Posted <span>{moment(this.state.post.date).format('LL')}</span>
							</div>
							<div className='author'>
								By <span>{this.state.post.author}</span>
							</div>
						</div>
						{this.state.post.image && this.state.post.image.url && (
							<img
								src={this.state.post.image.url}
								title={this.state.post.image.title}
								alt={this.state.post.image.alt}
							/>
						)}
						<div
							className='content'
							dangerouslySetInnerHTML={{
								__html: this.state.post.content
							}}
						/>
						{this.state.post.comments && (
							<div className='comments'>
								{this.state.post.comments.map((comment, index) => {
									return (
										<div key={index} className='comment'>
											<q
												dangerouslySetInnerHTML={{
													__html: comment.content
												}}
											/>
											<p className='author'>
												{moment(comment.date).format('LL')}
												<span className='sep' />
												{comment.name}
											</p>
										</div>
									);
								})}
							</div>
						)}
						<DocumentMeta {...this.state.meta} />
					</article>
				)}
			</section>
		);
	}
}
