// O(n^2) solution to find the Manhattan distance to "on" pixels in a two dimension array
function manhattan(image) {
	// traverse from top left to bottom right
	for (let i = 0; i < image.length; i++) {
		for (let j = 0; j < image[i].length; j++) {
			if (image[i][j] == 1) {
				// first pass and pixel was on, it gets a zero
				image[i][j] = 0;
			} else {
				// pixel was off
				// It is at most the sum of the lengths of the array
				// away from a pixel that is on
				image[i][j] = image.length + image[i].length;
				// or one more than the pixel to the north
				if (i > 0) image[i][j] = Math.min(image[i][j], image[i - 1][j] + 1);
				// or one more than the pixel to the west
				if (j > 0) image[i][j] = Math.min(image[i][j], image[i][j - 1] + 1);
			}
		}
	}
	// traverse from bottom right to top left
	for (let i = image.length - 1; i >= 0; i--) {
		for (let j = image[i].length - 1; j >= 0; j--) {
			// either what we had on the first pass
			// or one more than the pixel to the south
			if (i + 1 < image.length) image[i][j] = Math.min(image[i][j], image[i + 1][j] + 1);
			// or one more than the pixel to the east
			if (j + 1 < image[i].length) image[i][j] = Math.min(image[i][j], image[i][j + 1] + 1);
		}
	}
	return image;
}

function dilate(image, k) {
	image = manhattan(image);

	for (let i = 0; i < image.length; i++) {
		for (let j = 0; j < image[i].length; j++) {
			image[i][j] = image[i][j] <= k ? 1 : 0;
		}
	}

	return image;
}

function maskToMap(mask, w, h) {
	let map = [];

	for (let i = 0; i < mask.height; i++) {
		map[i] = [];
		for (let j = 0; j < mask.width; j++) {
			map[i][j] = mask.data[i * mask.width + j];
		}
	}

	return map;
}

function mapToMask(map) {
	let mask = {
		data: [],
		height: map.length,
		width: map[0].length,
		bounds: {
			maxX: 0,
			maxY: 0,
			minX: 0,
			minY: 0
		}
	};

	for (let y = 0; y < mask.height; y++) {
		for (let x = 0; x < mask.width; x++) {
			mask.data.push(map[y][x]);
			if (map[y][x]) {
				mask.bounds.maxX = Math.max(mask.bounds.maxX, x);
				mask.bounds.maxY = Math.max(mask.bounds.maxY, y);
				mask.bounds.minX = Math.min(mask.bounds.minX, x);
				mask.bounds.minY = Math.min(mask.bounds.minY, y);
			}
		}
	}

	return mask;
}

export default function dilateMask(mask, k) {
	return mapToMask(dilate(maskToMap(mask), k));
}
