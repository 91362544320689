import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {mapControlChangeType, mapControlTypes} from "../../shared/reducers/MapControlReducer";
import {mapTypes} from "../../shared/constants/mapConstants";
import {drawAreaToggleVisibility} from "../../shared/actions/drawAreaActions";
import {mapSetType, mapToggleVisibility, mapUpdateCenter, mapUpdateMaxZoom} from "../../shared/actions/mapActions";
import {layerIsEmptyAllLayers} from "../../shared/actions/layerActions";
import {modalOpen} from "../../shared/reducers/ModalReducer";
import {MODAL_TOGGLE_MAP} from "../ModalContainer/Modals/ModalToggleMap/ModalToggleMap";

class MapControls extends React.Component {

    geocoder = null;
    placesInput = null;
    placesAutocomplete = null;
    latInput = null;
    lngInput = null;

    onPaste = e => {
        try {
            const pastedValue = e.clipboardData.getData('Text');
            const ev = new Event('input', {bubbles: true});
            this.placesInput.value = pastedValue;
            this.placesInput.dispatchEvent(ev);
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        return (
            <div className="map-controls form-inline">
                <div className={`form-group ${mapControlTypes.MAP_TYPE}${this.setActiveTab(mapControlTypes.MAP_TYPE)}`}>
                    <button onClick={() => {this.changeActiveTab(mapControlTypes.MAP_TYPE)}} className="btn icon-map_icn"/>
                    <div className="controls">
                        <div className="btn-group" role="group">
                            <button onClick={this.hideMap.bind(this)} type="button" className="btn btn-secondary">Hide</button>
                            <button onClick={() => {this.changeMapType(mapTypes.ROADMAP)}} type="button" className={`btn btn-secondary${this.setCurrentMapType(mapTypes.ROADMAP)}`}>Map</button>
                            <button onClick={() => {this.changeMapType(mapTypes.HYBRID)}} type="button" className={`btn btn-secondary${this.setCurrentMapType(mapTypes.HYBRID)}`}>Hybrid</button>
                            <button onClick={() => {this.changeMapType(mapTypes.SATELLITE)}} type="button" className={`btn btn-secondary${this.setCurrentMapType(mapTypes.SATELLITE)}`}>Satellite</button>
                        </div>
                    </div>
                </div>
                <div className={`form-group ${mapControlTypes.GRID_VISIBILITY}${this.setActiveTab(mapControlTypes.GRID_VISIBILITY)}`}>
                    <button onClick={() => {this.changeActiveTab(mapControlTypes.GRID_VISIBILITY)}} className="btn  icon-grid_icn"/>
                    <div className="controls">
                        <button onClick={this.props.drawAreaToggleVisibility} type="button" className="btn btn-secondary">{this.props.gridVisible ? 'Hide' : 'Show'}</button>
                    </div>
                </div>
                <div className={`form-group ${mapControlTypes.FIND_BY_COORDINATE}${this.setActiveTab(mapControlTypes.FIND_BY_COORDINATE)}`}>
                    <button onClick={() => {this.changeActiveTab(mapControlTypes.FIND_BY_COORDINATE)}} className="btn icon-long_lat_icn"/>
                    <div className="controls">
                        <div className="form-inline">
                            <label htmlFor="latitude">Latitude</label>
                            <input type="number" className="form-control" id="latitude" placeholder={this.props.mapCenter.lat} ref={ el => this.latInput = el }/>
                            <label htmlFor="longitude">Longitude</label>
                            <input type="number" className="form-control" id="longitude" placeholder={this.props.mapCenter.lng} ref={ el => this.lngInput = el }/>
                            <button onClick={this.changeCenter.bind(this)} className="btn icon-submit_long_lat"/>
                        </div>
                    </div>
                </div>
                <div className={`form-group ${mapControlTypes.FIND_BY_ADDRESS}${this.setActiveTab(mapControlTypes.FIND_BY_ADDRESS)}`}>
                    <div className="controls">
                        <input
                            type="text"
                            name="places"
                            className="form-control"
                            placeholder="Search Maps"
                            ref={ el => this.placesInput = el }
                            onPaste={this.onPaste}
                        />
                    </div>
                    <button onClick={() => {this.changeActiveTab(mapControlTypes.FIND_BY_ADDRESS)}} className="btn icon-search_map"/>
                </div>
            </div>
        )
    }

    hideMap() {
        this.props.layerIsEmptyAllLayers().then(response => {
            if (response) {
                this.props.mapToggleVisibility();
            } else {
                this.props.modalOpen(MODAL_TOGGLE_MAP);
            }
        });
    }

    setActiveTab(type) {
        return this.props.activeTab === type ? ' active' : '';
    }

    setCurrentMapType(type) {
        return this.props.mapType === type ? ' active' : '';
    }

    changeActiveTab(type) {
        if (this.props.activeTab === type) {
            this.props.mapControlChangeType(null);
        } else {
            this.props.mapControlChangeType(type);
        }
    }

    changeMapType(type) {
        if (this.props.mapType !== type) {
            this.props.mapSetType(type);
        }
    }

    inRange(number, min, max) {
        return !isNaN(number) && (number >= min) && (number <= max);
    }

    changeCenter() {
        let lat = this.latInput.value ? this.latInput.value : this.props.mapCenter.lat,
            lng = this.lngInput.value ? this.lngInput.value : this.props.mapCenter.lng;

        if (this.props.mapCenter.lat === lat && this.props.mapCenter.lng === lng){
            return;
        }

        if (this.inRange(lat, -90, 90) && this.inRange(lng, -180, 180)){
            this.props.mapUpdateCenter(lat, lng);
        }
    }

    componentDidMount() {

        this.geocoder = new window.google.maps.Geocoder();

        this.placesAutocomplete = new window.google.maps.places.Autocomplete(
            this.placesInput, {types: ['geocode']}
        );

        this.placesAutocomplete.addListener('place_changed', this.placeChanged.bind(this))

    }

    placeChanged() {
        let geometry = this.placesAutocomplete.getPlace().geometry;
        if (geometry) {
            this.props.mapUpdateMaxZoom(true);
            this.props.mapUpdateCenter(geometry.location.lat(), geometry.location.lng());
        }
        else if (this.geocoder) {
            this.geocoder.geocode({'address': this.placesInput.value}, (results, status) => {
                if (status === 'OK') {
                    let formatted_address = results[0].formatted_address,
                        location = results[0].geometry.location;

                    if (formatted_address) {
                        this.placesInput.value = formatted_address;
                    }

                    if (location) {
                        this.props.mapUpdateMaxZoom(true);
                        this.props.mapUpdateCenter(location.lat(), location.lng());
                    }
                }
            });
        }
    }

}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        mapType: state.map.type,
        mapCenter: state.map.center,
        activeTab: state.mapControl.type,
        gridVisible: state.drawArea.visible
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        mapUpdateCenter,
        mapControlChangeType,
        drawAreaToggleVisibility,
        mapSetType,
        mapUpdateMaxZoom,
        mapToggleVisibility,
        layerIsEmptyAllLayers,
        modalOpen
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MapControls);