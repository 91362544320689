import * as noteTypes from '../constants/NoteConstants';
import store from '../store';
import { getEventPoint } from '../classes/pointsFunc';
import { ARROW_POSITIONS } from '../classes/Note';
import MapHelper from '../MapHelper';
import {updateFileState} from './fileStateAction';

export function noteAdd(point, latLng) {
	return dispatch => {
		dispatch({
			type: noteTypes.NOTE_ADD,
			payload: {point, latLng}
		});
		dispatch(updateFileState(true));
	};
}

export function noteStartEdit(index) {
	return {
		type: noteTypes.NOTE_START_EDIT,
		payload: { index }
	};
}

export function noteMove(event) {
	return dispatch => {
		let state = store.getState(),
			noteState = state.notes,
			map = state.map;

		let note = noteState.list[noteState.movableNote].clone(),
			newMovePoint = getEventPoint(event),
			startMovePoint = noteState.startMovePoint;

		note.point = [
			note.position[0] + newMovePoint[0] - startMovePoint[0],
			note.position[1] + newMovePoint[1] - startMovePoint[1]
		];

		note.originPoint = note.adjustScale(true);

		if (map.visible) {
			note.latLng = MapHelper.point2LatLng(note.position);
		}

		dispatch(noteUpdate(note, noteState.movableNote));

		dispatch({
			type: noteTypes.NOTE_MOVE,
			payload: { newMovePoint }
		});
		dispatch(updateFileState(true));
	};
}

export function noteStartMove(event, index) {
	return {
		type: noteTypes.NOTE_START_MOVE,
		payload: { index, event }
	};
}

export function noteEndMove(index) {
	return dispatch => {
		dispatch({
			type: noteTypes.NOTE_END_MOVE,
			payload: {index}
		});
		dispatch(updateFileState(true));
	};
}

export function noteAdjustZoom() {
	return {
		type: noteTypes.NOTE_ADJUST_ZOOM
	};
}

export function noteUpdateText(event, index) {
	return dispatch => {
		const textArea = event.nativeEvent.target;

		if (textArea.textLength > 150) {
			return;
		}

		const notesState = store.getState().notes;
		const editableNote = notesState.list[index].clone();
		let newHeight = textArea.scrollHeight + 9;

		if (newHeight < editableNote.height) {
			newHeight = editableNote.height - 18;
		}

		editableNote.setHeight(newHeight);
		editableNote.text = textArea.value;

		dispatch(noteUpdate(editableNote, index));
		dispatch(updateFileState(true));
	};
}

export function noteUpdate(note, index) {
	return {
		type: noteTypes.NOTE_UPDATE,
		payload: {
			index,
			note
		}
	};
}

export function noteEndEdit() {
	return {
		type: noteTypes.NOTE_END_EDIT
	};
}

export function noteDelete(index) {
	return dispatch => {
		dispatch({
			type: noteTypes.NOTE_DELETE,
			payload: {index}
		});
		dispatch(updateFileState(true));
	};
}

export function noteRotateArrow(index) {
	return dispatch => {
		let note = store.getState().notes.list[index].clone();

		note.arrowPosition++;

		if (note.arrowPosition > ARROW_POSITIONS.length - 1) {
			note.arrowPosition = 0;
		}

		dispatch(noteUpdate(note, index));
		dispatch(updateFileState(true));
	};
}

export function noteOver(index) {
	return {
		type: noteTypes.NOTE_OVER,
		payload: { index }
	};
}

export function noteMouseOut(index) {
	return {
		type: noteTypes.NOTE_MOUSE_OUT,
		payload: { index }
	};
}

export function noteReset() {
	return {
		type: noteTypes.NOTE_RESET
	};
}

export function noteRefreshPoints() {
	return (dispatch, state) => {
		let notes = state().notes.list.slice(0);

		notes.forEach(note => note.refreshPoints());

		if (notes.length) {
			dispatch(noteUpdateList(notes));
		}
	};
}

export function noteUpdatePointsByPan(pan) {
	return (dispatch, state) => {
		let notes = state().notes.list.slice(0);

		notes.forEach(note => note.updatePointsByPan(pan));

		if (notes.length) {
			dispatch(noteUpdateList(notes));
		}
	};
}

export function noteUpdateList(list) {
	return {
		type: noteTypes.NOTE_UPDATE_LIST,
		payload: { list }
	};
}
