import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { helpActivateScale1 } from '../../../shared/actions/helpActions';
import { scaleToolEl } from '../../App/InsturmentMenu/InstrumentMenu';
import { svgEl } from '../../App/DrawArea/DrawArea';

export let renderSetScale1;

class SetScale1 extends React.Component {
	componentWillMount() {
		renderSetScale1 = this.forceRender.bind(this);
	}

	componentWillUnmount() {
		renderSetScale1 = null;
	}

	forceRender() {
		this.forceUpdate();
	}

	render() {
		if (!scaleToolEl || !svgEl) {
			return null;
		}

		const elBounds = scaleToolEl.getBoundingClientRect(),
			svgBounds = svgEl.getBoundingClientRect(),
			style = { top: elBounds.top - svgBounds.top };

		let arrow = 'arrow-right';

		if (elBounds.left < 300) {
			style.left = elBounds.left + elBounds.width + 15;
			arrow = 'arrow-left';
		} else {
			style.left = elBounds.left - 290;
		}

		return (
			<div style={style} className={`contextual-modal ${arrow}`}>
				<h3 className='title'>
					Set Scale <span>1/3</span>
				</h3>
				<div className='content'>
					Identify and draw a known length
					<br />
					using the rule tool <span className='icon blue icon-scale_tool' />
				</div>
				<div className='actions'>
					<button
						onClick={() => {
							this.props.helpActivateScale1();
						}}
						className='btn btn-sm btn-modal'>
						ok
					</button>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			helpActivateScale1
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(SetScale1);
