const SETTINGS_TOGGLE_DISPLAY_LINE_LENGTH = 'SETTINGS_TOGGLE_DISPLAY_LINE_LENGTH';
const SETTINGS_SET_DISPLAY_ANGLES = 'SETTINGS_SET_DISPLAY_ANGLES';
const SETTINGS_SET_GRID_TRANSPARENCY = 'SETTINGS_SET_GRID_TRANSPARENCY';
const SETTINGS_SET_DRAWING_COLOR = 'SETTINGS_SET_DRAWING_COLOR';
const SETTINGS_SET_LINE_WEIGHT = 'SETTINGS_SET_LINE_WEIGHT';
const SETTINGS_SET_MEASUREMENT_SYSTEM = 'SETTINGS_SET_MEASUREMENT_SYSTEM';
const SETTINGS_SET_IMPERIAL = 'SETTINGS_SET_IMPERIAL';
const SETTINGS_UPDATE = 'SETTINGS_UPDATE';
const ACCEPT_COOKIES = 'ACCEPT_COOKIES';
const SETTINGS_SET_MARKETING_COOKIES = 'SETTINGS_SET_MARKETING_COOKIES';

const DISPLAY_ANGLE_OFF = 'Off';
const DISPLAY_ANGLE_SMALL = 'small';
const DISPLAY_ANGLE_MED = 'med';
const DISPLAY_ANGLE_LARGE = 'large';

export const displayAngleValues = {
	DISPLAY_ANGLE_OFF,
	DISPLAY_ANGLE_SMALL,
	DISPLAY_ANGLE_MED,
	DISPLAY_ANGLE_LARGE
};

const GRID_TRANSPARENCY_FULL = 'Full';
const GRID_TRANSPARENCY_75 = '75%';
const GRID_TRANSPARENCY_50 = '50%';
const GRID_TRANSPARENCY_25 = '25%';

export const gridTransparencyValues = {
	GRID_TRANSPARENCY_FULL,
	GRID_TRANSPARENCY_75,
	GRID_TRANSPARENCY_50,
	GRID_TRANSPARENCY_25
};

const DRAWING_COLOR_PINK = 'pink';
const DRAWING_COLOR_ORANGE = 'orange';
const DRAWING_COLOR_LIME = 'lime';
const DRAWING_COLOR_BLUE = 'blue';

export const drawingColorValues = {
	DRAWING_COLOR_PINK,
	DRAWING_COLOR_ORANGE,
	DRAWING_COLOR_LIME,
	DRAWING_COLOR_BLUE
};

const LINE_WEIGHT_MINI = 1;
const LINE_WEIGHT_SMALL = 2;
const LINE_WEIGHT_MED = 3;
const LINE_WEIGHT_LARGE = 4;

export const lineWeightValues = {
	LINE_WEIGHT_MINI,
	LINE_WEIGHT_SMALL,
	LINE_WEIGHT_MED,
	LINE_WEIGHT_LARGE
};

const MEASUREMENT_SYSTEM_METRIC = 'Metric';
const MEASUREMENT_SYSTEM_IMPERIAL = 'Imperial';

export const measurementSystemValues = {
	MEASUREMENT_SYSTEM_METRIC,
	MEASUREMENT_SYSTEM_IMPERIAL
};

const IMPERIAL_DECIMALS = 'decimals';
const IMPERIAL_FRACTIONS = 'fractions';

export const imperialValues = {
	IMPERIAL_DECIMALS,
	IMPERIAL_FRACTIONS
};

export default {
	SETTINGS_TOGGLE_DISPLAY_LINE_LENGTH,
	SETTINGS_SET_DISPLAY_ANGLES,
	SETTINGS_SET_GRID_TRANSPARENCY,
	SETTINGS_SET_DRAWING_COLOR,
	SETTINGS_SET_LINE_WEIGHT,
	SETTINGS_SET_MEASUREMENT_SYSTEM,
	SETTINGS_SET_IMPERIAL,
	SETTINGS_SET_MARKETING_COOKIES,
	ACCEPT_COOKIES,
	SETTINGS_UPDATE
};

const COOKIES_ACCEPTED = false;
const COOKIES_MARKETING_ENABLED = true;

export const cookiesValues = {
	COOKIES_ACCEPTED,
	COOKIES_MARKETING_ENABLED
};
