import * as React from 'react';
import { connect } from 'react-redux';
import MathHelper from '../../../shared/MathHelper';
import { bindActionCreators } from 'redux';
import { modalOpen } from '../../../shared/reducers/ModalReducer';
import { MODAL_DELETE_LAYER } from '../../ModalContainer/Modals/ModalDeleteLayer/ModalDeleteLayer';
import SketchDetailResults from '../SketchDetailResults/SketchDetailResults';
import { getDetailsValues } from '../../../shared/MeasureHelper';
import { renderResults } from '../../ContextualHelp/Modals/Results';
import { gridSize } from '../CanvasGrid/CanvasGrid';
import LayerColors from '../LayerColors/LayerColors';
import { isInputMeasure } from '../InputMeasure/InputMeasure';
import VisibilityLayers from '../VisibilityLayers/VisibilityLayers';
import isEqual from 'lodash.isequal';
import get from 'lodash/get';
import { SUBSCRIPTION_PLANS } from '../../../shared/constants/plansConstants';

export let sketchResultEl;

class SketchResults extends React.Component {
	perimeterEl = null;
	areaEl = null;
	indexEl = null;
	openShapesEl = null;

	constructor(props) {
		super(props);

		this.state = {
			displayPerimeterDetails: false,
			displayAreaDetails: false,
			displayOpenShapesDetails: false,
			displayColorsOrVisibility: false,
			left: 0,
			bottom: 0
		};
	}

	componentDidUpdate() {
		if (!this.props.showAllLayers && this.props.sketch.closed) {
			if (renderResults) {
				renderResults();
			}
		} else if (this.props.showAllLayers && this.state.displayColorsOrVisibility && this.indexEl) {
			const bounds = this.indexEl.getBoundingClientRect(),
				position = {
					left: bounds.left - 1,
					bottom: bounds.height + 1
				};

			if (
				!isEqual(
					{
						left: this.state.left,
						bottom: this.state.bottom
					},
					position
				)
			) {
				this.setState(position);
			}
		}
	}

	render() {
		if (this.props.showAllLayers) {
			return this.resultAllLayers();
		}

		const sketch = this.props.sketch;

		if (sketch.closed) {
			return this.resultClosedSketch();
		} else if (sketch.angleClosed) {
			return this.resultAngleClosedSketch();
		} else if (sketch.lineExists()) {
			return this.resultLengthLines();
		}

		return this.resultEmptySketch();
	}

	getIndexLayer() {
		const { isEducator } = this.props;

		if (isEducator) {
			return null;
		}

		return (
			<div
				ref={el => (this.indexEl = el)}
				className='index-layer'
				onMouseOver={this.onMouseIndexOver.bind(this)}
				onMouseOut={this.onMouseOut.bind(this)}>
				{this.props.index}
				{this.state.displayColorsOrVisibility && (
					<div>
						<LayerColors bottom={this.state.bottom} left={this.state.left} />
					</div>
				)}
			</div>
		);
	}

	resultClosedSketch() {
		const { sketch, measure, scale } = this.props;

		const perimeterDetailValues = getDetailsValues(sketch.getPerimeter(scale, false), measure, 'perimeter');

		const areaDetailValues = getDetailsValues(sketch.getArea(scale, false), measure, 'area');

		let measureSuffix = [measure];
		let perimeter = sketch.getPerimeter(scale);
		let area = sketch.getArea(scale);
		if (isInputMeasure(measure) && this.props.isFractions) {
			perimeter = MathHelper.convert(perimeter, measure);
			area = MathHelper.convert(area, measure, true);
			if (measure === 'ft') {
				measureSuffix = ['ft', 'in'];
			}
			if (measure === 'yd') {
				measureSuffix = ['yd', 'ft'];
			}
		}

		return (
			<div ref={el => (sketchResultEl = el)} className='sketch-result'>
				{this.getIndexLayer()}
				<div
					ref={el => (this.perimeterEl = el)}
					className='perimeter'
					onMouseOver={this.onMousePerimeterOver.bind(this)}
					onMouseOut={this.onMouseOut.bind(this)}>
					<span className='marker' />
					{perimeter}
					&nbsp;
					{measureSuffix.join('/')}
					{this.state.displayPerimeterDetails && (
						<SketchDetailResults
							bottom={this.state.bottom}
							left={this.state.left}
							results={perimeterDetailValues}
							type='perimeter'
						/>
					)}
				</div>
				<div
					ref={el => (this.areaEl = el)}
					className='area'
					onMouseOver={this.onMouseAreaOver.bind(this)}
					onMouseOut={this.onMouseOut.bind(this)}>
					<span className='marker' />
					{area}
					&nbsp;
					{measureSuffix.map((i, key, arr) => (
						<span key={i}>
							{i}
							<sup>2</sup>
							{arr.length - 1 !== key ? '/' : ''}
						</span>
					))}
					{this.state.displayAreaDetails && (
						<SketchDetailResults
							bottom={this.state.bottom}
							left={this.state.left}
							results={areaDetailValues}
							type='area'
						/>
					)}
				</div>
				{!this.props.isEducator &&
					<div className='remove-layer'>
						<span
							title='Delete Layer'
							className='icon icon-annotate_delete'
							onClick={this.removeLayerHandler.bind(this)}
						/>
					</div>
				}
			</div>
		);
	}

	onMousePerimeterOver() {
		if (!this.state.displayPerimeterDetails && this.perimeterEl) {
			const bounds = this.perimeterEl.getBoundingClientRect();

			this.setState({
				displayPerimeterDetails: true,
				displayAreaDetails: false,
				displayColorsOrVisibility: false,
				left: bounds.left - 8,
				bottom: bounds.height + 2
			});
		}
	}

	onMouseAreaOver() {
		if (!this.state.displayAreaDetails && this.areaEl) {
			const bounds = this.areaEl.getBoundingClientRect();

			this.setState({
				displayAreaDetails: true,
				displayPerimeterDetails: false,
				displayColorsOrVisibility: false,
				left: bounds.left - 8,
				bottom: bounds.height + 2
			});
		}
	}

	onMouseIndexOver() {
		if (!this.state.displayColorsOrVisibility && this.indexEl) {
			const bounds = this.indexEl.getBoundingClientRect();

			this.setState({
				displayColorsOrVisibility: true,
				displayAreaDetails: false,
				displayPerimeterDetails: false,
				left: bounds.left - 1,
				bottom: bounds.height + 1
			});
		}
	}

	onMouseOut(event) {
		if (this.state.displayPerimeterDetails) {
			const relatedTarget = event.nativeEvent.relatedTarget || event.nativeEvent.toElement;

			if (!this.perimeterEl.contains(relatedTarget)) {
				this.setState({ displayPerimeterDetails: false });
			}
		}

		if (this.state.displayAreaDetails) {
			const relatedTarget = event.nativeEvent.relatedTarget || event.nativeEvent.toElement;

			if (!this.areaEl.contains(relatedTarget)) {
				this.setState({ displayAreaDetails: false });
			}
		}

		if (this.state.displayColorsOrVisibility) {
			const relatedTarget = event.nativeEvent.relatedTarget || event.nativeEvent.toElement;

			if (!this.indexEl.contains(relatedTarget)) {
				this.setState({ displayColorsOrVisibility: false });
			}
		}

		if (this.state.displayOpenShapesDetails) {
			const relatedTarget = event.nativeEvent.relatedTarget || event.nativeEvent.toElement;

			if (!this.openShapesEl.contains(relatedTarget)) {
				this.setState({ displayOpenShapesDetails: false });
			}
		}
	}

	resultAngleClosedSketch() {
		const sketch = this.props.sketch,
			lineStart = sketch.getAngleLines()[0],
			lineEnd = sketch.getAngleLines()[1],
			angle = MathHelper.getAngleBetweenTwoLines(lineStart.getPoints(), lineEnd.getPoints()),
			text = `${angle}° / ${360 - angle}°`;

		return (
			<div className='sketch-result'>
				{this.getIndexLayer()}
				<div className='angle'>
					<span className='icon icon-angle_tool' />
					{text}
				</div>
				{!this.props.isEducator &&
					<div className='remove-layer'>
						<span
							title='Delete Layer'
							className='icon icon-annotate_delete'
							onClick={this.removeLayerHandler.bind(this)}
						/>
					</div>
				}
			</div>
		);
	}

	resultLengthLines() {
		const { sketch, scale, measure } = this.props,
			length = MathHelper.round(
				sketch
					.getLines()
					.map(line => line.getLength(scale) / gridSize)
					.reduce((a, b) => a + b)
			),
			value =
				isInputMeasure(this.props.measure) && this.props.isFractions
					? MathHelper.convert(length, measure)
					: length,
			text = `${sketch.hasCurved ? '~' : ''}${value} ${measure}`;
		return (
			<div className='sketch-result'>
				{this.getIndexLayer()}
				<div className='angle'>
					<span className='line-marker' />
					{text}
				</div>
				{!this.props.isEducator &&
					<div className='remove-layer'>
						<span
							title='Delete Layer'
							className='icon icon-annotate_delete'
							onClick={this.removeLayerHandler.bind(this)}
						/>
					</div>
				}
			</div>
		);
	}

	onMouseOpenShapesOver = () => {
		if (!this.state.displayOpenShapesDetails && this.openShapesEl) {
			const bounds = this.openShapesEl.getBoundingClientRect();

			this.setState({
				displayOpenShapesDetails: true,
				displayAreaDetails: false,
				displayPerimeterDetails: false,
				left: bounds.left - 1,
				bottom: bounds.height + 2
			});
		}
	};

	renderOpenShapeLines() {
		const { scale, measure, layers, isFractions } = this.props;
		const { left, bottom, displayOpenShapesDetails } = this.state;

		let hasCurved = false;
		const layersLength = layers
			.filter(({ sketch: { closed }, visible }) => !closed && visible)
			.map(({ sketch }) => {
				return sketch
					.getLines()
					.map(line => {
						if (line.type === 'curved') {
							hasCurved = true;
						}
						return line.getLength(scale) / gridSize;
					})
					.reduce((a, b) => a + b, 0);
			});

		let length = layersLength.reduce((a, b) => a + b, 0);
		length = length.toFixed(2);
		const value = isInputMeasure(measure) && isFractions ? MathHelper.convert(length, measure) : length;
		const text = `${hasCurved ? '~' : ''}${value} ${measure}`;

		const detailResults = getDetailsValues(Number(length), measure, 'perimeter');
		if (length === '0.00') return null;

		return (
			<div
				ref={el => (this.openShapesEl = el)}
				className='open-shapes'
				onMouseOver={this.onMouseOpenShapesOver}
				onMouseOut={this.onMouseOut.bind(this)}>
				<span className='line-marker' />
				{text}
				{displayOpenShapesDetails && (
					<SketchDetailResults bottom={bottom} left={left} results={detailResults} type='open shapes' />
				)}
			</div>
		);
	}

	resultEmptySketch() {
		return (
			<div className='sketch-result'>
				{this.getIndexLayer()}
				<div className='perimeter'>
					<span className='marker' />
					{`Perimeter ${this.props.measure}`}
				</div>
				<div className='area'>
					<span className='marker' />
					{`Area ${this.props.measure}`}
					<sup>2</sup>
				</div>
				{!this.props.isEducator &&
					<div className='remove-layer'>
						<span
							title='Delete Layer'
							className='icon icon-annotate_delete'
							onClick={this.removeLayerHandler.bind(this)}
						/>
					</div>
				}
			</div>
		);
	}

	resultAllLayers() {
		let perimeter = 0,
			area = 0,
			hasCurved = false,
			perimeterDetailValues = false,
			areaDetailValues = false;

		this.props.layers
			.filter(layer => layer.isVisible() && layer.sketch.closed)
			.map(layer => layer.getSketch())
			.forEach(sketch => {
				if (sketch.hasCurved) {
					hasCurved = true;
				}
				perimeter += parseFloat(sketch.getPerimeter(this.props.scale, false));
				area += parseFloat(sketch.getArea(this.props.scale, false));
			});

		if (perimeter > 0 && area > 0) {
			perimeter = MathHelper.round(perimeter);
			area = MathHelper.round(area);

			perimeterDetailValues = getDetailsValues(perimeter, this.props.measure, 'perimeter');

			areaDetailValues = getDetailsValues(area, this.props.measure, 'area');
		}

		if (hasCurved) {
			perimeter = `~${perimeter}`;
			area = `~${area}`;
		}

		let measureSuffix = ' ' + this.props.measure;

		if (isInputMeasure(this.props.measure) && this.props.isFractions) {
			perimeter = MathHelper.convert(perimeter, this.props.measure);
			area = MathHelper.convert(area, this.props.measure, true);
		}

		return (
			<div className='sketch-result'>
				<div
					ref={el => (this.indexEl = el)}
					className='index-layer'
					onMouseOver={this.onMouseIndexOver.bind(this)}
					onMouseOut={this.onMouseOut.bind(this)}
				>
					<span title='Sum of All Layers' className='icon icon-sum_all_layers' />
					{this.state.displayColorsOrVisibility && (
						<VisibilityLayers bottom={this.state.bottom} left={this.state.left} />
					)}
				</div>
				{this.renderOpenShapeLines()}
				<div
					ref={el => (this.perimeterEl = el)}
					className='perimeter'
					onMouseOver={perimeterDetailValues !== false && this.onMousePerimeterOver.bind(this)}
					onMouseOut={perimeterDetailValues !== false && this.onMouseOut.bind(this)}>
					<span className='marker' />
					{perimeter + measureSuffix}
					{this.state.displayPerimeterDetails && (
						<SketchDetailResults
							bottom={this.state.bottom}
							left={this.state.left}
							results={perimeterDetailValues}
							type='perimeter'
						/>
					)}
				</div>
				<div
					ref={el => (this.areaEl = el)}
					className='area'
					onMouseOver={areaDetailValues !== false && this.onMouseAreaOver.bind(this)}
					onMouseOut={areaDetailValues !== false && this.onMouseOut.bind(this)}>
					<span className='marker' />
					{area + measureSuffix}
					<sup>2</sup>
					{this.state.displayAreaDetails && (
						<SketchDetailResults
							bottom={this.state.bottom}
							left={this.state.left}
							results={areaDetailValues}
							type='area'
						/>
					)}
				</div>
			</div>
		);
	}

	removeLayerHandler() {
		if (this.props.sketch.mask) {
			//ToDo: show some error
		} else {
			this.props.modalOpen(MODAL_DELETE_LAYER);
		}
	}
}

function mapStateToProps(state, ownProps) {
	const plan = get(state, 'auth.user.subscription.stripe_plan', '');
	const isEducator = plan === SUBSCRIPTION_PLANS.educator;

	return {
		...ownProps,
		sketch: state.sketchState.sketch,
		scale: state.scale.value,
		measure: state.scale.measure,
		layers: state.layers.list,
		isFractions: state.settings.imperial === 'fractions',
		isEducator
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			modalOpen
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(SketchResults);
