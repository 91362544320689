import * as React from 'react';
import { stopPropagation } from '../../../shared/EventHelper';
import MathHelper from '../../../shared/MathHelper';
import LineLabel from '../LineLabel/LineLabel';

export class AngleLines extends React.Component {
	render() {
		if (!this.props.lines.length) {
			return null;
		}

		return (
			<g className='angle-lines'>
				{this.getLines()}
				{this.getControlPoints()}
				{this.props.closed && this.getAngleLabel()}
			</g>
		);
	}

	getLines() {
		let strokeWidth = this.props.strokeWidth,
			zoom = this.props.zoom,
			strokeDashArray = 5 / zoom;

		return this.props.lines.map((line, index) => {
			let points = line.getPoints();

			return (
				<line
					key={index}
					strokeWidth={strokeWidth}
					strokeDasharray={strokeDashArray}
					x1={points[0][0]}
					y1={points[0][1]}
					x2={points[1][0]}
					y2={points[1][1]}
				/>
			);
		});
	}

	getControlPoints() {
		let zoom = this.props.zoom,
			strokeWidth = 1 / zoom,
			rectSize = 6 / zoom;

		return this.props.lines.map((line, index) => {
			return line.getPoints().map((point, pointIndex) => {
				return (
					<rect
						className='point'
						width={rectSize}
						height={rectSize}
						x={point[0] - rectSize / 2}
						y={point[1] - rectSize / 2}
						key={`${index}-${pointIndex}`}
						strokeWidth={strokeWidth}
						onMouseDown={event => {
							stopPropagation(event);
							this.props.mouseDown(index, pointIndex);
						}}
					/>
				);
			});
		});
	}

	getAngleLabel() {
		let lineStart = this.props.lines[0],
			lineEnd = this.props.lines[1],
			angle = MathHelper.getAngleBetweenTwoLines(lineStart.getPoints(), lineEnd.getPoints()),
			point = MathHelper.getCenterPoint(
				lineStart.getStartPoint(),
				lineStart.getEndPoint(),
				lineEnd.getStartPoint(),
				lineEnd.getEndPoint()
			),
			text = `${angle}° / ${360 - angle}°`;

		return <LineLabel point={point} text={text} rotate={0} zoom={this.props.zoom} />;
	}
}
