import * as types from '../constants/zoomConstants';
import { mapZoomIn, mapZoomOut } from './mapActions';
import { updateFileState } from './fileStateAction';

export function zoomWheel(delta) {
	return dispatch => {
		if (delta > 0) {
			dispatch(zoomOut());
		} else {
			dispatch(zoomIn());
		}
		dispatch(updateFileState(true));
	};
}

export function zoomIn() {
	return (dispatch, state) => {
		const { drawArea, notes, map } = state();

		if (drawArea.mouseDown || notes.movableNote !== null) {
			return;
		}

		if (map.visible) {
			dispatch(mapZoomIn());
		} else {
			dispatch({ type: types.ZOOM_IN });
		}
		dispatch(updateFileState(true));
	};
}

export function zoomOut() {
	return (dispatch, state) => {
		const { drawArea, notes, map } = state();

		if (drawArea.mouseDown || notes.movableNote !== null) {
			return;
		}

		if (map.visible) {
			dispatch(mapZoomOut());
		} else {
			dispatch({ type: types.ZOOM_OUT });
		}
		dispatch(updateFileState(true));
	};
}

export function zoomUpdate(state) {
	return { type: types.ZOOM_UPDATE, payload: { state } };
}

export function zoomReset() {
	return {
		type: types.ZOOM_RESET
	};
}

export function zoomPanMoved(payload) {
	return {
		type: types.ZOOM_PAN_MOVED,
		payload
	};
}

export function zoomSetPanBy(panBy) {
	return {
		type: types.ZOOM_SET_PAN_BY,
		payload: { panBy }
	};
}
