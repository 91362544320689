import { svgPan } from '../../components/App/DrawArea/DrawArea';
import MapHelper from '../MapHelper';

const ARROW_BOTTOM = 'arrowBottom';
const ARROW_BOTTOM_RIGHT = 'arrowBottomRight';
const ARROW_RIGHT = 'arrowRight';
const ARROW_TOP_RIGHT = 'arrowTopRight';
const ARROW_TOP = 'arrowTop';
const ARROW_TOP_LEFT = 'arrowTopLeft';
const ARROW_LEFT = 'arrowLeft';
const ARROW_BOTTOM_LEFT = 'arrowBottomLeft';

export const ARROW_POSITIONS = [
	ARROW_BOTTOM,
	ARROW_BOTTOM_RIGHT,
	ARROW_RIGHT,
	ARROW_TOP_RIGHT,
	ARROW_TOP,
	ARROW_TOP_LEFT,
	ARROW_LEFT,
	ARROW_BOTTOM_LEFT
];

export default class Note {
	arrowPosition = 0;
	minHeight = 50;
	width = 200;

	constructor(point, latLngPoint = null) {
		this.height = this.minHeight;
		if (point) {
			this.position = point;
		}

		if (latLngPoint) {
			this.latLng = latLngPoint;
		}
	}

	adjustScale(invert = false) {
		let x = this.originPoint[0],
			y = this.originPoint[1],
			realZoom = svgPan.getSizes().realZoom,
			initW = this.width,
			initH = this.height,
			scaledWidth = initW / realZoom,
			scaledHeight = initH / realZoom;

		if (invert) {
			x = this.point[0];
			y = this.point[1];
			scaledWidth = 2 * initW - scaledWidth;
			scaledHeight = 2 * initH - scaledHeight;
		}

		switch (ARROW_POSITIONS[this.arrowPosition]) {
			case ARROW_BOTTOM:
				x += (initW - scaledWidth) / 2;
				y += initH - scaledHeight;
				break;
			case ARROW_BOTTOM_LEFT:
				y += initH - scaledHeight;
				break;
			case ARROW_BOTTOM_RIGHT:
				x += initW - scaledWidth;
				y += initH - scaledHeight;
				break;
			case ARROW_LEFT:
				y += (initH - scaledHeight) / 2;
				break;
			case ARROW_RIGHT:
				x += initW - scaledWidth;
				y += (initH - scaledHeight) / 2;
				break;
			case ARROW_TOP:
				x += (initW - scaledWidth) / 2;
				break;
			case ARROW_TOP_RIGHT:
				x += initW - scaledWidth;
				break;
			default:
				break;
		}

		return [x, y];
	}

	getTextPosition(offsets) {
		let realZoom = svgPan.getSizes().realZoom;

		return {
			x: this.point[0] * realZoom - Math.abs(offsets.x),
			y: this.point[1] * realZoom - Math.abs(offsets.y)
		};
	}

	getWidth() {
		return this.width / svgPan.getSizes().realZoom;
	}

	getHeight() {
		return this.height / svgPan.getSizes().realZoom;
	}

	setHeight(newHeight) {
		if (newHeight < this.minHeight) {
			this.height = this.minHeight;
		} else {
			this.height = newHeight;
		}
	}

	toObject() {
		return {
			text: this._text,
			height: this.height,
			originPoint: [...this.originPoint],
			point: [...this.point],
			arrowPosition: this.arrowPosition,
			latLngPoint: this.latLngPoint ? [...this.latLngPoint] : null
		};
	}

	static fromObject(initObject) {
		let newNote = new this();
		newNote.point = initObject.point;
		newNote.originPoint = initObject.originPoint;
		newNote.latLngPoint = initObject.latLngPoint;
		newNote.height = initObject.height || 40;
		newNote.text = initObject.text || '';
		newNote.arrowPosition = initObject.arrowPosition || 0;

		return newNote;
	}

	clone() {
		return this.constructor.fromObject(this.toObject());
	}

	drawArrow() {
		let zoom = svgPan.getSizes().realZoom,
			arrowWidth = 14 / zoom,
			arrowHeight = 14 / zoom,
			bgWidth = this.getWidth(),
			bgHeight = this.getHeight(),
			notePosition = this.position,
			path = '',
			rotation = 0,
			position = { x: 0, y: 0 },
			posOffset = ((arrowWidth / 2) * Math.sqrt(2)) / 2;

		switch (ARROW_POSITIONS[this.arrowPosition]) {
			case ARROW_BOTTOM:
			case ARROW_TOP:
			case ARROW_LEFT:
			case ARROW_RIGHT:
				path = 'M' + -arrowWidth / 2 + ',0' + 'L' + arrowWidth / 2 + ',0' + 'L0,' + arrowHeight + 'Z';
				break;
			case ARROW_BOTTOM_LEFT:
			case ARROW_BOTTOM_RIGHT:
			case ARROW_TOP_LEFT:
			case ARROW_TOP_RIGHT:
				path =
					'M' +
					-arrowWidth / 2 +
					',0' +
					'L0,' +
					arrowWidth / 2 +
					'L' +
					arrowWidth / 2 +
					',0' +
					'L0,' +
					arrowHeight +
					'Z';
				break;
			default:
				break;
		}

		switch (ARROW_POSITIONS[this.arrowPosition]) {
			case ARROW_BOTTOM:
				rotation = 0;
				position.x = bgWidth / 2;
				position.y = bgHeight;
				break;
			case ARROW_TOP:
				rotation = 180;
				position.x = bgWidth / 2;
				position.y = 0;
				break;
			case ARROW_RIGHT:
				rotation = -90;
				position.x = bgWidth;
				position.y = bgHeight / 2;
				break;
			case ARROW_LEFT:
				rotation = 90;
				position.x = 0;
				position.y = bgHeight / 2;
				break;
			case ARROW_BOTTOM_LEFT:
				rotation = 45;
				position.x = 0 + posOffset;
				position.y = bgHeight - posOffset;
				break;
			case ARROW_BOTTOM_RIGHT:
				rotation = -45;
				position.x = bgWidth - posOffset;
				position.y = bgHeight - posOffset;
				break;
			case ARROW_TOP_LEFT:
				rotation = 135;
				position.x = posOffset;
				position.y = posOffset;
				break;
			case ARROW_TOP_RIGHT:
				rotation = -135;
				position.x = bgWidth - posOffset;
				position.y = posOffset;
				break;
			default:
				break;
		}

		return {
			path,
			rotation,
			position: 'translate(' + (notePosition[0] + position.x) + ',' + (notePosition[1] + position.y) + ')'
		};
	}

	updatePointsByPan(pan) {
		this.position = [this.point[0] - pan[0], this.point[1] - pan[1]];
	}

	refreshPoints() {
		if (this.latLngPoint) {
			this.position = MapHelper.latLng2Point(this.latLngPoint);
		}
	}

	set position(point) {
		this.point = [...point];
		this.originPoint = [...point];
	}

	get position() {
		return this.point;
	}

	set latLng(latLngPoint) {
		this.latLngPoint = latLngPoint;
	}

	get latLng() {
		return this.latLngPoint;
	}

	set text(text) {
		this._text = text;
	}

	get text() {
		return this._text;
	}
}
