import axiosLib from 'axios';
import cookie from 'js-cookie';
import {dispatch, mobileDetect} from './store';
import { authLogout, loadUserData, showForceLoginMessage } from './actions/authActions';
import { stateReset } from './actions/stateActions';
import { history } from '../Routes';
import { modalClose } from './reducers/ModalReducer';
import SocketAPI from './SocketAPI';

const axios = axiosLib.create();
const customAxios = axiosLib.create();

export default class ApiHelper {
	static init() {
		axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

		axios.interceptors.response.use(
			response => {
				return response;
			},
			error => {
				if (error.response && error.response.status) {
					if (error.response.status === 401) {
						dispatch(stateReset());
						dispatch(authLogout());
					} else if (error.response.status === 402) {
						window.location = '/subscription';
					} else if (error.response.status === 423) {
						window.location = '/subscription';
					} else if (error.response.status === 403) {
						dispatch(modalClose());
						dispatch(showForceLoginMessage());
						history.replace('/login');
					}
				}

				return Promise.reject(error);
			}
		);

		if (cookie.get('accessToken')) {
			this.updateAccessToken(cookie.get('accessToken'));

			if (window.location.pathname !== '/' && !mobileDetect.mobile()) {
				dispatch(loadUserData());
			}
		}
	}

	static updateAccessToken(token) {
		axios.defaults.headers.common['x-access-token'] = token;
		SocketAPI.connect(token);
	}

	static login(credentials, forceLogin = false) {
		return forceLogin ? axios.post('/login/?force-login=true', credentials) : axios.post('/login', credentials);
	}

	static sessionLogin(credentials) {
		return axios.post('/session-login', credentials);
	}

	static logout(email) {
		return axios.get(`/logout/${email}`);
	}

	static register(user) {
		return axios.post('/register', user);
	}

	static registerApi(user) {
		return axios.post('/register-api', user);
	}

	static validateEmail(email) {
		return axios.get(`/validate/${email}`);
	}

	static sendDisposableEmail(email) {
		return axios.get(`/disposable/${email}`);
	}

	static getListDocuments() {
		return axios.get('/api/documents');
	}

	static getDocument(id) {
		return axios.get(`/api/document/${id}`);
	}

	static saveDocument(data) {
		return axios.post('/api/document', data);
	}

	static deleteDocument(id) {
		return axios.delete(`/api/document/${id}`);
	}

	static archiveOrRestoreDocument(data) {
		return axios.patch('/api/document', data);
	}

	static upload(data) {
		return axios.post('/api/uploads', data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	}

	static refreshToken() {
		return axios.get('/api/refresh');
	}

	static payment_countries(data) {
		return axios.get('/api/payment-countries');
	}

	static payment_sca(data) {
		return axios.post('/api/payment-sca', data);
	}

	static setup_intents(data) {
		return axios.post('/api/setup-intents', data);
	}

	static reportUsage() {
		return axios.post('/api/report-usage').then(result => {
			if (result.data && result.data.success) {
				dispatch(loadUserData());
			}
			return result;
		});
	}

	static updateCard(data) {
		return axios.post('/api/update/card', data);
	}

	static extendTrial(data) {
		return axios.post('/api/extend-trial', data);
	}

	static getUserData() {
		// return axios.get('http://localhost:3001/api/user');
		return axios.get('/api/user');
	}

	static getNotification() {
		// return axios.get('http://localhost:3001/api/notification');
		return axios.get('/api/notification');
	}

	static updateSettings(settings) {
		return axios.post('/api/settings', settings);
	}

	static updateName(data) {
		return axios.post('/api/update/name', data);
	}

	static updateEmail(data) {
		return axios.post('/api/update/email', data);
	}

	static updatePassword(data) {
		return axios.post('/api/update/password', data);
	}

	static unSubscribe(forgetMe) {
		let params = '';

		if (forgetMe) {
			params += '?forget=true';
		}

		return axios.get('/api/unsubscribe' + params);
	}

	static unsubscribeLogger(data) {
		return axios.post('/api/unsubscribe-logger', data);
	}

	static updateTours(data) {
		return axios.post('/api/tours', data);
	}

	static listening(data) {
		return axios.post('/api/listening', data);
	}

	static resetPassword(data) {
		return axios.post('/reset/password', data);
	}

	static print(data) {
		//return axios.post('http://localhost:3002/api/print', data);
		return axios.post('/api/print', data);
	}

	static export(data) {
		//return axios.post('http://localhost:3002/api/export', data);
		return axios.post('/api/exportWeb', data);
	}

	static blog() {
		return axios.get('/blog');
	}

	static post(slug) {
		return axios.get(`/blog/${slug}`);
	}

	static help() {
		return axios.get('/help');
	}

	static saveSearchText(data) {
		return axios.post('/help/search', data);
	}

	static resend(email) {
		return axios.get(`/resend/${email}`);
	}

	static verify(token) {
		return axios.get(`/verify/${token}`);
	}

	static preview(preview) {
		return axios.get(`/preview/${preview}`);
	}

	static onClearCanvas() {
		return axios.post('/api/document/clear-canvas');
	}

	static enableEducator(data) {
		return axios.post('/api/educator/enable', data);
	}

	static setBadgeUrl(url) {
		return axios.post('/api/educator/badge-url', { url });
	}

	static resendBadgeEmail(data) {
		return axios.post('/educator/resend-badge-email', data);
	}

	static createSession(data) {
		return axios.post('/api/educator/session', data);
	}

	static getExistingSession() {
		return axios.get('/api/educator/session');
	}

	static stopExistingSession() {
		return axios.post('/api/educator/session/stop');
	}

	static deleteSession(sessionId) {
		return axios.delete(`/api/educator/session/${sessionId}`);
	}

	static getSessionsList() {
		return axios.get('/api/educator/sessions');
	}

	static getSessionDocuments(sessionId) {
		return axios.get(`/api/educator/session/${sessionId}/documents`);
	}

	static revokeEducator() {
		return axios.post('/api/educator/revoke');
	}

	static getPrices(currency='usd') {
		return axios.get(`/prices?currency=${currency}`);
	}

	static getIpInfo() {
		return customAxios.get('https://ipapi.co/json/');
	}
}
