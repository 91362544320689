import React from 'react';

const Art9 = ({color="#fff"}) => {

    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path className="cls-1"
                  style={{ fill: color }}
                  d="M73.63,83l.42,4.86.11,0a12.08,12.08,0,0,1,4.42-4.15,12.83,12.83,0,0,1,6.25-1.45A11.73,11.73,0,0,1,91,83.78a9.56,9.56,0,0,1,3.86,4.75,12.72,12.72,0,0,1,4.51-4.66,12.51,12.51,0,0,1,6.48-1.61q5.67,0,8.81,3.75t3.13,11.86v23.21H105.83V97.87c0-2.51-.37-4.22-1.11-5.11a4.27,4.27,0,0,0-3.47-1.34,7.23,7.23,0,0,0-3.06.6A6.14,6.14,0,0,0,96,93.75q0,.6,0,1c0,.28,0,.57,0,.88v25.43H84.13V97.87c0-2.51-.37-4.22-1.11-5.11a4.31,4.31,0,0,0-3.51-1.34,6.89,6.89,0,0,0-2.94.6,6.57,6.57,0,0,0-2.24,1.73v27.33H62.43V83Z"/>
            <path className="cls-1" style={{ fill: color }} d="M137,74.12H125.1v-8H137Zm0,47H125.1V83H137Z"/>
            <rect className="cls-1" style={{ fill: color }} width="28.5" height="28.5"/>
            <rect className="cls-1" style={{ fill: color }} x="171.5" width="28.5" height="28.5"/>
            <rect className="cls-1" style={{ fill: color }} x="171.5" y="171.5" width="28.5" height="28.5"/>
            <rect className="cls-1" style={{ fill: color }} y="171.5" width="28.5" height="28.5"/>
            <line className="cls-2" style={{ stroke: color, strokeMiterlimit:10, strokeWidth: '5px' }} x1="28.5" y1="14.25" x2="171.5" y2="14.25"/>
            <line className="cls-2" style={{ stroke: color, strokeMiterlimit:10, strokeWidth: '5px' }} x1="185.75" y1="28.5" x2="185.75" y2="171.5"/>
            <line className="cls-2" style={{ stroke: color, strokeMiterlimit:10, strokeWidth: '5px' }} x1="171.5" y1="185.75" x2="28.5" y2="185.75"/>
            <line className="cls-2" style={{ stroke: color, strokeMiterlimit:10, strokeWidth: '5px' }} x1="14.25" y1="171.5" x2="14.25" y2="28.5"/>
        </svg>
    )
}

export default Art9