const TRIAL_UPDATE = 'TRIAL_UPDATE';

export function trialUpdate(value) {
	return {
		type: TRIAL_UPDATE,
		payload: { value }
	};
}

const initState = null;

export default function trialReducer(state = initState, action) {
	switch (action.type) {
		case TRIAL_UPDATE: {
			return action.payload.value;
		}
		default:
			return state;
	}
}
