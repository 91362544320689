import ApiHelper from '../ApiHelper';
import {
	EDUCATOR_REVOKE,
	EDUCATOR_SET_PENDING,
	EDUCATOR_SET_SESSION,
	EDUCATOR_SET_SESSIONS,
	EDUCATOR_DELETE_SESSION,
	EDUCATOR_SET_SESSION_DOCUMENTS,
	EDUCATOR_STUDENT_UPDATE_DOCUMENT
} from '../constants/educatorConstants';
import orderBy from 'lodash/orderBy';
import moment from 'moment';

export const enableEducator = (data) => {
	return async (dispatch) => {
		dispatch({ type: EDUCATOR_SET_PENDING, payload: true });
		const response = await ApiHelper.enableEducator(data);

		if (response.data.success) {
			dispatch({ type: EDUCATOR_SET_PENDING, payload: false });
			return true;
		}

		dispatch({ type: EDUCATOR_SET_PENDING, payload: false });
		return false;
	};
};

export const setBadgeUrl = url => {
	return async (dispatch) => {
		dispatch({ type: EDUCATOR_SET_PENDING, payload: true });
		const response = await ApiHelper.setBadgeUrl(url);

		if (response.data.success) {
			dispatch({ type: EDUCATOR_SET_PENDING, payload: false });
			return true;
		}

		dispatch({ type: EDUCATOR_SET_PENDING, payload: false });
		return false;
	};
};

export const resendBadgeEmail = ({ email, password }) => {
	return async () => {
		const response = await ApiHelper.resendBadgeEmail({email, password});
		return response.data.success;
	};
};

export const createSession = (data) => {
	return async (dispatch) => {
		dispatch({ type: EDUCATOR_SET_PENDING, payload: true });
		const response = await ApiHelper.createSession(data);

		if (response.data.success) {
			dispatch({ type: EDUCATOR_SET_SESSION, payload: response.data.session });
			dispatch({ type: EDUCATOR_SET_PENDING, payload: false });
			return true;
		}

		dispatch({ type: EDUCATOR_SET_PENDING, payload: false });
	};
};

export const getExistingSession = () => {
	return async (dispatch) => {
		dispatch({ type: EDUCATOR_SET_PENDING, payload: true });
		const response = await ApiHelper.getExistingSession();

		if (response.data.success) {
			dispatch({ type: EDUCATOR_SET_SESSION, payload: response.data.session });
			dispatch({ type: EDUCATOR_SET_PENDING, payload: false });
			return true;
		}

		dispatch({ type: EDUCATOR_SET_PENDING, payload: false });
	};
};

export const stopExistingSession = () => {
	return async (dispatch) => {
		dispatch({ type: EDUCATOR_SET_PENDING, payload: true });
		const response = await ApiHelper.stopExistingSession();

		if (response.data.success) {
			dispatch({ type: EDUCATOR_SET_SESSION, payload: null });
			dispatch({ type: EDUCATOR_SET_PENDING, payload: false });
			return true;
		}

		dispatch({ type: EDUCATOR_SET_PENDING, payload: false });
	};
};

export const getSessionsList = () => {
	return async (dispatch) => {
		const response = await ApiHelper.getSessionsList();
		let sessions = [];

		if (response.data.success) {
			sessions = orderBy(response.data.sessions, 'created_at', 'desc')
				.map(elem => {
					const isEnded = moment().isAfter(elem.ends_at);
					return { ...elem, isEnded };
				});
			dispatch({ type: EDUCATOR_SET_SESSIONS, payload: sessions });
		}

		return sessions;
	};
};

export const getSessionDocuments = (sessionId) => {
	return async (dispatch) => {
		const response = await ApiHelper.getSessionDocuments(sessionId);
		let documents = [];

		if (response.data.success) {
			documents = orderBy(response.data.documents, 'name', 'asc');
			dispatch({ type: EDUCATOR_SET_SESSION_DOCUMENTS, payload: {
					documents,
					sessionId
				} });
		}

		return documents;
	};
};

export const deleteSession = (sessionId) => {
	return async (dispatch) => {
		const response = await ApiHelper.deleteSession(sessionId);

		if (response.data.success) {
			dispatch({ type: EDUCATOR_DELETE_SESSION, payload: { sessionId } });
		}

		return true;
	};
};

export const revokeEducator = () => {
	return async (dispatch) => {
		const response = await ApiHelper.revokeEducator();

		if (response.data.success) {
			dispatch({ type: EDUCATOR_REVOKE });
		}

		return true;
	};
};

export const updateDocument_socket = (documents) => {
	return async (dispatch) => {
		dispatch({type:EDUCATOR_STUDENT_UPDATE_DOCUMENT, payload:{documents}});
	};
};
