import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import {withRouter} from "react-router";
import {bindActionCreators} from "redux";
import {authLogout} from "../../../shared/actions/authActions";
import {authTypes} from "../../../shared/constants/authConstants";

class StripeWidget extends React.Component {
	buy = async () => {
		const {auth, showCheckout, history, plan, btnText, authLogout} = this.props;
		if(btnText==='register')
		{
			await authLogout();
			history.push(`/register?type=${authTypes.educator}`);
			return;
		}

		if (auth.isAuthenticated && auth.user) {
			ReactGA.ga('send', 'event', 'form', 'click', 'buy', plan);

			showCheckout();
		} else {
			history.push('/login');
		}
	};

	render() {
		const {btnText, btnClass} = this.props;
		return (
			<button onClick={this.buy} className={btnClass}>
				{btnText}
			</button>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		auth: state.auth
	};
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			authLogout
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StripeWidget));
