import React from 'react';
import store from '../../../../shared/store';
import moment from 'moment';
import { imageAdd } from '../../../../shared/actions/ImageActions';
import { pdfUpload } from '../../../../shared/reducers/PdfReducer';
import { isDXF, isSVG, isDWG, upload } from '../../../../shared/actions/convertActions';
import { SUBSCRIPTION_PLANS } from '../../../../shared/constants/plansConstants';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modalClose } from '../../../../shared/reducers/ModalReducer';

export const MODAL_IMAGE_UPLOAD = 'MODAL_IMAGE_UPLOAD';

const availableImageMimeTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];

class ModalImageUpload extends React.Component {
	constructor() {
		super();
		this.inputChanged = this.inputChanged.bind(this);
		this.processFile = this.processFile.bind(this);
		this.state = { file: null, uploadError: false };
	}

	toSubscriptionPage = () => {
		this.props.modalClose();
	}

	render() {
		return (
			<div className='modal-file-upload'>
				<h2 className='text-center'>import .png .jpg .gif .pdf .dwg .dxf .svg</h2>
				<input
					type='text'
					className='form-control file-name'
					value={this.state.file ? this.state.file.name : ''}
					disabled='true'
				/>
				<input
					type='file'
					accept='.png,.jpg,.jpeg,.gif,.pdf,.dwg,.dxf,.svg'
					id='file-field'
					onChange={this.inputChanged}
				/>
				<div className='text-right'>
					{this.state.uploadError && (
						<label className='trial-upload-error'>
							Import .png .jpg or .gif only during trial.&nbsp;&nbsp;
							<Link onClick={this.toSubscriptionPage} className={'subscription-link'} to={`/subscription`}>
								View Plans
							</Link>
						</label>
					)}

					<label className='btn btn-modal' htmlFor='file-field'>
						browse
					</label>
					<button className='btn btn-modal' onClick={this.processFile}>
						upload
					</button>
				</div>
			</div>
		);
	}

	inputChanged(event) {
		const input = event.nativeEvent.target;

		if (input.files.length) {
			let file = input.files[0];
			if (
				availableImageMimeTypes.includes(file.type) ||
				file.type === 'application/pdf' ||
				isSVG(file) ||
				isDXF(file) ||
				isDWG(file)
			) {
				this.setState({ file: input.files[0] });
			}
		}
	}

	processFile() {
		let file = this.state.file;
		let hasSubscription = false;
		this.setState({ uploadError: false });
		let isSessionUser = false;

		if (this.props.user) {
			isSessionUser = this.props.user.is_session_user;
			let subscription = this.props.user.subscription;

			if (subscription && subscription.status === 'Opened') {
				if (subscription.stripe_plan === SUBSCRIPTION_PLANS.payPerDay && subscription.payPerDay) {
					hasSubscription = moment(subscription.payPerDay.dayEnds).isAfter(moment());
				} else {
					hasSubscription = moment(subscription.ends_at).isAfter(moment());
				}
			}
		}

		if (file instanceof File) {
			if (availableImageMimeTypes.includes(file.type)) {
				let img = new Image();

				img.onload = function() {
					store.dispatch(
						imageAdd(file, {
							width: this.width,
							height: this.height
						})
					);
				};

				img.src = URL.createObjectURL(file);
			} else {
				if (hasSubscription || isSessionUser) {
					if (file.type === 'application/pdf') {
						store.dispatch(pdfUpload(file));
					} else if (isSVG(file) || isDXF(file) || isDWG(file)) {
						store.dispatch(upload(file));
					}
				} else {
					this.setState({ uploadError: true });
				}
			}
		}
	}
}
const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			modalClose
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(ModalImageUpload);
