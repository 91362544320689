import React from 'react';

const Eye = ({size = 40, color = '#808080'}) => (
    <svg
        id="Layer_1"
        viewBox="0 0 100 100"
        height={size}
        width={size}
    >
        <path d="M36.4,79.4c-4.3-1.2-8.3-2.7-12-4.7s-7-4.1-9.9-6.4s-5.4-4.7-7.5-7s-3.7-4.5-4.8-6.5S0.5,51.2,0.5,50s0.6-2.9,1.7-4.8
        s2.7-4.2,4.8-6.5s4.6-4.7,7.5-7s6.3-4.5,9.9-6.4s7.7-3.5,12-4.7c4.3-1.2,8.9-1.8,13.7-1.8c4.8,0,9.4,0.6,13.7,1.8
        c4.3,1.2,8.3,2.7,12,4.7c3.7,1.9,7,4.1,9.9,6.4s5.4,4.7,7.5,7s3.6,4.5,4.7,6.5s1.6,3.6,1.6,4.8s-0.5,2.9-1.6,4.8s-2.7,4.2-4.7,6.5
        s-4.5,4.7-7.5,7s-6.2,4.5-9.9,6.4c-3.7,1.9-7.7,3.5-12,4.7c-4.3,1.2-8.9,1.8-13.8,1.8C45.2,81.2,40.7,80.6,36.4,79.4z M58,68.8
        c2.5-1.1,4.7-2.5,6.5-4.4c1.9-1.9,3.4-4.1,4.4-6.5c1.1-2.5,1.6-5.1,1.6-7.9c0-2.9-0.5-5.5-1.6-8s-2.5-4.6-4.4-6.5
        c-1.9-1.9-4.1-3.3-6.5-4.4c-2.5-1-5.1-1.6-8-1.6c-2.9,0-5.5,0.5-8,1.6s-4.7,2.5-6.6,4.4c-1.9,1.9-3.4,4-4.4,6.5
        c-1.1,2.5-1.6,5.1-1.6,8c0,2.8,0.6,5.4,1.6,7.9c1.1,2.5,2.5,4.6,4.4,6.5c1.9,1.9,4.1,3.4,6.5,4.4c2.5,1.1,5.1,1.6,8,1.6
        C52.9,70.4,55.5,69.9,58,68.8z M46.4,56.2c-1.1-0.6-2-1.5-2.6-2.6s-1-2.3-1-3.6c0-1.3,0.3-2.5,1-3.6c0.7-1.1,1.5-1.9,2.6-2.6
        c1.1-0.6,2.3-1,3.6-1c1.3,0,2.5,0.3,3.6,1c1.1,0.6,2,1.5,2.6,2.6c0.7,1.1,1,2.3,1,3.6c0,1.3-0.3,2.5-1,3.6s-1.5,2-2.6,2.6
        c-1.1,0.6-2.3,1-3.6,1C48.7,57.1,47.5,56.8,46.4,56.2z"/>
    </svg>

);

export default Eye