import MathHelper from './MathHelper';

export const availableMeasures = [
	{ unit: 'nanometers', abbr: 'nm' },
	{ unit: 'micrometers', abbr: 'μm' },
	{ unit: 'millimeters', abbr: 'mm' },
	{ unit: 'centimeters', abbr: 'cm' },
	{ unit: 'meters', abbr: 'm' },
	{ unit: 'kilometers', abbr: 'km' },
	{ unit: 'inches', abbr: 'in' },
	{ unit: 'feet', abbr: 'ft' },
	{ unit: 'yards', abbr: 'yd' },
	{ unit: 'miles', abbr: 'mi' }
];

export function convertFromCmToUnit(val, unit, type = 'perimeter') {
	if (type === 'perimeter') {
		switch (unit) {
			case 'nm':
				val *= 10000000;
				break;
			case 'μm':
				val *= 10000;
				break;
			case 'mm':
				val *= 10;
				break;
			case 'm':
				val /= 100;
				break;
			case 'km':
				val /= 100 * 1000;
				break;
			case 'in':
				val *= 0.393701;
				break;
			case 'ft':
				val *= 0.0328084;
				break;
			case 'yd':
				val *= 0.0109361;
				break;
			case 'mi':
				val /= 160934.4;
				break;
			default:
				break;
		}
	}
	return val;
}

export function convertDownToCm(val, unit, type = 'perimeter') {
	if (type === 'perimeter') {
		switch (unit) {
			case 'nm':
				val /= 10000000;
				break;
			case 'μm':
				val /= 10000;
				break;
			case 'mm':
				val /= 10;
				break;
			case 'm':
				val *= 100;
				break;
			case 'km':
				val *= 100 * 1000;
				break;
			case 'in':
				val *= 2.54;
				break;
			case 'ft':
				val *= 30.48;
				break;
			case 'yd':
				val *= 91.44;
				break;
			case 'mi':
				val *= 160934.4;
				break;
			default:
				break;
		}
	} else if (type === 'area') {
		switch (unit) {
			case 'nm':
				val /= Math.pow(10000000, 2);
				break;
			case 'μm':
				val /= Math.pow(10000, 2);
				break;
			case 'mm':
				val /= Math.pow(10, 2);
				break;
			case 'm':
				val *= Math.pow(100, 2);
				break;
			case 'km':
				val *= Math.pow(100000, 2);
				break;
			case 'in':
				val *= Math.pow(2.54, 2);
				break;
			case 'ft':
				val *= Math.pow(30.48, 2);
				break;
			case 'yd':
				val *= Math.pow(91.44, 2);
				break;
			case 'mi':
				val *= Math.pow(160934.4, 2);
				break;
			default:
				break;
		}
	}
	return val;
}

export function correctScale(scale, unit) {
	let newScale = scale;
	let initUnit = unit;
	let newUnit = unit;

	while (newScale < 0.01) {
		let found = false;
		let i = 0;
		for (; i < availableMeasures.length; i++) {
			if (availableMeasures[i].abbr === initUnit) {
				found = true;
				break;
			}
			if (found) {
				break;
			}
		}
		if (!found || i === 0) {
			break;
		}

		newUnit = availableMeasures[i - 1].abbr;
		newScale = MathHelper.round(convertFromCmToUnit(convertDownToCm(newScale, initUnit), newUnit));
		initUnit = newUnit;
	}

	return { scale: newScale, unit: newUnit };
}

export function getDetailsValues(val, unit, type = 'perimeter') {
	const values = [],
		value = convertDownToCm(val, unit, type);

	if (type === 'perimeter') {
		values.push({ abbr: 'nm', value: value * 10000000 });
		values.push({ abbr: 'μm', value: value * 10000 });
		values.push({ abbr: 'mm', value: value * 10 });
		values.push({ abbr: 'cm', value: value });
		values.push({ abbr: 'm', value: value / 100 });
		values.push({ abbr: 'km', value: value / 100000 });
		values.push({ abbr: 'in', value: value * 0.393701 });
		values.push({ abbr: 'ft', value: value * 0.0328084 });
		values.push({ abbr: 'yd', value: value * 0.0109361 });
		values.push({ abbr: 'mi', value: value / 160934.4 });
	} else if (type === 'area') {
		values.push({ abbr: 'nm', value: value * Math.pow(10000000, 2) });
		values.push({ abbr: 'μm', value: value * Math.pow(10000, 2) });
		values.push({ abbr: 'mm', value: value * Math.pow(10, 2) });
		values.push({ abbr: 'cm', value: value });
		values.push({ abbr: 'm', value: value / Math.pow(100, 2) });
		values.push({ abbr: 'km', value: value / Math.pow(100000, 2) });
		values.push({ abbr: 'in', value: value * Math.pow(0.393701, 2) });
		values.push({ abbr: 'ft', value: value * Math.pow(0.0328084, 2) });
		values.push({ abbr: 'yd', value: value * Math.pow(0.0109361, 2) });
		values.push({ abbr: 'mi', value: value / Math.pow(160934.4, 2) });
		values.push({ abbr: 'a', value: value / 40468564.2 });
		values.push({ abbr: 'ha', value: value / 100000000 });
	}

	return values;
}
