import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Text } from '../SVG/Text';
import { stopPropagation } from '../../../shared/EventHelper';
import * as instrumentsTypes from '../../../shared/classes/Instruments';
import { labelReset, labelSetLineIndex, labelTogglePointIndex } from '../../../shared/reducers/LabelReducer';
import { sketchResetActivePoint } from '../../../shared/actions/SketchActions';
import { helpActivateEditLineLength } from '../../../shared/actions/helpActions';
import { DIAMETER_LINE } from '../../../shared/classes/DiameterLine';
import MathHelper from '../../../shared/MathHelper';

class LineLabel extends Component {
	render() {
		let zoom = this.props.zoom || 1,
			rectX = this.props.point[0],
			rectY = this.props.point[1],
			textX = this.props.point[0],
			textY = this.props.point[1] + 4 / zoom,
			points = [],
			trianglePoints = [],
			trianglePosition = this.props.label.editablePointIndex,
			lineSize = parseFloat(this.props.lineSize),
			labelWidth = 70 / zoom,
			labelHeight = 20 / zoom,
			asTooltip = this.props.asTooltip ? this.props.asTooltip : lineSize < labelWidth + 20 / zoom,
			rotate = this.props.rotate;

		if (asTooltip) {
			rectX -= labelWidth / 2;
			rectY -= labelHeight * 1.5;
			textY -= labelHeight;
			points = [
				[this.props.point[0] - 5 / zoom, rectY + labelHeight - 1 / zoom],
				[this.props.point[0], rectY + labelHeight + 5 / zoom],
				[this.props.point[0] + 5 / zoom, rectY + labelHeight - 1 / zoom]
			];
		} else {
			rectX -= labelWidth / 2;
			rectY -= labelHeight / 2;
		}

		if (rotate > 75 || rotate < -90) {
			rotate += 180;
			trianglePosition = trianglePosition ? 0 : 1;
		}

		if (this.props.lineIndex === this.props.label.editableLineIndex) {
			if (trianglePosition === 0) {
				trianglePoints = [
					[rectX, rectY],
					[rectX, rectY + labelHeight],
					[rectX - 10 / zoom, rectY + labelHeight / 2]
				];
			} else {
				trianglePoints = [
					[rectX + labelWidth, rectY],
					[rectX + labelWidth, rectY + labelHeight],
					[rectX + labelWidth + 10 / zoom, rectY + labelHeight / 2]
				];
			}
		}

		const classNames = ['line-label'];

		if (this.props.hidden) {
			classNames.push('hidden');
		}

		if (this.props.isPinned) {
			classNames.push('pinned');
			classNames.push(this.props.color);
		}

		const { text, measure, isFractions } = this.props;
		let labelText = text;

		if (measure === 'yd' && isFractions) {
			labelText = MathHelper.yardsFeet(text);
		} else if (measure === 'ft' && isFractions) {
			labelText = MathHelper.feetInches(text);
		}

		return (
			<g
				ref={this.props.labelRef}
				onMouseDown={this.mouseEvent.bind(this)}
				onMouseUp={this.mouseEvent.bind(this)}
				onClick={this.editingLabel.bind(this)}
				transform={`rotate(${rotate}, ${this.props.point[0]}, ${this.props.point[1]})`}
				className={classNames.join(' ')}>
				<rect height={labelHeight} width={labelWidth} x={rectX} y={rectY} />
				<Text x={textX} y={textY} text={labelText} className='text-white' zoom={zoom} />
				{asTooltip && <polygon points={points} />}
				{trianglePoints.length && <polygon points={trianglePoints} />}
			</g>
		);
	}

	componentDidUpdate() {
		if (
			!this.props.sketchClosed &&
			(this.props.label.editableLineIndex !== null || this.props.label.editablePointIndex !== null)
		) {
			this.props.labelReset();
		}
	}

	mouseEvent(event) {
		if (this.props.sketchClosed) {
			stopPropagation(event);
		}
	}

	editingLabel() {
		if (this.props.activeIndex !== null) {
			this.props.sketchResetActivePoint();
		}

		if (![instrumentsTypes.STRAIGHT_LINE, DIAMETER_LINE].includes(this.props.lineType)) {
			return;
		}
		if (this.props.label.editableLineIndex !== this.props.lineIndex) {
			this.props.labelSetLineIndex(this.props.lineIndex);

			/*if (!this.props.helpEditLineLength) {
                this.props.helpActivateEditLineLength();
            }*/
		}
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		label: state.label,
		sketchClosed: state.sketchState.sketch.closed,
		activeIndex: state.sketchState.activeIndex,
		helpEditLineLength: state.help.helpEditLineLength,
		measure: state.scale.measure,
		isFractions: state.settings.imperial === 'fractions'
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			labelSetLineIndex,
			labelTogglePointIndex,
			labelReset,
			sketchResetActivePoint,
			helpActivateEditLineLength
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(LineLabel);
