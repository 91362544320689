import React from 'react';
import update from 'immutability-helper';
import { ruleRunner, run } from '../../../../Validation/ruleRunner';
import { isValidEmail, required } from '../../../../Validation/rules';
import TextView from '../../../../Util/TextView';
import ApiHelper from '../../../../../shared/ApiHelper';
import { dispatch } from '../../../../../shared/store';
import { authSetUser } from '../../../../../shared/actions/authActions';

const fieldValidations = [ruleRunner('email', 'Email Address', required, isValidEmail)];

export default class AccountEmailField extends React.Component {
	handleTimeout = null;

	constructor(props) {
		super(props);

		this.state = {
			email: props.email,
			disabled: false,
			showErrors: false,
			validationErrors: {},
			success: false
		};
	}

	clearTimeout() {
		if (this.handleTimeout) {
			window.clearTimeout(this.handleTimeout);
			this.handleTimeout = null;
		}
	}

	resetSuccess() {
		this.setState({ success: false });
	}

	componentWillMount() {
		this.setState({ validationErrors: run(this.state, fieldValidations) });
	}

	componentWillUnmount() {
		this.clearTimeout();
	}

	errorFor(field) {
		return this.state.validationErrors[field] || '';
	}

	handleFieldChanged(field) {
		return e => {
			let newState = update(this.state, {
				[field]: { $set: e.target.value }
			});

			newState.validationErrors = run(newState, fieldValidations);
			this.setState(newState);
		};
	}

	handleSubmit() {
		this.setState({ showErrors: true });

		if (Object.keys(this.state.validationErrors).length) return;

		const email = this.state.email;

		this.setState({
			showErrors: false,
			disabled: true
		});

		ApiHelper.updateEmail({ email })
			.then(response => {
				const state = { disabled: false };

				if (response.data.success && response.data.user) {
					dispatch(authSetUser(response.data.user));
					state.success = true;
					this.handleTimeout = window.setTimeout(this.resetSuccess.bind(this), 3000);
				}

				this.setState(state);
			})
			.catch(error => {
				const state = {
					disabled: false
				};
				if (error.response.data.message) {
					state.showErrors = true;
					state.validationErrors = {
						email: error.response.data.message
					};
				}

				this.setState(state);
			});
	}

	render() {
		const btnClassNames = ['btn', 'btn-modal'];

		if (this.state.success) {
			btnClassNames.push('btn-success');
		}

		return (
			<div className='field'>
				<TextView
					placeholder='Email'
					showError={this.state.showErrors}
					text={this.state.email}
					onFieldChanged={this.handleFieldChanged('email')}
					errorText={this.errorFor('email')}
				/>
				<button
					onClick={this.handleSubmit.bind(this)}
					className={btnClassNames.join(' ')}
					disabled={
						this.state.success ||
						!this.state.email.length ||
						this.state.disabled ||
						this.props.email === this.state.email
					}>
					{this.state.success ? 'done' : 'update'}
				</button>
			</div>
		);
	}
}
