import * as React from 'react';
import store from '../../../shared/store';
import { measuresChanged } from '../../../shared/actions/scaleControlActions';
import { availableMeasures } from '../../../shared/MeasureHelper';

export class MeasureSelect extends React.Component {
	render() {
		return (
			<div className='measures-select-wrapper'>
				<span className='icon icon-down_arrow' />
				<select
					className='measures-select'
					onKeyDown={event=>{
						event.target.blur();
						event.preventDefault();
					}}
					onChange={event => {
						store.dispatch(measuresChanged(event.target.value));
						event.target.blur();
					}}
					value={this.props.measure}>
					{this.renderMeasuresOptions()}
				</select>
			</div>
		);
	}

	renderMeasuresOptions() {
		const options = [];
		for (let measure of availableMeasures) {
			options.push(
				<option value={measure.abbr} key={measure.abbr}>
					{this.props.shortText ? measure.abbr : measure.unit}
				</option>
			);
		}
		return options;
	}
}
