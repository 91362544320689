import * as React from 'react';
import { connect } from 'react-redux';
import MathHelper from '../../../shared/MathHelper';
import { toggleSumLabel } from '../../../shared/reducers/LabelReducer';
import { bindActionCreators } from 'redux';
import {
	layerLabelSetMouseDown,
	layerLabelSetOffsets,
	layerLabelToggleExpand
} from '../../../shared/reducers/LayerLabelReducer';

import './LayerLabelsFixed.scss';
import UpDown from '../../icons/UpDown';

class LayerLabelsFixed extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showArrows: false,
			offset: 0,
			disableTopMove: false,
			disableBottomMove: false
		};
	}
	toggleLabels = () => {
		this.props.layerLabelToggleExpand();
		this.props.toggleSumLabel(!this.props.showSumLabels);
	}

	getLabels() {
		const {layers} = this.props;

		return <ul
			ref={el => this.layersEl = el}
			style={{
				marginTop: `-${this.state.offset}px`
			}}
		>
			{layers.map((layer) => {
				const sketch = layer.getSketch();
				const isClosed = sketch.closed;
				const { scale, measure, isFractions } = this.props;
				let text = `  ${sketch.getArea(scale)} ${measure}`;

				if (measure === 'yd' && isFractions) {
					text = MathHelper.yardsFeet(text);
				} else if (measure === 'ft' && isFractions) {
					text = MathHelper.feetInches(text);
				}

				if (!isClosed) return null;

				const layerId = layer.getId();
				const color = layer.getColor() || 'pink';

				return (
					<div key={layerId} className={`layer-label ${color}`}>
						<div className={`layer-id ${color}`}>{layerId}</div>
						<div className={'text'}>{text}<sup>2</sup></div>
					</div>
				);
			})}
		</ul>;
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.layersEl || !this.layersWrapperEl) {
			return;
		}

		if (prevProps.layers.length !== this.props.layers.length) {
			if (this.state.offset) {
				this.setState({ offset: 0 });
			}
		}

		let layersBounds = this.layersEl.getBoundingClientRect(),
			layersWrapperBounds = this.layersWrapperEl.getBoundingClientRect();

		if (layersBounds.height > layersWrapperBounds.height) {
			if (this.state.offset === 0 && !this.state.disableTopMove) {
				this.setState({ disableTopMove: true });
			} else if (this.state.offset > 0 && this.state.disableTopMove) {
				this.setState({ disableTopMove: false });
			}

			if (layersWrapperBounds.bottom + 10 > layersBounds.bottom) {
				if (!this.state.disableBottomMove) {
					this.setState({ disableBottomMove: true });
				}
			} else {
				if (this.state.disableBottomMove) {
					this.setState({ disableBottomMove: false });
				}
			}

			if (this.state.showArrows === false) {
				this.setState({ showArrows: true });
			} else if (this.state.isNewLayer) {
				let offset = layersBounds.height - layersWrapperBounds.height;
				this.setState({
					offset: offset,
					isNewLayer: false
				});
			}
		} else if (this.state.showArrows === true) {
			this.setState({ showArrows: false });
		}
	}

	mouseDown = (event) => {
		let bounds = this.layerLabelsContainer.getBoundingClientRect();
		let offsets = {
			x: event.nativeEvent.clientX - bounds.left,
			y: event.nativeEvent.clientY - bounds.top
		};

		this.props.layerLabelSetOffsets(offsets);
		this.props.layerLabelSetMouseDown(true);
	}

	leftMove() {
		if (!this.layersEl || !this.layersWrapperEl) {
			return;
		}
		let offset = this.state.offset - 200;
		if (offset < 0) {
			offset = 0;
		}
		this.setState({ offset: offset });
	}

	rightMove() {
		if (!this.layersEl || !this.layersWrapperEl) {
			return;
		}

		let layersBounds = this.layersEl.getBoundingClientRect(),
			layersWrapperBounds = this.layersWrapperEl.getBoundingClientRect(),
			maxOffset = layersBounds.height - layersWrapperBounds.height,
			offset = 0;

		if (maxOffset > 0) {
			offset = this.state.offset + 200;

			if (offset > maxOffset) {
				offset = maxOffset;
			}
		}

		this.setState({ offset: offset });
	}

	render() {
		const { layerLabel, image, canvasVisible, showAllLayers } = this.props;
		const showCanvasElements = !image.dynamicImage && canvasVisible;
		if (!showCanvasElements || !showAllLayers) {
			return null;
		}
		const moveLegendDown = showCanvasElements && image.url;

		let style = false;

		if (layerLabel.position) {
			style = {
				top: layerLabel.position.y,
				left: layerLabel.position.x
			};
		}


		return <div
			ref={ref => this.layerLabelsContainer = ref}
			style={style ? style : {}}
			className={`layer-label-container${layerLabel.expand ? '' : ' minimize'}${moveLegendDown ? ' move-down' : ''}`}>
			<ul className='layer-label-actions'>
				<li className='icon icon-toolbar_handle' onMouseDown={this.mouseDown} />
				<li
					className={`icon turn ${layerLabel.expand ? 'on' : 'off'}`}
					onClick={this.toggleLabels}
				/>
			</ul>
			{!!(layerLabel.expand && this.state.showArrows) && (
				<div
					className={`action-item icon${this.state.disableTopMove ? ' disabled' : ''}`}
					onClick={!this.state.disableTopMove && this.leftMove.bind(this)}
				>
					<UpDown height='10px' width='auto'/>
				</div>
			)}
			{layerLabel.expand && <div
				ref={el => (this.layersWrapperEl = el)}
				className={'layers-legend'}
			>
				{this.getLabels()}
			</div>}
			{!!(layerLabel.expand && this.state.showArrows) && (
				<div
					className={`action-item rotate icon${this.state.disableBottomMove ? ' disabled' : ''}`}
					onClick={!this.state.disableBottomMove && this.rightMove.bind(this)}
				>
					<UpDown height='10px' width='auto'/>
				</div>
			)}
		</div>;
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			toggleSumLabel,
			layerLabelSetMouseDown,
			layerLabelSetOffsets,
			layerLabelToggleExpand
		},
		dispatch
	);
}

const mapStateToProps = state => {
	const layers = state.layers.list.filter(layer => {
		return layer.isVisible();
	});

	return {
		measure: state.scale.measure,
		isFractions: state.settings.imperial === 'fractions',
		scale: state.scale.value,
		zoom: state.zoom.value,
		showAllLayers: state.layers.showAllLayers,
		image: state.image,
		canvasVisible: state.drawArea.visible,
		showSumLabels: state.label.showSumLabels,
		layers,
		layerLabel: state.layerLabel,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LayerLabelsFixed);
