import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextView from '../../../Util/TextView';
import { ruleRunner, run } from '../../../Validation/ruleRunner';
import { required } from '../../../Validation/rules';
import update from 'immutability-helper';
import ItemsList from '../../../App/ItemsList';
import { getSessionDocuments, getSessionsList } from '../../../../shared/actions/educatorActions';
import moment from 'moment';
import saveSvgAsPng from 'save-svg-as-png';
import ApiHelper from '../../../../shared/ApiHelper';
import { notificationTypes } from '../../../../shared/constants/notificationConstants';
import { notificationShow } from '../../../../shared/actions/notificationActions';
import { stateRestoreFromObject, stateToSaveObject } from '../../../../shared/actions/stateActions';
import { saveFileName } from '../../../../shared/actions/fileNameActions';
import { modalClose } from '../../../../shared/reducers/ModalReducer';
import { layerIsEmptyAllLayers } from '../../../../shared/actions/layerActions';
import { updateFileState } from '../../../../shared/actions/fileStateAction';
import { authLogout } from '../../../../shared/actions/authActions';
import './_ModalSaveOpenStudentStyle.scss';


export const MODAL_SAVE_OPEN_STUDENT = 'MODAL_SAVE_OPEN_STUDENT';

const fieldValidations = [
	ruleRunner('name', 'Name', required),
];

class ModalSaveOpenStudent extends React.Component {
	handleTimeout = null;

	constructor(props) {
		super(props);

		this.state = {
			openIsDisabled: false,
			detailsMessage: null,
			name: props.fileName || '',
			showErrors: false,
			validationErrors: [],
			sessionsLoading: false,
			documentsLoading: false,
			activeSessionId: null,
			sessionIdForSave: null,
			activeDocument: null,
			message: null,
			rewriteConfirmation: false,
			saveUnsavedChanges: this.props.modalData.action === 'logout',
			createNewFile: false,
			loadFileAfterSave: '',
			logoutAction: this.props.modalData.action === 'logout'
		};
	}

	async componentWillMount() {
		this.setState({ sessionsLoading: true, documentsLoading:true });
		const sessions = await this.props.getSessionsList();
		if (sessions && sessions[0]) {
			this.selectSession(sessions[0]);
			const activeSession = sessions.filter(session => !session.isEnded)[0] || sessions[0];
			this.setState({
				sessionIdForSave: activeSession.id
			});
		} else {
			this.setState({ documentsLoading: false });
		}
		this.setState({ sessionsLoading: false });
	}

	handleFieldChanged = (field) => {
		return e => {
			let newState = update(this.state, {
				[field]: { $set: e.target.value }
			});
			newState.validationErrors = run(newState, fieldValidations);
			newState.activeDocument = null;
			newState.isOpenDisabled = false;
			newState.detailsMessage = null;

			return this.setState(newState);
		};
	}

	errorFor(field) {
		return this.state.validationErrors[field] || '';
	}

	getSessionName = session => {
		const { created_at, name } = session;
		const time = moment(created_at).format('DD-MM-YYYY HH:MM');

		return <div className={'session-item d-flex justify-content-between w-100 flex-column'}>
			<div className={'session-item-name'}>{name}</div>
			<div className={'session-item-time'}>{time}</div>
		</div>;
	}

	selectSession = session => {
		const { getSessionDocuments } = this.props;
		this.setState({
			activeSessionId: session.id,
			documentsLoading: true,
			activeDocument: null,
			isOpenDisabled: false,
			detailsMessage: null
		});
		getSessionDocuments(session.id).finally(() => {
			this.setState({documentsLoading: false});
		});
	}

	selectDocument = document => {
		const { currentDeviceUuid } = this.props;
		let openIsDisabled = false;
		let detailsMessage = null;

		if (document.device_uuid === currentDeviceUuid) {
			detailsMessage = {
				message: 'File currently open',
				classes: 'details_error'
			};
			openIsDisabled = true;
		} else if (document.device_uuid) {
			detailsMessage = {
				message: 'File is open by other user',
				classes: 'details_error'
			};
			openIsDisabled = true;
		}

		this.setState({
			activeDocument: document,
			detailsMessage,
			openIsDisabled
		});
	}

	clearTimeout() {
		if (this.handleTimeout) {
			window.clearTimeout(this.handleTimeout);
			this.handleTimeout = null;
		}
	}

	renderConfirmOverwrite() {
		const onClick = () => {
			this.onSave(this.state.createNewFile && this.state.saveUnsavedChanges);
		};

		return (
			<div className='modal-clear-canvas modal-inner-wrapper'>
				<h2 className='text-center'>Overwrite saved file?</h2>
				<div className='actions text-center'>
					<button
						className='btn btn-modal'
						onClick={() => {
							this.setState({
								rewriteConfirmation: false,
							});
						}}>
						cancel
					</button>
					<button
						className='btn btn-modal'
						onClick={onClick}>
						overwrite
					</button>
				</div>
			</div>
		);
	}

	renderSaveFileForm = (onSave) => {
		const { name, documentsLoading, sessionsLoading } = this.state;
		return <div className='modal-clear-canvas modal-inner-wrapper'>
			<h2 className='text-center'>Enter new file name.</h2>
			<div className='row mt-2'>
				<div className='col save d-flex'>
					<input
						value={name}
						onChange={this.handleFieldChanged('name')}
						autoComplete='new-password'
						className={'form-control'}
					/>
					<button
						disabled={!name || documentsLoading || sessionsLoading}
						onClick={onSave}
						className='btn btn-modal'
					>
						save
					</button>
				</div>
			</div>
		</div>;
	}

	renderUnsavedChanges = () => {
		if (this.state.createNewFile) {
			const saveAndOpenFile = () => {
				this.props.updateFileState(false);
				this.onSave(true);
			};
			return this.renderSaveFileForm(saveAndOpenFile);
		}

		const title = this.state.logoutAction ? 'save your work?' : 'save changes and open';
		const cancelText = this.state.logoutAction ? 'discard' : 'cancel';
		const okayText = this.state.logoutAction ? 'save' : 'open';
		const onCancel = () => {
			this.props.updateFileState(false);
			if (this.state.logoutAction) {
				this.props.saveFileName('');
				this.props.authLogout(this.props.userEmail);
			} else {
				this.setState({
					rewriteConfirmation: false,
					saveUnsavedChanges: false,
					rewriteFileName: null,
					loadFileAfterSave: ''
				});
			}
		};
		const onOkay = () => {
			if (this.props.fileName) {
				this.props.updateFileState(false);
				this.saveFile(this.props.fileName, true);
			} else {
				this.setState({createNewFile: true});
			}
		};

		return (
			<div className='modal-clear-canvas modal-inner-wrapper'>
				<h2 className='text-center'>{title}</h2>
				<div className='actions text-center'>
					<button
						className='btn btn-modal'
						onClick={onCancel}>
						{cancelText}
					</button>
					<button
						className='btn btn-modal'
						onClick={onOkay}>
						{okayText}
					</button>
				</div>
			</div>
		);
	}


	onSave = async (loadFile) => {
		const { layerIsEmptyAllLayers, documents } = this.props;
		const { name, rewriteConfirmation, sessionIdForSave } = this.state;
		this.clearTimeout();
		const layersAreEmpty = await layerIsEmptyAllLayers();

		if (layersAreEmpty) {
			this.setState({
				message: {
					type: 'danger',
					message: 'All layers are empty!'
				}
			});

			this.handleTimeout = window.setTimeout(this.resetMessage.bind(this), 1000);
			return;
		}

		if (name.length) {
			const documentList = documents[sessionIdForSave];

			if (documentList.find(document => document.name === name) && !rewriteConfirmation) {
				this.setState({
					rewriteConfirmation: true
				});
			} else {
				this.saveFile(name, loadFile);
			}
		} else {
			this.setState({
				message: {
					type: 'danger',
					message: 'Please specify a file name.'
				}
			});

			this.handleTimeout = window.setTimeout(this.resetMessage.bind(this), 1000);
		}
	}

	resetMessage() {
		this.setState({ message: null });
	}

	saveFile = (name, loadFile) => {
		const { sessionIdForSave } = this.state;
		const { stateToSaveObject, getSessionsList, saveFileName, notificationShow, modalClose, updateFileState } = this.props;
		this.setState({
			isDisabled: true,
			message: {
				type: 'success',
				message: 'Saving... Please wait!'
			}
		});

		stateToSaveObject().then(state => {
			saveSvgAsPng
				.svgAsPngUri(document.getElementById('main'), {
					encoderOptions: 0.8,
					scale: 0.2
				})
				.then(preview => {
					return ApiHelper.saveDocument({
						name: name,
						state: state,
						session_id: sessionIdForSave,
						preview
					});
				})
				.then(() => {
					this.setState({
						isDisabled: false,
						message: null,
						name: ''
					});

					if (this.state.rewriteConfirmation) {
						this.setState({
							rewriteConfirmation: false
						});
						notificationShow('Saved', notificationTypes.SUCCESS, 1000);
						modalClose();
					} else {
						getSessionsList();
					}
					if (this.state.logoutAction) {
						this.props.updateFileState(false);
						this.props.saveFileName('');
						return this.props.authLogout(this.props.userEmail);
					} else {
						loadFile && setTimeout(() => this.openDocument(), 500);
					}
					saveFileName(name);
					setTimeout(() => updateFileState(false), 500);
				})
				.catch(error => {
					const state = {
						isDisabled: false,
						message: null
					};

					if (error.response.data.message) {
						state.message = {
							type: 'danger',
							message: error.response.data.message
						};

						this.handleTimeout = window.setTimeout(this.resetMessage.bind(this), 1000);
					}

					this.setState(state);
				});
		});
	}

	openDocument = () => {
		const { stateRestoreFromObject, modalClose, saveFileName, needSave } = this.props;
		const { activeDocument, loadFileAfterSave } = this.state;

		let document = activeDocument || loadFileAfterSave;
		if (document) {
			if (needSave) {
				return this.setState({saveUnsavedChanges: true, loadFileAfterSave: document});
			}
			ApiHelper.getDocument(document.id);
			saveFileName(document.name);
			stateRestoreFromObject(document);
			setTimeout(() => this.props.updateFileState(false), 1000);
			modalClose();
		}
	}

	checkBlocked = document => {
		const { currentDeviceUuid } = this.props;
		const classnames = [];

		if (!document.device_uuid) {
			return classnames;
		}

		if (currentDeviceUuid === document.device_uuid) {
			classnames.push('blocked_by_current_device');
		}

		if (currentDeviceUuid !== document.device_uuid) {
			classnames.push('blocked_by_other_device');
		}

		return classnames;
	}

	render() {
		const { sessionsList, documents } = this.props;
		const { showErrors, name, sessionsLoading, activeSessionId, message, documentsLoading,
			activeDocument, detailsMessage, openIsDisabled, rewriteConfirmation, saveUnsavedChanges } = this.state;

		if (rewriteConfirmation) {
			return this.renderConfirmOverwrite();
		}

		if (saveUnsavedChanges) {
			return this.renderUnsavedChanges();
		}

		return <div className={'modal-save-open-student modal-inner-wrapper'}>
			<h2 className='text-center mb-4'>save | open</h2>
			<div className='row'>
				<div className='col save'>
					<TextView
						showError={showErrors}
						text={name}
						onFieldChanged={this.handleFieldChanged('name')}
						errorText={this.errorFor('name')}
						autoComplete='new-password'
						className={'session-name'}
						wrapperClass={'w-100'}
					/>
					<button
						disabled={!name || documentsLoading || sessionsLoading}
						onClick={this.onSave}
						className='btn btn-modal'
					>
						save
					</button>
				</div>
			</div>
			<div className='row message-row'>
				<div className='col messages'>
					{message && (
						<p className={`text-center text-${message.type}`}>
							{message.message}
						</p>
					)}
				</div>
			</div>
			<div className={'row lists-row'}>
				<div className={'col lists'}>
					<div className={'sessions'}>
						<ItemsList
							withLoader
							loading={sessionsLoading}
							items={sessionsList}
							title={'session'}
							itemLabelKey={'name'}
							getLabel={this.getSessionName}
							activeIndex={activeSessionId}
							onClick={this.selectSession}
						/>
					</div>
				</div>
				<div className={'col lists'}>
					<div className={'documents'}>
						<ItemsList
							withLoader
							loading={documentsLoading}
							items={documents[activeSessionId] || []}
							title={'name'}
							itemLabelKey={'name'}
							getLabel={document => document.name}
							activeIndex={activeDocument? activeDocument.id : null}
							onClick={this.selectDocument}
							getItemClasses={this.checkBlocked}
						/>
					</div>
				</div>
				<button
					onClick={this.openDocument}
					className='btn btn-modal'
					disabled={!activeDocument || openIsDisabled}
				>
					open
				</button>
			</div>
			<div className={'row'}>
				<div className={'col error-col'}>
					{detailsMessage &&
						<div className={`details-block ${detailsMessage.classes}`}>
							{detailsMessage.message}
						</div>
					}
				</div>
			</div>
		</div>;
	}
}

const mapStateToProps = (state) => {
	const {
		auth: {
			user
		},
		educator: {
			sessionsList,
			documents
		},
		fileName: {
			fileName
		}
	} = state;

	return {
		currentDeviceUuid: user? user.device_uuid : null,
		sessionsList,
		documents,
		fileName,
		needSave: state.fileState.needSave,
		modalData: state.modal.data || {},
		userEmail: user && user.email
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		layerIsEmptyAllLayers,
		stateRestoreFromObject,
		getSessionDocuments,
		stateToSaveObject,
		notificationShow,
		getSessionsList,
		saveFileName,
		modalClose,
		updateFileState,
		authLogout
	}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSaveOpenStudent);
