import React from 'react';
import update from 'immutability-helper';
import { ruleRunner, run } from '../../../../Validation/ruleRunner';
import { required } from '../../../../Validation/rules';
import TextView from '../../../../Util/TextView';
import ApiHelper from '../../../../../shared/ApiHelper';
import { dispatch } from '../../../../../shared/store';
import { authSetUser } from '../../../../../shared/actions/authActions';

const fieldValidations = [ruleRunner('name', 'Name', required)];

export default class AccountNameField extends React.Component {
	handleTimeout = null;

	constructor(props) {
		super(props);

		this.state = {
			name: props.name,
			disabled: false,
			showErrors: false,
			validationErrors: {},
			success: false
		};
	}

	clearTimeout() {
		if (this.handleTimeout) {
			window.clearTimeout(this.handleTimeout);
			this.handleTimeout = null;
		}
	}

	resetSuccess() {
		this.setState({ success: false });
	}

	componentWillMount() {
		this.setState({ validationErrors: run(this.state, fieldValidations) });
	}

	componentWillUnmount() {
		this.clearTimeout();
	}

	errorFor(field) {
		return this.state.validationErrors[field] || '';
	}

	handleFieldChanged(field) {
		return e => {
			let newState = update(this.state, {
				[field]: { $set: e.target.value }
			});

			newState.validationErrors = run(newState, fieldValidations);
			this.setState(newState);
		};
	}

	handleSubmit() {
		this.setState({ showErrors: true });

		if (Object.keys(this.state.validationErrors).length) return;

		const name = this.state.name;

		this.setState({
			showErrors: false,
			disabled: true
		});

		ApiHelper.updateName({ name })
			.then(response => {
				const state = {
					disabled: false
				};

				if (response.data.success && response.data.userMeta) {
					const meta = response.data.userMeta;

					dispatch(authSetUser(meta));

					state.name = `${meta.first_name} ${meta.last_name}`;
					state.success = true;
					this.handleTimeout = window.setTimeout(this.resetSuccess.bind(this), 3000);
				}

				this.setState(state);
			})
			.catch(error => {
				const state = {
					disabled: false
				};
				if (error.response.data.message) {
					state.showErrors = true;
					state.validationErrors = {
						name: error.response.data.message
					};
				}

				this.setState(state);
			});
	}

	render() {
		const btnClassNames = ['btn', 'btn-modal'];

		if (this.state.success) {
			btnClassNames.push('btn-success');
		}

		return (
			<div className='field'>
				<TextView
					placeholder='Name'
					showError={this.state.showErrors}
					text={this.state.name}
					onFieldChanged={this.handleFieldChanged('name')}
					errorText={this.errorFor('name')}
				/>
				<button
					onClick={this.handleSubmit.bind(this)}
					className={btnClassNames.join(' ')}
					disabled={
						this.state.success ||
						!this.state.name.length ||
						this.state.disabled ||
						this.props.name === this.state.name
					}>
					{this.state.success ? 'done' : 'update'}
				</button>
			</div>
		);
	}
}
