const LAYER_ADD = 'LAYER_ADD';
const LAYER_CHANGE = 'LAYER_CHANGE';
const LAYER_UPDATE = 'LAYER_UPDATE';
const LAYER_SET_SHOW_ALL_LAYERS = 'LAYER_SET_SHOW_ALL_LAYERS';
const LAYER_SET_LIST_LAYERS = 'LAYER_SET_LIST_LAYERS';
const LAYER_DELETE = 'LAYER_DELETE';
const LAYER_RESET = 'LAYER_RESET';
const LAYER_DUPLICATE = 'LAYER_DUPLICATE';
const TOGGLE_PIN_LAYER = 'TOGGLE_PIN_LAYER';

export const availableCustomColors = [
	'yellow',
	'selective-yellow',
	'orange',
	'blue',
	'green',
	'lime',
	'red',
	'daisy-bush',
	'blue-ribbon',
	'pink',
	'seance',
	'shiraz'
];

export default {
	LAYER_ADD,
	LAYER_CHANGE,
	LAYER_UPDATE,
	LAYER_SET_SHOW_ALL_LAYERS,
	LAYER_SET_LIST_LAYERS,
	LAYER_DELETE,
	LAYER_RESET,
	LAYER_DUPLICATE,
	TOGGLE_PIN_LAYER
};
