import { RECTANGLE_TOOL } from './Instruments';
import Line from './Line';

export default class Rectangle {
	constructor(p1 = null, p2 = null, p3 = null, p4 = null, startLatLng = null, endLatLng = null) {
		this.type = RECTANGLE_TOOL;

		this.p1 = p1;
		this.p2 = p2;
		this.p3 = p3;
		this.p4 = p4;

		this.startLatLng = startLatLng;
		this.endLatLng = endLatLng;
	}

	start(point) {
		this.p1 = point;
		this.p4 = point;
	}

	setStartLatLng(latLngPoint) {
		this.startLatLng = latLngPoint;
	}

	getStartLatLng() {
		return this.startLatLng;
	}

	end(point, shiftHolded) {
		if (shiftHolded) {
			const squarePoints = this.getSquare(point);
			this.p2 = squarePoints[1];
			this.p3 = squarePoints[2];
			this.p4 = squarePoints[3];
		} else {
			this.p2 = point;
			this.p3 = point;
		}
	}

	setEndLatLng(latLngPoint) {
		this.endLatLng = latLngPoint;
	}

	getEndLatLng() {
		return this.endLatLng;
	}

	getSquare(point) {
		const p1 = this.p1,
			p3 = point,
			m = [(p1[0] + point[0]) / 2, (p1[1] + point[1]) / 2],
			t = [(p1[1] - point[1]) / 2, (p1[0] - point[0]) / 2];

		let p4 = [m[0] + t[0], m[1] - t[1]];
		let p2 = [m[0] - t[0], m[1] + t[1]];

		return [p1, p2, p3, p4];
	}

	isCollapsed() {
		return this.p3 === this.p2 && this.p4 === this.p1;
	}

	expand(point) {
		const expandedRect = this.getExpandedRect(point);
		this.p1 = expandedRect[0];
		this.p2 = expandedRect[1];
		this.p3 = expandedRect[2];
		this.p4 = expandedRect[3];
	}

	getExpandedRect(point) {
		const distance = this.getDistanceToPoint(point),
			vector = [this.p2[0] - this.p1[0], this.p2[1] - this.p1[1]],
			angle = Math.atan2(vector[0], vector[1]);

		return [
			this.p1,
			this.p2,
			[this.p2[0] - Math.cos(angle) * distance, this.p2[1] + Math.sin(angle) * distance],
			[this.p1[0] - Math.cos(angle) * distance, this.p1[1] + Math.sin(angle) * distance]
		];
	}

	getDistanceToPoint(point) {
		const a = this.p1[1] - this.p2[1],
			b = this.p2[0] - this.p1[0],
			c = this.p1[0] * this.p2[1] - this.p1[1] * this.p2[0];

		return (a * point[0] + b * point[1] + c) / Math.sqrt(a * a + b * b);
	}

	getLines() {
		return [
			new Line(this.p1, this.p2),
			new Line(this.p2, this.p3),
			new Line(this.p3, this.p4),
			new Line(this.p4, this.p1)
		];
	}

	getPoints() {
		return [this.p1, this.p2, this.p3, this.p4];
	}

	toPolygon() {
		let result = this.p1.join(',') + ' ' + this.p2.join(',') + ' ' + +this.p3.join(',') + ' ' + +this.p4.join(',');

		return result;
	}

	getType() {
		return this.type;
	}

	getMiddlePoint() {
		return [(this.p1[0] + this.p2[0]) / 2, (this.p1[1] + this.p2[1]) / 2];
	}

	getLabelAngle() {
		let x = this.endPoint[0] - this.startPoint[0];
		let y = this.endPoint[1] - this.startPoint[1];
		let theta = Math.atan2(y, x);
		theta *= 180 / Math.PI;

		return theta;
	}

	getPreviewByShapeControl(point) {
		return this.getExpandedRect(point);
	}

	getInitPreview(point, isConstrained) {
		if (isConstrained) {
			return this.getSquare(point);
		} else {
			return [this.p1, point];
		}
	}

	updatePoint(pointIndex, newPoint) {
		this['p' + pointIndex] = newPoint;
	}

	toPath() {
		if (this.isCollapsed()) {
			return 'M ' + this.p1.join(',') + ' L ' + this.p2.join(',');
		}
	}

	toParams() {
		return {
			id: this.id,
			p1: this.p1,
			p2: this.p2,
			p3: this.p3,
			p4: this.p4,
			startLatLng: this.startLatLng,
			endLatLng: this.endLatLng
		};
	}
}
