import {
    EDUCATOR_REVOKE,
    EDUCATOR_SET_PENDING,
    EDUCATOR_SET_SESSION,
    EDUCATOR_SET_SESSIONS,
    EDUCATOR_DELETE_SESSION,
    EDUCATOR_SET_SESSION_DOCUMENTS, EDUCATOR_STUDENT_UPDATE_DOCUMENT
} from '../constants/educatorConstants';
import get from 'lodash/get';

const initState = {
    pending: false,
    session: null,
    sessionsList: [],
    documents: {}
};

export default (state = initState, action) => {
    switch (action.type) {
        case EDUCATOR_SET_PENDING: {
            return {
                ...state,
                pending: action.payload
            }
        }

        case EDUCATOR_SET_SESSION: {
            return {
                ...state,
                session: action.payload
            }
        }

        case EDUCATOR_SET_SESSIONS: {
            return {
                ...state,
                sessionsList: action.payload
            }
        }

        case EDUCATOR_SET_SESSION_DOCUMENTS: {
            return {
                ...state,
                documents: {
                    ...state.documents,
                    [action.payload.sessionId]: action.payload.documents
                }
            }
        }

        case EDUCATOR_DELETE_SESSION: {
            const { sessionId } = action.payload;
            const { documents, sessionsList, session } = state;
            delete documents[sessionId];
            const newSessionsList = sessionsList.filter(s => s.id !== sessionId);
            const newSession = session && session.id === sessionId ? null : session;

            return {
                ...state,
                sessionsList: newSessionsList,
                session: newSession
            }
        }

        case EDUCATOR_REVOKE: {
            return { ...initState }
        }

        case EDUCATOR_STUDENT_UPDATE_DOCUMENT: {
            const { documents: updatedDocuments } = action.payload;
            const { documents, sessionsList } = state;
            const sessionId = get(updatedDocuments, '[0].session_id', null);

            if (!sessionId) {
                return state
            }

            const updatedSessionsList = [...sessionsList]
            try {
                const updatedDocumentsCount = updatedDocuments.length;
                const index = updatedSessionsList.findIndex(session => session.id === sessionId);

                if (index!==-1) {
                    updatedSessionsList[index] = { ...updatedSessionsList[index], documents_count:updatedDocumentsCount }
                }

            } catch (e) {}

            return {
                ...state,
                documents: {
                    ...documents,
                    [sessionId]: updatedDocuments
                },
                sessionsList: updatedSessionsList
            }

        }

        default:
            return state;
    }
}
