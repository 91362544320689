import * as React from 'react';
import LineLabel from '../LineLabel/LineLabel';
import { CURVED_LINE } from '../../../shared/classes/Instruments';
import CurvedLine from '../../../shared/classes/CurvedLine';
import polygonOverlap from 'polygon-overlap';
import isEqual from 'lodash.isequal';
import { gridSize } from '../CanvasGrid/CanvasGrid';
import MathHelper from '../../../shared/MathHelper';
import { renderEditLineLength } from '../../ContextualHelp/Modals/EditLineLength';
import { renderLineLengthsLabels } from '../../ContextualHelp/Modals/LineLengthsLabels';
import DiameterLine from '../../../shared/classes/DiameterLine';

export class LineLabels extends React.Component {
	handleRefs = [];

	constructor(props) {
		super(props);

		this.state = {
			hiddenLabels: []
		};
	}

	render() {
		this.handleRefs = [];
		return <g className='line-labels'>{this.props.visible && this.getLabels()}</g>;
	}

	getPolygonByElement(el) {
		const polygon = [],
			svg = el.ownerSVGElement,
			p = svg.createSVGPoint(),
			bbox = el.getBBox(),
			matrix = el.getScreenCTM();

		p.x = bbox.x;
		p.y = bbox.y;
		polygon.push(p.matrixTransform(matrix));
		p.x += bbox.width;
		polygon.push(p.matrixTransform(matrix));
		p.y += bbox.height;
		polygon.push(p.matrixTransform(matrix));
		p.x -= bbox.width;
		polygon.push(p.matrixTransform(matrix));

		return polygon.map(point => [point.x, point.y]);
	}

	componentDidMount() {
		this.hideOverlaps();
	}

	componentDidUpdate() {
		this.hideOverlaps();
	}

	hideOverlaps() {
		const polygons = [],
			hiddenLabels = [];
		let lastVisibleIndex = this.handleRefs.length - 1;

		if (this.props.closed) {
			this.handleRefs.forEach((ref, index) => {
				if (ref) {
					const currentPolygon = this.getPolygonByElement(ref);

					if (!polygons.length && currentPolygon) {
						polygons.push(currentPolygon);

						if (!this.props.helpEditLineLength && renderEditLineLength) {
							const bounds = ref.getBoundingClientRect();

							renderEditLineLength({
								top: bounds.top,
								left: bounds.left,
								width: bounds.width,
								height: bounds.height
							});
						}
					} else {
						let isIntersect = false;

						if (currentPolygon) {
							for (let polygon of polygons) {
								if (polygonOverlap(currentPolygon, polygon)) {
									isIntersect = true;
									break;
								}
							}

							if (isIntersect) {
								hiddenLabels.push(index);
							} else {
								polygons.push(currentPolygon);
								lastVisibleIndex = index;
							}
						}
					}
				}
			});
		}

		if (!this.props.helpHideLineLengthLabels && renderLineLengthsLabels && this.handleRefs[lastVisibleIndex]) {
			const bounds = this.handleRefs[lastVisibleIndex].getBoundingClientRect();

			renderLineLengthsLabels({
				top: bounds.top,
				left: bounds.left,
				width: bounds.width,
				height: bounds.height
			});
		}

		if (!isEqual(this.state.hiddenLabels, hiddenLabels)) {
			this.setState({ hiddenLabels });
		}
	}

	getLabels() {
		let { isShowDiameter } = this.props;

		return this.props.lines.map((line, index) => {
			if (line instanceof DiameterLine && !isShowDiameter) {
				return null;
			}

			let text = MathHelper.round(line.getLength(this.props.scale) / gridSize);

			if (line instanceof CurvedLine) {
				text = '~' + text;
			}

			return (
				<LineLabel
					labelRef={el => (this.handleRefs[index] = el)}
					key={index}
					zoom={this.props.zoom}
					asTooltip={line.type === CURVED_LINE}
					point={line.getMiddlePoint()}
					lineSize={line.getLength()}
					lineType={line.getType()}
					lineIndex={index}
					text={text}
					rotate={line.getLabelAngle()}
					hidden={this.state.hiddenLabels.includes(index)}
				/>
			);
		});
	}
}
