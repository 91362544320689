import { overlay } from '../components/App/Map/Map';

export default class MapHelper {
	static latLng2Point(latLng) {
		let gLatLng = new window.google.maps.LatLng(latLng[0], latLng[1]),
			projection = overlay.getProjection(),
			point = projection.fromLatLngToContainerPixel(gLatLng);

		return [point.x, point.y];
	}

	static point2LatLng(point) {
		let projection = overlay.getProjection(),
			gPoint = new window.google.maps.Point(point[0], point[1]),
			latLng = projection.fromContainerPixelToLatLng(gPoint);

		return [latLng.lat(), latLng.lng()];
	}
}
