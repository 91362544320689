import * as types from '../constants/previewConstants';
import { ANGLE_LINE, CONSTRAINED_LINE, STRAIGHT_LINE, RECTANGLE_TOOL } from '../classes/Instruments';
import MathHelper from '../MathHelper';

export function previewShow(point, startPoint) {
	return (dispatch, state) => {
		let { instrument, sketchState } = state();

		if (isSkip(instrument)) {
			return;
		}

		if (instrument === CONSTRAINED_LINE) {
			point = MathHelper.convertLineToConstrainedLine(startPoint, point);
		}

		if (instrument === RECTANGLE_TOOL) {
			dispatch(startShapePreview());
		} else {
			dispatch({
				type: types.DRAW_AREA_PREVIEW_SHOW,
				payload: { point, from: startPoint }
			});
		}
	};
}

export function previewMove(point) {
	return (dispatch, state) => {
		let { instrument, preview, sketchState, drawArea } = state();

		if (isSkip(instrument)) {
			return;
		}

		if (instrument === CONSTRAINED_LINE) {
			point = MathHelper.convertLineToConstrainedLine(preview.from, point);
		}

		if (instrument === RECTANGLE_TOOL && sketchState.currentShape) {
			dispatch({
				type: types.DRAW_AREA_SHAPE_PREVIEW_MOVE,
				payload: {
					shapePreview: sketchState.currentShape.getInitPreview(point, drawArea.shiftHolded)
				}
			});
		} else {
			dispatch({
				type: types.DRAW_AREA_PREVIEW_MOVE,
				payload: { point }
			});
		}
	};
}

export function previewHide() {
	return {
		type: types.DRAW_AREA_PREVIEW_HIDE
	};
}

export function startShapePreview() {
	return {
		type: types.DRAW_AREA_SHAPE_PREVIEW_START
	};
}

export function moveShapePreview(shapePreview) {
	return (dispatch, state) => {
		let { preview } = state();

		if (!preview.shapeShow) {
			return;
		}

		dispatch({
			type: types.DRAW_AREA_SHAPE_PREVIEW_MOVE,
			payload: { shapePreview }
		});
	};
}

export function endShapePreview() {
	return {
		type: types.DRAW_AREA_SHAPE_PREVIEW_END
	};
}

function isSkip(instrument) {
	return ![STRAIGHT_LINE, CONSTRAINED_LINE, ANGLE_LINE, RECTANGLE_TOOL].includes(instrument);
}
