import authConstants from '../constants/authConstants';
import cookie from 'js-cookie';

const initState = {
	isAuthenticated: !!cookie.get('accessToken'),
	user: null,
	isForceLogin: Boolean(window.sessionStorage.getItem('shouldShowMessage')),
	pending: false
};

export default function auth(state = initState, action) {
	switch (action.type) {
		case authConstants.AUTH_PENDING: {
			return {...state, pending: action.payload || false}
		}

		case authConstants.AUTH_LOGIN: {
			return { ...state, isAuthenticated: true, pending: true };
		}

		case authConstants.AUTH_SET_USER: {
			return {
				...state,
				user: { ...state.user, ...action.payload.user },
				pending: false
			};
		}

		case authConstants.AUTH_LOGOUT: {
			return { ...initState, isAuthenticated: false, isForceLogin: false };
		}

		case authConstants.SHOW_FORCE_LOGIN_MESSAGE: {
			window.sessionStorage.setItem('shouldShowMessage', '1');
			return { ...initState, isAuthenticated: false, isForceLogin: true };
		}

		default:
			return state;
	}
}
