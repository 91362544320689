import React from 'react';
import { withRouter } from 'react-router';
import './ThankYouStyle.scss';

class ThankYou extends React.Component {

    submit = () => {
        window.location.href = process.env.REACT_APP_MAIN_WP_URL
    }

    render() {
        return (
            <div className={'thank-you-wraper'}>
            <div className={'thank-you-block'}>
                    <div className={'title'}>Thank you</div>
                    <div className={'subtitle'}>
                        We will be sending you an email shortly.
                        <br />
                        We aim to review registrations within 24 hours.
                    </div>
                    <button
                        className={'primary-btn'}
                        onClick={this.submit}
                    >
                        Return to Homepage
                    </button>
            </div>
            </div>
        )
    }
}

export default withRouter(ThankYou)