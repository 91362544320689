import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modalClose } from '../../../../shared/reducers/ModalReducer';
import { canvasDrawingReset, canvasReset } from '../../../../shared/actions/drawAreaActions';
import { updateFileState } from '../../../../shared/actions/fileStateAction';
import { saveFileName } from '../../../../shared/actions/fileNameActions';
import ApiHelper from '../../../../shared/ApiHelper';

export const MODAL_CLEAR_CANVAS = 'MODAL_CLEAR_CANVAS';

class ModalClearCanvas extends React.Component {
	render() {
		return (
			<div className='modal-clear-canvas modal-inner-wrapper'>
				<h2 className='text-center'>Clear Canvas</h2>
				<div className='actions text-center'>
					<button
						className='btn btn-modal'
						onClick={() => {
							ApiHelper.onClearCanvas();
							this.props.canvasReset();
							this.props.modalClose();
							this.props.updateFileState(false);
							this.props.saveFileName('');
						}}>
						Clear All
					</button>
					<button
						className='btn btn-modal'
						onClick={() => {
							this.props.canvasDrawingReset();
							this.props.modalClose();
							this.props.updateFileState(false);
							this.props.saveFileName('');
						}}>
						Clear Drawing Only
					</button>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			modalClose,
			canvasReset,
			canvasDrawingReset,
			updateFileState,
			saveFileName
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(ModalClearCanvas);
