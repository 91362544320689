import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactGA from 'react-ga';
import stripeLogo from '../../../assets/img/stripe.svg';
import DocumentMeta from 'react-document-meta';
import { modalClose, modalOpen } from '../../../shared/reducers/ModalReducer';
import { MODAL_REGISTRATION } from '../../ModalContainer/Modals/ModalRegistration/ModalRegistration';
import TrustLogo from '../../TrustLogo';

import './_ApiPage.scss';


class ApiPage extends React.Component {
	plans(plans) {
		return plans.map((plan, key) => (
			<div className='plan' key={key}>
				<h6>{plan.title}</h6>
				<p className='short'>{plan.short}</p>
				<p className='price'>
					<span className='big'>
						$ <span className='amount'>{plan.price}</span>{' '}
					</span>
					<span>
						/session<sup>1</sup>
					</span>
				</p>
				<p className='description-1'>billed monthly</p>
				<button onClick={() => this.props.modalOpen(MODAL_REGISTRATION)}>register</button>
				<p className='line'> </p>
				<ul className='options'>
					{plan.options.map((option, key) => (
						<li key={key} dangerouslySetInnerHTML={{ __html: option }}></li>
					))}
				</ul>
			</div>
		));
	}

	render() {
		const plansData = [
			{
				title: 'maps',
				short: 'quick area calculations over maps',
				price: '0.20',
				options: [
					'free month trial',
					'import maps',
					'search on postal code or longitude and latitude',
					'all measurement systems plus acres and hectares',
					'popup guides and help<sup>2</sup>',
					'copy results to clipboard',
					'basic drawing tools<sup>4</sup>',
					'customize settings and theme<sup>5</sup>'
				]
			},
			{
				title: 'maps + images',
				short: 'calculate area of maps and imported images',
				price: '0.30',
				options: ['import images .jpg .gif .png .pdf', 'autoscale tool', 'save, open, and print<sup>3</sup>'],
				value: ''
			},
			{
				title: 'maps + images + pro',
				short: 'import .dwx .gpx files plus pro tools',
				price: '0.40',
				options: [
					'import .dwg autoCAD .ai .eps and .gpx files',
					'fill and magicwand tool',
					'multiple image layers'
				]
			}
		];

		return (
			<div id='api' className='api'>
				<DocumentMeta
					title='API Access | Irregular Area Calculator | Calculate Irregular Shape Area'
					description='Integrate our sketch and calculate area for irregular shapes software into your solution with our api. Get started today.'
				/>
				<h2>
					Add Drawing Tools to Your Site <span className='api-coming-soon'>In Development</span>
				</h2>
				<h3>Integrate SketchAndCalc into your Website with our API</h3>
				<h3>First Month is Free, then Pay Only for What You Use.</h3>

				<div className='plans'>{this.plans(plansData)}</div>

				<ul className='footnotes'>
					<li>
						1. Loading a webpage that contains the calculator module is considered a session. Each session
						will be recorded and billed monthly.
					</li>
					<li>2. Popup help messages guide new users on how to use the calculator.</li>
					<li>
						3. Save calculation prompts user to create an account. Account holders can login to open, save
						and delete files.
					</li>
					<li>
						4. Basic drawing tools include: line tool, constrained line tool, curved line tool, rectangle
						tool, circle tool, angle tool.
					</li>
					<li>
						5. Control how your calculator displays line length labels, angles, grid transparency, drawing
						color, line weight, default measurement system and toolbar color.
					</li>
				</ul>

				<div className='logo'>
					<TrustLogo />
					<ReactGA.OutboundLink
						className='stripe'
						eventLabel='Power by stripe'
						to='https://stripe.com'
						target='_blank'>
						<img src={stripeLogo} alt='Power by stripe' />
					</ReactGA.OutboundLink>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			modalOpen,
			modalClose
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(ApiPage);
