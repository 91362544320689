import * as types from '../constants/zoomConstants';

const initiateState = {
	value: 1,
	zoomViewParams: null,
	panBy: null
};

export default function zoomReducer(state = initiateState, action) {
	switch (action.type) {
		case types.ZOOM_IN: {
			const scale = state.value + 0.1;

			if (scale < 10) {
				return { ...state, value: scale };
			}

			return { ...state, value: 10 };
		}

		case types.ZOOM_OUT: {
			const scale = state.value - 0.1;

			if (scale > 1) {
				return { ...state, value: scale };
			}

			return { ...state, value: 1 };
		}

		case types.ZOOM_PAN_MOVED: {
			return { ...state, zoomViewParams: action.payload };
		}

		case types.ZOOM_SET_PAN_BY: {
			return { ...state, panBy: action.payload.panBy };
		}

		case types.ZOOM_UPDATE: {
			return { ...state, ...action.payload.state };
		}

		case types.ZOOM_RESET: {
			return { ...initiateState };
		}

		default: {
			return state;
		}
	}
}
