import React from 'react';
import OptionallyDisplayed from './OptionallyDisplayed.js';
import PropTypes from 'prop-types';

export default class TextAreaView extends React.Component {
	constructor(props) {
		super(props);
		this.shouldDisplayError = this.shouldDisplayError.bind(this);
	}

	shouldDisplayError() {
		return this.props.showError && this.props.errorText !== '';
	}

	render() {
		return (
			<div>
				<textarea
					className='form-control'
					placeholder={this.props.placeholder}
					onChange={this.props.onFieldChanged}
					value={this.props.text}
				/>
				<OptionallyDisplayed display={this.shouldDisplayError()}>
					<div className='validation-error'>
						<span className='text'>{this.props.errorText}</span>
					</div>
				</OptionallyDisplayed>
			</div>
		);
	}
}

TextAreaView.propTypes = {
	showError: PropTypes.bool.isRequired,
	onFieldChanged: PropTypes.func.isRequired
};
