import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { helpActivateScale3 } from '../../../shared/actions/helpActions';

class SetScale3 extends React.Component {
	render() {
		return (
			<div
				style={{
					top: 65,
					left: 10
				}}
				className='contextual-modal arrow-top-left'>
				<h3 className='title'>
					Set Scale <span>3/3</span>
				</h3>
				<div className='content'>
					With the scale now set, select
					<br />
					<span className='bg-blue'>ADD TO CANVAS</span> to begin drawing
				</div>
				<div className='actions'>
					<button
						onClick={() => {
							this.props.helpActivateScale3();
						}}
						className='btn btn-sm btn-modal'>
						ok
					</button>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			helpActivateScale3
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(SetScale3);
