import React from 'react';
import {Link} from "react-router-dom";
import mapControlPlaceholderImg from '../../../assets/img/homepage/map-control-ph.png';
import ScrollableAnchor from 'react-scrollable-anchor'
import {mobileDetect} from '../../../shared/store';
import DocumentMeta from 'react-document-meta';

export default class HomePage extends React.Component {

    videoEl = null;

    constructor(props) {
        super(props);

        this.state = {
            isPlaying: false,
            messageShown: true,
            startTime: null
        };
    }

    onPlay() {
        if (this.videoEl) {
            this.setState({isPlaying: true});
            this.setState({messageShown: false});
        }
    }

    onPause() {
        if (this.videoEl) {
            this.setState({isPlaying: false});
            this.setState({messageShown: true});
        }
    }

    onEnded() {
        if (this.videoEl) {
            this.setState({isPlaying: false});
            this.setState({messageShown: true});
        }
    }

    componentDidMount() {
        if (this.videoEl) {
            this.videoEl.addEventListener('play', this.onPlay.bind(this));
            this.videoEl.addEventListener('pause', this.onPause.bind(this));
            this.videoEl.addEventListener('ended', this.onEnded.bind(this));
        }

        this.setState({startTime: Date.now()});
        document.body.classList.add('home');
    }

    componentWillUnmount() {
        if (this.videoEl) {
            this.videoEl.removeEventListener('play', this.onPlay.bind(this));
            this.videoEl.removeEventListener('pause', this.onPause.bind(this));
            this.videoEl.removeEventListener('ended', this.onEnded.bind(this));
        }

        this.videoEl = null;

        document.body.classList.remove('home');
    }

    playToggle() {
        if (this.videoEl) {
            if (this.state.isPlaying) {
                this.videoEl.pause();
            } else {
                this.videoEl.play();
            }
        }
    }

    render() {
        const videoClassNames = ['video'];

        if (!this.state.isPlaying) {
            videoClassNames.push('stop-fade');
        }

        return (
            <div className="home-page-content">
                <DocumentMeta
                    title="Irregular Area Calculator | Calculate Irregular Shape Area"
                    description="Easily calculate the area or perimeter of any irregular shape. Upload your own image or use coordinates - get started today!"
                />
                <div className="first row no-gutters">
                    <div className="shadow row no-gutters align-items-center justify-content-center">
                        {!mobileDetect.mobile() && <div className={videoClassNames.join(' ')}>
                            <video
                                onTimeUpdate={() => {
                                    this.onVideoTimeUpdate()
                                }}
                                onPause={() => {
                                    this.onVideoPaused()
                                }}
                                onLoadedMetadata={() => {
                                    this.tryToStartVideo();
                                }}
                                ref={el => this.videoEl = el}
                                muted>
                                <source src="/video/Introv5.1.6.mp4" type="video/mp4"/>
                            </video>
                            <button className="btn play-toggle" onClick={this.playToggle.bind(this)}>
                                {this.state.isPlaying ? 'Pause' : 'Play'}
                            </button>
                        </div>}
                        <div className={"inner"}>
                            <div className={this.state.messageShown ? "" : "faded"}>
                                <div className="text">
                                    <h1>
                                        Area<sup>2</sup> in an Instant
                                    </h1>
                                    <p>
                                        Calculate the Area and Perimeter of any
                                        Shape you Draw or Image you Trace
                                    </p>
                                </div>
                                {mobileDetect.mobile() &&
                                <Link to='/download' className="btn">Try it</Link>}
                                {!mobileDetect.mobile() &&
                                <Link to='/register' className="btn">Try it</Link>}
                            </div>
                        </div>
                    </div>
                    <a href="#second" className="scroll-next icon-nav_down text-center">
                        <span className="sr-only">scroll</span>
                    </a>
                </div>
                <ScrollableAnchor id="second">
                    <div className="second">
                        <div className="inner">
                            <h1>
                                Draw over Maps
                            </h1>
                            <p>
                                Search an address, post code, or longitude & latitude
                            </p>
                            <img src={mapControlPlaceholderImg} alt="Map Menu"/>
                        </div>
                    </div>
                </ScrollableAnchor>
                <div className="third">
                    <div className="inner">
                        <h1>
                            Draw over Images
                        </h1>
                        <p>
                            Upload .png, .gif, .jpg, .pdf, .dxf, .svg or .dwg files
                        </p>
                        <i className="icon icon-download"/>
                    </div>
                </div>
                <div className="fourth">
                    <div className="inner">
                        <h1>
                            Straight Lines or Curves
                        </h1>
                        <p>
                            Accurate drawing tools for the best results
                        </p>
                        {mobileDetect.mobile() && <Link to='/download' className="btn">Try it</Link>}
                        {!mobileDetect.mobile() && <Link to='/register' className="btn">Try it</Link>}
                    </div>
                </div>
                <div className="fifth">
                    <div className="inner">
                        <h1>
                            Irregular Area Calculator
                        </h1>
                        <h2>
                            Easily Calculate Irregular Area drawn over Google Maps, Images, Photographs or CAD files.
                        </h2>
                        <p>
                            SketchAndCalc™ is an irregular area calculator app for all manner of images <br/>
                            containing irregular shapes.
                            <br className="sep"/><br className="sep"/>
                            Uniquely, the area calculator is capable of accurately calculating irregular areas
                            of uploaded<br/>
                            images, photographs or plans quickly. Using the area calculators autoscale tool,
                            you can <br/>
                            set the drawing scale of common image formats such as PNG, GIF, and JPEG, along<br/>
                            with PDF's. CAD formats like DXF and DWG used extensively by the architectural industry <br/>
                            can be used to calculate area of irregular building floor plans and odd shaped engineering <br/>
                            projects. Whereas designers using vector files can easily import an SVG, and calculate area <br/>
                            of multiple odd shapes just by drawing their perimeter. Irregular area’s containing fixed <br/>
                            angles or curves are also easily calculated without complex geometry math.
                            <br className="sep"/><br className="sep"/>
                            Simply put, if you have an image you can upload, or a maps address to search, you can calculate <br/>
                            the irregular area of the shape regardless of how complex it is just by drawing around the <br/>
                            perimeter of the area. The app can even sum multiple area calculations together by way of <br/>
                            drawing layers. After the first area has been calculated, a new drawing layer can be added, <br/>
                            allowing for an unlimited number of area calculations to be performed.
                            <br className="sep"/><br className="sep"/>
                            Results of the area calculator app are displayed in imperial and metric systems, increasing <br/>
                            the calculator's utility and removing the need to convert between different square area <br/>
                            measurements, including Hectares to Acres. This, alongside the calculator's precise drawing <br/>
                            tools and magnification, ensures that irregular area’s of every size are calculated quickly, <br/>
                            and accurately.
                            <br className="sep"/><br className="sep"/>
                            The area calculator app is available on all modern web browsers alongside SketchAndCalc™ <br/>
                            for Android, iOS, Windows desktop, and macOS apps for those that need to calculate irregular <br/>
                            area offline or in the field.
                            <br className="sep"/><br className="sep"/>
                            If regular polygon shapes with fixed angles and precise line lengths are needed, the drawing <br/>
                            tools can snap to common angles, perfect squares, and circles. Line lengths can also be <br/>
                            manually edited using the keyboard, helpful if your irregular area has a straight side or <br/>
                            length. And no irregular area calculator would be complete without a curve drawing tool for <br/>
                            odd shapes, another unique feature of SketchAndCalc™.
                            <br className="sep"/><br className="sep"/>
                            Many Google map area calculator apps allow searching of address, SketchAndCalc™ takes it a <br/>
                            step further to support searching of longitude and latitude coordinates. So whether your <br/>
                            area is on agricultural land or sea, you’ll spend less time searching and more time <br/>
                            calculating your irregular area.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    tryToStartVideo() {
        let dt = Date.now() - this.state.startTime;
        if ( dt > 4000) {
            this.playToggle();
        } else {
            setTimeout(this.tryToStartVideo.bind(this), 500);
        }
    }

    onVideoTimeUpdate() {
        /*let time = this.videoEl.currentTime;

        if (time > 3 && time < 53) {
            this.setState({messageShown: false});
        } else if (time > 53) {
            this.setState({messageShown: true});
        } else {
            this.setState({messageShown: true});
        }*/
    }

    onVideoPaused() {
        this.setState({messageShown: true});
    }
}
