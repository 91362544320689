import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { STRAIGHT_LINE } from '../../../shared/classes/Instruments';
import { DIAMETER_LINE } from '../../../shared/classes/DiameterLine';
import { labelReset, labelTogglePointIndex } from '../../../shared/reducers/LabelReducer';
import MathHelper from '../../../shared/MathHelper';
import { lineChangeLength } from '../../../shared/actions/lineActions';
import { gridSize } from '../CanvasGrid/CanvasGrid';
import { stopPropagation } from '../../../shared/EventHelper';
import InputMeasure, { isInputMeasure } from '../InputMeasure/InputMeasure';

class LabelEditComponent extends React.Component {
	input = null;

	componentDidUpdate() {
		if (this.input) {
			this.input.focus();
			this.input.select();
		}
	}

	render() {
		let sketch = this.props.sketch,
			lineIndex = this.props.label.editableLineIndex,
			width = 70,
			height = 20,
			line,
			point,
			rectX,
			rectY,
			value,
			length,
			rotate,
			transformOrigin = '50% 50%';

		if (lineIndex === null || !sketch.closed || !this.props.visible) {
			return null;
		}

		line = sketch.getLines()[lineIndex];

		if (!line || ![STRAIGHT_LINE, DIAMETER_LINE].includes(line.type)) {
			return null;
		}

		value = MathHelper.round(line.getLength(this.props.scale) / gridSize);
		if (this.input) {
			this.input.value = value;
		}

		length = line.getLength();

		point = line.getMiddlePoint();
		point[0] = point[0] * this.props.zoom - Math.abs(this.props.offset.x);
		point[1] = point[1] * this.props.zoom - Math.abs(this.props.offset.y);

		if (length < width / this.props.zoom + 20 / this.props.zoom) {
			rectX = point[0] - width / 2;
			rectY = point[1] - height * 1.5;
			transformOrigin = '50% 150%';
		} else {
			rectX = point[0] - width / 2;
			rectY = point[1] - height / 2;
		}

		rotate = line.getLabelAngle();
		rotate = MathHelper.prettifyAngle(rotate);

		return (
			<div
				className='label-edit-container'
				style={{
					position: 'absolute',
					left: `${rectX}px`,
					top: `${rectY}px`,
					width: `${width}px`,
					height: `${height}px`,
					transform: `rotate(${rotate}deg)`,
					WebkitTransform: `rotate(${rotate}deg)`,
					transformOrigin: `${transformOrigin}`,
					WebkitTransformOrigin: `${transformOrigin}`
				}}
				onMouseDown={stopPropagation}
				onMouseMove={stopPropagation}
				onMouseUp={stopPropagation}
				onClick={stopPropagation}>
				<span onClick={this.props.labelTogglePointIndex.bind(this)} className='icon icon-keypad' />
				{isInputMeasure(this.props.measure) && this.props.isFractions ? (
					<InputMeasure
						value={value}
						measure={this.props.measure}
						style={{
							width: 48,
							height: 16,
							border: 0,
							padding: 0,
							margin: 0,
							fontSize: 13,
							textAlign: 'center',
							boxShadow: 'none',
							paddingLeft: 5
						}}
						charWidth={7}
						afterConvert={value => {
							this.scaleValue = value;
						}}
						keyUp={(e, v) => this.onKeyUp(e, v)}
					/>
				) : (
					<input
						type='number'
						min='0'
						step='any'
						defaultValue={value}
						onKeyUp={this.onKeyUp.bind(this)}
						ref={el => (this.input = el)}
					/>
				)}
			</div>
		);
	}

	onKeyUp(event, value = null) {
		let nativeEvent = event.nativeEvent,
			keyCode;

		if (nativeEvent) {
			keyCode = nativeEvent.keyCode || nativeEvent.which;

			if ([13, 27].includes(keyCode)) {
				if (keyCode === 13) {
					value = value === null ? this.input.value : value;
					value = Math.abs(value);

					if (value > 0) {
						value = MathHelper.round((value * gridSize) / this.props.scale);
						this.props.lineChangeLength(
							this.props.label.editableLineIndex,
							this.props.label.editablePointIndex,
							value
						);
					}
				}

				this.props.labelReset();
			}
		}
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		label: state.label,
		scale: state.scale.value,
		measure: state.scale.measure,
		zoom: state.zoom.value,
		offset: state.offset,
		sketch: state.sketchState.sketch,
		visible: state.drawArea.visible,
		isFractions: state.settings.imperial === 'fractions'
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			labelReset,
			labelTogglePointIndex,
			lineChangeLength
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(LabelEditComponent);
