import React from 'react';
import './AuthPolicyWarn.scss';


const AuthPolicyWarn = ({text}) => {
    const termsOfUseHref = process.env.REACT_APP_MAIN_WP_URL ?
        `${process.env.REACT_APP_MAIN_WP_URL}/terms-and-conditions` : '/terms-and-conditions';
    const cookieHref = process.env.REACT_APP_MAIN_WP_URL ?
        `${process.env.REACT_APP_MAIN_WP_URL}/cookie-policy` : '/cookie-policy';
    const privacyPolicy = process.env.REACT_APP_MAIN_WP_URL ?
        `${process.env.REACT_APP_MAIN_WP_URL}/privacy-policy` : '/privacy-policy';

    return (
        <div className={'auth-policy-warn'}>
            <h5 className={'policy'}>
                { text }
                <a
                    target='_blank'
                    href={termsOfUseHref}>
                    Terms of Use
                </a>
                {', '}
                <a
                    target='_blank'
                    href={cookieHref}>
                    Cookie
                </a>{' '}
                and{' '}
                <a
                    target='_blank'
                    href={privacyPolicy}>
                    Privacy Policy
                </a>
                .
            </h5>
        </div>
    )
}

export default AuthPolicyWarn;