export const STRAIGHT_LINE = 'straight';
export const CONSTRAINED_LINE = 'constrained';
export const CURVED_LINE = 'curved';
export const PAN_MOVE = 'pan_move';
export const SELECT_TOOL = 'select_tool';
export const ANGLE_LINE = 'angle';
export const NOTE_TOOL = 'note_tool';
export const RECTANGLE_TOOL = 'rectangle_tool';
export const CIRCLE_TOOL = 'circle_tool';
export const MAGIC_WAND = 'magic_wand';
export const MAGIC_WAND_ADD = 'magic_wand_add';
export const MAGIC_WAND_SUB = 'magic_wand_sub';
