import ApiHelper from '../ApiHelper';
import externalConstants from '../constants/externalConstants';
import get from 'lodash/get';

export const getLocationInfo = () => {
    return async (dispatch) => {
        let response = {}

        try {
            response = await ApiHelper.getIpInfo()
        } catch (e) {
            console.log(e)
        }


        const currency = get(response, 'data.currency', 'GBP');
        const country = get(response, 'data.country', 'US');
        const payload = {
            location:{
                currency,
                country
            }
        }

        dispatch({ type: externalConstants.EXTERNAL_IP_INFO, payload });

        return payload.location;
    }
}