import React from 'react';
import MainMenu from '../Menus/MainMenu';
import SocialMenu from '../Menus/SocialMenu';

export class Footer extends React.Component {
	render() {
		const date = new Date();
		const currentYear = date.getFullYear();

		return (
			<footer>
				<div className='row no-gutters justify-content-between align-items-center'>
					<MainMenu />
					<SocialMenu />
				</div>
				<div className='copyright text-center'>Copyright &copy; 2010-{currentYear} SketchAndCalc&trade;</div>
			</footer>
		);
	}
}
