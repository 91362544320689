import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { modalClose } from '../../../../shared/reducers/ModalReducer';
import { ppdIgnoreWelcome } from '../../../../shared/reducers/ppdReducer';

export const MODAL_PAYPERDAY_CONTINUE = 'MODAL_PAYPERDAY_CONTINUE';

class ModalPayPerDayContinue extends React.Component {
	render() {
		if (!this.props.payPerDay.timeLeft) {
			return null;
		}

		return (
			<div className='modal-ppd-continue modal-inner-wrapper'>
				<h2 className='text-center'>welcome back</h2>
				<p className='text-center'>
					You have {this.props.payPerDay.timeLeft.format('h[hr] m[m]')} remaining in your current session
				</p>
				<div className='text-center'>
					<button
						className='btn btn-modal'
						onClick={() => {
							this.props.ppdIgnoreWelcome(true);
							this.props.modalClose();
						}}>
						continue
					</button>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			ppdIgnoreWelcome,
			modalClose
		},
		dispatch
	);
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		payPerDay: state.ppd
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalPayPerDayContinue);
