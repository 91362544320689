import * as lineTypes from '../classes/Instruments';
import { helpActivateScale1 } from '../actions/helpActions';

const INSTRUMENT_BEFORE_CHANGE = 'INSTRUMENT_BEFORE_CHANGE';
const INSTRUMENT_CHANGE = 'INSTRUMENT_CHANGE';
const INSTRUMENT_AFTER_CHANGE = 'INSTRUMENT_AFTER_CHANGE';
const INSTRUMENT_RESET = 'INSTRUMENT_RESET';

const initState = lineTypes.STRAIGHT_LINE;

export function instrumentChange(instrument) {
	return (dispatch, state) => {
		if (instrument === lineTypes.STRAIGHT_LINE) {
			const { image, help } = state();

			if (image.dynamicImage && !help.helpScale1) {
				dispatch(helpActivateScale1());
			}
		}

		dispatch({ type: INSTRUMENT_BEFORE_CHANGE, payload: { instrument } });
		dispatch({ type: INSTRUMENT_CHANGE, payload: { instrument } });
		dispatch({ type: INSTRUMENT_AFTER_CHANGE, payload: { instrument } });
	};
}

export function instrumentReset() {
	return {
		type: INSTRUMENT_RESET
	};
}

export default function instrumentReducer(instrument = initState, action) {
	switch (action.type) {
		case INSTRUMENT_CHANGE: {
			return action.payload.instrument;
		}
		case INSTRUMENT_RESET: {
			return initState;
		}
		default: {
			return instrument;
		}
	}
}
