import React from 'react';

const Art4 = ({color="#fff"}) => {

    return (
        <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 200 200">
            <path className="cls-1"
                  style={{ fill: color }}
                  d="M83.51,83l.43,4.86.1,0a12.08,12.08,0,0,1,4.42-4.15,12.87,12.87,0,0,1,6.26-1.45,11.72,11.72,0,0,1,6.14,1.52,9.56,9.56,0,0,1,3.86,4.75,12.82,12.82,0,0,1,4.51-4.67,12.6,12.6,0,0,1,6.48-1.6q5.67,0,8.81,3.75t3.14,11.86v23.21h-12V97.87c0-2.51-.37-4.22-1.11-5.11a4.25,4.25,0,0,0-3.47-1.34,7.26,7.26,0,0,0-3.06.6,6.14,6.14,0,0,0-2.22,1.73q0,.6,0,1a8.26,8.26,0,0,1,0,.88v25.43H94V97.87c0-2.51-.37-4.22-1.11-5.11a4.3,4.3,0,0,0-3.5-1.34,6.91,6.91,0,0,0-3,.6,6.53,6.53,0,0,0-2.23,1.73v27.33H72.31V83Z"/>
            <rect className="cls-1" style={{ fill: color }} width="28.5" height="28.5"/>
            <rect className="cls-1" style={{ fill: color }} x="171.5" width="28.5" height="28.5"/>
            <line className="cls-2" style={{ stroke: color, strokeMiterlimit:10, strokeWidth: '5px' }} x1="28.5" y1="14.25" x2="171.5" y2="14.25"/>
        </svg>
    )
}

export default Art4