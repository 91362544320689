import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import MobileDetect from 'mobile-detect';

import drawAreaReducer from './reducers/drawAreaReducer';
import previewReducer from './reducers/previewReducer';
import zoomReducer from './reducers/zoomReducer';
import instrumentReducer from './reducers/instrumentReducer';
import layerLabelReducer from './reducers/LayerLabelReducer';
import historyReducer from './reducers/historyReducer';
import { noteReducer } from './reducers/NoteReducer';
import { modalReducer } from './reducers/ModalReducer';
import { imageReducer } from './reducers/ImageReducer';
import { scaleReducer } from './reducers/scaleControlReducer';
import { sketchReducer } from './reducers/SketchReducer';
import { clipboardReducer } from './reducers/clipboardReducer';
import ppdReducer from './reducers/ppdReducer';
import PdfReducer from './reducers/PdfReducer';
import MapReducer from './reducers/MapReducer';
import MapControlReducer from './reducers/MapControlReducer';
import LabelReducer from './reducers/LabelReducer';
import instrumentMenuReducer from './reducers/instrumentMenuReducer';
import layerReducer from './reducers/layerReducer';
import authReducer from './reducers/authReducer';
import trialReducer from './reducers/trialReducer';
import settingsReducer from './reducers/settingsReducer';
import notificationReducer from './reducers/notificationReducer';
import { offsetReducer } from './reducers/offsetReducer';
import helpReducer from './reducers/helpReducer';
import printReducer from './reducers/printReducer';
import fileNameReducer from './reducers/fileNameReducer';
import socketReducer from './reducers/socketReducer';
import documentsReducer from './reducers/documentsReducer';
import educatorReducer from './reducers/educatorReducer';
import externalReducer from './reducers/externalReducer';
import fileStateReducer from './reducers/fileStateReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import { jwt } from './MiddlewareHelper';

const reducer = combineReducers({
	drawArea: drawAreaReducer,
	preview: previewReducer,
	zoom: zoomReducer,
	instrument: instrumentReducer,
	instrumentMenu: instrumentMenuReducer,
	layerLabel: layerLabelReducer,
	history: historyReducer,
	notes: noteReducer,
	modal: modalReducer,
	image: imageReducer,
	scale: scaleReducer,
	sketchState: sketchReducer,
	pdf: PdfReducer,
	map: MapReducer,
	mapControl: MapControlReducer,
	label: LabelReducer,
	layers: layerReducer,
	auth: authReducer,
	trial: trialReducer,
	ppd: ppdReducer,
	settings: settingsReducer,
	notification: notificationReducer,
	offset: offsetReducer,
	help: helpReducer,
	print: printReducer,
	clipboard: clipboardReducer,
	fileName: fileNameReducer,
	socket: socketReducer,
	documents: documentsReducer,
	educator: educatorReducer,
	external: externalReducer,
	fileState: fileStateReducer
});

const middleware = [
	jwt,
	thunk
];

const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middleware)));

export function dispatch(action) {
	return store.dispatch(action);
}
export default store;

export const mobileDetect = new MobileDetect(window.navigator.userAgent);
