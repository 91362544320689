import * as d3 from 'd3-shape';

import { CURVED_LINE } from './Instruments';
import { isPointValid } from './pointsFunc';
import LineInterface from './LineInterface';
import MathHelper from '../MathHelper';
import MapHelper from '../MapHelper';

const curvedInterpolateFunc = d3
	.line()
	.x(d => d[0])
	.y(d => d[1])
	.curve(d3.curveCardinal);
export const curvedOpenInterpolateFunc = d3
	.line()
	.x(d => d[0])
	.y(d => d[1])
	.curve(d3.curveCardinalOpen);

export default class CurvedLine extends LineInterface {
	constructor(points = [], latLngPoints = []) {
		super();
		this.points = points;
		this.latLngPoints = latLngPoints;
		this.type = CURVED_LINE;
	}

	stopDraw(lastPoint) {
		if (lastPoint) {
			this.pushPoint(lastPoint);
		}
		this.simplify();
	}

	pushPoint(point) {
		if (isPointValid(point)) {
			this.points.push(point);
		}
	}

	toChunkedPaths() {
		return this.getChunkedPoints().map(points => curvedOpenInterpolateFunc(points).replace(/Z$/, ''));
	}

	toPath(first = false) {
		let linePath = curvedInterpolateFunc(this.points).replace(/Z$/, '');

		if (first) {
			return linePath;
		}

		return linePath.replace(/^M[0-9,.-]*/, '');
	}

	getLength(scale = 1) {
		let length = 0;
		let points = this.points;

		for (let i = 0, pointsLen = points.length - 1; i < pointsLen; i++) {
			length += parseFloat(MathHelper.lineLength(points[i], points[i + 1]));
		}

		return MathHelper.round(length * scale);
	}

	getLabelAngle() {
		const endPoint = this.points[this.getMiddlePointIndex()];
		const startPoint = this.points[this.getMiddlePointIndex() - 1];

		let x = endPoint[0] - startPoint[0];
		let y = endPoint[1] - startPoint[1];
		let theta = Math.atan2(y, x);
		theta = MathHelper.degrees(theta);
		theta = MathHelper.prettifyAngle(theta);

		return theta;
	}

	getMiddlePoint() {
		const endPoint = this.points[this.getMiddlePointIndex()];
		const startPoint = this.points[this.getMiddlePointIndex() - 1];
		return [(startPoint[0] + endPoint[0]) / 2, (startPoint[1] + endPoint[1]) / 2];
	}

	getMiddlePointIndex() {
		return Math.round(this.points.length / 2) - 1;
	}

	getType() {
		return this.type;
	}

	getPoints() {
		return this.points;
	}

	getLatLngPoints() {
		return this.latLngPoints;
	}

	updatePoint(pointIndex, point) {
		this.points[pointIndex] = point;
	}

	updateLatLngPoint(latLngIndex, latLng) {
		this.latLngPoints[latLngIndex] = latLng;
	}

	removeFirstPoint() {
		this.points.splice(0, 1);
		this.latLngPoints.splice(0, 1);
	}

	removeLastPoint() {
		this.points.splice(this.points.length - 1, 1);
		this.latLngPoints.splice(this.latLngPoints.length - 1, 1);
	}

	removePointByIndex(index) {
		this.points.splice(index, 1);
		this.latLngPoints.splice(index, 1);
	}

	isValidLineIfEndAt(endPoint, zoom) {
		const allowance = 6 / zoom;

		if (this.points.length < 3 || this.getLength() < allowance) {
			return false;
		}

		return true;
	}

	getChunkedPoints() {
		let chunks = [];
		let chunkSize = 4;

		chunks.push([this.points[0], ...this.points.slice(0, 3)]);
		for (let i = 0; i < this.points.length - 3; i++) {
			chunks.push([...this.points.slice(i, i + chunkSize)]);
		}

		chunks.push([...this.points.slice(this.points.length - 3), this.points[this.points.length - 1]]);
		return chunks;
	}

	refreshLatLngPoints() {
		this.latLngPoints = [];

		this.points.forEach(point => {
			this.latLngPoints.push(MapHelper.point2LatLng(point));
		});
	}

	simplify(lookAhead = 10, tolerance = 2) {
		let points = this.points;

		if (lookAhead <= 1 || points.length < 3) {
			return;
		}
		let nP = [];

		let offset;
		let len = points.length,
			lastPoint = points[len - 1];

		if (lookAhead > len - 1) {
			lookAhead = len - 1;
		}

		nP.push([points[0][0], points[0][1]]);
		nP.push([points[1][0], points[1][1]]);

		for (let i = 0; i < len; i++) {
			if (i + lookAhead > len) {
				lookAhead = len - i - 1;
			}

			offset = this.recursiveToleranceBar(points, i, lookAhead, tolerance);

			if (offset > 0 && points[i + offset]) {
				nP.push([points[i + offset][0], points[i + offset][1]]);
				i += offset - 1; // don't loop through the skipped points
			}
		}
		nP.pop();
		if (nP[nP.length - 1][0] !== lastPoint[0] || nP[nP.length - 1][1] !== lastPoint[1]) {
			nP.push([...lastPoint]);
		}
		this.points = nP;
	}

	// this function is called by simplifyLang
	recursiveToleranceBar(points, i, lookAhead, tolerance) {
		let n = lookAhead;
		let cP, cLP, v1, v2, angle, dx, dy;

		cP = points[i]; // current point

		if (!points[i + n]) {
			return 0;
		}

		// the vector through the current point and the max look ahead point
		v1 = { x: points[i + n][0] - cP[0], y: points[i + n][0] - cP[1] };
		// loop through the intermediate points

		for (let j = 1; j <= n; j++) {
			// the vector	through the current point and the current intermediate point
			cLP = points[i + j]; // current look ahead point
			v2 = { x: cLP.x - cP.x, y: cLP.y - cP.y };
			angle = Math.acos(
				(v1.x * v2.x + v1.y * v2.y) /
					(Math.sqrt(v1.y * v1.y + v1.x * v1.x) * Math.sqrt(v2.y * v2.y + v2.x * v2.x))
			);
			if (isNaN(angle)) {
				angle = 0;
			}
			// the hypothenuse is the line between the current point and the current intermediate point
			dx = cP.x - cLP.x;
			dy = cP.y - cLP.y;
			let lH = Math.sqrt(dx * dx + dy * dy); // lenght of hypothenuse

			// length of opposite leg / perpendicular offset
			if (Math.sin(angle) * lH >= tolerance) {
				// too long, exceeds tolerance
				n--;
				if (n > 0) {
					// back the vector up one point
					//trace('== recursion, new lookAhead '+n);
					return this.recursiveToleranceBar(points, i, n, tolerance);
				} else {
					//trace('== return 0, all exceed tolerance');
					return 0; // all intermediate points exceed tolerance
				}
			}
		}

		return n;
	}
}
