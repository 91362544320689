import React from 'react';
import MathHelper from '../../../shared/MathHelper';
import './_InputMeasure.scss';

export const isInputMeasure = measure => ['yd', 'ft'].includes(measure);

export const coefficient = measure => {
	if (measure === 'yd') {
		return 3;
	}

	if (measure === 'ft') {
		return 12;
	}

	return 1;
};

class InputMeasure extends React.Component {
	focus = [];

	constructor(props) {
		super(props);

		this.state = {
			main: 0,
			item: 0,
			numerator: 0,
			denominator: 0
		};
	}

	componentDidMount() {
		this.parse();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// if (prevProps.measure !== this.props.measure || prevProps.value !== this.props.value) {
		if (prevProps.measure !== this.props.measure) {
			this.parse();
		}
	}

	render() {
		const style = this.props.style || {};
		const disabled = this.props.disabled || false;
		const charWidth = this.props.charWidth || 9;

		const input = (index, name) => {
			const width = (this.state[name].toString().length || 1) * charWidth + 3,
				nextIndex = index + 1 > 3 ? 0 : index + 1,
				prevIndex = index - 1 < 0 ? 3 : index - 1,
				style = {
					width: 70,
					border: 'none',
					fontWeight: '300',
					background: 'none'
				};

			return (
				<input
					type='text'
					ref={el => (this.focus[index] = el)}
					placeholder='0'
					value={this.state[name]}
					style={{ ...style, width }}
					disabled={disabled}
					onKeyUp={event => {
						if (this.props.keyUp) {
							this.props.keyUp(event, this.convert());
						}
					}}
					onKeyDown={event => {
						let nativeEvent = event.nativeEvent,
							keyCode;

						if (nativeEvent) {
							keyCode = nativeEvent.keyCode || nativeEvent.which;

							if (keyCode === 190) {
								// [.]
								if (this.focus[nextIndex]) {
									this.focus[nextIndex].focus();
								}
							}

							if (
								keyCode === 39 &&
								event.target.value.toString().length === event.target.selectionStart
							) {
								// [->]
								if (this.focus[nextIndex]) {
									this.focus[nextIndex].focus();
								}
							}

							if (keyCode === 37 && 0 === event.target.selectionStart) {
								// [<-]
								if (this.focus[prevIndex]) {
									this.focus[prevIndex].focus();
								}
							}
						}
					}}
					onChange={event => {
						let value = parseInt(event.target.value.replace(/[^0-9]+/g, '')); // only numeric

						if (this.state[name] !== value) {
							if (name === 'item' && value && value >= MathHelper.coefficient(this.props.measure)) {
								return;
							}

							this.setState({ [name]: value || '' }, this.convert);
						}
					}}
				/>
			);
		};

		return (
			<div className='input-measure' style={style}>
				'{input(0, 'main')}"{input(1, 'item')}
				{input(2, 'numerator')}/{input(3, 'denominator')}
			</div>
		);
	}

	increaseScaleValue() {
		let k = MathHelper.coefficient(this.props.measure);
		let item = parseInt(this.state.item) || 0;
		let main = parseInt(this.state.main) || 0;
		item += 1;

		main += ~~(item / k); // TODO: Math.trunc not work on print
		item = item % k;

		this.setState({ main, item }, this.convert);
	}

	decreaseScaleValue() {
		let k = MathHelper.coefficient(this.props.measure);
		let item = parseInt(this.state.item) || 0;
		let main = parseInt(this.state.main) || 0;
		item -= 1;

		if (item < 0) {
			if (main > 0) {
				main -= 1;
				item = k - 1;
			} else {
				item = 0;
			}
		}

		this.setState({ main, item }, this.convert);
	}

	parse = () => {
		let valueText = '',
			measures,
			fractions = [],
			part,
			main = 0,
			item = 0,
			numerator = 0,
			denominator = 0;

		if (this.props.measure === 'yd') {
			valueText = MathHelper.yardsFeet(this.props.value);
		}

		if (this.props.measure === 'ft') {
			valueText = MathHelper.feetInches(this.props.value);
		}

		measures = valueText.split(' ');

		if (measures[0]) {
			main = parseInt(measures[0].replace("'", '')) || 0;
		}

		if (measures[1]) {
			part = measures[1].replace('"', '');

			if (part.indexOf('/') !== -1) {
				measures[2] = part;
			} else {
				item = parseInt(part) || 0;
			}
		}

		if (measures[2]) {
			fractions = measures[2].split('/');

			if (fractions[0]) {
				numerator = parseInt(fractions[0]) || 0;
			}

			if (fractions[1]) {
				denominator = parseInt(fractions[1]) || 0;
			}
		}

		this.setState({
			main,
			item,
			numerator,
			denominator
		});
	};

	convert = () => {
		let value,
			fraction,
			k = MathHelper.coefficient(this.props.measure),
			{ main, item, numerator, denominator } = this.state;

		main = parseInt(main) || 0;
		item = parseInt(item) || 0;
		numerator = parseInt(numerator) || 0;
		denominator = parseInt(denominator) || 0;
		fraction = denominator !== 0 ? parseFloat(numerator / denominator) || 0 : 0;

		value = main + (item + fraction) / k || 0;

		if (this.props.afterConvert) {
			this.props.afterConvert(value);
		}

		return value;
	};
}

export default InputMeasure;
