import * as historyConstants from '../constants/historyConstants';
import store from '../store';
import Sketch from '../classes/Sketch';
import * as SketchTypes from '../classes/SketchTypes';
import Line from '../classes/Line';
import { instrumentChange } from '../reducers/instrumentReducer';
import * as instrumentTypes from '../classes/Instruments';
import CurvedLine from '../classes/CurvedLine';
import { sketchUpdateSketch } from './SketchActions';
import {updateFileState} from './fileStateAction';

export function historyMoveBack() {
	return dispatch => {
		let state = store.getState(),
			currentSketch = state.sketchState.sketch,
			historyState = state.history,
			map = state.map,
			currentPosition = historyState.currentPosition;

		if (currentPosition === 0) {
			return;
		}

		let sketch = Sketch.restoreFromDump(historyState.history[currentPosition]);

		--currentPosition;

		dispatch(historyUpdatePosition(currentPosition));

		let newSketch = Sketch.restoreFromDump(historyState.history[currentPosition]);

		if (map.visible && (newSketch.lineExists() || newSketch.angleLineExists())) {
			newSketch.refreshPoints();
			newSketch.refreshControlPoints();
		}

		if (newSketch.mask) {
			let instrument = instrumentTypes.MAGIC_WAND;

			if (currentSketch.mask.lastAdded) {
				instrument = instrumentTypes.MAGIC_WAND_ADD;
			} else if (currentSketch.mask.lastSubstracted) {
				instrument = instrumentTypes.MAGIC_WAND_SUB;
			}

			dispatch(instrumentChange(instrument));
		} else if (!newSketch.closed && !newSketch.angleClosed) {
			updateInstrumentBasedOnLastLine(dispatch, sketch);
		}

		dispatch(sketchUpdateSketch(newSketch));
	};
}

export function historyMoveForward() {
	return dispatch => {
		let state = store.getState(),
			historyState = state.history,
			map = state.map,
			currentPosition = historyState.currentPosition,
			nextSketch;

		if (currentPosition === historyState.history.length - 1) {
			return;
		}

		++currentPosition;

		dispatch(historyUpdatePosition(currentPosition));

		let newSketch = Sketch.restoreFromDump(historyState.history[currentPosition]);

		if (currentPosition + 1 < historyState.history.length) {
			nextSketch = Sketch.restoreFromDump(historyState.history[currentPosition + 1]);
		}

		if (map.visible && (newSketch.lineExists() || newSketch.angleLineExists())) {
			newSketch.refreshPoints();
			newSketch.refreshControlPoints();
		}

		if (newSketch.mask) {
			dispatch(instrumentChange(instrumentTypes.MAGIC_WAND));
		} else if (!newSketch.closed && !newSketch.angleClosed) {
			if (nextSketch) {
				updateInstrumentBasedOnLastLine(dispatch, nextSketch);
			} else {
				updateInstrumentBasedOnLastLine(dispatch, newSketch);
			}
		} else {
			dispatch(instrumentChange(instrumentTypes.SELECT_TOOL));
		}

		dispatch(sketchUpdateSketch(newSketch));
	};
}

export function historyRestoreCurrentHistory() {
	return (dispatch, state) => {
		let { history, map } = state(),
			newSketch = Sketch.restoreFromDump(history.history[history.currentPosition]);

		if (map.visible && (newSketch.lineExists() || newSketch.angleLineExists())) {
			newSketch.refreshPoints();
			newSketch.refreshControlPoints();
		}

		dispatch(sketchUpdateSketch(newSketch));
	};
}

export function historySetLabelsVisible(value) {
	return (dispatch, state) => {
		const history = state().history;

		dispatch(
			historyUpdate({
				currentPosition: history.currentPosition,
				history: history.history.map(item => {
					return { ...item, labelsVisible: value };
				})
			})
		);
	};
}

export function historyUpdate(history) {
	return { type: historyConstants.HISTORY_UPDATE, payload: { history } };
}

export function historyPush(item) {
	return (dispatch) => {
		dispatch({ type: historyConstants.HISTORY_PUSH, payload: { item } });
		dispatch(updateFileState(true));
	};
}

export function historyReset() {
	return { type: historyConstants.HISTORY_RESET };
}

function historyUpdatePosition(position) {
	return {
		type: historyConstants.HISTORY_UPDATE_POSITION,
		payload: { position }
	};
}

export function updateInstrumentBasedOnLastLine(dispatch, sketch) {
	if (sketch.angleLineExists()) {
		dispatch(instrumentChange(instrumentTypes.ANGLE_LINE));
	} else if (sketch.lineExists()) {
		let lines = sketch.getLines();
		let lastLine = lines[lines.length - 1];

		if (lastLine instanceof Line) {
			if (sketch.type === SketchTypes.SHAPE) {
				dispatch(instrumentChange(instrumentTypes.RECTANGLE_TOOL));
			} else if (lastLine.isConstrainedLine()) {
				dispatch(instrumentChange(instrumentTypes.CONSTRAINED_LINE));
			} else {
				dispatch(instrumentChange(instrumentTypes.STRAIGHT_LINE));
			}
		} else if (lastLine instanceof CurvedLine) {
			dispatch(instrumentChange(instrumentTypes.CURVED_LINE));
		}
	} else {
		dispatch(instrumentChange(instrumentTypes.STRAIGHT_LINE));
	}
}
