import isEqual from 'lodash.isequal';
import mapConstants from '../constants/mapConstants';
import { canvasReset } from './drawAreaActions';
import * as instrumentsTypes from '../classes/Instruments';
import { sketchRefreshPoints, sketchUpdatePointsByPan } from './SketchActions';
import { noteRefreshPoints, noteUpdatePointsByPan } from './NoteActions';
import { DRAW_AREA_SET_MIN_SIZES } from '../constants/drawAreaConstants';
import { zoomPanMoved } from './zoomActions';
import { layerRefreshLayers, layerUpdateLayersByPan } from './layerActions';
import { notificationShow } from './notificationActions';
import { notificationTypes } from '../constants/notificationConstants';
import {updateFileState} from './fileStateAction';

export function mapToggleVisibility() {
	return (dispatch, state) => {
		if (state().map.visible) {
			dispatch(canvasReset());
		} else {
			dispatch(canvasReset());
			dispatch({ type: mapConstants.MAP_TOGGLE_VISIBILITY });
			dispatch(notificationShow('Grid scale locked to map scale!', notificationTypes.SUCCESS, 3000));
		}
		dispatch(updateFileState(true));
	};
}

export function mapSetType(type) {
	return (dispatch) => {
		dispatch({
			type: mapConstants.MAP_UPDATE_TYPE,
			payload: { type }
		});
		dispatch(updateFileState(true));
	};
}

export function mapZoomIn() {
	return { type: mapConstants.MAP_ZOOM_IN };
}

export function mapZoomOut() {
	return { type: mapConstants.MAP_ZOOM_OUT };
}

export function mapUpdateZoom(zoom) {
	return {
		type: mapConstants.MAP_UPDATE_ZOOM,
		payload: { zoom }
	};
}

export function mapUpdateCenter(lat, lng) {
	return (dispatch) => {
		dispatch({
			type: mapConstants.MAP_UPDATE_CENTER,
			payload: {
				center: {lat, lng}
			}
		});
		dispatch(updateFileState(true));
	};
}

export function mapUpdateMovePoint(point) {
	return {
		type: mapConstants.MAP_UPDATE_MOVE_POINT,
		payload: { point }
	};
}

export function mapUpdatePan(pan) {
	return {
		type: mapConstants.MAP_UPDATE_PAN,
		payload: { pan }
	};
}

export function mapUpdateMaxZoom(value) {
	return {
		type: mapConstants.MAP_UPDATE_MAX_ZOOM,
		payload: { value }
	};
}

export function mapMouseDown(point) {
	return (dispatch, state) => {
		let {
			map,
			instrument,
			sketchState: { sketch }
		} = state();

		if (map.visible && [instrumentsTypes.PAN_MOVE].includes(instrument)) {
			dispatch(mapUpdateMovePoint(point));
		}
	};
}

export function mapMouseMove(point) {
	return (dispatch, state) => {
		let { map, instrument, layers } = state();

		if (!(map.movePoint && point)) {
			return;
		}

		if (map.visible && [instrumentsTypes.SELECT_TOOL, instrumentsTypes.PAN_MOVE].includes(instrument)) {
			if (map.movePoint[0] !== point[0] || map.movePoint[1] !== point[1]) {
				let pan = [map.movePoint[0] - point[0], map.movePoint[1] - point[1]];
				dispatch(mapUpdatePan(pan));
				if (layers.showAllLayers) {
					dispatch(layerUpdateLayersByPan(pan));
				} else {
					dispatch(sketchUpdatePointsByPan(pan));
					dispatch(noteUpdatePointsByPan(pan));
				}
				dispatch(mapUpdateMovePoint(point));
			}
		}
	};
}

export function mapMouseUp(point) {
	return (dispatch, state) => {
		let { map, layers } = state();

		if (!(map.movePoint || map.pan)) {
			return;
		}

		if (layers.showAllLayers) {
			dispatch(layerRefreshLayers());
		} else {
			dispatch(sketchRefreshPoints());
			dispatch(noteRefreshPoints());
		}

		dispatch(mapUpdateMovePoint(null));
		dispatch(mapUpdatePan(null));
	};
}

export function mapRefreshZoomView() {
	return (dispatch, store) => {
		let state = store(),
			lines = [...state.sketchState.sketch.getLines(), ...state.sketchState.sketch.getAngleLines()],
			stageSizes = state.drawArea.stageSizes,
			Xs = [],
			Ys = [],
			maxX,
			maxY,
			minX,
			minY,
			newMinSizes = {},
			zoomViewParams = {},
			width,
			height,
			x = 0,
			y = 0,
			ratioX,
			ratioY,
			realZoom = 1;

		if (state.layers.showAllLayers) {
			lines = [];

			state.layers.list.forEach(layer => {
				if (layer.sketch.closed && layer.isVisible()) {
					lines.push(...layer.sketch.getLines());
				}
			});
		}

		if (lines.length) {
			lines.forEach(line => {
				Xs = [...Xs, ...line.getPoints().map(point => point[0])];
				Ys = [...Ys, ...line.getPoints().map(point => point[1])];
			});

			maxX = Math.max(...Xs);
			maxY = Math.max(...Ys);
			minX = Math.min(...Xs);
			minY = Math.min(...Ys);

			if (minX < 0) {
				let absMinX = Math.abs(minX);

				if (maxX > stageSizes.width) {
					newMinSizes.width = absMinX + maxX;
				} else {
					newMinSizes.width = absMinX + stageSizes.width;
				}
				x = absMinX;
			} else {
				if (maxX > stageSizes.width) {
					newMinSizes.width = maxX;
				} else {
					newMinSizes.width = stageSizes.width;
				}
			}

			if (minY < 0) {
				let absMinY = Math.abs(minY);

				if (maxY > stageSizes.height) {
					newMinSizes.height = absMinY + maxY;
				} else {
					newMinSizes.height = absMinY + stageSizes.height;
				}
				y = absMinY;
			} else {
				if (maxY > stageSizes.height) {
					newMinSizes.height = maxY;
				} else {
					newMinSizes.height = stageSizes.height;
				}
			}

			ratioX = newMinSizes.width / stageSizes.width;
			ratioY = newMinSizes.height / stageSizes.height;

			width = stageSizes.width / ratioX;
			height = stageSizes.height / ratioY;

			if (x > 0) {
				x = x / ratioX;
			}

			if (y > 0) {
				y = y / ratioY;
			}

			newMinSizes = {
				width: stageSizes.width,
				height: stageSizes.height
			};

			if (!isEqual(state.drawArea.minStageSizes, newMinSizes)) {
				dispatch({
					type: DRAW_AREA_SET_MIN_SIZES,
					payload: {
						sizes: { ...newMinSizes }
					}
				});
			}

			zoomViewParams = { width, height, x, y, realZoom };

			if (!isEqual(state.zoom.zoomViewParams, zoomViewParams)) {
				dispatch(zoomPanMoved(zoomViewParams));
			}
		} else {
			dispatch(zoomPanMoved(null));
		}
	};
}

export function mapUpdate(state) {
	return { type: mapConstants.MAP_UPDATE, payload: { state } };
}

export function mapReset() {
	return { type: mapConstants.MAP_RESET };
}
