import React from 'react';
import './DropdownStyle.scss';
import OptionallyDisplayed from '../OptionallyDisplayed';

const Dropdown = ({options, value, label, onChange, errorText}) => {
    const shouldDisplayError = () => {
        return !!errorText;
    }

    return (
        <div>
            <div className={'dropdown relative'}>
                <select
                    size
                    required
                    className='form-control'
                    onChange={onChange}
                    value={value}>
                    {label && <option hidden selected value=''>{label}</option>}
                    {options.map(({ value, label, divider }, index) => {
                        return divider ? (
                            <option key={index.toString()} disabled>
                                {divider}
                            </option>
                        ) : (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        );
                    })}
                </select>
                <span className={'select-arrow'} />
            </div>
            <OptionallyDisplayed display={shouldDisplayError()}>
                <div className='validation-error'>
                    <span className='text'>{errorText}</span>
                </div>
            </OptionallyDisplayed>
        </div>
    )
}

export default Dropdown