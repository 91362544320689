import React from 'react';
import { connect } from 'react-redux';
import { modalClose } from '../../../../shared/reducers/ModalReducer';

export const MODAL_INFO = 'MODAL_INFO';

class ModalInfo extends React.Component {
	render() {
		const { modalClose, data } = this.props;

		const close = () => {
			const { onClose } = data;

			if (onClose) {
				return onClose()
			}

			modalClose()
		}

		return (
			<div className='modal-toggle-map modal-inner-wrapper'>
				<h2 className='text-center'>
					{ data.title }
				</h2>
				<p className='text-center'>
					{ data.message }
				</p>
				<div className='text-center'>
					<button className='btn btn-modal' onClick={close}>
						Ok
					</button>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		modal: {
			data
		}
	} = state

	return {
		data
	};
}

const mapDispatchToProps = {
	modalClose
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalInfo);
