import { helpTypes } from '../constants/helpConstants';
import ApiHelper from '../ApiHelper';

export function helpUpdateTours() {
	return (dispatch, store) => {
		const state = store().help;
		ApiHelper.updateTours({ state });
	};
}

export function helpActivateIntro() {
	return dispatch => {
		dispatch({ type: helpTypes.HELP_ACTIVATE_INTRO });
		dispatch(helpUpdateTours());
	};
}

export function helpActivateMenu() {
	return dispatch => {
		dispatch({ type: helpTypes.HELP_ACTIVATE_MENU });
		dispatch(helpUpdateTours());
	};
}

export function helpActivateScale1() {
	return dispatch => {
		dispatch({ type: helpTypes.HELP_ACTIVATE_SCALE_1 });
		dispatch(helpUpdateTours());
	};
}

export function helpActivateScale2() {
	return dispatch => {
		dispatch({ type: helpTypes.HELP_ACTIVATE_SCALE_2 });
		dispatch(helpUpdateTours());
	};
}

export function helpActivateScale3() {
	return dispatch => {
		dispatch({ type: helpTypes.HELP_ACTIVATE_SCALE_3 });
		dispatch(helpUpdateTours());
	};
}

export function helpActivateLengthSubmitted() {
	return dispatch => {
		dispatch({ type: helpTypes.HELP_ACTIVATE_LENGTH_SUBMITTED });
		dispatch(helpUpdateTours());
	};
}

export function helpActivateResults() {
	return dispatch => {
		dispatch({ type: helpTypes.HELP_ACTIVATE_RESULTS });
		dispatch(helpUpdateTours());
	};
}

export function helpActivateMap() {
	return dispatch => {
		dispatch({ type: helpTypes.HELP_ACTIVATE_MAP });
		dispatch(helpUpdateTours());
	};
}

export function helpActivateEditLineLength() {
	return dispatch => {
		dispatch({ type: helpTypes.HELP_ACTIVATE_EDIT_LINE_LENGTH });
		dispatch(helpUpdateTours());
	};
}

export function helpActivateMoveVertex() {
	return dispatch => {
		dispatch({ type: helpTypes.HELP_ACTIVATE_MOVE_VERTEX });
		dispatch(helpUpdateTours());
	};
}

export function helpActivateLineLengthsLabels() {
	return dispatch => {
		dispatch({ type: helpTypes.HELP_HIDE_LINE_LENGTH_LABELS });
		dispatch(helpUpdateTours());
	};
}

export function helpUpdate(state) {
	return {
		type: helpTypes.HELP_UPDATE,
		payload: { state }
	};
}
