import React from 'react';
import { connect } from 'react-redux';
import store from '../../../shared/store';

class ZoomView extends React.Component {
	render() {
		if (this.props.dynamicImage || !this.props.viewParams) {
			return null;
		}

		let { width, height, x, y, realZoom } = this.props.viewParams,
			canvasMinSize = this.props.canvasMinSize;

		if (this.props.zoomValue === 1 && height >= canvasMinSize.height && width >= canvasMinSize.width) {
			return null;
		}

		let gridWidth, gridHeight, ratio, viewWidth, viewHeight, viewBoxWidth, viewBoxHeight, viewBoxX, viewBoxY;

		if (store.getState().map.visible) {
			ratio = canvasMinSize.width / canvasMinSize.height;

			if (ratio >= 1) {
				viewWidth = 100;
				viewHeight = viewWidth / ratio;
			} else {
				viewHeight = 100;
				viewWidth = viewHeight * ratio;
			}

			ratio = canvasMinSize.width / viewWidth;
			viewBoxX = x / ratio;
			viewBoxWidth = width / ratio;

			ratio = canvasMinSize.height / viewHeight;
			viewBoxY = y / ratio;
			viewBoxHeight = height / ratio;
		} else {
			gridWidth = canvasMinSize.width > width ? canvasMinSize.width : width;
			gridHeight = canvasMinSize.height > height ? canvasMinSize.height : height;

			gridWidth *= realZoom;
			gridHeight *= realZoom;

			ratio = gridWidth / gridHeight;

			if (ratio >= 1) {
				viewWidth = 100;
				viewHeight = viewWidth / ratio;
			} else {
				viewHeight = 100;
				viewWidth = viewHeight * ratio;
			}

			ratio = gridWidth / viewWidth;
			viewBoxX = Math.abs(x) / ratio;
			viewBoxWidth = width / ratio;

			ratio = gridHeight / viewHeight;
			viewBoxY = Math.abs(y) / ratio;
			viewBoxHeight = height / ratio;
		}

		return (
			<div
				className='zoom-view'
				style={{
					width: viewWidth.toString() + 'px',
					height: viewHeight.toString() + 'px'
				}}>
				<div className='view-box-wrapper'>
					<div
						className='view-box'
						style={{
							position: 'absolute',
							top: viewBoxY.toString() + 'px',
							left: viewBoxX.toString() + 'px',
							height: viewBoxHeight.toString() + 'px',
							width: viewBoxWidth.toString() + 'px'
						}}
					/>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		viewParams: state.zoom.zoomViewParams,
		dynamicImage: state.image.dynamicImage,
		canvasMinSize: state.drawArea.minStageSizes,
		zoomValue: state.zoom.value
	};
}

export default connect(mapStateToProps, {})(ZoomView);
