import * as types from '../constants/scaleControlConstants';
import Line from '../classes/Line';
import MathHelper from '../MathHelper';

window.defaults = { ...window.defaults, measure: 'cm' };

const initState = {
	value: 1,
	text: '1',
	measure: window.defaults.measure,
	line: null,
	lineReady: false,
	manual: true
};

export function scaleReducer(state = initState, action) {
	let value = state.value;

	switch (action.type) {
		case types.SCALE_DECREASE: {
			value = parseFloat(value);
			if (value <= 0.2) {
				value = 0.1;
			} else {
				value -= 0.1;
			}
			value = MathHelper.round(value);
			return { ...state, value, text: value };
		}

		case types.SCALE_INCREASE: {
			value = parseFloat(value);
			value += 0.1;
			value = MathHelper.round(value);
			return { ...state, value, text: value };
		}

		case types.SCALE_MEASURES_CHANGED: {
			return { ...state, measure: action.payload.measure };
		}

		case types.SCALE_SET_VALUE: {
			return {
				...state,
				value: action.payload.value,
				text: action.payload.value
			};
		}

		case types.SCALE_SET_TEXT_VALUE: {
			return { ...state, text: action.payload.text };
		}

		case types.SCALE_SET_MEASURE: {
			return { ...state, measure: action.payload.measure };
		}

		case types.SCALE_LINE_START: {
			return { ...state, line: new Line(action.payload.point) };
		}

		case types.SCALE_LINE_END: {
			if (!(state.line instanceof Line)) {
				return state;
			}

			const newLine = state.line.clone();

			newLine.end(action.payload.point);

			return { ...state, line: newLine, lineReady: true };
		}

		case types.SCALE_LINE_RESET: {
			return { ...state, line: null, lineReady: false };
		}

		case types.SCALE_UPDATE: {
			return {
				...state,
				...action.payload.state,
				text: action.payload.state.value
			};
		}

		case types.SCALE_RESET: {
			const { manualValues } = action.payload;

			return { ...initState, measure: window.defaults.measure, ...manualValues };
		}

		default: {
			return state;
		}
	}
}
