import React from 'react';
import moment from 'moment';
import ApiHelper from '../../../shared/ApiHelper';
import { Link } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';

export default class Blog extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			list: []
		};
	}

	componentWillMount() {
		ApiHelper.blog().then(response => {
			if (response.data.success) {
				this.setState({
					list: response.data.posts.map(post => {
						return {
							title: post.title,
							content: post.excerpt,
							date: post.date,
							slug: post.slug,
							author: post.author,
							image: post.image
						};
					})
				});
			}
		});
	}

	render() {
		return (
			<section className='blog'>
				<DocumentMeta title='Blog' />
				{this.state.list.map((post, index) => {
					return (
						<article key={index} className='post'>
							{post.image && post.image.url && (
								<img src={post.image.url} title={post.image.title} alt={post.image.alt} />
							)}
							<h2 className='title'>
								<Link to={`/blog/${post.slug}`}>{post.title}</Link>
							</h2>
							<div className='desc'>
								<div className='date'>
									Posted <span>{moment(post.date).format('LL')}</span>
								</div>
								<div className='author'>
									By <span>{post.author}</span>
								</div>
							</div>
							<div
								className='content'
								dangerouslySetInnerHTML={{
									__html: post.content
								}}
							/>
						</article>
					);
				})}
			</section>
		);
	}
}
