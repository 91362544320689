import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DrawArea from '../../App/DrawArea/DrawArea';
import InstrumentMenu from '../../App/InsturmentMenu/InstrumentMenu';
import ZoomView from '../../App/ZoomView/ZoomView';
import NotesComponent from '../../App/NotesComponent/NotesComponent';
import ScalePopup from '../../App/ScalePopup/ScalePopup';
import CanvasControlsContainer from '../../App/CanvasControlsContainer/CanvasControlsContainer';
import Map from '../../App/Map/Map';
import LabelEditComponent from '../../App/LabelEditComponent/LabelEditComponent';
import { instrumentMenuMouseMove, instrumentMenuMouseUp } from '../../../shared/reducers/instrumentMenuReducer';
import { layerLabelMouseMove, layerLabelMouseUp } from '../../../shared/reducers/LayerLabelReducer';
import SketchLayers from '../../App/SketchLayers/SketchLayers';
import Notification from '../../App/Notification/Notification';
import { trialUpdate } from '../../../shared/reducers/trialReducer';
import { ppdUpdate } from '../../../shared/reducers/ppdReducer';
import { loadUserData } from '../../../shared/actions/authActions';
import ContextualHelp from '../../ContextualHelp/ContextualHelp';
import PrintImage from '../../App/PrintImage/PrintImage';
import DocumentMeta from 'react-document-meta';
import moment from 'moment';
import { MODAL_PAYPERDAY_CONTINUE } from '../../ModalContainer/Modals/ModalPayPerDayNotification/ModalPayPerDayContinue';
import { MODAL_PAYPERDAY_ACTIVATE } from '../../ModalContainer/Modals/ModalPayPerDayNotification/ModalPayPerDayActivate';
import { modalOpen } from '../../../shared/reducers/ModalReducer';
import isEqual from 'lodash.isequal';
import LayerLabelsFixed from '../../App/LayerLabelsFixed/LayerLabelsFixed';

class AppPage extends React.Component {
	handleInterval = null;
	handlePPDInterval = null;

	componentWillMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push('/login');
		} else {
			if (!window.isPuppeteer) {
				this.props.loadUserData();
			}
		}
	}

	componentWillReceiveProps(props) {
		if (!isEqual(props.auth.user, this.props.auth.user)) {
			if (
				props.auth.user &&
				props.auth.user.subscription &&
				props.auth.user.subscription.payPerDay &&
				props.auth.user.subscription.payPerDay.activeDay
			) {
				let ppd = props.auth.user.subscription.payPerDay,
					ends = ppd.dayEnds && moment(ppd.dayEnds);

				if (ends && moment().isAfter(ends)) {
					this.props.ppdUpdate(null);
					this.props.modalOpen(MODAL_PAYPERDAY_ACTIVATE);
				} else {
					let timeLeft = moment.duration(ends.diff(moment()));
					this.props.ppdUpdate(timeLeft);
				}
			}
		}
	}

	componentWillUnmount() {
		this.props.trialUpdate(null);

		if (this.handleInterval) {
			window.clearInterval(this.handleInterval);
			this.handleInterval = null;
		}

		if (this.handlePPDInterval) {
			window.clearInterval(this.handlePPDInterval);
			this.handlePPDInterval = null;
		}
		window.removeEventListener('beforeunload', this.beforeUnloadCallback);
	}

	updateTrialTime() {
		if (!this.handleInterval) {
			return;
		}

		const user = this.props.auth.user;

		if (user) {
			const local = moment.utc(user.trial_end).local(),
				diff = local.diff(moment());

			if (diff > 0) {
				this.props.trialUpdate(moment.utc(diff).format('HH:mm'));
			} else {
				window.clearInterval(this.handleInterval);
				this.props.trialUpdate(null);
				this.verificationSubscribe();
			}
		}
	}

	updatePPDTime() {
		if (!this.handlePPDInterval) {
			return null;
		}

		let user = this.props.auth.user,
			ppd = user.subscription.payPerDay,
			ends = ppd.dayEnds && moment(ppd.dayEnds);

		if (user.trial_end && Date.parse(user.trial_end) > Date.now()) {
			this.props.ppdUpdate(null);
			return null;
		} else if (ends && moment().isAfter(ends)) {
			this.props.ppdUpdate(null);
			this.props.modalOpen(MODAL_PAYPERDAY_ACTIVATE);
			return null;
		} else {
			let timeLeft = moment.duration(ends.diff(moment()));
			this.props.ppdUpdate(timeLeft);
			return timeLeft;
		}
	}

	beforeUnloadCallback = (event) => {
		event.preventDefault();
		event.returnValue = 'You have unsaved changes. Are you sure you want to exit?';
		return 'You have unsaved changes. Are you sure you want to exit?';
	};

	componentDidUpdate(prevProps) {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push('/login');
		} else {
			this.verificationSubscribe();
		}

		if (window.isPuppeteer) {
			if (!this.props.print.isExport && this.props.print.isPhantomJs && this.props.print.isReady) {
				console.log('puppeteer_load_finished');
			}
		}

		if (prevProps.needSave !== this.props.needSave) {
			if (this.props.needSave) {
				window.addEventListener('beforeunload', this.beforeUnloadCallback);
			} else {
				window.removeEventListener('beforeunload', this.beforeUnloadCallback);
			}
		}
	}

	verificationSubscribe() {
		const user = this.props.auth.user;

		if (user) {
			if (user.subscription && user.subscription.status !== 'Opened') {
				if (user.subscription.status === 'Outdated') {
					this.props.history.push('/subscription');
				} else {
					this.props.history.push('/update-card');
				}

			} else if (
				!user.subscription &&
				user.trial_end &&
				user.extend_trial !== undefined &&
				!user.extend_trial &&
				Date.parse(user.trial_end) < Date.now()
			) {
				this.props.history.push('/subscription');
			} else if (!user.subscription && user.trial_end && Date.parse(user.trial_end) > Date.now()) {
				if (!this.handleInterval) {
					this.handleInterval = window.setInterval(this.updateTrialTime.bind(this), 60 * 1000);
					this.updateTrialTime();
				}
			} else if (
				!user.subscription &&
				user.trial_end &&
				user.extend_trial &&
				Date.parse(user.trial_end) < Date.now()
			) {
				this.props.history.push('/subscription');
			} else if (user.subscription && user.subscription.payPerDay) {
				if (user.subscription.payPerDay) {
					if (!this.handlePPDInterval) {
						this.handlePPDInterval = window.setInterval(this.updatePPDTime.bind(this), 60 * 1000);
						if (this.updatePPDTime() !== null) {
							!this.props.ppd.ignoreWelcome &&
								!window.isPuppeteer &&
								this.props.modalOpen(MODAL_PAYPERDAY_CONTINUE);
						}
					}
					if (user.trial_end && Date.parse(user.trial_end) > Date.now()) {
						if (!this.handleInterval) {
							this.handleInterval = window.setInterval(this.updateTrialTime.bind(this), 60 * 1000);
							this.updateTrialTime();
						}
					}
				}
			}
		}
	}

	onMouseMove = (event) => {
		this.props.instrumentMenuMouseMove(event);
		this.props.layerLabelMouseMove(event);
	}

	onMouseUp = (event) => {
		this.props.instrumentMenuMouseUp(event);
		this.props.layerLabelMouseUp(event);
	}

	render() {
		const classNames = ['app-content', this.props.drawingColor],
			layer = this.props.layers.list[this.props.layers.currentIndex],
			showAllLayers = this.props.layers.showAllLayers;

		if (!showAllLayers && layer && layer.getColor()) {
			classNames.push(`custom-${layer.getColor()}`);
		}

		return (
			<div
				className={classNames.join(' ')}
				onMouseMove={this.onMouseMove}
				onMouseUp={this.onMouseUp}>
				<DocumentMeta title='Area Calculator' />
				<CanvasControlsContainer />
				<Map />
				<DrawArea />
				<LabelEditComponent />
				<NotesComponent />
				<ScalePopup />
				<ZoomView />
				<InstrumentMenu />
				<SketchLayers />
				<Notification />
				<ContextualHelp />
				<PrintImage />
				<LayerLabelsFixed />
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		drawingColor: state.settings.drawingColor,
		auth: state.auth,
		layers: state.layers,
		mapVisible: state.map.visible,
		print: state.print,
		ppd: state.ppd,
		needSave: state.fileState.needSave
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			instrumentMenuMouseMove,
			instrumentMenuMouseUp,
			layerLabelMouseUp,
			layerLabelMouseMove,
			trialUpdate,
			loadUserData,
			modalOpen,
			ppdUpdate
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(AppPage);
