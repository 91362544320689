import React from 'react';

const HideShowGrid = ({height= 30, width = 40, color='#fff'}) => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px"
         viewBox="0 0 85.04 85.04"
         height={height}
         width={width}
         className={'grid-icon'}
    >
        <path fill={color} d="M53.71,53.71H31.33V31.33h22.38V53.71z M22.38,31.33H0v22.38h22.38V31.33z M85.04,31.33H62.66v22.38h22.38V31.33z
	        M53.71,62.66H31.33v22.38h22.38V62.66z M22.38,62.66H0v22.38h22.38V62.66z M85.04,62.66H62.66v22.38h22.38V62.66z M53.71,0H31.33
	        v22.38h22.38V0z M22.38,0H0v22.38h22.38V0z M85.04,0H62.66v22.38h22.38V0z"
        />
    </svg>
);

export default HideShowGrid;