import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import logo from '../../assets/img/header/app-logo.png';
import ScaleControl from '../App/ScaleControl/ScaleControl';
import AppMenu from '../Menus/AppMenu';
import Details from '../App/User/Details';

class AppHeader extends React.Component {
	render() {
		const classNames = ['row', 'no-gutters', 'align-items-center', 'app-header'],
			isExport = this.props.isExport;

		if (isExport) {
			classNames.push('export');
		}

		return (
			<header className={classNames.join(' ')}>
				<div className='logo col col-auto'>
					<Link to='/'>
						<img src={logo} alt='Logo' />
					</Link>
				</div>
				{isExport && <div className='export col col-auto'>SketchAndCalc.com</div>}
				<div className='col col-auto'>
					<Details />
				</div>
				<div className='col row no-gutters justify-content-end'>
					<ScaleControl />
				</div>
				<div className='col-auto'>
					<AppMenu />
				</div>
			</header>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		isExport: state.print.isExport
	};
}

export default connect(mapStateToProps)(AppHeader);
