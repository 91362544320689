import { historyPush, historyReset, historyRestoreCurrentHistory, historySetLabelsVisible } from './historyActions';
import { startShapePreview, moveShapePreview, endShapePreview } from './previewActions';
import * as types from '../constants/SketchConstants';
import * as SketchTypes from '../classes/SketchTypes';
import store from '../store';
import Sketch from '../classes/Sketch';

import * as instrumentsTypes from '../classes/Instruments';
import CurvedLine from '../classes/CurvedLine';
import Line from '../classes/Line';
import Rectangle from '../classes/Rectangle';
import Circle from '../classes/Circle';
import { drawAreaSetMinSizes, drawAreaSetMouseDown } from './drawAreaActions';
import { SELECT_TOOL } from '../classes/Instruments';
import { instrumentChange } from '../reducers/instrumentReducer';
import MathHelper from '../MathHelper';
import MapHelper from '../MapHelper';
import AngleLine from '../classes/AngleLine';
import { notificationShow, notificationReset } from './notificationActions';
import { notificationTypes } from '../constants/notificationConstants';
import { labelReset } from '../reducers/LabelReducer';
import { helpActivateMoveVertex } from './helpActions';
import { getEventPoint } from '../classes/pointsFunc';
import Layer from '../classes/Layer';
import { layerSetListLayers } from '../actions/layerActions';

export function sketchMouseDown(scaledPoint) {
	return dispatch => {
		let state = store.getState(),
			instrument = state.instrument,
			sketch = state.sketchState.sketch,
			startPoint = sketch.getLastPoint(),
			startLatLng = sketch.getLastLatLngPoint(),
			map = state.map,
			currentLine = null,
			currentShape = null;

		if (instrument === instrumentsTypes.CURVED_LINE) {
			currentLine = new CurvedLine();

			if (startPoint) {
				currentLine.pushPoint(startPoint);
			}

			currentLine.pushPoint(scaledPoint);
		} else if ([instrumentsTypes.CONSTRAINED_LINE, instrumentsTypes.STRAIGHT_LINE].includes(instrument)) {
			currentLine = new Line();
			currentLine.start(startPoint ? startPoint : scaledPoint);

			if (instrument === instrumentsTypes.CONSTRAINED_LINE) {
				currentLine.setConstrainedLine(true);
			}

			if (map.visible) {
				let latLng = startLatLng ? startLatLng : MapHelper.point2LatLng(scaledPoint);
				currentLine.startLatLng(latLng);
			}
		} else if (instrument === instrumentsTypes.ANGLE_LINE) {
			currentLine = new AngleLine(scaledPoint);

			if (map.visible) {
				currentLine.startLatLng(MapHelper.point2LatLng(scaledPoint));
			}
		} else if (instrument === instrumentsTypes.RECTANGLE_TOOL) {
			//Block the dragIndex
			dispatch(sketchStopDrag());

			currentShape = new Rectangle();
			currentShape.start(scaledPoint);

			if (map.visible) {
				currentShape.setStartLatLng(MapHelper.point2LatLng(scaledPoint));
			}
		} else if (instrument === instrumentsTypes.CIRCLE_TOOL) {
			dispatch(sketchStopDrag());
			currentShape = new Circle();
			currentShape.start(scaledPoint);
			dispatch(startShapePreview());

			if (map.visible) {
				currentShape.saveLatLngPoints();
			}
		}

		if (currentShape) {
			dispatch(setCurrentShape(currentShape));
		}

		if (currentLine) {
			dispatch(setCurrentLine(currentLine));
		}
	};
}

export function sketchMouseMove(scaledPoint) {
	return dispatch => {
		let { instrument, sketchState, map, zoom } = store.getState(),
			{
				dragIndex,
				dragAngleIndex,
				currentLine,
				currentShape,
				willClose,
				sketch,
				dragShapeIndex,
				dragSupportPoint
			} = sketchState,
			checkPoint = [...scaledPoint];

		if (instrument === instrumentsTypes.SELECT_TOOL && !sketch.closed) {
			dispatch(sketchUpdatePoint(scaledPoint));

			if (map.visible) {
				dispatch(sketchUpdateLatLng(MapHelper.point2LatLng(scaledPoint)));
			}
		}

		if (currentLine && instrument === instrumentsTypes.CONSTRAINED_LINE) {
			checkPoint = MathHelper.convertLineToConstrainedLine(currentLine.getStartPoint(), scaledPoint);
		}

		if (currentShape && instrument === instrumentsTypes.CIRCLE_TOOL) {
			dispatch(moveShapePreview(currentShape.getPreview(scaledPoint)));
		}

		if (!sketch.closed && sketch.type === SketchTypes.SHAPE && dragShapeIndex !== null) {
			dispatch(moveShapePreview(sketch.getPreviewByShapeControl(dragShapeIndex, scaledPoint)));
		}

		let readyForClose = isReadyForClose(true, sketchState, checkPoint, zoom);

		if (
			(sketch.closed && dragIndex !== null) ||
			(!sketch.closed && sketch.angleClosed && dragAngleIndex !== null)
		) {
			dispatch(sketchUpdatePoint(scaledPoint));

			if (map.visible) {
				dispatch(sketchUpdateLatLng(MapHelper.point2LatLng(scaledPoint)));
			}

			drawAreaSetMinSizes(scaledPoint);
		} else if (!(sketch.closed || readyForClose) && currentLine instanceof CurvedLine) {
			drawAreaSetMinSizes(scaledPoint);
			dispatch(sketchCurvedLinePushPoint(scaledPoint));
		} else if (sketch.closed && dragSupportPoint) {
			dispatch(sketchUpdatePoint(scaledPoint));
		}

		if (readyForClose && !willClose) {
			dispatch(sketchSetWillClose(true));
		} else if (!readyForClose && willClose) {
			dispatch(sketchSetWillClose(false));
		}
	};
}

export function sketchMouseUp(scaledPoint) {
	return dispatch => {
		let state = store.getState(),
			{
				dragIndex,
				dragAngleIndex,
				currentLine,
				currentShape,
				sketch,
				willClose,
				dragShapeIndex,
				dragSupportPoint
			} = state.sketchState,
			instrument = state.instrument,
			zoomValue = state.zoom.value || 1;

		if (currentLine && currentLine !== null && !sketch.closed) {
			if (instrument === instrumentsTypes.CONSTRAINED_LINE) {
				scaledPoint = MathHelper.convertLineToConstrainedLine(currentLine.getStartPoint(), scaledPoint);
			}

			const readyForClose = isReadyForClose(true, state.sketchState, scaledPoint, state.zoom),
				controlPoints = [
					...state.sketchState.sketch.getControlPoints().slice(0, -1),
					...currentLine.getPoints().filter(point => point)
				];

			if (
				[
					instrumentsTypes.CONSTRAINED_LINE,
					instrumentsTypes.STRAIGHT_LINE,
					instrumentsTypes.CURVED_LINE
				].includes(instrument) &&
				((!readyForClose && MathHelper.isPathIntersect([...controlPoints, scaledPoint])) ||
					(readyForClose &&
						MathHelper.isPathIntersect([
							...controlPoints,
							state.sketchState.sketch.getFirstPoint() || controlPoints[0]
						])))
			) {
				dispatch(notificationShow('Lines cannot intersect!', notificationTypes.DANGER, 1000));

				if (willClose) {
					dispatch(sketchSetWillClose(false));
				}

				dispatch(resetCurrentLine());
			} else if (
				[
					instrumentsTypes.CONSTRAINED_LINE,
					instrumentsTypes.STRAIGHT_LINE,
					instrumentsTypes.CURVED_LINE
				].includes(instrument)
			) {
				if (!readyForClose && !currentLine.isValidLineIfEndAt(scaledPoint, zoomValue)) {
					dispatch(resetCurrentLine());
					return;
				}

				if (readyForClose) {
					dispatch(sketchCloseSketch());
					dispatch(instrumentChange(SELECT_TOOL));
				} else {
					dispatch(sketchStopDraw(scaledPoint));
				}
			} else if (instrument === instrumentsTypes.ANGLE_LINE) {
				if (!currentLine.isValidLineIfEndAt(scaledPoint, zoomValue)) {
					dispatch(resetCurrentLine());
					return;
				}

				dispatch(sketchStopDraw(scaledPoint));

				if (store.getState().sketchState.sketch.angleClosed) {
					dispatch(instrumentChange(SELECT_TOOL));
				}
			}
		} else if (dragSupportPoint !== null) {
			dispatch(sketchResetCurrentSupportPoint());
			if (MathHelper.isPathIntersect([...sketch.shapes[0].toPolygonPoints()])) {
				dispatch(notificationShow('Lines cannot intersect!', notificationTypes.DANGER, 1000));

				dispatch(historyRestoreCurrentHistory());
			} else {
				dispatch(historyPush(sketch.convertForSave()));
			}
		} else if (dragIndex !== null || dragAngleIndex !== null) {
			if (!sketch.closed && dragIndex !== null) {
				if (MathHelper.isPathIntersect(sketch.getControlPoints())) {
					dispatch(notificationShow('Lines cannot intersect!', notificationTypes.DANGER, 1000));
					dispatch(historyRestoreCurrentHistory());
				} else {
					dispatch(historyPush(sketch.convertForSave()));
				}
			} else if (sketch.isCircle()) {
				if (MathHelper.isPathIntersect([...sketch.shapes[0].toPolygonPoints()])) {
					dispatch(notificationShow('Lines cannot intersect!', notificationTypes.DANGER, 1000));

					dispatch(historyRestoreCurrentHistory());
				} else {
					dispatch(historyPush(sketch.convertForSave()));
				}
			} else if (
				dragIndex !== null &&
				MathHelper.isPathIntersect([...sketch.getControlPoints(), sketch.getFirstPoint()]) &&
				instrument === instrumentsTypes.SELECT_TOOL &&
				!sketch.closed
			) {
				dispatch(notificationShow('Lines cannot intersect!', notificationTypes.DANGER, 1000));

				dispatch(historyRestoreCurrentHistory());
			} else if (
				dragIndex !== null &&
				MathHelper.isPathIntersect([...sketch.getControlPoints(), sketch.getFirstPoint()])
			) {
				dispatch(notificationShow('Lines cannot intersect!', notificationTypes.DANGER, 1000));

				dispatch(historyRestoreCurrentHistory());
			} else {
				dispatch(historyPush(sketch.convertForSave()));
			}
		} else if (currentShape !== null || sketch.type === SketchTypes.SHAPE) {
			if (instrument === instrumentsTypes.RECTANGLE_TOOL || instrument === instrumentsTypes.CIRCLE_TOOL) {
				dispatch(sketchStopDraw(scaledPoint));
			}

			if (dragShapeIndex !== null) {
				dispatch(sketchActivateShapeControl(scaledPoint));
				dispatch(instrumentChange(instrumentsTypes.SELECT_TOOL));
				dispatch(sketchStopDrag());
			}
		}

		dispatch(sketchStopDrag());
	};
}

export function sketchStopDraw(point) {
	return dispatch => {
		let { currentLine, currentShape, sketch, dragIndex, dragShapeIndex } = store.getState().sketchState;
		let { instrument, map, drawArea } = store.getState();

		let newSketch = Sketch.restoreFromDump(sketch.convertForSave());

		if ([instrumentsTypes.CONSTRAINED_LINE, instrumentsTypes.STRAIGHT_LINE].includes(instrument)) {
			currentLine.end(point);

			if (map.visible) {
				currentLine.endLatLng(MapHelper.point2LatLng(point));
			}
		}

		if (instrument === instrumentsTypes.CURVED_LINE) {
			currentLine.stopDraw(point);

			if (map.visible) {
				currentLine.refreshLatLngPoints();
			}
		}

		if (instrument === instrumentsTypes.ANGLE_LINE) {
			currentLine.end(point);

			if (map.visible) {
				currentLine.endLatLng(MapHelper.point2LatLng(point));
			}
		}

		if (instrument === instrumentsTypes.RECTANGLE_TOOL) {
			if (dragIndex === null && currentShape) {
				dispatch(endShapePreview());
				currentShape.end(point, drawArea.shiftHolded);
				newSketch.addShape(currentShape);

				if (map.visible) {
					currentShape.setEndLatLng(MapHelper.point2LatLng(point));
				}

				if (drawArea.shiftHolded) {
					newSketch.transformShapeToLines(currentShape);
					newSketch.closeSketch();
					dispatch(instrumentChange(instrumentsTypes.SELECT_TOOL));
					dispatch(endShapePreview());
				} else {
					dispatch(notificationShow('Drag ♦ to define rectangle', notificationTypes.SUCCESS));
				}
			}
		}

		if (instrument === instrumentsTypes.CIRCLE_TOOL) {
			if (currentShape) {
				dispatch(endShapePreview());
				currentShape.end(point);
				newSketch.addShape(currentShape);
				newSketch.closeSketch();
				dispatch(instrumentChange(instrumentsTypes.SELECT_TOOL));

				if (map.visible) {
					currentShape.saveLatLngPoints();
				}
			}
		}

		if (currentLine) {
			newSketch.push(currentLine);
		}

		dispatch(drawAreaSetMinSizes(point));

		dispatch(historyPush(newSketch.convertForSave()));
		dispatch(sketchUpdateSketch(newSketch));
		dispatch(resetCurrentLine());
		dispatch(resetCurrentShape());
	};
}

export function sketchActivateShapeControl(point) {
	return dispatch => {
		let { sketch, dragShapeIndex } = store.getState().sketchState;

		let newSketch = Sketch.restoreFromDump(sketch.convertForSave());

		if (dragShapeIndex !== null) {
			dispatch(endShapePreview());
			newSketch.activateShapeControl(dragShapeIndex, point);
		}

		dispatch(drawAreaSetMinSizes(point));

		dispatch(historyPush(newSketch.convertForSave()));
		dispatch(sketchUpdateSketch(newSketch));
		dispatch(resetCurrentShape());
	};
}

export function sketchCloseSketch() {
	return dispatch => {
		let { currentLine, sketch } = store.getState().sketchState;
		let { instrument, map } = store.getState();

		let newSketch = Sketch.restoreFromDump(sketch.convertForSave()),
			firstPoint = newSketch.getFirstPoint();

		if ([instrumentsTypes.CONSTRAINED_LINE, instrumentsTypes.STRAIGHT_LINE].includes(instrument)) {
			currentLine.end(firstPoint);

			if (map.visible) {
				currentLine.endLatLng(newSketch.getFirstLatLngPoint());
			}
		}

		if (instrument === instrumentsTypes.CURVED_LINE) {
			if (firstPoint) {
				currentLine.stopDraw(firstPoint);
			} else {
				currentLine.stopDraw(currentLine.getPoints()[0]);
			}

			if (map.visible) {
				currentLine.refreshLatLngPoints();
			}
		}

		newSketch.push(currentLine, true);

		dispatch(historyPush(newSketch.convertForSave()));
		dispatch(sketchUpdateSketch(newSketch));
		dispatch(resetCurrentLine());
		dispatch(sketchSetWillClose(false));
	};
}

export function sketchLabelsToggleVisibility() {
	return dispatch => {
		let oldSketch = store.getState().sketchState.sketch;

		if (oldSketch.closed) {
			let newSketch = Sketch.restoreFromDump(oldSketch.convertForSave());

			newSketch.labelsVisible = !oldSketch.labelsVisible;

			dispatch(sketchUpdateSketch(newSketch));
			dispatch(historySetLabelsVisible(newSketch.labelsVisible));
		}
	};
}

export function sketchUpdatePointsByPan(pan) {
	return (dispatch, state) => {
		let oldSketch = state().sketchState.sketch;

		if (oldSketch.lineExists() || oldSketch.angleLineExists()) {
			let sketch = Sketch.restoreFromDump(oldSketch.convertForSave());
			sketch.updatePointsByPan(pan);
			sketch.refreshControlPoints();
			dispatch(sketchUpdateSketch(sketch));
		}
	};
}

export function sketchRefreshPoints() {
	return (dispatch, state) => {
		let oldSketch = state().sketchState.sketch;

		if (oldSketch.lineExists() || oldSketch.angleLineExists() || oldSketch.shapeExists()) {
			let sketch = Sketch.restoreFromDump(oldSketch.convertForSave());
			sketch.refreshPoints();
			sketch.refreshControlPoints();
			dispatch(sketchUpdateSketch(sketch));
		}
	};
}

export function sketchUpdateSketch(sketchInstance) {
	return {
		type: types.SKETCH_UPDATE_SKETCH,
		payload: { sketch: sketchInstance }
	};
}

export function setCurrentLine(currentLine) {
	return { type: types.SKETCH_SET_CURRENT_LINE, payload: { currentLine } };
}

export function setCurrentShape(currentShape) {
	return { type: types.SKETCH_SET_CURRENT_SHAPE, payload: { currentShape } };
}

export function resetCurrentLine() {
	return { type: types.SKETCH_RESET_CURRENT_LINE };
}

export function resetCurrentShape() {
	return { type: types.SKETCH_RESET_CURRENT_SHAPE };
}

export function sketchPointMouseDown(index, event, isShape = false, ignoreClose = false) {
	return (dispatch, state) => {
		const { label, sketchState } = state();
		const { sketch } = sketchState;

		if (label.editableLineIndex !== null) {
			dispatch(labelReset());
		}

		if (!sketch.closed && sketch.type === SketchTypes.SHAPE) {
			dispatch(startShapePreview());
			dispatch(notificationReset());
		}

		dispatch(drawAreaSetMouseDown(true));

		dispatch({
			type: types.SKETCH_POINT_MOUSE_DOWN,
			payload: { index, event, isShape, ignoreClose }
		});
	};
}

export function sketchSupportPointMouseDown(pointIndex, supportIndex) {
	return (dispatch, state) => {
		dispatch(drawAreaSetMouseDown(true));

		dispatch({
			type: types.SKETCH_SUPPORT_POINT_MOUSE_DOWN,
			payload: { pointIndex, supportIndex }
		});
	};
}

export function sketchMoveEndActivePoint() {
	return (dispatch, state) => {
		const { sketchState } = state();

		if (sketchState.activeIndex !== null && sketchState.sketch.closed) {
			if (
				MathHelper.isPathIntersect([
					...sketchState.sketch.getControlPoints(),
					sketchState.sketch.getFirstPoint()
				])
			) {
				dispatch(notificationShow('Lines cannot intersect!', notificationTypes.DANGER, 1000));

				dispatch(historyRestoreCurrentHistory());
			} else {
				dispatch(historyPush(sketchState.sketch.convertForSave()));
			}
		} else if (sketchState.activeIndexBeforeClose !== null && !sketchState.sketch.closed) {
			if (MathHelper.isPathIntersect(sketchState.sketch.getControlPoints())) {
				dispatch(notificationShow('Lines cannot intersect!', notificationTypes.DANGER, 1000));
				dispatch(historyRestoreCurrentHistory());
			} else {
				dispatch(historyPush(sketchState.sketch.convertForSave()));
			}
		}
	};
}

export function sketchMoveActivePoint(position) {
	return (dispatch, state) => {
		const { sketchState, drawArea, zoom, map, help } = state();

		if ((sketchState.activeIndex !== null && sketchState.sketch.closed) || sketchState.activeIndexBeforeClose !== null) {
			const index = sketchState.activeIndex !== null ? sketchState.activeIndex : sketchState.activeIndexBeforeClose;
			let controlPoint = sketchState.sketch.getControlPoints()[index],
				sizes = drawArea.stageSizes,
				offset = 1 / zoom.value;

			if (!help.helpMoveVertex) {
				dispatch(helpActivateMoveVertex());
			}

			if (controlPoint) {
				controlPoint = [...controlPoint];

				switch (position) {
					case 'up':
						controlPoint[1] -= offset;
						break;
					case 'right':
						controlPoint[0] += offset;
						break;
					case 'down':
						controlPoint[1] += offset;
						break;
					default:
						controlPoint[0] -= offset;
				}

				if (
					controlPoint[0] > sizes.width ||
					controlPoint[1] > sizes.height ||
					controlPoint[0] < 0 ||
					controlPoint[1] < 0
				) {
					dispatch(
						notificationShow(
							'You cannot move a vertex off screen by editing the line length!',
							notificationTypes.DANGER,
							3000
						)
					);
				} else if (sketchState.supportIndex) {
					debugger;
				} else {
					let newSketch = Sketch.restoreFromDump(sketchState.sketch.convertForSave());
					newSketch.updateControlPoint(index, controlPoint, sketchState.sketch.closed);

					if (map.visible) {
						newSketch.updateControlLatLngPoint(
							index,
							MapHelper.point2LatLng(controlPoint),
							sketchState.sketch.closed
						);
					}

					dispatch(sketchUpdateSketch(newSketch));
				}
			}
		}
	};
}

export function shapeAddPoint(event, index, chunkIndex) {
	return (dispatch, state) => {
		let point = getEventPoint(event),
			{ sketchState, map } = state(),
			newSketch = Sketch.restoreFromDump(sketchState.sketch.convertForSave());

		newSketch.shapes[index].addPoint(point, chunkIndex);
		newSketch.closeSketch();

		if (map.visible) {
			newSketch.shapes[index].saveLatLngPoints();
		}

		dispatch(sketchUpdateSketch(newSketch));
		dispatch(historyPush(newSketch.convertForSave()));
	};
}

export function sketchResetActivePoint() {
	return { type: types.SKETCH_RESET_ACTIVE_POINT };
}

export function sketchResetCurrentSupportPoint() {
	return { type: types.SKETCH_RESET_SUPPORT_POINT };
}

export function sketchDeleteActivePoint() {
	return (dispatch, state) => {
		const { sketch, activeIndex, activeIndexBeforeClose } = state().sketchState;

		if ((activeIndex !== null && activeIndex >= 0) || (activeIndexBeforeClose !== null && activeIndexBeforeClose >= 0)) {
			let controlPointLimit = !sketch.closed ? 1 : sketch.isCircle() ? 2 : 3;

			if (sketch.getControlPoints().length > controlPointLimit) {
				const newSketch = Sketch.restoreFromDump(sketch.convertForSave());

				if (!state().help.helpMoveVertex) {
					dispatch(helpActivateMoveVertex());
				}

				newSketch.removeControlPoint(activeIndex !== null ? activeIndex : activeIndexBeforeClose);
				dispatch(sketchResetActivePoint());
				dispatch(historyPush(newSketch.convertForSave()));
				dispatch(sketchUpdateSketch(newSketch));
			} else {
				dispatch(
					notificationShow(
						'Cannot have less than ' + controlPointLimit + ' points!',
						notificationTypes.DANGER,
						1000
					)
				);
			}
		}
	};
}

export function sketchAngleMouseDown(index, pointIndex) {
	return dispatch => {
		dispatch(drawAreaSetMouseDown(true));

		dispatch({
			type: types.SKETCH_ANGLE_MOUSE_DOWN,
			payload: { index, pointIndex }
		});
	};
}

export function sketchUpdatePoint(point) {
	return {
		type: types.SKETCH_UPDATE_POINT,
		payload: { point }
	};
}

export function sketchUpdateLatLng(latLng) {
	return {
		type: types.SKETCH_UPDATE_LAT_LNG,
		payload: { latLng }
	};
}

export function sketchCurvedLinePushPoint(point) {
	return {
		type: types.SKETCH_CURVED_LINE_PUSH_POINT,
		payload: { point }
	};
}

export function sketchSetWillClose(value) {
	return {
		type: types.SKETCH_SET_WILL_CLOSE,
		payload: { value }
	};
}

export function sketchStopDrag() {
	return {
		type: types.SKETCH_STOP_DRAG
	};
}

export function sketchReset() {
	return {
		type: types.SKETCH_RESET
	};
}

export function sketchSetCurrentMask(value) {
	return (dispatch, state) => {
		const { sketch } = state().sketchState;

		const newSketch = Sketch.restoreFromDump(sketch.convertForSave());

		newSketch.mask = value;
		dispatch(sketchResetActivePoint());
		dispatch(historyPush(newSketch.convertForSave()));
		dispatch(sketchUpdateSketch(newSketch));
	};
}

export function sketchResetCurrentMask() {
	return (dispatch, state) => {
		const { sketch } = state().sketchState;

		const newSketch = Sketch.restoreFromDump(sketch.convertForSave());

		newSketch.mask = null;
		dispatch(sketchResetActivePoint());
		dispatch(historyPush(newSketch.convertForSave()));
		dispatch(sketchUpdateSketch(newSketch));
	};
}

export function sketchSetMagicWandTolerance(value) {
	return (dispatch, state) => {
		const { sketch } = state().sketchState;

		const newSketch = Sketch.restoreFromDump(sketch.convertForSave());

		newSketch.mask.setTolerance(value);
		dispatch(sketchResetActivePoint());
		dispatch(historyPush(newSketch.convertForSave()));
		dispatch(sketchUpdateSketch(newSketch));
	};
}

export function sketchSetMagicWandBlur(value) {
	return (dispatch, state) => {
		const { sketch } = state().sketchState;

		const newSketch = Sketch.restoreFromDump(sketch.convertForSave());

		newSketch.mask.setBlur(value);
		dispatch(sketchResetActivePoint());
		dispatch(historyPush(newSketch.convertForSave()));
		dispatch(sketchUpdateSketch(newSketch));
	};
}

export function sketchAddToMagicWandMask(value) {
	return (dispatch, state) => {
		const { sketch } = state().sketchState;

		const newSketch = Sketch.restoreFromDump(sketch.convertForSave());

		newSketch.mask.addToArea(value);
		dispatch(sketchResetActivePoint());
		dispatch(historyPush(newSketch.convertForSave()));
		dispatch(sketchUpdateSketch(newSketch));
	};
}

export function sketchSubFromMagicWandMask(value) {
	return (dispatch, state) => {
		const { sketch } = state().sketchState;

		const newSketch = Sketch.restoreFromDump(sketch.convertForSave());

		newSketch.mask.substractFromArea(value);
		dispatch(sketchResetActivePoint());
		dispatch(historyPush(newSketch.convertForSave()));
		dispatch(sketchUpdateSketch(newSketch));
	};
}

export function sketchSetMagicWandCompoundShapes(value) {
	return (dispatch, state) => {
		const { sketch } = state().sketchState;

		const newSketch = Sketch.restoreFromDump(sketch.convertForSave());

		newSketch.mask.setCompound(value);
		dispatch(sketchResetActivePoint());
		dispatch(historyPush(newSketch.convertForSave()));
		dispatch(sketchUpdateSketch(newSketch));
	};
}

export function sketchConfirmCurrentMask() {
	return (dispatch, state) => {
		let stateData = state(),
			sketch = stateData.sketchState.sketch,
			layers = stateData.layers.list.slice(0),
			polygons = sketch.mask.getResultPolygons(),
			maxId = Math.max(...layers.map(layer => layer.getId()));

		dispatch(historyReset());

		sketch.addLinesFromPolygon(polygons[0]);

		for (let i = 1; i < polygons.length; i++) {
			let newLayer = new Layer(),
				currentColor = layers[stateData.layers.currentIndex].getColor();

			if (currentColor) {
				newLayer.setColor(currentColor);
			}

			maxId += 1;
			newLayer.setId(maxId);
			newLayer.sketch.addLinesFromPolygon(polygons[i]);
			layers.push(newLayer);
		}

		dispatch(layerSetListLayers(layers));

		dispatch(sketchResetCurrentMask());

		dispatch(instrumentChange(instrumentsTypes.SELECT_TOOL));
	};
}

function isReadyForClose(mouseDown, state, currentPoint, zoom) {
	let firstPoint = state.sketch.getFirstPoint(),
		zoomValue = zoom.value || 1,
		allowance = 6 / zoomValue;

	if (!(mouseDown && state.currentLine)) {
		return false;
	}

	if (
		[instrumentsTypes.STRAIGHT_LINE, instrumentsTypes.CONSTRAINED_LINE].includes(state.currentLine.getType()) &&
		state.sketch.getControlPoints().length < 3
	) {
		return false;
	}

	if (state.currentLine && !firstPoint) {
		if (state.currentLine.getType() === instrumentsTypes.CURVED_LINE) {
			if (!state.currentLine.isValidLineIfEndAt(currentPoint, zoomValue)) {
				return false;
			}
			firstPoint = state.currentLine.getPoints()[0];
		}
	}

	return (
		firstPoint &&
		Math.abs(firstPoint[0] - currentPoint[0]) < allowance && Math.abs(firstPoint[1] - currentPoint[1]) < allowance
	);
}

export function sketchSetDragIndex(index) {
	return {
		type: types.SKETCH_SET_DRAG_INDEX,
		payload: { index }
	};
}
