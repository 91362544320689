import settingsConstants from '../constants/settingsConstants';
import {
	displayAngleValues,
	drawingColorValues,
	gridTransparencyValues,
	lineWeightValues,
	measurementSystemValues,
	cookiesValues,
	imperialValues
} from '../constants/settingsConstants';

export let displayLineLength = true;

const initState = {
	displayLineLength: displayLineLength,
	displayAngles: displayAngleValues.DISPLAY_ANGLE_MED,
	gridTransparency: gridTransparencyValues.GRID_TRANSPARENCY_75,
	drawingColor: drawingColorValues.DRAWING_COLOR_PINK,
	lineWeight: lineWeightValues.LINE_WEIGHT_SMALL,
	measurementSystem: measurementSystemValues.MEASUREMENT_SYSTEM_METRIC,
	cookiesAccepted: cookiesValues.COOKIES_ACCEPTED,
	marketingCookiesOn: cookiesValues.COOKIES_MARKETING_ENABLED,
	imperial: imperialValues.IMPERIAL_DECIMALS
};

export default function settingsReducer(state = initState, action) {
	switch (action.type) {
		case settingsConstants.SETTINGS_TOGGLE_DISPLAY_LINE_LENGTH: {
			displayLineLength = !displayLineLength;
			return { ...state, displayLineLength: displayLineLength };
		}
		case settingsConstants.SETTINGS_SET_DISPLAY_ANGLES: {
			return { ...state, displayAngles: action.payload.value };
		}
		case settingsConstants.SETTINGS_SET_GRID_TRANSPARENCY: {
			return { ...state, gridTransparency: action.payload.value };
		}
		case settingsConstants.SETTINGS_SET_DRAWING_COLOR: {
			return { ...state, drawingColor: action.payload.value };
		}
		case settingsConstants.SETTINGS_SET_LINE_WEIGHT: {
			return { ...state, lineWeight: action.payload.value };
		}
		case settingsConstants.SETTINGS_SET_MEASUREMENT_SYSTEM: {
			return { ...state, measurementSystem: action.payload.value };
		}
		case settingsConstants.SETTINGS_SET_IMPERIAL: {
			return { ...state, imperial: action.payload.value };
		}
		case settingsConstants.SETTINGS_SET_MARKETING_COOKIES: {
			return { ...state, marketingCookiesOn: action.payload.value };
		}
		case settingsConstants.ACCEPT_COOKIES: {
			let newSettings = { ...state, cookiesAccepted: true };

			//ToDo: if user is auth then save settings

			return newSettings;
		}
		case settingsConstants.SETTINGS_UPDATE: {
			const newState = { ...state, ...action.payload.state };
			displayLineLength = newState.displayLineLength;
			return newState;
		}
		default: {
			return state;
		}
	}
}
