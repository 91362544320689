import React, { Component } from 'react';

export default class LayerLabel extends Component {
	render() {
	    if (!this.props.point) {
	        return null;
	    }
		let zoom = this.props.zoom || 1,
			rectX = this.props.point[0],
			rectY = this.props.point[1],
			textX = this.props.point[0],
			textY = this.props.point[1] + 4 / zoom,
			labelWidth = 20 / zoom,
			labelHeight = 20 / zoom,
			fontSize = 12 / zoom;

		rectY -= labelHeight / 2;
		textX += labelWidth / 2;

		const classNames = ['layer-label'];

		if (this.props.color) {
			classNames.push(this.props.color);
		}

		if (this.props.isPinned) {
			classNames.push('pinned');
		}

		return (
			<g className={classNames.join(' ')}>
				<rect height={labelHeight} width={labelWidth} x={rectX} y={rectY} />
				<text x={textX} y={textY} fontSize={fontSize} textAnchor='middle'>
					<tspan className='index'>{this.props.layer}</tspan>
				</text>
			</g>
		);
	}
}
