import React from 'react';

const Art8 = ({color="#fff"}) => {

    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path className="cls-1"
                  style={{ fill: color }}
                  d="M79.55,104.28l.07.53h.11L86.1,83H98.78L83.6,126.76a17.51,17.51,0,0,1-4.31,6.94c-1.92,1.85-4.94,2.78-9.08,2.78a13.16,13.16,0,0,1-2.53-.19c-.64-.13-1.67-.37-3.1-.73L66,127.07l.74.16a2.78,2.78,0,0,0,.64.06,8.37,8.37,0,0,0,4.19-.8A4.44,4.44,0,0,0,73.46,124l1-2.46L61.23,83H73.88Z"/>
            <path className="cls-1"
                  style={{ fill: color }}
                  d="M99.84,102.1q0-9.09,3.82-14.47t11-5.37a9.51,9.51,0,0,1,4.75,1.18,12.44,12.44,0,0,1,3.74,3.37l.1,0V66.13h11.91v55H124.89l-.88-4.72a13,13,0,0,1-4.05,4.06,10.2,10.2,0,0,1-5.32,1.37q-7.19,0-11-5.11t-3.81-13.84Zm11.91.74a16,16,0,0,0,1.32,7.27,4.75,4.75,0,0,0,4.6,2.52,7.49,7.49,0,0,0,3.27-.67,6.08,6.08,0,0,0,2.36-2V94.56a6.08,6.08,0,0,0-5.56-3.14,4.75,4.75,0,0,0-4.6,2.89,18.14,18.14,0,0,0-1.39,7.79Z"/>
            <rect className="cls-1" style={{ fill: color }} width="28.5" height="28.5"/>
            <rect className="cls-1" style={{ fill: color }} x="171.5" width="28.5" height="28.5"/>
            <rect className="cls-1" style={{ fill: color }} x="171.5" y="171.5" width="28.5" height="28.5"/>
            <rect className="cls-1" style={{ fill: color }} y="171.5" width="28.5" height="28.5"/>
            <line className="cls-2" style={{ stroke: color, strokeMiterlimit:10, strokeWidth: '5px' }} x1="28.5" y1="14.25" x2="171.5" y2="14.25"/>
            <line className="cls-2" style={{ stroke: color, strokeMiterlimit:10, strokeWidth: '5px' }} x1="185.75" y1="28.5" x2="185.75" y2="171.5"/>
            <line className="cls-2" style={{ stroke: color, strokeMiterlimit:10, strokeWidth: '5px' }} x1="171.5" y1="185.75" x2="28.5" y2="185.75"/>
        </svg>
    )
}

export default Art8