import React from 'react';

import './_TrustLogo.scss'


class TrustLogo extends React.Component  {
    logoProps = {
        href: `javascript:if(window.open('http://www.trustlogo.com/ttb_searcher/trustlogo?v_querytype=W&v_shortname=SC5&v_search=${window.location.href}&x=6&y=5','tl_wnd_credentials'+(new Date()).getTime(),'toolbar=0,scrollbars=1,location=1,status=1,menubar=1,resizable=1,width=374,height=660,left=60,top=120')){};tLlB(tLTB);`,
        onMouseOver: event => window.tLeB(
            event,
            `https://www.trustlogo.com/ttb_searcher/trustlogo?v_querytype=C&v_shortname=SC5&v_search=${window.location.href}&x=6&y=5`,
            'tl_popupSC5'
        ),
        onMouseMove: event => window.tLXB(event),
        onMouseOut: () => window.tLTC('tl_popupSC5'),
        onMouseDown: event => window.tLKB(event, `https://www.trustlogo.com/ttb_searcher/trustlogo?v_querytype=W&v_shortname=SC5&v_search=${window.location.href}/subscription&x=6&y=5`),
        onContextMenu: event => window.tLLB(event)
    }

    render() {
        return (
            <a
                className={'sectigo-trust'}
                href={this.logoProps.href}
                onMouseOver={this.logoProps.onMouseOver}
                onMouseMove={this.logoProps.onMouseMove}
                onMouseOut={this.logoProps.onMouseOut}
                onDragStart={() => {}}
            >
                <img
                    src="https://sectigo.com/images/seals/sectigo_trust_seal_lg_2x.png"
                    onMouseDown={this.logoProps.onMouseDown}
                    onContextMenu={this.logoProps.onContextMenu}
                    alt='Secured by sectigo'
                />
            </a>
        )
    }
}

export default TrustLogo