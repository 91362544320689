import React from 'react';
import ReactGA from 'react-ga';
import DocumentMeta from 'react-document-meta';

export default class ContactPage extends React.Component {
	render() {
		return (
			<section id='contact'>
				<DocumentMeta title='Contact' />
				<div>
					<h2>How to reach us?</h2>

					<h6>Whether you need support or want to suggest a new feature...</h6>

					<div id='tiles'>
						<figure>
							<ReactGA.OutboundLink eventLabel='Mail to info' to='mailto:info@sketchandcalc.com'>
								<span className='icon icon-email' />
								<span>email</span>
							</ReactGA.OutboundLink>
						</figure>
						<figure>
							<ReactGA.OutboundLink
								target='_blank'
								eventLabel='Facebook SketchAndCalc'
								to='https://www.facebook.com/SketchAndCalc-Area-Calculator-361029050630065/'>
								<span className='icon icon-facebook' />
								<span>facebook</span>
							</ReactGA.OutboundLink>
						</figure>
						<figure>
							<ReactGA.OutboundLink
								target='_blank'
								eventLabel='Twitter SketchAndCalc'
								to='https://twitter.com/SketchAndCalc'>
								<span className='icon icon-twitter' />
								<span>twitter</span>
							</ReactGA.OutboundLink>
						</figure>
					</div>
				</div>
			</section>
		);
	}
}
