import React from 'react';

class TestPage extends React.Component {
    render(){
        return(
            <a href="https://www.sketchandcalc.com" id='SketchAndCalc-link' target='_blank' title="Area Calculator">
                <img
                    src='https://www.sketchandcalc.com/static/media/Badge_wt.svg'
                    alt='SketchAndCalc: Area Calculator'
                    id='SketchAndCalc-badge'
                />
            </a>
        )
    }
}

export default TestPage