import { svgEl } from '../../components/App/DrawArea/DrawArea';

const LAYER_LABEL_TOGGLE_EXPAND = 'LAYER_LABEL_TOGGLE_EXPAND';
const LAYER_LABEL_SET_MOUSE_DOWN = 'LAYER_LABEL_SET_MOUSE_DOWN';
const LAYER_LABEL_SET_POSITION = 'LAYER_LABEL_SET_POSITION';
const LAYER_LABEL_SET_OFFSETS = 'LAYER_LABEL_SET_OFFSETS';
const LAYER_LABEL_RESET = 'LAYER_LABEL_RESET';

export const layerLabelTypes = {
    LAYER_LABEL_TOGGLE_EXPAND,
    LAYER_LABEL_SET_MOUSE_DOWN,
    LAYER_LABEL_SET_POSITION,
    LAYER_LABEL_SET_OFFSETS,
    LAYER_LABEL_RESET
};

export function layerLabelToggleExpand() {
    return { type: layerLabelTypes.LAYER_LABEL_TOGGLE_EXPAND };
}

export function layerLabelSetMouseDown(value) {
    return {
        type: layerLabelTypes.LAYER_LABEL_SET_MOUSE_DOWN,
        payload: { value }
    };
}

export function layerLabelMouseMove(event) {
    return (dispatch, store) => {
        let { layerLabel } = store();

        if (layerLabel.mouseDown) {
            let bounds = svgEl.getBoundingClientRect(),
                position = {
                    x: event.nativeEvent.clientX - bounds.left - layerLabel.offsets.x,
                    y: event.nativeEvent.clientY - bounds.top - layerLabel.offsets.y
                };

            dispatch(layerLabelSetPosition(position));
        }
    };
}

export function layerLabelMouseUp() {
    return (dispatch, store) => {
        let { layerLabel } = store();

        if (layerLabel.mouseDown) {
            dispatch(layerLabelSetMouseDown(false));
            dispatch(layerLabelSetOffsets(initState.offsets));
        }
    };
}

export function layerLabelSetPosition(position) {
    return {
        type: layerLabelTypes.LAYER_LABEL_SET_POSITION,
        payload: { position }
    };
}

export function layerLabelSetOffsets(offsets) {
    return {
        type: layerLabelTypes.LAYER_LABEL_SET_OFFSETS,
        payload: { offsets }
    };
}

export function layerLabelReset() {
    return { type: layerLabelTypes.LAYER_LABEL_RESET };
}

const initState = {
    expand: true,
    mouseDown: false,
    position: null,
    offsets: { x: 0, y: 0 }
};

export default function layerLabelReducer(state = initState, action) {
    switch (action.type) {
        case layerLabelTypes.LAYER_LABEL_TOGGLE_EXPAND: {
            return { ...state, expand: !state.expand };
        }
        case layerLabelTypes.LAYER_LABEL_SET_MOUSE_DOWN: {
            return { ...state, mouseDown: action.payload.value };
        }
        case layerLabelTypes.LAYER_LABEL_SET_POSITION: {
            return { ...state, position: action.payload.position };
        }
        case layerLabelTypes.LAYER_LABEL_SET_OFFSETS: {
            return { ...state, offsets: action.payload.offsets };
        }
        case layerLabelTypes.LAYER_LABEL_RESET: {
            return { ...initState };
        }
        default: {
            return state;
        }
    }
}
