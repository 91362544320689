const AUTH_LOGIN = 'AUTH_LOGIN';
const AUTH_LOGOUT = 'AUTH_LOGOUT';
const AUTH_PENDING = 'AUTH_PENDING';
const AUTH_SET_USER = 'AUTH_SET_USER';
const SHOW_FORCE_LOGIN_MESSAGE = 'SHOW_FORCE_LOGIN_MESSAGE';

export const authTypes = {
	user: 'user',
	student: 'student',
	educator: 'educator'
}

export const failedAuthReasons = {
	badge: 'failed badge',
	review: 'failed review'
}

export default {
	AUTH_LOGIN,
	AUTH_LOGOUT,
	AUTH_PENDING,
	AUTH_SET_USER,
	SHOW_FORCE_LOGIN_MESSAGE,
	authTypes
};
