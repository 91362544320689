import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MathHelper from '../../../shared/MathHelper';
import { copyToClipboard } from '../../../shared/EventHelper';
import { notificationShow } from '../../../shared/actions/notificationActions';
import { notificationTypes } from '../../../shared/constants/notificationConstants';

class SketchDetailResults extends React.Component {
	labels = ['nm', 'μm', 'mm', 'cm', 'm', 'km', 'in', 'ft', 'yd', 'mi', 'a', 'ha'];

	render() {
		return (
			<div
				className={`details ${this.props.type}`}
				style={{
					left: `${this.props.left}px`,
					bottom: `${this.props.bottom}px`
				}}>
				<table>
					<tbody>
						<tr>
							<td>
								<div
									className='extra'
									onClick={() => {
										this.copyValuesToClipboard(this.props.type, this.props.results);
									}}>
									<span className='icon icon-clipboard' />
									copy {this.props.type}
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div className='values'>
									<ul>
										{this.props.type === 'perimeter' && this.getPerimeterDetails()}
										{this.props.type === 'area' && this.getAreaDetails()}
										{this.props.type === 'open shapes' && this.getOpenShapesDetails()}
									</ul>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}

	getShortTextResult(result, measure, area = false) {
		let value = result,
			numDecimals = 0,
			numPow = 0,
			valueText,
			subText = null,
			convert = false;

		if (value === 0) {
			return {
				text: '0',
				sub: subText
			};
		}

		if (value < 0.01) {
			while (value < 1) {
				value *= 10;
				numDecimals++;
			}
		}

		if (value >= 1000000) {
			while (value > 1) {
				value /= 10;
				numPow++;
			}
		}

		valueText = `${MathHelper.round(value)}`;

		if (numDecimals > 0) {
			valueText += ' X 10';
			subText = `-${numDecimals}`;
		} else if (numPow > 0) {
			valueText += ' X 10';
			subText = `${numPow}`;
		}

		if (['yd', 'ft'].includes(measure) && numDecimals === 0 && numPow === 0 && this.props.isFractions) {
			convert = true;
			switch (measure) {
				case 'yd':
					valueText = MathHelper.yardsFeet(value, area);
					break;
				case 'ft':
					valueText = MathHelper.feetInches(value, area);
					break;
			}
		}

		return {
			text: valueText,
			sub: subText,
			convert
		};
	}

	getPerimeterDetails() {
		return this.props.results.map((result, index) => {
			if (this.labels.includes(result.abbr)) {
				const { text, sub, convert } = this.getShortTextResult(result.value, result.abbr);
				let abbr = result.abbr;
				if (convert) {
					switch (result.abbr) {
						case 'yd':
							abbr = 'yd/ft';
							break;
						case 'ft':
							abbr = 'ft/in';
							break;
					}
				}

				return (
					<li key={index}>
						<span>{abbr}</span>
						{text}
						{sub && <sub>{sub}</sub>}
					</li>
				);
			}

			return null;
		});
	}

	getAreaDetails() {
		return this.props.results.map((result, index) => {
			if (this.labels.includes(result.abbr)) {
				const { text, sub, convert } = this.getShortTextResult(result.value, result.abbr, true),
					isArea = !['a', 'ha'].includes(result.abbr);

				let abbr = (
					<span>
						{result.abbr}
						{isArea && <sub>2</sub>}
					</span>
				);
				if (convert) {
					switch (result.abbr) {
						case 'yd':
							abbr = (
								<span>
									yd{isArea && <sub>2</sub>}/ft{isArea && <sub>2</sub>}{' '}
								</span>
							);
							break;
						case 'ft':
							abbr = (
								<span>
									ft{isArea && <sub>2</sub>}/in{isArea && <sub>2</sub>}{' '}
								</span>
							);
							break;
					}
				}

				return (
					<li key={index}>
						{abbr}
						{text}
						{sub && <sub>{sub}</sub>}
					</li>
				);
			}

			return null;
		});
	}

	getOpenShapesDetails() {
		return this.props.results.map((result, index) => {
			if (this.labels.includes(result.abbr)) {
				const { text, sub, convert } = this.getShortTextResult(result.value, result.abbr);
				let abbr = result.abbr;
				if (convert) {
					switch (result.abbr) {
						case 'yd':
							abbr = 'yd/ft';
							break;
						case 'ft':
							abbr = 'ft/in';
							break;
					}
				}

				return (
					<li key={index}>
						<span>{abbr}</span>
						{text}
						{sub && <sub>{sub}</sub>}
					</li>
				);
			}

			return null;
		});
	}

	copyValuesToClipboard(type, data) {
		const isArea = type === 'area';

		let out = data
			.map((result, index) => {
				if (this.labels.includes(result.abbr)) {
					const squared = isArea && !['a', 'ha'].includes(result.abbr);
					return result.value + ' ' + result.abbr + (squared ? '^2' : '');
				}

				return null;
			})
			.join('\n');

		copyToClipboard(out);

		this.props.notificationShow('Copied', notificationTypes.INFO, 500);
	}
}

const mapStateToProps = state => ({
	isFractions: state.settings.imperial === 'fractions'
});

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			notificationShow
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(SketchDetailResults);
