import React from 'react';
import { connect } from 'react-redux';
import store, {mobileDetect} from '../../../shared/store';
import DocumentMeta from 'react-document-meta';
import {authLogin, authLogout, loadUserData} from '../../../shared/actions/authActions';
import cookie from 'js-cookie';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { authTypes } from '../../../shared/constants/authConstants';
import ThankYou from '../../App/ThankYou/ThankYou';

class RegisterCompletePage extends React.Component {
	login = () => {
		ReactGA.ga('send', 'event', 'form', 'click', 'verification');
		this.props.history.push('/login');
	}

	componentWillMount() {
		if (mobileDetect.mobile() && store.getState().auth.isAuthenticated) {
			try {
				store.dispatch(authLogout(store.getState().auth.user.email))
			} catch (e) {}
			return;
		}

		if (cookie.get('accessToken') && !store.getState().auth.isAuthenticated && !store.getState().auth.user) {
			store.dispatch(authLogin());
			store.dispatch(loadUserData());
		}

		if (get(store.getState(), 'auth.user.subscription.isOutdated', false)) {
			store.dispatch(loadUserData());
		}
	}

	render() {
		if (this.props.user && this.props.user.is_from_app) {
			return (
				<div className='form-wrapper register-from_app'>
					<DocumentMeta title='Complete' />
					<form>
						<h2>Thank you {this.props.user.first_name}</h2>
						<p>Your subscription is complete</p>
						<br />
						<p>Login with your email address and password</p>
						<a href='sketchandcalc://' className='btn btn-primary transparent'>
							Open companion app
						</a>
						<br />
						<p>Have questions?</p>
						<Link to='/help' target='_blank' className='btn btn-primary transparent'>
							FAQ
						</Link>
					</form>
				</div>
			);
		}

		const queryStr = get(this, 'props.history.location.search', '');
		let params = new URLSearchParams(queryStr);

		if (params.get('type') === authTypes.educator) {
			return (
				<ThankYou />
			)
		}

		const isMobile = mobileDetect.mobile();
		let message = 'Login anytime using your email address and password';

		if (isMobile) {
			message = 'Success, please open the app now'
		}

		return (
			<div className='form-wrapper'>
				<DocumentMeta title='Complete' />
				<form>
					<h2>Thank you</h2>

					<h5>{message}</h5>

					<div>
						{!isMobile &&
							<button onClick={this.login} type='button'>
								Launch
							</button>
						}
					</div>
				</form>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		user: state.auth.user
	};
}

export default connect(mapStateToProps)(RegisterCompletePage);
