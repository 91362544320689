import * as React from 'react';
import { gridTransparencyValues } from '../../../shared/constants/settingsConstants';

export const gridSize = 15;

export default class CanvasGrid extends React.Component {
	render() {
		const classNames = ['grid'];

		switch (this.props.gridTransparency) {
			case gridTransparencyValues.GRID_TRANSPARENCY_FULL: {
				classNames.push('transparency-full');
				break;
			}
			case gridTransparencyValues.GRID_TRANSPARENCY_75: {
				classNames.push('transparency-75');
				break;
			}
			case gridTransparencyValues.GRID_TRANSPARENCY_50: {
				classNames.push('transparency-50');
				break;
			}
			case gridTransparencyValues.GRID_TRANSPARENCY_25: {
				classNames.push('transparency-25');
				break;
			}
			default: {
				classNames.push('transparency-full');
			}
		}

		return <g className={classNames.join(' ')}>{this.renderLines()}</g>;
	}

	renderLines() {
		let gridElements = [];
		let strokeWidth = 1 / (this.props.zoomValue || 1);

		if (this.props.canvasSizes.width) {
			let width = Math.max(this.props.canvasSizes.width, this.props.minCanvasSizes.width) + 40,
				height = Math.max(this.props.canvasSizes.height, this.props.minCanvasSizes.height) + 40;

			// Render vertical lines
			for (let x = gridSize; x <= width; x += gridSize) {
				gridElements.push(
					<line x1={x} y1={0} x2={x} y2={height} key={`${x}-${width}`} strokeWidth={strokeWidth} />
				);
			}
			// Render horizontal lines
			for (let y = gridSize; y <= height; y += gridSize) {
				gridElements.push(
					<line x1={0} y1={y} x2={width} y2={y} key={`${y}-${height}`} strokeWidth={strokeWidth} />
				);
			}
		}

		return gridElements;
	}
}
