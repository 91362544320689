import { mapTypes } from '../constants/mapConstants';
import mapConstants from '../constants/mapConstants';

const initState = {
	visible: false,
	zoom: 6,
	type: mapTypes.ROADMAP,
	center: { lat: 38.754083, lng: -101.337891 },
	maxZoom: false,
	movePoint: null,
	pan: null
};

export default function(state = { ...initState }, action) {
	switch (action.type) {
		case mapConstants.MAP_TOGGLE_VISIBILITY: {
			return { ...state, visible: !state.visible };
		}
		case mapConstants.MAP_UPDATE_CENTER: {
			return { ...state, center: action.payload.center };
		}
		case mapConstants.MAP_UPDATE_ZOOM: {
			return { ...state, zoom: action.payload.zoom };
		}
		case mapConstants.MAP_UPDATE_MAX_ZOOM: {
			return { ...state, maxZoom: action.payload.value };
		}
		case mapConstants.MAP_ZOOM_IN: {
			return { ...state, zoom: state.zoom + 1 };
		}
		case mapConstants.MAP_ZOOM_OUT: {
			let zoom = state.zoom - 1;

			if (zoom > 0) {
				return { ...state, zoom: state.zoom - 1 };
			}

			return { ...state, zoom: 0 };
		}
		case mapConstants.MAP_UPDATE_MOVE_POINT: {
			return { ...state, movePoint: action.payload.point };
		}
		case mapConstants.MAP_UPDATE_PAN: {
			return { ...state, pan: action.payload.pan };
		}
		case mapConstants.MAP_UPDATE_TYPE: {
			return { ...state, type: action.payload.type };
		}
		case mapConstants.MAP_UPDATE: {
			return { ...state, ...action.payload.state };
		}
		case mapConstants.MAP_RESET: {
			return { ...initState };
		}
		default: {
			return state;
		}
	}
}
