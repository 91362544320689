import { GET_DOCUMENTS_SUCCESS } from '../constants/documentsConstants';
import ApiHelper from '../ApiHelper';

export const getDocuments = () => {
	return async (dispatch) => {
		const response = await ApiHelper.getListDocuments();

		if (response.data.success) {
			dispatch({ type: GET_DOCUMENTS_SUCCESS, payload: response.data.documents });
		}
	};
}