import { FILE_NAME_SAVE } from '../constants/fileNameConstants';

const initState = {
	fileName: null
};

export default function fileName(state = initState, action) {
	switch (action.type) {
		case FILE_NAME_SAVE: {
			return { ...state, fileName: action.payload.fileName };
		}
		default: {
			return state;
		}
	}
}
