import React, { Component } from 'react';
import LineLabel from '../LineLabel/LineLabel';
import MathHelper from '../../../shared/MathHelper';
import { gridSize } from '../CanvasGrid/CanvasGrid';

export default class PreviewShape extends Component {
	render() {
		if (!this.props.preview.shapeShow) {
			return null;
		}

		if (this.props.preview.shapePreview) {
			if (this.props.preview.shapePreview.length > 2) {
				let points = this.props.preview.shapePreview.map((p, i) => p.join(' ')).join(' ');
				let fromPoint = this.props.preview.shapePreview[0],
					toPoint0 = this.props.preview.shapePreview[1],
					toPoint1 = this.props.preview.shapePreview[3],
					lineSize0 = MathHelper.lineLength(fromPoint, toPoint0),
					lineSize1 = MathHelper.lineLength(fromPoint, toPoint1);

				return (
					<g>
						<polygon className='shape-preview' points={points} />
						<LineLabel
							point={MathHelper.lineMiddlePoint(fromPoint, toPoint0)}
							lineSize={lineSize0}
							text={MathHelper.round((lineSize0 * this.props.scale) / gridSize)}
							rotate={MathHelper.lineAngle(fromPoint, toPoint0)}
							zoom={this.props.zoom}
						/>
						<LineLabel
							point={MathHelper.lineMiddlePoint(fromPoint, toPoint1)}
							lineSize={lineSize1}
							text={MathHelper.round((lineSize1 * this.props.scale) / gridSize)}
							rotate={MathHelper.lineAngle(fromPoint, toPoint1)}
							zoom={this.props.zoom}
						/>
					</g>
				);
			} else if (this.props.preview.shapePreview.length === 2) {
				let fromPoint = this.props.preview.shapePreview[0],
					toPoint = this.props.preview.shapePreview[1],
					lineSize = MathHelper.lineLength(fromPoint, toPoint);

				return (
					<g className='preview-line'>
						<line
							strokeWidth={this.props.strokeWidth}
							x1={fromPoint[0]}
							y1={fromPoint[1]}
							x2={toPoint[0]}
							y2={toPoint[1]}
						/>
						<LineLabel
							point={MathHelper.lineMiddlePoint(fromPoint, toPoint)}
							lineSize={lineSize}
							text={MathHelper.round((lineSize * this.props.scale) / gridSize)}
							rotate={MathHelper.lineAngle(fromPoint, toPoint)}
							zoom={this.props.zoom}
						/>
					</g>
				);
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
}
