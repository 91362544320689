import React from 'react';

export class Text extends React.Component {
	render() {
		let fontSize = (this.props.fontSize || 12) / this.props.zoom;

		return (
			<text x={this.props.x} y={this.props.y} fontSize={fontSize} textAnchor='middle'>
				{this.props.text}
			</text>
		);
	}
}
