import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';
import { measuresChanged, scaleSetMeasure, scaleSetValue } from '../../../shared/actions/scaleControlActions';
import { sketchRefreshPoints } from '../../../shared/actions/SketchActions';
import { noteRefreshPoints } from '../../../shared/actions/NoteActions';
import {
	mapRefreshZoomView,
	mapUpdateCenter,
	mapUpdateMaxZoom,
	mapUpdateZoom
} from '../../../shared/actions/mapActions';
import { layerRefreshLayers } from '../../../shared/actions/layerActions';
import { printActivateReady } from '../../../shared/reducers/printReducer';
import { gridSize } from '../CanvasGrid/CanvasGrid';
import { measurementSystemValues } from '../../../shared/constants/settingsConstants';

export let overlay;

class Map extends React.Component {
	handleTimeout = null;

	mapRef = null;
	map = null;

	maxZoomService = null;

	render() {
		const classNames = ['map'];

		if (!this.props.visible) {
			classNames.push('hidden');
		}

		return <div className={classNames.join(' ')} ref={el => (this.mapRef = el)} />;
	}

	componentDidMount() {
		overlay = new window.google.maps.OverlayView();
		overlay.draw = () => {};

		this.map = new window.google.maps.Map(this.mapRef, {
			zoom: this.props.zoom,
			center: this.props.center,
			mapTypeId: this.props.type,
			disableDefaultUI: true,
			tilt: 0
		});

		overlay.setMap(this.map);

		this.maxZoomService = new window.google.maps.MaxZoomService();

		this.map.addListener('zoom_changed', this.mapZoomChanged.bind(this));
		this.map.addListener('center_changed', this.mapCenterChanged.bind(this));
		this.map.addListener('bounds_changed', this.mapBoundsChanged.bind(this));
		this.map.addListener('idle', this.mapSettled.bind(this));

		this.map.addListener('tilesloaded', this.setCorrectMapZoom.bind(this));

		if (window.isPuppeteer) {
			this.map.addListener('tilesloaded', this.tilesLoaded.bind(this));
		}
	}

	setCorrectMapZoom() {
		this.map.setZoom(this.props.zoom);
		this.mapCalcScale();
	}

	tilesLoaded() {
		if (this.handleTimeout) {
			window.clearTimeout(this.handleTimeout);
			this.handleTimeout = null;
		}

		if (
			this.props.visible &&
			!this.props.print.isExport &&
			this.props.print.isPhantomJs &&
			!this.props.print.isReady
		) {
			this.handleTimeout = window.setTimeout(this.props.printActivateReady.bind(this), 1000);
		}
	}

	mapBoundsChanged() {
		if (this.props.visible) {
			if (!(this.props.movePoint || this.props.pan)) {
				if (this.props.showAllLayers) {
					this.props.layerRefreshLayers();
				} else {
					this.props.sketchRefreshPoints();
					this.props.noteRefreshPoints();
				}
			}
			this.props.mapRefreshZoomView();
		}
	}

	mapZoomChanged() {
		if (this.map.zoom !== this.props.zoom) {
			this.props.mapUpdateZoom(this.map.zoom);
		}
	}

	mapCenterChanged() {
		if (!this.checkMapCenter()) {
			let center = this.map.getCenter();
			this.props.mapUpdateCenter(center.lat(), center.lng());
		}
	}

	mapCalcScale(isDefault = false) {
		if (this.props.visible) {
			let bounds = this.map.getBounds();
			let NE = bounds.getNorthEast();
			let SW = bounds.getSouthWest();

			let centerTopLatLng = new window.google.maps.LatLng(NE.lat(), (NE.lng() + SW.lng()) / 2);
			let centerBottomLatLng = new window.google.maps.LatLng(SW.lat(), (NE.lng() + SW.lng()) / 2);

			let heightDistance = window.google.maps.geometry.spherical.computeDistanceBetween(
				centerTopLatLng,
				centerBottomLatLng
			);
			let pixelScale = heightDistance / this.mapRef.offsetHeight;
			let measure = this.props.measure;

			this.props.scaleSetMeasure('m');
			this.props.scaleSetValue(pixelScale * gridSize);

			if (isDefault) {
				switch (this.props.measurementSystem) {
					case measurementSystemValues.MEASUREMENT_SYSTEM_IMPERIAL: {
						this.props.measuresChanged('mi');
						break;
					}
					default: {
						this.props.measuresChanged('km');
					}
				}
			} else {
				this.props.measuresChanged(measure);
			}
		}
	}

	setMaxZoomCenter(latLng) {
		this.props.mapUpdateMaxZoom(false);

		if (this.maxZoomService) {
			this.maxZoomService.getMaxZoomAtLatLng(latLng, response => {
				this.map.panTo(latLng);
				if (response.status === 'OK') {
					this.map.setZoom(response.zoom);
				}
			});
		} else {
			this.map.panTo(latLng);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.movePoint && this.props.pan && !isEqual(prevProps.pan, this.props.pan)) {
			this.map.panBy(this.props.pan[0], this.props.pan[1]);
		}

		if (!isEqual(prevProps.center, this.props.center) && !this.checkMapCenter()) {
			let center = new window.google.maps.LatLng(this.props.center.lat, this.props.center.lng);

			if (this.props.maxZoom) {
				this.setMaxZoomCenter(center);
			} else {
				this.map.panTo(center);
			}
		}

		if (prevProps.zoom !== this.props.zoom) {
			this.map.setZoom(this.props.zoom);
			this.mapCalcScale();
		}

		if (prevProps.visible !== this.props.visible) {
			this.mapCalcScale(true);
		}

		if (prevProps.type !== this.props.type) {
			this.map.setMapTypeId(this.props.type);
		}

		if (window.isPuppeteer) {
			if (
				this.props.visible &&
				!this.props.print.isExport &&
				this.props.print.isPhantomJs &&
				!this.props.print.isReady &&
				!this.handleTimeout
			) {
				this.handleTimeout = window.setTimeout(this.props.printActivateReady.bind(this), 3000);
			}
		}
	}

	checkMapCenter() {
		let center = this.map.getCenter();
		let latLng = {
			lat: center.lat(),
			lng: center.lng()
		};

		return isEqual(latLng, this.props.center);
	}

	mapSettled() {
		if (this.props.visible) {
			this.props.mapRefreshZoomView();
			if (this.props.showAllLayers || this.props.isSomePinnedLayer) {
				this.props.layerRefreshLayers();
			} else {
				this.props.sketchRefreshPoints();
				this.props.noteRefreshPoints();
			}
			this.props.mapRefreshZoomView();
		}
	}
}

function mapStateToProps(state) {
	return {
		visible: state.map.visible,
		zoom: state.map.zoom,
		maxZoom: state.map.maxZoom,
		type: state.map.type,
		center: state.map.center,
		movePoint: state.map.movePoint,
		pan: state.map.pan,
		sketch: state.sketchState.sketch,
		measurementSystem: state.settings.measurementSystem,
		measure: state.scale.measure,
		showAllLayers: state.layers.showAllLayers,
		print: state.print,
		isSomePinnedLayer: state.layers.list.some(({ pinned }) => pinned === true)
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			mapUpdateZoom,
			mapUpdateCenter,
			mapUpdateMaxZoom,
			scaleSetValue,
			scaleSetMeasure,
			measuresChanged,
			sketchRefreshPoints,
			noteRefreshPoints,
			mapRefreshZoomView,
			layerRefreshLayers,
			printActivateReady
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Map);
