import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { modalClose } from '../../../../shared/reducers/ModalReducer';
import { layerIsEmptyAllLayers } from '../../../../shared/actions/layerActions';
import ApiHelper from '../../../../shared/ApiHelper';
import { stateRestoreFromObject, stateToObject, stateToSaveObject } from '../../../../shared/actions/stateActions';
import { notificationShow } from '../../../../shared/actions/notificationActions';
import { notificationTypes } from '../../../../shared/constants/notificationConstants';
import { saveFileName } from '../../../../shared/actions/fileNameActions';
import { updateFileState } from '../../../../shared/actions/fileStateAction';
import { authLogout } from '../../../../shared/actions/authActions';
import orderBy from 'lodash.orderby';
import saveSvgAsPng from 'save-svg-as-png';
import defaultPreview from '../../../../assets/img/default-preview.png';
import Lock from '../../../icons/Lock';
import Archive from '../../../icons/Archive';
import File from '../../../icons/File';
import { getDocuments } from '../../../../shared/actions/documentsActions';
import _isEqual from 'lodash/isEqual';

import './_ModalSaveOpenStyle.scss';

export const MODAL_SAVE_OPEN = 'MODAL_SAVE_OPEN';

const LOADING_MESSAGE = 'Loading...';

class ModalSaveOpen extends React.Component {
	nameEl = null;
	handleTimeout = null;
	handleSuccessSave = null;
	handleArchive = null;
	handleDelete = null;

	SORT_ASC = 'asc';
	SORT_DESC = 'desc';

	constructor(props) {
		super(props);

		this.state = {
			openIsDisabled: false,
			openByTeamMember: false,
			isDisabled: false,
			message: null,
			listLoaded: false,
			activeIndex: null,
			list: [],
			sort: {
				name: null,
				date: this.SORT_DESC
			},
			rewriteConfirmation: false,
			saveUnsavedChanges: this.props.modalData.action === 'logout',
			createNewFile: false,
			loadFileNameAfterSave: '',
			rewriteFileName: null,
			detailsMessage: null,
			logoutAction: this.props.modalData.action === 'logout',
			isEmptyLayers: true
		};
	}

	clearTimeout() {
		if (this.handleTimeout) {
			window.clearTimeout(this.handleTimeout);
			this.handleTimeout = null;
		}
		if (this.handleSuccessSave) {
			window.clearTimeout(this.handleSuccessSave);
			this.handleSuccessSave = null;
		}
		if (this.handleArchive) {
			window.clearTimeout(this.handleArchive);
			this.handleArchive = null;
		}
		if (this.handleDelete) {
			window.clearTimeout(this.handleDelete);
			this.handleDelete = null;
		}
	}

	resetMessage() {
		this.setState({ message: null, detailsMessage: null });
	}

	setActiveItem(index) {
		const { activeIndex, list } = this.state;
		const { currentDeviceUuid } = this.props;

		if (index !== activeIndex) {
			let item = list[index];
			let openIsDisabled = false;
			let detailsMessage = null;
			let openByTeamMember = false;

			if (item.device_uuid === currentDeviceUuid) {
				detailsMessage = {
					message: 'File currently open',
					classes: 'details_error'
				};
				openIsDisabled = true;
			} else if (item.device_uuid) {
				detailsMessage = {
					message: 'File is open by a team member',
					classes: 'details_error'
				};
				openIsDisabled = true;
				openByTeamMember = true;
			}
			this.setState({ activeIndex: index, detailsMessage, openIsDisabled, openByTeamMember });
			this.nameEl.value = list[index].name;
		}
	}

	loadListDocuments() {
		this.props.getDocuments();
	}

	loadDocument = () => {
		let document;
		if (this.state.loadFileNameAfterSave) {
			document = this.state.list.find(doc => doc.name === this.state.loadFileNameAfterSave);
		} else {
			document = this.state.list[this.state.activeIndex];

			if (!document) {
				document = this.state.list.find(doc => doc.name === this.nameEl.value.trim());
			}
		}

		if (document) {
			if (this.props.needSave) {
				return this.setState({saveUnsavedChanges: true, loadFileNameAfterSave: document.name});
			}

			this.setState({
				isDisabled: true,
				detailsMessage: {
					type: 'success',
					message: 'Opening...'
				}
			});

			ApiHelper.getDocument(document.id).then(response => {
				this.setState({
					isDisabled: false,
					message: null
				});

				if (response.data.success) {
					this.props.stateRestoreFromObject(response.data.document);
					this.props.modalClose();
				}

				this.props.saveFileName(document.name);
				setTimeout(() => this.props.updateFileState(false), 1000);
			});
		} else {
			this.setState({
				detailsMessage: {
					type: 'details_error',
					message: 'Document not found!'
				}
			});

			this.handleTimeout = window.setTimeout(this.resetMessage.bind(this), 1000);
		}
	}

	getListDocuments() {
		const { currentDeviceUuid } = this.props;

		return (
			<ul className='list'>
				{this.state.list.map((item, index) => {
					const classNames = ['item'];
					let showLock = false, showFile = false;

					if (index === this.state.activeIndex) {
						classNames.push('active');
					}

					if (item.archived) {
						classNames.push('archived');
					} else if (currentDeviceUuid === item.device_uuid) {
						showFile = true;
					} else if (item.device_uuid) {
						showLock = true;
					}

					return (
						<li
							key={`${item.id}_${item.device_uuid}`}
							className={classNames.join(' ')}
							onClick={() => {
								this.setActiveItem(index);
							}}>
							<span className='name'>{item.name} {showLock && <Lock size='13'/>}{showFile && <File size='13'/>}{!!item.archived && <Archive size='13'/>}</span>
							<span className='date'>{moment(item.updated_at).format('DD-MM-YYYY HH:mm:ss')}</span>
						</li>
					);
				})}
			</ul>
		);
	}

	save = ({ loadFile }) => {
		this.clearTimeout();

		this.props.layerIsEmptyAllLayers().then(response => {
			if (response) {
				this.setState({
					detailsMessage: {
						type: 'details_error',
						message: 'All layers are empty!'
					}
				});

				this.handleTimeout = window.setTimeout(this.resetMessage.bind(this), 1000);
			} else {
				const name = this.nameEl.value.trim();
				let sameFile = false;
				if (this.state.activeIndex !== null && this.props.fileName) {
					const document = this.state.list[this.state.activeIndex];
					if (document.name === this.props.fileName) {
						sameFile = true;
					}
				}

				if (name.length) {
					if (this.state.list.filter(item => item.name === name).length && !sameFile) {
						this.setState({
							rewriteConfirmation: true,
							rewriteFileName: name
						});
					} else {
						this.saveFile(name, loadFile);
					}
				} else {
					this.setState({
						detailsMessage: {
							type: 'details_error',
							message: 'Please specify a file name.'
						}
					});

					this.handleTimeout = window.setTimeout(this.resetMessage.bind(this), 1000);
				}
			}
		});
	}

	saveFile = (name, loadFile) => {
		this.setState({
			isDisabled: true,
			detailsMessage: {
				type: 'success',
				message: 'Saving... Please wait!'
			}
		});

		this.props.stateToSaveObject().then(state => {
			saveSvgAsPng
				.svgAsPngUri(document.getElementById('main'), {
					encoderOptions: 0.8,
					scale: 0.2
				})
				.then(preview => {
					return ApiHelper.saveDocument({
						name: name,
						state: state,
						preview
					});
				})
				.then(() => {
					this.setState({
						isDisabled: false,
						message: null
					});

					if (this.state.rewriteConfirmation) {
						this.setState({
							rewriteConfirmation: false,
							rewriteFileName: null
						});
						this.props.notificationShow('Saved', notificationTypes.SUCCESS, 1000);
						this.props.modalClose();
					} else {
						if (this.nameEl) {
							this.nameEl.value = '';
						}
						this.loadListDocuments();
					}
					if (this.state.logoutAction) {
						this.props.updateFileState(false);
						this.props.saveFileName('');
						return this.props.authLogout(this.props.userEmail);
					} else {
						loadFile && setTimeout(() => this.loadDocument(), 500);
					}
					this.props.saveFileName(name);
					setTimeout(() => this.props.updateFileState(false), 500);
					this.setState({successSave: true, detailsMessage: null});
					this.handleSuccessSave = setTimeout(() => this.setState({successSave: false}), 3000);
				})
				.catch(error => {
					const state = {
						isDisabled: false,
						message: null
					};

					if (error.response.data.message) {
						state.detailsMessage = {
							type: 'danger',
							message: error.response.data.message
						};

						this.handleTimeout = window.setTimeout(this.resetMessage.bind(this), 1000);
					}

					this.setState(state);
				});
		});
	}

	onArchiveOrRestore = () => {
		let document = this.state.list[this.state.activeIndex];

		if (!document) {
			document = this.state.list.find(doc => doc.name === this.nameEl.value.trim());
		}

		if (document) {
			this.setState({
				isDisabled: true,
				detailsMessage: {
					type: 'success',
					message: document.archived ? 'Restoring... Please wait!' : 'Archiving... Please wait!'
				}
			});

			ApiHelper.archiveOrRestoreDocument({id: document.id}).then(response => {
				if (response.data.success) {
					const doc = response.data.document;
					this.setState({
						isDisabled: false,
						message: null,
						list: this.state.list.map(item => item.id === doc.id ? doc : item)
					});
					if (doc.archived) {
						this.setState({successArchive: true});
						this.handleArchive = setTimeout(() => this.setState({successArchive: false}), 3000);
					} else {
						this.setState({successRestore: true});
						this.handleArchive = setTimeout(() => this.setState({successRestore: false}), 3000);
					}
					this.setState({detailsMessage: null, activeIndex: null});
				} else {
					this.setState({
						isDisabled: false,
						message: null,
						detailsMessage: null
					});
				}

			});
		} else {
			this.setState({
				detailsMessage: {
					type: 'danger',
					message: 'Document not found!'
				}
			});

			this.handleTimeout = window.setTimeout(this.resetMessage.bind(this), 1000);
		}
	}

	onDelete = () => {
		let document = this.state.list[this.state.activeIndex];

		if (!document) {
			document = this.state.list.find(doc => doc.name === this.nameEl.value.trim());
		}

		if (document) {
			this.setState({
				isDisabled: true,
				detailsMessage: {
					type: 'success',
					message: 'Deleting... Please wait!'
				}
			});

			ApiHelper.deleteDocument(document.id).then(response => {
				if (response.data.success) {
					this.setState({
						isDisabled: false,
						message: null,
						list: [
							...this.state.list.slice(0, this.state.activeIndex),
							...this.state.list.slice(this.state.activeIndex + 1)
						]
					});
					this.setState({successDelete: true, detailsMessage: null});
					this.handleDelete = setTimeout(() => this.setState({successDelete: false}), 3000);
				} else {
					this.setState({
						isDisabled: false,
						message: null,
						detailsMessage: null
					});
				}
			});
		} else {
			this.setState({
				detailsMessage: {
					type: 'danger',
					message: 'Document not found!'
				}
			});

			this.handleTimeout = window.setTimeout(this.resetMessage.bind(this), 1000);
		}
	}

	sortList(sort) {
		let list = [];

		if (sort.name) {
			list = orderBy(this.state.list, ['name'], [sort.name]);
		} else if (sort.date) {
			list = orderBy(this.state.list, ['updated_at'], [sort.date]);
		}

		if (list.length) {
			this.setState({ list });
		}
	}

	sortName() {
		const sort = { date: null };

		if (!this.state.sort.name) {
			sort.name = this.SORT_ASC;
		} else {
			sort.name = this.state.sort.name === this.SORT_ASC ? this.SORT_DESC : this.SORT_ASC;
		}

		this.sortList(sort);
		this.setState({ sort });
	}

	getSortNameElement() {
		const classNames = ['sort'];

		if (this.state.sort.name) {
			classNames.push('active');

			if (this.state.sort.name === this.SORT_DESC) {
				classNames.push('up');
			}
		}

		return (
			<span onClick={!this.state.isDisabled && this.sortName.bind(this)} className={classNames.join(' ')}>
				filename
				<i className='icon icon-nav_down' />
			</span>
		);
	}

	sortDate() {
		const sort = { name: null };

		if (!this.state.sort.date) {
			sort.date = this.SORT_ASC;
		} else {
			sort.date = this.state.sort.date === this.SORT_ASC ? this.SORT_DESC : this.SORT_ASC;
		}

		this.sortList(sort);
		this.setState({ sort });
	}

	getSortDateElement() {
		const classNames = ['sort'];

		if (this.state.sort.date) {
			classNames.push('active');

			if (this.state.sort.date === this.SORT_DESC) {
				classNames.push('up');
			}
		}

		return (
			<span onClick={!this.state.isDisabled && this.sortDate.bind(this)} className={classNames.join(' ')}>
				<i className='icon icon-nav_down' />
				modified
			</span>
		);
	}

	componentWillMount() {
		this.loadListDocuments();
	}

	componentWillUnmount() {
		this.clearTimeout();
	}

	async componentDidMount() {
		await this.setState({ list: this.props.documentsList });
		await this.sortList(this.state.sort);
		await this.setState({ listLoaded: false });
		this.props.layerIsEmptyAllLayers().then(isEmptyLayers => {
			this.setState({isEmptyLayers});
		});
	}

	async componentDidUpdate(prevProps) {
		const listUpdated = !_isEqual(prevProps.documentsList, this.props.documentsList);
		const socketReconnected = (prevProps.socketStatus !== this.props.socketStatus) && this.props.socketStatus;


		if (socketReconnected) {
			this.loadListDocuments();
		}

		if (listUpdated) {
			await this.setState({ list: this.props.documentsList });
			await this.sortList(this.state.sort);
		}

		if (!this.state.listLoaded) {
			await this.setState({listLoaded: true});
		}
    }

	render() {
		if (this.state.rewriteConfirmation) {
			return this.renderConfirmOverwrite();
		}
		if (this.state.saveUnsavedChanges){
			return this.renderUnsavedChanges();
		}

		return this.renderSaveOpen();
	}

	getPreview() {
		let { list, activeIndex } = this.state;
		let src =
			list && activeIndex !== null && list[activeIndex] && list[activeIndex].preview
				? `${process.env.REACT_APP_API_HOST}/preview/${list[activeIndex].preview}`
				: defaultPreview;

		return (
			<div
				style={{
					width: '100%',
					height: '105px',
					backgroundImage: `url(${src})`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat'
				}}></div>
		);
	}

	renderSaveOpen() {
		const { detailsMessage, isDisabled, openIsDisabled, message, listLoaded, list, activeIndex,
			successArchive, successDelete, successSave, successRestore, isEmptyLayers, openByTeamMember} = this.state;
		let document, archived;
		if (activeIndex !== null) {
			document = list[activeIndex];
			archived = document ? document.archived : false;
		}

		return (
			<div className='modal-save-open modal-inner-wrapper'>
				<h2 className='text-center'>save | open</h2>
				<div className={`container-fluid content ${detailsMessage !== null && 'message'}`}>
					<div className='left-block'>
						<input
							ref={el => (this.nameEl = el)}
							type='text'
							className='form-control'
							disabled={isDisabled}
							onChange={e => {
								this.setState({ activeIndex: null, isOpenDisabled: false, detailsMessage: null });
							}}
						/>
						<div className='table-block'>
							<div className='col open'>
								<div className='wrapper-list'>
									{!listLoaded && (
										<div className='list-overlay'>
											<p className='text-center text-success'>{LOADING_MESSAGE}</p>
										</div>
									)}
									<div className='sort-actions'>
										{this.getSortNameElement()}
										{this.getSortDateElement()}
									</div>
									<hr/>
									{this.getListDocuments()}
								</div>
								{detailsMessage &&
								<div className={`details-block ${detailsMessage.classes}`}>
									{detailsMessage.message}
								</div>
								}
							</div>
						</div>
					</div>
					<div className='right-block'>
						<div>
							<button
								onClick={this.save}
								className={`btn ${successSave ? 'btn-success' : 'btn-modal'}`}
								disabled={isDisabled || archived || openByTeamMember ||isEmptyLayers || (this.nameEl && !this.nameEl.value.trim())}>
								{successSave ? 'done' : 'save'}
							</button>
						</div>
						<div>
							<button
								className={`btn ${successRestore ? 'btn-success' : 'btn-modal'}`}
								disabled={isDisabled || !list.length || openIsDisabled || activeIndex === null}
								onClick={archived ? this.onArchiveOrRestore : this.loadDocument}>
								{successRestore ? 'done' : archived ? 'restore' : 'open'}
							</button>
						</div>
						<div>
							{this.getPreview()}
						</div>
						<div>
							<button
								className={`btn btn-modal light ${(successArchive || successDelete) && 'success-danger'} ${(activeIndex !== null && !openByTeamMember && !(successArchive || successDelete)) && 'active-danger'}`}
								disabled={this.state.isDisabled || !this.state.list.length || openIsDisabled || activeIndex === null}
								onClick={archived ? this.onDelete : this.onArchiveOrRestore}>
								{(successArchive || successDelete) ? 'done' : archived ? 'delete' : 'archive'}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderConfirmOverwrite() {
		return (
			<div className='modal-clear-canvas modal-inner-wrapper'>
				<h2 className='text-center'>Overwrite saved file?</h2>
				<div className='actions text-center'>
					<button
						className='btn btn-modal'
						onClick={() => {
							this.setState({
								rewriteConfirmation: false,
								rewriteFileName: null
							});
						}}>
						cancel
					</button>
					<button
						className='btn btn-modal'
						onClick={() => {
							this.saveFile(this.state.rewriteFileName, this.state.createNewFile && this.state.saveUnsavedChanges);
						}}>
						overwrite
					</button>
				</div>
			</div>
		);
	}

	renderSaveFileForm = (onSave) => {
		return <div className='modal-clear-canvas modal-inner-wrapper'>
			<h2 className='text-center'>Enter new file name.</h2>
			<div className='row mt-2'>
				<div className='col save d-flex'>
					<input
						ref={el => (this.nameEl = el)}
						type='text'
						className='form-control'
						disabled={this.state.isDisabled}
						onChange={() => {
							this.setState({ isOpenDisabled: false, detailsMessage: null });
						}}
					/>
					<button
						onClick={onSave}
						className='btn btn-modal'
						disabled={this.state.isDisabled}>
						save
					</button>
				</div>
			</div>
		</div>;
	}

	renderUnsavedChanges = () => {
		if (this.state.createNewFile) {
			const saveAndOpenFile = () => {
				this.props.updateFileState(false);
				this.save({loadFile: true});
			};
			return this.renderSaveFileForm(saveAndOpenFile);
		}

		const title = this.state.logoutAction ? 'save your work?' : 'save changes and open';
		const cancelText = this.state.logoutAction ? 'discard' : 'cancel';
		const okayText = this.state.logoutAction ? 'save' : 'open';
		const onCancel = () => {
			this.props.updateFileState(false);
			if (this.state.logoutAction) {
				this.props.saveFileName('');
				this.props.authLogout(this.props.userEmail);
			} else {
				this.setState({
					rewriteConfirmation: false,
					saveUnsavedChanges: false,
					rewriteFileName: null,
					loadFileNameAfterSave: ''
				});
			}
		};
		const onOkay = () => {
			if (this.props.fileName) {
				this.props.updateFileState(false);
				this.saveFile(this.props.fileName, true);
			} else {
				this.setState({createNewFile: true});
			}
		};

		return (
			<div className='modal-clear-canvas modal-inner-wrapper'>
				<h2 className='text-center'>{title}</h2>
				<div className='actions text-center'>
					<button
						className='btn btn-modal'
						onClick={onCancel}>
						{cancelText}
					</button>
					<button
						className='btn btn-modal'
						onClick={onOkay}>
						{okayText}
					</button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	const {
		documents: {
			documentsList
		},
		auth: {
			user
		},
		socket: {
			status: socketStatus
		},
		fileName: {
			fileName
		},
		fileState: {
			needSave
		},
	} = state;

	return {
		socketStatus,
		documentsList,
		currentDeviceUuid: user? user.device_uuid : null,
		needSave,
		fileName,
		modalData: state.modal.data || {},
		userEmail: user && user.email
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			modalClose,
			layerIsEmptyAllLayers,
			stateToObject,
			stateRestoreFromObject,
			stateToSaveObject,
			notificationShow,
			saveFileName,
			getDocuments,
			updateFileState,
			authLogout
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSaveOpen);
