import * as React from 'react';
import Line from '../../../shared/classes/Line';
import CurvedLine from '../../../shared/classes/CurvedLine';
import DiameterLine from '../../../shared/classes/DiameterLine';

export class LinesAsSVGElements extends React.Component {
	render() {
		return (
			<g className='sketch-lines'>
				{this.renderLines()}
				{this.renderLines(true)}
				{this.renderShapes()}
				{this.renderShapes(true)}
			</g>
		);
	}

	renderLines(isHidden = false) {
		let elements = [];
		let { isShowDiameter, lines } = this.props;

		for (let lineIndex = 0, lineLength = lines.length; lineIndex < lineLength; lineIndex++) {
			let line = lines[lineIndex];
			let isDiameterLine = line instanceof DiameterLine;

			if (line instanceof Line || line instanceof DiameterLine) {
				if (isDiameterLine && !isShowDiameter) {
					continue;
				}

				elements.push(
					<path
						d={line.toPath(true)}
						className={isHidden ? 'hidden' : ''}
						style={isDiameterLine ? { stroke: 'red' } : {}}
						key={`${isHidden ? 'hidden-line' : 'line'}-${lineIndex}`}
						strokeWidth={isHidden ? 10 : this.props.strokeWidth}
						onDoubleClick={event => (isDiameterLine ? () => {} : this.props.doubleClick(event, lineIndex))}
					/>
				);
			}

			if (line instanceof CurvedLine) {
				let curvedChunks = line.toChunkedPaths();

				for (let chunkIndex = 0, chunksLength = curvedChunks.length; chunkIndex < chunksLength; chunkIndex++) {
					elements.push(
						<path
							d={curvedChunks[chunkIndex]}
							className={isHidden ? 'hidden' : ''}
							key={`${isHidden ? 'hidden-curve' : 'curve'}-${lineIndex}-${chunkIndex}`}
							onDoubleClick={event => this.props.doubleClick(event, lineIndex, chunkIndex)}
							strokeWidth={isHidden ? 10 : this.props.strokeWidth}
						/>
					);
				}
			}
		}

		return elements;
	}

	renderShapes(isHidden) {
		return this.props.shapes.map((shape, i) => {
			let chunks = shape.toChunkedPath();

			if (chunks) {
				return chunks.map((path, j) => (
					<path
						d={path}
						className={isHidden ? 'hidden' : ''}
						key={`${isHidden ? 'hidden-shapePart' : 'shapePart'}-${i}-${j}`}
						strokeWidth={isHidden ? 10 : this.props.strokeWidth}
						onDoubleClick={event => {
							this.props.shapeDoubleClick(event, i, j);
						}}
					/>
				));
			}

			return null;
		});
	}
}
