import * as lineTypes from './Instruments';
import * as SketchTypes from './SketchTypes';
import Line from './Line';
import CurvedLine from './CurvedLine';
import AngleLine from './AngleLine';
import Rectangle from './Rectangle';
import Circle from './Circle';
import MagicWand from './MagicWand';
import MathHelper from '../MathHelper';
import MapHelper from '../MapHelper';
import * as d3 from 'd3-polygon';
import isEmpty from 'lodash.isempty';
import { gridSize } from '../../components/App/CanvasGrid/CanvasGrid';
import { displayLineLength } from '../reducers/settingsReducer';

export default class Sketch {
	constructor(lines = [], angleLines = [], shapes = []) {
		this.lines = [];
		this.shapes = [];
		this.angleLines = [];
		this.closed = false;
		this.type = null;
		this.angleClosed = false;
		this.currentLine = null;
		this.currentShape = null;
		this.controlPoints = [];
		this.shapeControls = [];
		this.controlPointsMapping = {};
		this.shapeControlsMapping = {};
		this.hasCurved = false;
		this.labelsVisible = displayLineLength;
		this.mask = null;

		for (const line of lines) {
			this.lines.push(line);
		}

		for (const line of angleLines) {
			this.angleLines.push(line);
		}

		for (const shape of shapes) {
			this.shapes.push(shape);
		}
	}

	setMask(mask) {
		this.mask = mask;
	}

	getMask() {
		return this.mask;
	}

	getControlPoints() {
		return this.controlPoints;
	}

	getShapeControls() {
		return this.shapeControls;
	}

	getLines() {
		let lines = this.lines;
		this.shapes.forEach(shape => {
			const shapeLines = shape.getLines();
			if (shapeLines) {
				lines = lines.concat(shapeLines);
			}
		});
		return lines;
	}

	getShapes() {
		return this.shapes;
	}

	getAngleLines() {
		return this.angleLines;
	}

	lineExists() {
		return this.lines.length > 0;
	}

	shapeExists() {
		return this.shapes.length > 0;
	}

	angleLineExists() {
		return this.angleLines.length > 0;
	}

	updateAnglePoint(angleIndex, point) {
		let line = this.angleLines[angleIndex[0]];

		if (line && line instanceof AngleLine) {
			line.updatePoint(angleIndex[1], point);
		}
	}

	removeControlPoint(pointIndex) {
		if (this.controlPointsMapping[pointIndex]) {
			let pointMap = this.controlPointsMapping[pointIndex].split('-'),
				lineIndex = parseInt(pointMap[0], 10),
				linePointIndex = parseInt(pointMap[1], 10),
				line = this.lines[lineIndex],
				indexNearLine,
				nearLine;
			const isLastOrFirstPoint = pointIndex === 0 || pointIndex === this.controlPoints.length - 1;

			if (pointMap[0] === 'circle') {
				this.shapes[pointMap[1]].removePoint(pointIndex);
				this.shapes[pointMap[1]].saveLatLngPoints();
			} else if (line instanceof Line) {
				if (!this.closed && isLastOrFirstPoint) {
					this.lines.splice(lineIndex, 1);
				} else {
					if (linePointIndex === 0) {
						indexNearLine = lineIndex > 0 ? lineIndex - 1 : this.lines.length - 1;
					} else {
						indexNearLine = lineIndex < this.lines.length - 1 ? lineIndex + 1 : 0;
					}

					nearLine = this.lines[indexNearLine];

					if (nearLine instanceof Line) {
						this.lines.splice(indexNearLine, 1);

						if (linePointIndex === 0) {
							line.start(nearLine.getStartPoint());
							line.startLatLng(nearLine.getStartLatLngPoint());
						} else {
							line.end(nearLine.getEndPoint());
							line.endLatLng(nearLine.getEndLatLngPoint());
						}

						this.lines.splice(lineIndex, 1, line);
					} else if (nearLine instanceof CurvedLine) {
						if (linePointIndex === 0) {
							nearLine.removeLastPoint();
							line.start(nearLine.getPoints()[nearLine.getPoints().length - 1]);
							line.startLatLng(nearLine.getLatLngPoints()[nearLine.getLatLngPoints().length - 1]);
						} else {
							nearLine.removeFirstPoint();
							line.end(nearLine.getPoints()[0]);
							line.endLatLng(nearLine.getLatLngPoints()[0]);
						}

						if (nearLine.getPoints().length < 3) {
							nearLine = new Line(
								nearLine.getPoints()[0],
								nearLine.getPoints()[1],
								nearLine.getLatLngPoints[0],
								nearLine.getLatLngPoints[1]
							);
						}

						this.lines.splice(indexNearLine, 1, nearLine);
						this.lines.splice(lineIndex, 1, line);
					}
				}
			} else if (line instanceof CurvedLine) {
				if (linePointIndex === 0 || linePointIndex === line.getPoints().length - 1) {
					if (linePointIndex === 0) {
						indexNearLine = lineIndex > 0 ? lineIndex - 1 : this.lines.length - 1;
						line.removeFirstPoint();
					} else {
						indexNearLine = lineIndex < this.lines.length - 1 ? lineIndex + 1 : 0;
						line.removeLastPoint();
					}
					if (this.closed) {
						if (this.lines.length === 1) {
							if (linePointIndex === 0) {
								line.updatePoint(line.getPoints().length - 1, line.getPoints()[0]);
								line.updateLatLngPoint(line.getLatLngPoints().length - 1, line.getLatLngPoints()[0]);
							} else {
								line.updatePoint(0, line.getPoints()[line.getPoints().length - 1]);
								line.updateLatLngPoint(0, line.getLatLngPoints()[line.getLatLngPoints().length - 1]);
							}
						} else {
							nearLine = this.lines[indexNearLine];

							if (nearLine instanceof Line) {
								if (linePointIndex === 0) {
									nearLine.end(line.getPoints()[0]);
									nearLine.endLatLng(line.getLatLngPoints()[0]);
								} else {
									nearLine.start(line.getPoints()[line.getPoints().length - 1]);
									nearLine.startLatLng(line.getLatLngPoints()[line.getLatLngPoints().length - 1]);
								}
							} else if (nearLine instanceof CurvedLine) {
								if (linePointIndex === 0) {
									nearLine.updatePoint(nearLine.getPoints().length - 1, line.getPoints()[0]);
									nearLine.updateLatLngPoint(
										nearLine.getLatLngPoints().length - 1,
										line.getLatLngPoints()[0]
									);
								} else {
									nearLine.updatePoint(0, line.getPoints()[line.getPoints().length - 1]);
									nearLine.updateLatLngPoint(
										0,
										line.getLatLngPoints()[line.getLatLngPoints().length - 1]
									);
								}
							}

							this.lines.splice(indexNearLine, 1, nearLine);
						}
					}
				} else {
					line.removePointByIndex(linePointIndex);
				}

				if (line.getPoints().length < 3) {
					line = new Line(
						line.getPoints()[0],
						line.getPoints()[1],
						line.getLatLngPoints()[0],
						line.getLatLngPoints()[1]
					);
				}

				this.lines.splice(lineIndex, 1, line);
			}

			this.refreshControlPoints();
		}
	}

	updateControlPoint(pointIndex, point, isShapeClosed = true) {
		let pointMap = this.controlPointsMapping[pointIndex].split('-');
		if (pointMap[0] === 'rect') {
			let rectangle = this.shapes[pointMap[1]];
			const pointIndex = Number(pointMap[2]);
			rectangle.updatePoint(pointIndex, point);
			this.refreshControlPoints();
		} else if (pointMap[0] === 'circle') {
			const circle = this.shapes[pointMap[1]];
			const pointIndex = Number(pointMap[2]);
			circle.updateVertex(pointIndex, point);
			circle.saveLatLngPoints();
			this.refreshControlPoints();
		} else {
			let lineIndex = parseInt(pointMap[0], 10);
			let linePointIndex = parseInt(pointMap[1], 10);

			this.updatePoint(lineIndex, linePointIndex, point, isShapeClosed);
		}
	}

	updateShapeControl(pointIndex, point, isShapeClosed) {
		let pointMap = this.shapeControlsMapping[pointIndex].split('-');
		if (pointMap[0] === 'rect') {
			let rectangle = this.shapes[pointMap[1]];
			rectangle.expand(point);
			this.closeSketch();
		}
	}

	activateShapeControl(pointIndex, point) {
		let pointMap = this.shapeControlsMapping[pointIndex].split('-');
		if (pointMap[0] === 'rect') {
			let rectangle = this.shapes[pointMap[1]];
			rectangle.expand(point);
			this.transformShapeToLines(rectangle);
			this.closeSketch();
		}
	}

	transformShapeToLines(shape) {
		const index = this.shapes.indexOf(shape);
		let shapeLines = shape.getLines();
		shapeLines.forEach(line => {
			line.startLatLng(MapHelper.point2LatLng(line.getStartPoint()));
			line.endLatLng(MapHelper.point2LatLng(line.getEndPoint()));
			this.lines.push(line);
		});
		this.shapes.splice(index, 1);
	}

	getPreviewByShapeControl(pointIndex, point) {
		let pointMap = this.shapeControlsMapping[pointIndex].split('-');
		if (pointMap[0] === 'rect') {
			const rectangle = this.shapes[pointMap[1]],
				preview = rectangle.getPreviewByShapeControl(point);

			this.shapeControls[pointIndex] = MathHelper.getCenterPoint(preview[2], preview[3]);

			return preview;
		}
	}

	updatePoint(lineIndex, linePointIndex, point, isShapeClosed) {
		let line = this.lines[lineIndex];

		//set diameter as line
		if (!line && this.shapes[0]) {
			line = this.shapes[0];
		}

		line.updatePoint(linePointIndex, point);

		// update prev line
		if (linePointIndex === 0) {
			if (lineIndex === 0) {
				if (isShapeClosed) {
					this.updateLineLastPoint(this.lines.length - 1, point);
				}
			} else {
				this.lines[lineIndex - 1].updatePoint(1, point);
			}
		}

		// update next line start point
		if (
			(line instanceof Line && linePointIndex === 1) ||
			(line instanceof CurvedLine && line.getPoints().length - 1 === linePointIndex)
		) {
			let nextLine = this.lines[lineIndex + 1];

			if (!nextLine && this.lines.length - 1 === lineIndex) {
				if (isShapeClosed) {
					this.lines[0].updatePoint(0, point);
				}
			} else {
				nextLine.updatePoint(0, point);
			}
		}

		this.refreshControlPoints();
	}

	updateSupportPoint(pointIndex, supportIndex, point) {
		let pointMap = this.controlPointsMapping[pointIndex].split('-');
		if (pointMap[0] === 'circle') {
			const circle = this.shapes[pointMap[1]];
			const pointIndex = Number(pointMap[2]);

			circle.updateSupportPoint(pointIndex, supportIndex, point);
			circle.saveLatLngPoints();
		}
	}

	updateAngleLatLngPoint(angleIndex, latLng) {
		let line = this.angleLines[angleIndex[0]];

		if (line && line instanceof AngleLine) {
			line.updateLatLngPoint(angleIndex[1], latLng);
		}
	}

	updateControlLatLngPoint(pointIndex, latLng, isShapeClosed = true) {
		let pointMap = this.controlPointsMapping[pointIndex].split('-');
		let lineIndex = parseInt(pointMap[0], 10);
		let linePointIndex = parseInt(pointMap[1], 10);

		this.updateLatLngPoint(lineIndex, linePointIndex, latLng, isShapeClosed);
	}

	updateLatLngPoint(lineIndex, linePointIndex, latLng, isShapeClosed) {
		let line = this.lines[lineIndex];

		if (!line) {
			return;
		}

		line.updateLatLngPoint(linePointIndex, latLng);

		// update prev line
		if (linePointIndex === 0) {
			if (lineIndex === 0) {
				if (isShapeClosed) {
					this.updateLineLastLatLngPoint(this.lines.length - 1, latLng);
				}
			} else {
				this.lines[lineIndex - 1].updateLatLngPoint(1, latLng);
			}
		}

		// update next line start point
		if (
			(line instanceof Line && linePointIndex === 1) ||
			(line instanceof CurvedLine && line.getPoints().length - 1 === linePointIndex)
		) {
			let nextLine = this.lines[lineIndex + 1];

			if (!nextLine && this.lines.length - 1 === lineIndex) {
				if (isShapeClosed) {
					this.lines[0].updateLatLngPoint(0, latLng);
				}
			} else {
				nextLine.updateLatLngPoint(0, latLng);
			}
		}

		this.refreshControlPoints();
	}

	updateLineLastPoint(lineIndex, newPoint) {
		let line = this.lines[lineIndex];

		if (line instanceof Line) {
			line.updatePoint(1, newPoint);
		}

		if (line instanceof CurvedLine) {
			line.updatePoint(line.getPoints().length - 1, newPoint);
		}
	}

	updateLineLastLatLngPoint(lineIndex, newLatLng) {
		let line = this.lines[lineIndex];

		if (line instanceof Line) {
			line.updateLatLngPoint(1, newLatLng);
		}

		if (line instanceof CurvedLine) {
			line.updateLatLngPoint(line.getLatLngPoints().length - 1, newLatLng);
		}
	}

	toPath(close = false) {
		let lastLineIndex = this.lines.length - 1;

		let path = '';
		this.getLines().forEach((line, index) => {
			if (close && index === lastLineIndex && line.getType() === lineTypes.STRAIGHT_LINE) {
				return;
			}

			path += line.toPath(!index);
		});

		if (this.currentLine && this.currentLine.getType() === lineTypes.CURVED_LINE) {
			path += this.currentLine.toPath(!this.lines.length);
		}

		this.getShapes().forEach(shape => {
			path += shape.toPath();
		});

		if (close) {
			path += 'Z';
		}

		return path;
	}

	refreshPoints() {
		this.lines.forEach(line => {
			if (line.getType() === lineTypes.STRAIGHT_LINE) {
				let startLatLng = line.getStartLatLngPoint(),
					endLatLng = line.getEndLatLngPoint();

				if (startLatLng && endLatLng) {
					let startPoint = MapHelper.latLng2Point(startLatLng),
						endPoint = MapHelper.latLng2Point(endLatLng);

					line.start(startPoint);
					line.end(endPoint);
				}
			} else if (line.getType() === lineTypes.CURVED_LINE) {
				let points = [];
				line.getLatLngPoints().forEach(latLng => {
					points.push(MapHelper.latLng2Point(latLng));
				});

				if (points.length) {
					line.points = points;
				}
			}
		});

		this.angleLines.forEach(line => {
			if (line.getType() === lineTypes.ANGLE_LINE) {
				let startLatLng = line.getStartLatLngPoint(),
					endLatLng = line.getEndLatLngPoint();

				if (startLatLng && endLatLng) {
					let startPoint = MapHelper.latLng2Point(startLatLng),
						endPoint = MapHelper.latLng2Point(endLatLng);

					line.start(startPoint);
					line.end(endPoint);
				}
			}
		});

		this.shapes.forEach(shape => {
			if (shape.getType() === lineTypes.CIRCLE_TOOL) {
				shape.updateFromLatLngPoints();
			} else if (shape.getType() === lineTypes.RECTANGLE_TOOL) {
				let startLatLng = shape.getStartLatLng(),
					endLatLng = shape.getEndLatLng();

				if (startLatLng && endLatLng) {
					let startPoint = MapHelper.latLng2Point(startLatLng),
						endPoint = MapHelper.latLng2Point(endLatLng);

					shape.start(startPoint);
					shape.end(endPoint);
				}
			}
		});
	}

	updatePointsByPan(pan) {
		this.lines.forEach(line => {
			if (line.getType() === lineTypes.STRAIGHT_LINE) {
				let start = line.getStartPoint(),
					end = line.getEndPoint();

				start = [start[0] - pan[0], start[1] - pan[1]];
				end = [end[0] - pan[0], end[1] - pan[1]];

				line.start(start);
				line.end(end);
			} else if (line.getType() === lineTypes.CURVED_LINE) {
				let points = [];
				line.getPoints().forEach(point => {
					points.push([point[0] - pan[0], point[1] - pan[1]]);
				});

				if (points.length) {
					line.points = points;
				}
			}
		});

		this.angleLines.forEach(line => {
			if (line.getType() === lineTypes.ANGLE_LINE) {
				let start = line.getStartPoint(),
					end = line.getEndPoint();

				start = [start[0] - pan[0], start[1] - pan[1]];
				end = [end[0] - pan[0], end[1] - pan[1]];

				line.start(start);
				line.end(end);
			}
		});
	}

	refreshControlPoints() {
		let controlPoints = [];
		let controlPointsMapping = {};
		let shapeControls = [];
		let shapeControlsMapping = [];
		const lastLineIndex = this.lines.length ? this.lines.length - 1 : 0;
		let index = 0;
		let shapeControlsIndex = 0;

		this.lines.forEach((line, lineIndex) => {
			let points;

			if (line.getType() === lineTypes.STRAIGHT_LINE) {
				points = line.getPoints();

				if (lineIndex === 0) {
					controlPoints.push(points[0]);
					controlPointsMapping[index] = lineIndex + '-0';
					index++;
				}

				if (!(lineIndex === lastLineIndex && this.closed)) {
					controlPoints.push(points[1]);
					controlPointsMapping[index] = lineIndex + '-1';
					index++;
				}
			}

			if (line.getType() === lineTypes.CURVED_LINE) {
				points = line.getPoints();
				const lastPointIndex = points.length - 1;

				points.forEach((point, pointIndex) => {
					if (pointIndex === 0 && lineIndex) {
						return;
					}

					if (!(lineIndex === lastLineIndex && pointIndex === lastPointIndex && this.closed)) {
						controlPoints.push(point);

						controlPointsMapping[index] = lineIndex + '-' + pointIndex;
						index++;
					}
				});
			}
		});

		this.shapes.forEach((shape, i) => {
			if (shape.getType() === lineTypes.RECTANGLE_TOOL) {
				if (shape.isCollapsed()) {
					shapeControls.push(shape.getMiddlePoint());
					shapeControlsMapping[shapeControlsIndex] = 'rect-' + i + '-' + 0;
					shapeControlsIndex++;

					controlPoints.push(shape.p1);
					controlPointsMapping[index] = 'rect-' + i + '-' + 1;
					index++;
					controlPoints.push(shape.p2);
					controlPointsMapping[index] = 'rect-' + i + '-' + 0;
					index++;
				} else {
					shape.getPoints().forEach((point, pointIndex) => {
						controlPoints.push(point);
						controlPointsMapping[index] = 'rect' + '-' + i + '-' + (pointIndex + 1);
						index++;
					});
				}
			} else if (shape.getType() === lineTypes.CIRCLE_TOOL) {
				shape.getVertexes().forEach((point, pointIndex) => {
					controlPoints.push(point);
					controlPointsMapping[index] = 'circle' + '-' + i + '-' + pointIndex;
					index++;
				});
			}
		});

		this.controlPoints = controlPoints;
		this.shapeControls = shapeControls;
		this.controlPointsMapping = controlPointsMapping;
		this.shapeControlsMapping = shapeControlsMapping;
	}

	getPointNeighbors(pointIndex) {
		let neighborIndexes = this.getPointNeighborsIndexes(pointIndex);

		let prevPoint = this.controlPoints[neighborIndexes.nextIndex],
			nextPoint = this.controlPoints[neighborIndexes.prevIndex];

		return {
			prev: prevPoint,
			next: nextPoint
		};
	}

	getPointNeighborsIndexes(pointIndex) {
		let pointsLength = this.controlPoints.length;

		let prevPointIndex = pointIndex - 1,
			nextPointIndex = pointIndex + 1;

		if (pointIndex === 0) {
			prevPointIndex = pointsLength - 1;
		} else if (pointIndex === pointsLength - 1) {
			nextPointIndex = 0;
		}

		return {
			prevIndex: prevPointIndex,
			nextIndex: nextPointIndex
		};
	}

	getFirstPoint() {
		let firstLine = this.lines[0];

		if (firstLine) {
			if (firstLine instanceof Line) {
				return firstLine.getStartPoint();
			}

			if (firstLine instanceof CurvedLine) {
				return firstLine.getPoints()[0];
			}
		} else {
			return this.getControlPoints()[0] || null;
		}
	}

	getFirstLatLngPoint() {
		let firstLine = this.lines[0];

		if (firstLine) {
			if (firstLine instanceof Line) {
				return firstLine.getStartLatLngPoint();
			}

			if (firstLine instanceof CurvedLine) {
				return firstLine.getLatLngPoints()[0];
			}
		}

		return null;
	}

	getLastPoint() {
		let lastLine = this.lines[this.lines.length - 1];

		if (lastLine) {
			if (lastLine instanceof Line) {
				return lastLine.getEndPoint();
			}

			if (lastLine instanceof CurvedLine) {
				let points = lastLine.getPoints();
				return points[points.length - 1];
			}
		}

		return null;
	}

	getLastLatLngPoint() {
		let lastLine = this.lines[this.lines.length - 1];

		if (lastLine) {
			if (lastLine instanceof Line) {
				return lastLine.getEndLatLngPoint();
			}

			if (lastLine instanceof CurvedLine) {
				let latLngPoints = lastLine.getLatLngPoints();
				return latLngPoints[latLngPoints.length - 1];
			}
		}

		return null;
	}

	setCurrentLine(currentLine) {
		this.currentLine = currentLine;
	}

	setCurrentShape(currentShape) {
		this.currentShape = currentShape;
	}

	push(line, closed = false) {
		if (this.type === null) {
			this.type = SketchTypes.LINES;
		} else if (this.type !== SketchTypes.LINES) {
			console.error('Unavailable to add line to non line sketch');
			return;
		}

		if (line instanceof AngleLine) {
			this.angleLines.push(line);

			if (this.angleLines.length > 1) {
				this.angleClosed = true;
			}

			return;
		}

		if (closed) {
			this.closed = true;
		}

		if (line instanceof CurvedLine) {
			this.hasCurved = true;
		}

		this.currentLine = null;
		this.lines.push(line);
		this.refreshControlPoints();
	}

	addLinesFromPolygon(points) {
		if (this.type === null) {
			this.type = SketchTypes.LINES;
		} else {
			console.error('Unavailable to add polygon to non empty sketch');
			return;
		}

		for (let i = 0; i < points.length - 1; i++) {
			let line = new Line(points[i], points[i + 1]);
			this.lines.push(line);
		}

		this.closed = true;

		this.refreshControlPoints();
	}

	addShape(shape) {
		if (this.type === null) {
			this.type = SketchTypes.SHAPE;
		} else {
			console.error('Unavailable to add shape to non shape sketch');
			return;
		}

		this.shapes.push(shape);
		this.currentShape = null;
		if (shape instanceof Rectangle) {
			/*shape.getLines().forEach( (line) => {
               this.push(line);
            });*/
		}
		this.drawingShape = true;
		this.refreshControlPoints();
	}

	closeSketch() {
		this.closed = true;
		this.drawingShape = false;
		this.refreshControlPoints();
	}

	getArea(scale = 1, prefix = true) {
		if (this.isCircle()) {
			const shape = this.shapes[0];
			//we calculate the area of the circle according to the formula PiR^2
			if (shape.vertexes.length === 2) {
				const d = MathHelper.roundLineLength(shape.vertexes, scale, gridSize);
				return MathHelper.round(Math.PI * Math.pow(d / 2, 2));
			}

			const circlePoints = shape.toPolygonPoints();
			return circlePoints.length
				? MathHelper.round(
						(Math.abs(d3.polygonArea(circlePoints)) * Math.pow(scale, 2)) / Math.pow(gridSize, 2)
				  )
				: 0;
		} else {
			let prefixSign = prefix && this.hasCurved ? '~' : '';
			//we consider the area of the rectangle according to the formula a*b
			if (this.type === SketchTypes.SHAPE && this.controlPoints.length === 4) {
				let a = MathHelper.roundLineLength([this.controlPoints[0], this.controlPoints[1]], scale, gridSize),
					b = MathHelper.roundLineLength([this.controlPoints[1], this.controlPoints[2]], scale, gridSize),
					c = MathHelper.roundLineLength([this.controlPoints[2], this.controlPoints[3]], scale, gridSize),
					d = MathHelper.roundLineLength([this.controlPoints[3], this.controlPoints[0]], scale, gridSize);

				if (a === c && b === d) {
					return prefixSign + MathHelper.round(a * b);
				}
			}

			return prefixSign + this.controlPoints.length
				? MathHelper.round(
						(Math.abs(d3.polygonArea(this.controlPoints)) * Math.pow(scale, 2)) / Math.pow(gridSize, 2)
				  )
				: 0;
		}
	}

	getCenterPoint() {
		let points = this.controlPoints;

		if (points.length === 2) {
			return MathHelper.getCenterPoint(...points);
		} else {
			return d3.polygonCentroid(points);
		}
	}

	getPerimeter(scale = 1, prefix = true) {
		if (this.isCircle()) {
			const shape = this.shapes[0];
			//we calculate the area of the circle according to the formula 2PiR
			if (shape.vertexes.length === 2) {
				const d = MathHelper.roundLineLength(shape.vertexes, scale, gridSize);
				return MathHelper.round(d * Math.PI);
			}

			const circlePoints = shape.toPolygonPoints();
			return circlePoints.length ? MathHelper.getPerimeter(circlePoints, scale, gridSize) : 0;
		} else if (this.controlPoints.length < 1) {
			return 0;
		} else {
			return (
				(prefix && this.hasCurved ? '~' : '') +
				(this.controlPoints.length ? MathHelper.getPerimeter(this.controlPoints, scale, gridSize) : 0)
			);
		}
	}

	isCircle() {
		return this.type === SketchTypes.SHAPE && this.shapes[0] && this.shapes[0].getType() === lineTypes.CIRCLE_TOOL;
	}

	getSupportPoints(pointIndex) {
		if (this.isCircle()) {
			return this.shapes[0].getSupportPointsOfPoint(pointIndex);
		}

		return [];
	}

	convertForSave() {
		return {
			closed: this.closed,
			type: this.type,
			angleClosed: this.angleClosed,
			hasCurved: this.hasCurved,
			labelsVisible: this.labelsVisible,
			lines: this.lines.map(line => {
				return {
					type: line.getType(),
					points: [...line.getPoints()],
					latLngPoints: [...line.getLatLngPoints()],
					isConstrained: line.isConstrained
				};
			}),
			angleLines: this.angleLines.map(line => {
				return {
					type: line.getType(),
					points: [...line.getPoints()],
					latLngPoints: [...line.getLatLngPoints()]
				};
			}),
			shapes: this.shapes.map(shape => {
				return {
					type: shape.getType(),
					params: shape.toParams()
				};
			}),
			mask: (() => {
				if (this.mask === null) {
					return this.mask;
				} else {
					return this.mask.toObject();
				}
			})()
		};
	}

	static restoreFromDump(dump) {
		if (isEmpty(dump)) {
			return new this();
		}

		let lines = [],
			angleLines = [],
			shapes = [];
		if (dump.lines) {
			dump.lines.forEach(line => {
				if (line.type === lineTypes.STRAIGHT_LINE) {
					let newLine = new Line(line.points[0], line.points[1], line.latLngPoints[0], line.latLngPoints[1]);
					newLine.setConstrainedLine(line.isConstrained);
					lines.push(newLine);
				}

				if (line.type === lineTypes.CURVED_LINE) {
					lines.push(new CurvedLine(line.points, line.latLngPoints));
				}
			});
		}

		if (dump.angleLines) {
			dump.angleLines.forEach(line => {
				angleLines.push(
					new AngleLine(line.points[0], line.points[1], line.latLngPoints[0], line.latLngPoints[1])
				);
			});
		}

		if (dump.shapes) {
			dump.shapes.forEach(shape => {
				if (shape.type === lineTypes.RECTANGLE_TOOL) {
					shapes.push(
						new Rectangle(
							shape.params.p1,
							shape.params.p2,
							shape.params.p3,
							shape.params.p4,
							shape.params.startLatLng,
							shape.params.endLatLng
						)
					);
				} else if (shape.type === lineTypes.CIRCLE_TOOL) {
					shapes.push(
						new Circle(
							shape.params.vertexes,
							shape.params.center,
							shape.params.supportPoints,
							shape.params.initialPoints,
							shape.params.latLngPoints,
							shape.params.latLngSupports
						)
					);
				}
			});
		}

		let newSketch = new this(lines, angleLines, shapes);

		newSketch.angleClosed = dump.angleClosed;
		newSketch.hasCurved = dump.hasCurved;
		newSketch.labelsVisible = dump.labelsVisible;
		newSketch.type = dump.type;

		newSketch.drawingShape = dump.drawingShape;

		if (dump.closed) {
			newSketch.closeSketch();
		} else {
			newSketch.refreshControlPoints();
		}

		if (dump.mask) {
			newSketch.mask = MagicWand.restoreFromObject(dump.mask);
		}

		return newSketch;
	}
}
