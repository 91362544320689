import * as types from '../constants/previewConstants';

export default function previewReducer(
	state = {
		display: false,
		from: null,
		to: null,
		shapeShow: false,
		shapePreview: null
	},
	action
) {
	switch (action.type) {
		case types.DRAW_AREA_PREVIEW_SHOW: {
			return {
				...state,
				display: true,
				from: action.payload.from,
				to: action.payload.point
			};
		}

		case types.DRAW_AREA_PREVIEW_MOVE: {
			if (!state.display) {
				return state;
			}

			return {
				...state,
				to: action.payload.point
			};
		}

		case types.DRAW_AREA_PREVIEW_HIDE: {
			return {
				...state,
				display: false
			};
		}

		case types.DRAW_AREA_SHAPE_PREVIEW_START: {
			return {
				...state,
				shapeShow: true
			};
		}

		case types.DRAW_AREA_SHAPE_PREVIEW_MOVE: {
			if (!state.shapeShow) {
				return state;
			}
			return {
				...state,
				shapePreview: action.payload.shapePreview
			};
		}

		case types.DRAW_AREA_SHAPE_PREVIEW_END: {
			return {
				...state,
				shapeShow: false,
				shapePreview: null
			};
		}

		default: {
			return state;
		}
	}
}
