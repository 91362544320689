import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { modalClose, modalOpen } from '../../shared/reducers/ModalReducer';

import ModalImageUpload, { MODAL_IMAGE_UPLOAD } from './Modals/ModalImageUpload/ModalImageUpload';
import { MODAL_PDF_PREVIEW } from './Modals/ModalPDFPreview/ModalPDFPreview';
import ModalPDFPreview from './Modals/ModalPDFPreview/ModalPDFPreview';
import { MODAL_DELETE_LAYER } from './Modals/ModalDeleteLayer/ModalDeleteLayer';
import ModalDeleteLayer from './Modals/ModalDeleteLayer/ModalDeleteLayer';
import { MODAL_TOGGLE_MAP } from './Modals/ModalToggleMap/ModalToggleMap';
import ModalToggleMap from './Modals/ModalToggleMap/ModalToggleMap';
import { MODAL_IMAGE_UPLOAD_CANVAS_RESET } from './Modals/ModalImageUpload/ModalImageUploadCanvasReset';
import ModalImageUploadCanvasReset from './Modals/ModalImageUpload/ModalImageUploadCanvasReset';
import { MODAL_SETTINGS } from './Modals/ModalSettings/ModalSettings';
import ModalSettings from './Modals/ModalSettings/ModalSettings';
import { MODAL_LISTENING } from './Modals/ModalListening/ModalListening';
import ModalListening from './Modals/ModalListening/ModalListening';
import { MODAL_SAVE_OPEN } from './Modals/ModalSaveOpen/ModalSaveOpen';
import ModalSaveOpen from './Modals/ModalSaveOpen/ModalSaveOpen';
import { MODAL_CLEAR_CANVAS } from './Modals/ModalClearCanvas/ModalClearCanvas';
import ModalClearCanvas from './Modals/ModalClearCanvas/ModalClearCanvas';
import { MODAL_LOADER } from './Modals/ModalLoader/ModalLoader';
import ModalLoader from './Modals/ModalLoader/ModalLoader';
import { MODAL_CONFIRM_WAND } from './Modals/ModalConfirmWand/ModalConfirmWand';
import ModalConfirmWand from './Modals/ModalConfirmWand/ModalConfirmWand';
import { MODAL_PAYPERDAY_ACTIVATE } from './Modals/ModalPayPerDayNotification/ModalPayPerDayActivate';
import ModalPayPerDayActivate from './Modals/ModalPayPerDayNotification/ModalPayPerDayActivate';
import { MODAL_PAYPERDAY_EXTEND } from './Modals/ModalPayPerDayNotification/ModalPayPerDayExtend';
import ModalPayPerDayExtend from './Modals/ModalPayPerDayNotification/ModalPayPerDayExtend';
import { MODAL_PAYPERDAY_CONTINUE } from './Modals/ModalPayPerDayNotification/ModalPayPerDayContinue';
import ModalPayPerDayContinue from './Modals/ModalPayPerDayNotification/ModalPayPerDayContinue';
import { MODAL_CLIPBOARD_CANVAS_RESET } from '../App/ClipBoardImport/ModalClipboardCanvasReset';
import ModalClipboardCanvasReset from '../App/ClipBoardImport/ModalClipboardCanvasReset';
import { MODAL_EXPORT } from './Modals/ModalExport/ModalExport';
import ModalExport from './Modals/ModalExport/ModalExport';
import { MODAL_REGISTRATION } from './Modals/ModalRegistration/ModalRegistration';
import ModalRegistration from './Modals/ModalRegistration/ModalRegistration';
import ModalInfo, { MODAL_INFO } from './Modals/ModalInfo/ModalInfo';
import { MODAL_EDUCATOR_DASHBOARD } from './Modals/ModalEducatorDashboard';
import ModalEducatorDashboard from './Modals/ModalEducatorDashboard/ModalEducatorDashboard';
import ModalSaveOpenStudent, { MODAL_SAVE_OPEN_STUDENT } from './Modals/ModalSaveOpenStudent/ModalSaveOpenStudent';

class ModalContainer extends React.Component {
	closeModal = () => {
		const { modalData, modalClose } = this.props;

		if (modalData.onClose) {
			return modalData.onClose();
		}

		modalClose();
	}

	render() {
		if (this.props.activeModal === null) {
			return null;
		}

		let componentClassNames = ['modal-component'],
			classNames = ['modal-container'],
			closeModal = true;

		if (
			[MODAL_DELETE_LAYER, MODAL_TOGGLE_MAP, MODAL_IMAGE_UPLOAD_CANVAS_RESET, MODAL_CLEAR_CANVAS].includes(
				this.props.activeModal
			)
		) {
			classNames.push('alert');
		}

		if ([MODAL_SETTINGS, MODAL_LISTENING, MODAL_CONFIRM_WAND, MODAL_EXPORT].includes(this.props.activeModal)) {
			classNames.push('dialog');
		}

		if ([MODAL_SAVE_OPEN, MODAL_EDUCATOR_DASHBOARD, MODAL_SAVE_OPEN_STUDENT].includes(this.props.activeModal)) {
			classNames.push('modal');
		}

		if ([MODAL_LOADER].includes(this.props.activeModal)) {
			classNames.push('loader');
			componentClassNames.push('dark');
			closeModal = false;
		}

		if (
			[MODAL_PAYPERDAY_ACTIVATE, MODAL_PAYPERDAY_EXTEND, MODAL_PAYPERDAY_CONTINUE].includes(
				this.props.activeModal
			)
		) {
			classNames.push('ppd');
			closeModal = false;
		}

		if ([MODAL_REGISTRATION].includes(this.props.activeModal)) {
			classNames.push('registration');
		}

		if ([MODAL_EDUCATOR_DASHBOARD].includes(this.props.activeModal)) {
			closeModal = false;
		}

		return (
			<div className={componentClassNames.join(' ')}>
				<div className={classNames.join(' ')}>
					{closeModal && <i className='icon icon-close_icn close-modal' onClick={this.closeModal} />}
					{this.isModalActive(MODAL_IMAGE_UPLOAD) && <ModalImageUpload user={this.props.user} />}
					{this.isModalActive(MODAL_PDF_PREVIEW) && <ModalPDFPreview />}
					{this.isModalActive(MODAL_DELETE_LAYER) && <ModalDeleteLayer />}
					{this.isModalActive(MODAL_TOGGLE_MAP) && <ModalToggleMap />}
					{this.isModalActive(MODAL_IMAGE_UPLOAD_CANVAS_RESET) && <ModalImageUploadCanvasReset />}
					{this.isModalActive(MODAL_SETTINGS) && <ModalSettings />}
					{this.isModalActive(MODAL_LISTENING) && <ModalListening />}
					{this.isModalActive(MODAL_SAVE_OPEN) && <ModalSaveOpen />}
					{this.isModalActive(MODAL_SAVE_OPEN_STUDENT) && <ModalSaveOpenStudent />}
					{this.isModalActive(MODAL_CLEAR_CANVAS) && <ModalClearCanvas />}
					{this.isModalActive(MODAL_LOADER) && <ModalLoader />}
					{this.isModalActive(MODAL_CONFIRM_WAND) && <ModalConfirmWand />}
					{this.isModalActive(MODAL_PAYPERDAY_ACTIVATE) && <ModalPayPerDayActivate />}
					{this.isModalActive(MODAL_PAYPERDAY_EXTEND) && <ModalPayPerDayExtend />}
					{this.isModalActive(MODAL_PAYPERDAY_CONTINUE) && <ModalPayPerDayContinue />}
					{this.isModalActive(MODAL_EXPORT) && <ModalExport />}
					{this.isModalActive(MODAL_CLIPBOARD_CANVAS_RESET) && <ModalClipboardCanvasReset />}
					{this.isModalActive(MODAL_REGISTRATION) && <ModalRegistration modalClose={this.closeModal} />}
					{this.isModalActive(MODAL_INFO) && <ModalInfo />}
					{this.isModalActive(MODAL_EDUCATOR_DASHBOARD) && <ModalEducatorDashboard />}
				</div>
			</div>
		);
	}

	isModalActive(modalId) {
		return modalId === this.props.activeModal;
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		activeModal: state.modal.activeModal,
		modalData: state.modal.data || {},
		user: state.auth.user
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			modalOpen,
			modalClose
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
