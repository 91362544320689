import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { modalClose, modalOpen } from '../../../../shared/reducers/ModalReducer';
import { MODAL_LOADER } from '../ModalLoader/ModalLoader';
import { authLogout } from '../../../../shared/actions/authActions';
import ApiHelper from '../../../../shared/ApiHelper';
import { notificationShow } from '../../../../shared/actions/notificationActions';
import { notificationTypes } from '../../../../shared/constants/notificationConstants';
import get from 'lodash/get';

export const MODAL_PAYPERDAY_ACTIVATE = 'MODAL_PAYPERDAY_ACTIVATE';

class ModalPayPerDayActivate extends React.Component {
	render() {
		const price = get(this.props, 'auth.user.subscription.payPerDay.price');

		return (
			<div className='modal-ppd-activate modal-inner-wrapper'>
				<h2 className='text-center'>pay per day</h2>
				<p className='text-center'>Use SketchAndCalc for the next 24hrs for {price}</p>
				<div className='text-center'>
					<button className='btn btn-modal' onClick={() => this.props.authLogout()}>
						logout
					</button>
					<button
						className='btn btn-modal'
						onClick={() => {
							this.props.modalOpen(MODAL_LOADER);
							ApiHelper.reportUsage()
								.then(result => {
									if (result.data.success) {
										this.props.modalClose();
									} else {
										this.props.notificationShow(result.data.message, notificationTypes.DANGER);
										this.props.modalOpen(MODAL_PAYPERDAY_ACTIVATE);
									}
								})
								.catch(e => {
									this.props.notificationShow(e.response.data.message, notificationTypes.DANGER);
									this.props.modalOpen(MODAL_PAYPERDAY_ACTIVATE);
								});
						}}>
						confirm
					</button>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			authLogout,
			modalClose,
			modalOpen,
			notificationShow
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalPayPerDayActivate);
