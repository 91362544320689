import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { modalClose } from '../../../../shared/reducers/ModalReducer';
import { layerDeleteCurrentSketch } from '../../../../shared/actions/layerActions';
import get from 'lodash/get';

export const MODAL_PAYPERDAY_EXTEND = 'MODAL_PAYPERDAY_EXTEND';

class ModalPayPerDayExtend extends React.Component {
	render() {
		const price = get(this.props, 'auth.user.subscription.payPerDay.price');

		return (
			<div className='modal-ppd-extend modal-inner-wrapper'>
				<h2 className='text-center'>extend period?</h2>
				<p className='text-center'>Times up... continue for the next 24hrs for {price}</p>
				<div className='text-center'>
					<button className='btn btn-modal' onClick={this.props.modalClose}>
						logout
					</button>
					<button
						className='btn btn-modal'
						onClick={() => {
							//this.props.layerDeleteCurrentSketch();
							this.props.modalClose();
						}}>
						confirm
					</button>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			layerDeleteCurrentSketch,
			modalClose
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalPayPerDayExtend);
