const CLIPBOARD_SET_FILE = 'CLIPBOARD_SET_FILE';
const CLIPBOARD_CONFIRM_LOAD = 'CLIPBOARD_CONFIRM_LOAD';
const CLIPBOARD_RESET = 'CLIPBOARD_RESET';

export function clipboardSetFile(file, isPdf) {
	return {
		type: CLIPBOARD_SET_FILE,
		payload: { file, isPdf }
	};
}

export function clipboardConfirmLoad() {
	return {
		type: CLIPBOARD_CONFIRM_LOAD
	};
}

export function clipboardReset() {
	return {
		type: CLIPBOARD_RESET
	};
}

const initState = {
	file: null,
	isPdf: false,
	confirmed: false
};

export function clipboardReducer(state = initState, action) {
	switch (action.type) {
		case CLIPBOARD_RESET: {
			return { file: null, isPdf: false, confirmed: false };
		}
		case CLIPBOARD_CONFIRM_LOAD: {
			return { ...state, confirmed: true };
		}
		case CLIPBOARD_SET_FILE: {
			return {
				file: action.payload.file,
				isPdf: action.payload.isPdf,
				confirmed: false
			};
		}
		default:
			return state;
	}
}
