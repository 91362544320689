import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { helpActivateLineLengthsLabels } from '../../../shared/actions/helpActions';
import { svgEl } from '../../App/DrawArea/DrawArea';

export let renderLineLengthsLabels;

class LineLengthsLabels extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			bounds: null
		};
	}

	componentWillMount() {
		renderLineLengthsLabels = this.forceRender.bind(this);
	}

	componentWillUnmount() {
		renderLineLengthsLabels = null;
	}

	forceRender(bounds) {
		this.setState({ bounds });
	}

	render() {
		if (!this.state.bounds || !svgEl) {
			return null;
		}

		const svgBounds = svgEl.getBoundingClientRect(),
			style = {
				left: this.state.bounds.left + this.state.bounds.width / 2 - 275 / 2
			};

		let arrow = 'arrow-bottom';

		if (this.state.bounds.top - svgBounds.top < 230) {
			style.top = this.state.bounds.top + this.state.bounds.height - svgBounds.top + 15;
			arrow = 'arrow-top';
		} else {
			style.top = this.state.bounds.top - svgBounds.top - 230;
		}

		return (
			<div style={style} className={`contextual-modal ${arrow}`}>
				<h3 className='title'>Line Lengths</h3>
				<div className='content'>
					Labels getting in the way?
					<div className='pt-2 pb-1'>Switch off labels from Settings</div>
				</div>
				<div className='actions'>
					<button onClick={() => this.props.helpActivateLineLengthsLabels()} className='btn btn-sm btn-modal'>
						ok
					</button>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			helpActivateLineLengthsLabels
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(LineLengthsLabels);
