import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import InputMeasure, { isInputMeasure } from '../InputMeasure/InputMeasure';
import * as scaleActions from '../../../shared/actions/scaleControlActions';
import { MeasureSelect } from '../MeasureSelect/MeasureSelect';

class ScaleControl extends React.Component {
	render() {
		return (
			<div className='scale-control'>
				<span className='title'>scale</span>
				{this.props.manual && (
					<button
						disabled={this.props.disabled}
						className='btn icon icon-up_arrow hover-pointer'
						onClick={() =>
							isInputMeasure(this.props.measure) && this.InputMeasure
								? this.InputMeasure.increaseScaleValue()
								: this.props.incraseScaleValue()
						}
					/>
				)}
				{this.props.manual && (
					<button
						disabled={this.props.disabled}
						className='btn icon icon-down_arrow hover-pointer'
						onClick={() =>
							isInputMeasure(this.props.measure) && this.InputMeasure
								? this.InputMeasure.decreaseScaleValue()
								: this.props.decraseScaleValue()
						}
					/>
				)}
				<span className='base-unit'>1:</span>
				{isInputMeasure(this.props.measure) && !this.props.disabled && this.props.isFractions ? (
					<InputMeasure
						ref={instance => {
							this.InputMeasure = instance;
						}}
						value={this.props.value}
						measure={this.props.measure}
						afterConvert={value => {
							this.props.scaleSetValue(value);
							this.props.scaleSetTextValue(value);
						}}
						disabled={this.props.disabled}
					/>
				) : (
					<input
						type='number'
						className='scale-multiple text-center'
						value={this.props.text}
						onChange={this.onChange.bind(this)}
						onBlur={this.onBlur.bind(this)}
						disabled={this.props.disabled}
					/>
				)}
				<MeasureSelect measure={this.props.measure} shortText={false} />
			</div>
		);
	}

	onChange(event) {
		let scaleValue = Number.parseFloat(event.target.value);

		if (isNaN(scaleValue) || scaleValue < 0.1) {
			scaleValue = 0.1;
		}

		this.props.scaleSetValue(scaleValue);
		this.props.scaleSetTextValue(event.target.value);
	}

	onBlur() {
		this.props.scaleSetTextValue(this.props.value);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		value: state.scale.value,
		text: state.scale.text,
		measure: state.scale.measure,
		manual: state.scale.manual,
		disabled: state.map.visible,
		isFractions: state.settings.imperial === 'fractions'
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ ...scaleActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ScaleControl);
