import cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';
import ApiHelper from './ApiHelper';

let token = null;
let decode = null;
let locked = false;

export function jwt({ getState }) {
	return next => action => {
		if (getState().auth.isAuthenticated) {
			if (!token) {
				token = cookie.get('accessToken');
			}

			if (token && !locked) {
				if (!decode) {
					decode = jwtDecode(token);
				}

				if (decode && decode.exp) {
					const difference = Math.round(decode.exp - Date.now() / 1000);

					if (difference < 86400) {
						locked = true;

						ApiHelper.refreshToken()
							.then(response => {
								if (response.data.success) {
									cookie.set('accessToken', response.data.accessToken, { expires: 365 });
									ApiHelper.updateAccessToken(response.data.accessToken);
									token = response.data.accessToken;
									decode = jwtDecode(token);
								}

								locked = false;
							})
							.catch(() => {
								locked = false;
							});
					}
				}
			}
		}

		return next(action);
	};
}
