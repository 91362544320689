const MAP_CONTROL_CHANGE_TYPE = 'MAP_CONTROL_CHANGE_TYPE';
const MAP_CONTROL_RESET = 'MAP_CONTROL_RESET';

const MAP_TYPE = 'map-type';
const GRID_VISIBILITY = 'grid-visibility';
const FIND_BY_COORDINATE = 'find-by-coordinate';
const FIND_BY_ADDRESS = 'find-by-address';

export const mapControlTypes = {
	MAP_TYPE,
	GRID_VISIBILITY,
	FIND_BY_COORDINATE,
	FIND_BY_ADDRESS
};

export function mapControlChangeType(type) {
	return {
		type: MAP_CONTROL_CHANGE_TYPE,
		payload: { type }
	};
}

export function mapControlReset() {
	return { type: MAP_CONTROL_RESET };
}

const initState = {
	type: null
};

export default function(state = { ...initState }, action) {
	switch (action.type) {
		case MAP_CONTROL_CHANGE_TYPE: {
			return { ...state, type: action.payload.type };
		}
		case MAP_CONTROL_RESET: {
			return { ...initState };
		}
		default: {
			return state;
		}
	}
}
