import store from '../store';
import * as types from '../constants/drawAreaConstants';
import * as previewActions from '../actions/previewActions';
import * as instrumentsTypes from '../classes/Instruments';
import { getEventPoint } from '../classes/pointsFunc';
import { noteAdd, noteEndEdit, noteMove, noteReset } from './NoteActions';
import { imageEndDrag, imageMove, imageReset, imageStartDrag } from './ImageActions';
import { scaleLineEnd, scaleLineReset, scaleLineStart, scaleReset } from './scaleControlActions';
import {
	sketchMouseDown,
	sketchMouseMove,
	sketchMouseUp,
	sketchReset,
	sketchSetCurrentMask,
	sketchAddToMagicWandMask,
	sketchSubFromMagicWandMask
} from './SketchActions';
import { instrumentReset } from '../reducers/instrumentReducer';
import { zoomReset } from './zoomActions';
import { historyReset } from './historyActions';
import { mapMouseDown, mapMouseMove, mapMouseUp, mapReset } from './mapActions';
import MapHelper from '../MapHelper';
import { mapControlReset } from '../reducers/MapControlReducer';
import { instrumentMenuReset } from '../reducers/instrumentMenuReducer';
import { layerReset } from './layerActions';
import { offsetReset } from '../reducers/offsetReducer';
import MagicWand from '../classes/MagicWand';
import { notificationShow } from './notificationActions';
import { notificationTypes } from '../constants/notificationConstants';
import Sketch from '../classes/Sketch';
import { sketchUpdateSketch } from './SketchActions';
import CurvedLine from '../classes/CurvedLine';
import Rectangle from '../classes/Rectangle';
import Line from '../classes/Line';
import { layerLabelReset } from '../reducers/LayerLabelReducer';


export function drawAreaMouseDown(event) {
	return dispatch => {
		const state = store.getState();
		const scaledPoint = getEventPoint(event);

		if (state.instrumentMenu.mouseDown) {
			return;
		}

		if (state.image.dynamicImage) {
			dispatch(scaleLineReset());
			if (state.instrument === instrumentsTypes.PAN_MOVE) {
				if (event.nativeEvent.target.nodeName === 'image') {
					dispatch(imageStartDrag(scaledPoint));
				}
			} else if (state.instrument === instrumentsTypes.STRAIGHT_LINE) {
				dispatch(previewActions.previewShow(scaledPoint, scaledPoint));
				dispatch(scaleLineStart(scaledPoint));
			}
			dispatch(drawAreaSetMouseDown(true));

			return;
		}

		if (!state.drawArea.visible) {
			return;
		}

		let sketch = state.sketchState.sketch,
			startPoint = sketch.getLastPoint();

		dispatch(mapMouseDown(scaledPoint));

		if (sketch.closed || sketch.angleClosed || state.layers.showAllLayers) {
			return;
		}

		if (
			[instrumentsTypes.CONSTRAINED_LINE, instrumentsTypes.STRAIGHT_LINE, instrumentsTypes.CURVED_LINE].includes(
				state.instrument
			) &&
			sketch.angleLineExists()
		) {
			return;
		}

		if (state.instrument === instrumentsTypes.ANGLE_LINE && sketch.lineExists()) {
			return;
		}

		dispatch(sketchMouseDown(scaledPoint));

		dispatch(drawAreaSetMouseDown(true));

		if (!sketch.closed) {
			dispatch(previewActions.previewShow(scaledPoint, startPoint ? startPoint : scaledPoint));
		}
	};
}

export function drawAreaMouseMove(event) {
	return dispatch => {
		const point = getEventPoint(event);

		let { drawArea, notes, instrument, instrumentMenu, image, sketchState, map } = store.getState(),
			{ mouseDown, visible, shouldDragLayer } = drawArea;

		if (shouldDragLayer) {
			const { sketch } = sketchState;
			const convertedSketch = sketch.convertForSave();
			const newSketch = Sketch.restoreFromDump(convertedSketch);
			const [centerX, centerY] = newSketch.getCenterPoint();
			const [mapCenterX, mapCenterY] = MapHelper.point2LatLng(newSketch.getCenterPoint());
			const [mapPointX, mapPointY] = MapHelper.point2LatLng(point);

			const deltaX = point[0] - centerX;
			const deltaY = point[1] - centerY;
			const mapDeltaX = mapPointX - mapCenterX;
			const mapDeltaY = mapPointY - mapCenterY;

			newSketch.lines.map(line => {
				const newPoints = [];
				const newMapPoints = [];

				if (line instanceof Line || line instanceof Rectangle) {
					line.startPoint = [line.startPoint[0] + deltaX, line.startPoint[1] + deltaY];
					line.endPoint = [line.endPoint[0] + deltaX, line.endPoint[1] + deltaY];

					if (map.visible) {
						line.startLatLngPoint = [
							line.startLatLngPoint[0] + mapDeltaX,
							line.startLatLngPoint[1] + mapDeltaY
						];
						line.endLatLngPoint = [line.endLatLngPoint[0] + mapDeltaX, line.endLatLngPoint[1] + mapDeltaY];
					}
				}

				if (line instanceof CurvedLine) {
					for (const [x, y] of line.points) {
						newPoints.push([x + deltaX, y + deltaY]);
					}

					if (map.visible) {
						for (const [x, y] of line.latLngPoints) {
							newMapPoints.push([x + mapDeltaX, y + mapDeltaY]);
						}
					}

					line.points = newPoints;
					line.latLngPoints = newMapPoints;
				}

				return line;
			});

			newSketch.shapes.map(shape => {
				const newVertexes = [];
				const newMapPoints = [];
				const newMapSupportPoints = [];

				for (const [x, y] of shape.vertexes) {
					newVertexes.push([x + deltaX, y + deltaY]);
				}

				if (map.visible) {
					for (const [x, y] of shape.latLngPoints) {
						newMapPoints.push([x + mapDeltaX, y + mapDeltaY]);
					}
				}

				if (map.visible && shape.latLngSupports && shape.latLngSupports.length > 0) {
					shape.latLngSupports.forEach(point => {
						if (point) {
							const temp = [];

							for (const item of point) {
								if (item) {
									temp.push([item[0] + mapDeltaX, item[1] + mapDeltaY]);
								} else {
									temp.push(item);
								}
							}

							newMapSupportPoints.push(temp);
						} else {
							newMapSupportPoints.push(null);
						}
					});
				}

				shape.vertexes = newVertexes;
				shape.latLngPoints = newMapPoints;
				shape.latLngSupports = newMapSupportPoints;

				return shape;
			});

			dispatch(sketchUpdateSketch(newSketch));
		}

		if (instrumentMenu.mouseDown) {
			return;
		}

		if (notes.movableNote !== null) {
			return dispatch(noteMove(event));
		}

		dispatch(mapMouseMove(point));

		if (!mouseDown) {
			return;
		}

		if (image.dynamicImage) {
			if (instrument === instrumentsTypes.PAN_MOVE) {
				if (image.dragActive) {
					dispatch(imageMove(point));
				}
			} else if (instrument === instrumentsTypes.STRAIGHT_LINE) {
				dispatch(previewActions.previewMove(point));
			}

			return;
		}

		if (!visible) {
			return;
		}

		if (!sketchState.sketch.closed) {
			dispatch(previewActions.previewMove(point));
		}

		dispatch(sketchMouseMove(point));
	};
}

export function drawAreaMouseUp(event) {
	return dispatch => {
		const state = store.getState();
		const scaledPoint = getEventPoint(event);
		let { instrument, instrumentMenu, sketchState } = state;

		if (instrumentMenu.mouseDown) {
			return;
		}

		if (state.image.dynamicImage) {
			if (instrument === instrumentsTypes.PAN_MOVE) {
				if (state.image.dragActive) {
					dispatch(imageEndDrag());
				}
			} else if (instrument === instrumentsTypes.STRAIGHT_LINE) {
				dispatch(previewActions.previewHide());
				dispatch(scaleLineEnd(scaledPoint));
			}

			dispatch(drawAreaSetMouseDown(false));
			return;
		}

		if (!state.drawArea.visible) {
			return;
		}

		if (
			instrument === instrumentsTypes.NOTE_TOOL &&
			state.sketchState.dragIndex === null
		) {
			if (state.notes.editableNote !== null) {
				dispatch(noteEndEdit());
			} else {
				if (state.map.visible) {
					dispatch(noteAdd(scaledPoint, MapHelper.point2LatLng(scaledPoint)));
				} else {
					dispatch(noteAdd(scaledPoint));
				}
			}
		}

		if (instrument === instrumentsTypes.MAGIC_WAND) {
			let image = state.image;

			if (image && image.data) {
				if (
					scaledPoint[0] >= image.position[0] &&
					scaledPoint[0] <= image.position[0] + image.realSizes.width &&
					scaledPoint[1] >= image.position[1] && scaledPoint[1] <= image.position[1] + image.realSizes.height
				) {
					dispatch(sketchSetCurrentMask(new MagicWand(scaledPoint)));
				} else {
					dispatch(notificationShow('Point out of bounds', notificationTypes.DANGER, 1500));
				}
			} else if (state.map.visible) {
				dispatch(notificationShow('Magic Wand can not be used on maps', notificationTypes.DANGER, 1500));
			} else {
				dispatch(notificationShow('Image not loaded', notificationTypes.DANGER, 1500));
			}
		}

		if (instrument === instrumentsTypes.MAGIC_WAND_ADD) {
			if (sketchState.sketch.mask) {
				dispatch(sketchAddToMagicWandMask(scaledPoint));
			} else {
				dispatch(notificationShow('Mask not found', notificationTypes.WARNING, 1500));
			}
		}

		if (instrument === instrumentsTypes.MAGIC_WAND_SUB) {
			if (sketchState.sketch.mask) {
				dispatch(sketchSubFromMagicWandMask(scaledPoint));
			} else {
				dispatch(notificationShow('Mask not found', notificationTypes.WARNING, 1500));
			}
		}

		if (state.drawArea.mouseDown) {
			dispatch(previewActions.previewHide(event));
			dispatch(sketchMouseUp(scaledPoint));
		}

		dispatch(mapMouseUp(scaledPoint));
		dispatch(drawAreaSetMouseDown(false));
	};
}

export function drawAreaShiftConstraintEnabled() {
	return { type: types.DRAW_AREA_SHIFT_HOLD };
}

export function drawAreaShiftConstraintDisabled() {
	return { type: types.DRAW_AREA_SHIFT_RELEASE };
}

export function drawAreaUpdate(state) {
	return { type: types.DRAW_AREA_UPDATE, payload: { state } };
}

export function drawAreaSetMouseDown(value) {
	return { type: types.DRAW_AREA_SET_MOUSE_DOWN, payload: { value } };
}

export function drawAreaToggleVisibility() {
	return {
		type: types.DRAW_AREA_TOGGLE_VISIBILITY
	};
}

export function drawAreaSetSizes(sizes) {
	return {
		type: types.DRAW_AREA_SET_SIZES,
		payload: { sizes }
	};
}

export function drawAreaSetMinSizes(sizes) {
	return dispatch => {
		if (store.getState().map.visible) {
			return;
		}

		const minSizeIndent = 20;
		let currentMinSize = store.getState().drawArea.minStageSizes,
			newMinWidth = sizes.width + minSizeIndent * 2,
			newMinHeight = sizes.height + minSizeIndent * 2,
			newMinSizes = { ...currentMinSize },
			needUpdate = false;

		if (Array.isArray(sizes)) {
			newMinWidth = sizes[0] + minSizeIndent * 2;
			newMinHeight = sizes[1] + minSizeIndent * 2;
		}

		if (currentMinSize.width < newMinWidth) {
			newMinSizes.width = newMinWidth;
			needUpdate = true;
		}

		if (currentMinSize.height < newMinHeight) {
			newMinSizes.height = newMinHeight;
			needUpdate = true;
		}

		if (!needUpdate) {
			return;
		}

		dispatch({
			type: types.DRAW_AREA_SET_MIN_SIZES,
			payload: {
				sizes: { ...newMinSizes }
			}
		});
	};
}

export function drawAreaReset() {
	return { type: types.DRAW_AREA_RESET };
}

export function canvasReset(props={}) {
	return dispatch => {
		dispatch(imageReset());
		dispatch(noteReset());
		dispatch(sketchReset());
		dispatch(mapReset());
		dispatch(mapControlReset());
		dispatch(scaleReset(props));
		dispatch(instrumentReset());
		dispatch(zoomReset());
		dispatch(drawAreaReset());
		dispatch(historyReset());
		dispatch(instrumentMenuReset());
		dispatch(layerLabelReset());
		dispatch(layerReset());
		dispatch(offsetReset());
	};
}

export function canvasDrawingReset() {
	return dispatch => {
		dispatch(noteReset());
		dispatch(sketchReset());
		dispatch(instrumentReset());
		dispatch(historyReset());
		dispatch(layerReset());
	};
}

export function setShouldDragLayer(value) {
	return {
		type: types.SET_SHOULD_DRAG_LAYER,
		payload: { value }
	};
}
