import React from 'react';
import ApiHelper from '../../../../../shared/ApiHelper';

export default class AccountCardField extends React.Component {
	stripe = null;
	elements = null;
	card = null;
	cardEl = null;

	constructor(props) {
		super(props);

		this.state = {
			disabled: true,
			error: null,
			success: false
		};
	}

	componentDidMount() {
		this.stripe = window.Stripe(process.env.REACT_APP_STRIPE_KEY);
		this.elements = this.stripe.elements();
		this.card = this.elements.create('card', {
			hidePostalCode: true
		});

		this.card.mount(this.cardEl);

		this.card.addEventListener('change', this.handleChange.bind(this));
	}

	componentWillUnmount() {
		this.clearTimeout();
	}

	resetSuccess = () => {
		this.setState({ success: false });
	}

	clearTimeout = () => {
		if (this.handleTimeout) {
			window.clearTimeout(this.handleTimeout);
			this.handleTimeout = null;
		}
	}

	handleChange(event) {
		if (event.error) {
			this.setState({
				disabled: true,
				error: event.error.message
			});
		} else {
			this.setState({
				disabled: false,
				error: null
			});
		}
	}

	handleToken() {
		return new Promise((resolve, reject) => {
			this.stripe.createToken(this.card).then(result => {
				if (result.error) {
					reject(result.error.message);
				} else {
					resolve(result.token);
				}
			});
		});
	}

	handleSubmit() {
		this.setState({
			disabled: true,
			error: null
		});

		this.handleToken()
			.then(token => {
				ApiHelper.updateCard({ token })
					.then(response => {
						if (response.data.success) {
							this.setState({success: true});
							this.handleTimeout = window.setTimeout(() => {
								this.resetSuccess();
								this.props.onSuccess();
							}, 3000);
						} else {
							this.setState({ disabled: false });
						}
					})
					.catch(error => {
						const state = { disabled: false };

						if (error.response.data.message) {
							state.error = error.response.data.message;
						}

						this.setState(state);
					});
			})
			.catch(error => {
				this.setState({
					disabled: true,
					error: error
				});
			});
	}

	render() {
		const btnClassNames = ['btn', 'btn-modal'];

		if (this.state.success) {
			btnClassNames.push('btn-success');
		}

		return (
			<div className='field'>
				<div className='payment-card'>
					<div ref={el => (this.cardEl = el)} />
					{this.state.error && (
						<div className='validation-error'>
							<span className='text'>{this.state.error}</span>
						</div>
					)}
				</div>
				<button onClick={this.handleSubmit.bind(this)} className={btnClassNames.join(' ')} disabled={this.state.disabled}>
					{this.state.success ? 'done' : 'update'}
				</button>
			</div>
		);
	}
}
