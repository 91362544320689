import React from 'react';
import ReactGA from 'react-ga';
import DocumentMeta from 'react-document-meta';
import Mailto from 'react-mailto';

export default class PrivacyPolicyPage extends React.Component {
	render() {
		return (
			<section className='policy'>
				<DocumentMeta title='Privacy Policy' />
				<div>
					<h2>Privacy Policy</h2>

					<p>Last updated: July 06, 2018</p>
					<p>
						iCalc Inc ("us", "we", or "our") operates the https://www.sketchandcalc.com website (the
						"Service").
					</p>
					<p>
						This page informs you of our policies regarding the collection, use and disclosure of Personal
						Information when you use our Service.
					</p>
					<p>
						We will not use or share your information with anyone except as described in this Privacy
						Policy.
					</p>
					<p>
						We use your Personal Information for providing and improving the Service. By using the Service,
						you agree to the collection and use of information in accordance with this policy. Unless
						otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same
						meanings as in our Terms and Conditions, accessible at https://www.sketchandcalc.com
					</p>

					<h5>Information Collection And Use</h5>
					<p>
						While using our Service, we may ask you to provide us with certain personally identifiable
						information that can be used to contact or identify you. Personally identifiable information may
						include, but is not limited to, your email address, name ("Personal Information").
					</p>
					<p>
						We collect this information for the purpose of providing the Service, identifying and
						communicating with you, responding to your requests/inquiries, servicing your purchase orders,
						and improving our services.
					</p>

					<h5>Log Data</h5>
					<p>
						We may also collect information that your browser sends whenever you visit our Service ("Log
						Data"). This Log Data may include information such as your computer's Internet Protocol ("IP")
						address, browser type, browser version, the pages of our Service that you visit, the time and
						date of your visit, the time spent on those pages and other statistics.
					</p>
					<p>
						In addition, we may use third party services such as Google Analytics that collect, monitor and
						analyze this type of information in order to increase our Service's functionality. These third
						party service providers have their own privacy policies addressing how they use such
						information.
					</p>

					<h5>Cookies</h5>
					<p>
						Cookies are files with a small amount of data, which may include an anonymous unique identifier.
						Cookies are sent to your browser from a web site and transferred to your device. We use cookies
						to collect information in order to improve our services for you.
					</p>
					<p>
						You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
						The Help feature on most browsers provide information on how to s, disable cookies or to notify
						you when receiving a new cookie.
					</p>
					<p>
						If you do not s, you may not be able to use some features of our Service and we recommend that
						you leave them turned on.
					</p>

					<h5>Do Not Track Disclosure</h5>
					<p>
						We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web
						browser to inform websites that you do not want to be tracked.
					</p>
					<p>
						You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web
						browser.
					</p>

					<h5>Service Providers</h5>
					<p>
						We may employ third party companies and individuals to facilitate our Service, to provide the
						Service on our behalf, to perform Service-related services and/or to assist us in analyzing how
						our Service is used.
					</p>
					<p>
						These third parties have access to your Personal Information only to perform specific tasks on
						our behalf and are obligated not to disclose or use your information for any other purpose.
					</p>
					<p>
						We use Stripe for payment, analytics, and other business services. Stripe collects identifying
						information about the devices that connect to its services. Stripe uses this information to
						operate and improve the services it provides to us, including for fraud detection. You can learn
						more about Stripe and read its privacy policy at{' '}
						<a href='https://stripe.com/privacy'>https://stripe.com/privacy</a>.
					</p>

					<h5>Communications</h5>
					<p>
						We may use your Personal Information to contact you with newsletters, marketing or promotional
						materials and other information that may be of interest to you. You may opt out of receiving
						any, or all, of these communications from us by following the unsubscribe link or instructions
						provided in any email we send.
					</p>

					<h5>Compliance With Laws</h5>
					<p>
						We will disclose your Personal Information where required to do so by law or subpoena or if we
						believe that such action is necessary to comply with the law and the reasonable requests of law
						enforcement or to protect the security or integrity of our Service.
					</p>

					<h5>Security</h5>
					<p>
						The security of your Personal Information is important to us, and we strive to implement and
						maintain reasonable, commercially acceptable security procedures and practices appropriate to
						the nature of the information we store, in order to protect it from unauthorized access,
						destruction, use, modification, or disclosure.
					</p>
					<p>
						However, please be aware that no method of transmission over the internet, or method of
						electronic storage is 100% secure and we are unable to guarantee the absolute security of the
						Personal Information we have collected from you.
					</p>

					<h5>International Transfer</h5>
					<p>
						Your information, including Personal Information, may be transferred to — and maintained on —
						computers located outside of your state, province, country or other governmental jurisdiction
						where the data protection laws may differ than those from your jurisdiction.
					</p>
					<p>
						If you are located outside United States and choose to provide information to us, please note
						that we transfer the information, including Personal Information, to United States and process
						it there.
					</p>
					<p>
						Your consent to this Privacy Policy followed by your submission of such information represents
						your agreement to that transfer.
					</p>

					<h5>Links To Other Sites</h5>
					<p>
						Our Service may contain links to other sites that are not operated by us. If you click on a
						third party link, you will be directed to that third party's site. We strongly advise you to
						review the Privacy Policy of every site you visit.
					</p>
					<p>
						We have no control over, and assume no responsibility for the content, privacy policies or
						practices of any third party sites or services.
					</p>

					<h5>Children's Privacy</h5>
					<p>
						Only persons age 18 or older have permission to access our Service. Our Service does not address
						anyone under the age of 13 ("Children").
					</p>
					<p>
						We do not knowingly collect personally identifiable information from children under 13. If you
						are a parent or guardian and you learn that your Children have provided us with Personal
						Information, please contact us. If we become aware that we have collected Personal Information
						from a children under age 13 without verification of parental consent, we take steps to remove
						that information from our servers.
					</p>

					<h5>Changes To This Privacy Policy</h5>
					<p>
						This Privacy Policy is effective as of July 06, 2018 and will remain in effect except with
						respect to any changes in its provisions in the future, which will be in effect immediately
						after being posted on this page.
					</p>
					<p>
						We reserve the right to update or change our Privacy Policy at any time and you should check
						this Privacy Policy periodically. Your continued use of the Service after we post any
						modifications to the Privacy Policy on this page will constitute your acknowledgment of the
						modifications and your consent to abide and be bound by the modified Privacy Policy.
					</p>
					<p>
						If we make any material changes to this Privacy Policy, we will notify you either through the
						email address you have provided us, or by placing a prominent notice on our website.
					</p>

					<h5>Your right to 'Be forgotten'</h5>
					<p>
						If you have created a SketchAndCalc account and are not currently subscribed you can request
						your data be deleted from our servers by sending email to:{' '}
						<Mailto email='forgetme@sketchandcalc.com'>forgetme@sketchandcalc.com</Mailto>.
					</p>

					<p>Current subscribers will be asked if they wish their data be deleted when they unsubscribe.</p>

					<h5>Contact Us</h5>
					<p>
						If you have any questions about this Privacy Policy, please{' '}
						<ReactGA.OutboundLink eventLabel='Mail to support' to='mailto:support@sketchandcalc.com'>
							contact us
						</ReactGA.OutboundLink>
						.
					</p>

					<h5 className='confirmation'>
						Click the 'I Accept' button below to indicate you have read and agree to the terms of this
						policy.
					</h5>

					<button
						className='btn'
						onClick={() => {
							this.props.history.push('/');
						}}>
						I Accept
					</button>
				</div>
			</section>
		);
	}
}
