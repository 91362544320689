import React from 'react';
import update from 'immutability-helper';
import { ruleRunner, run } from '../../../../Validation/ruleRunner';
import { minLength, required } from '../../../../Validation/rules';
import TextView from '../../../../Util/TextView';
import ApiHelper from '../../../../../shared/ApiHelper';

const fieldValidations = [
	ruleRunner('oldPassword', 'Password', required, minLength(6)),
	ruleRunner('newPassword', 'New Password', required, minLength(6)),
	ruleRunner('confirmPassword', 'Confirm Password', required, minLength(6))
];

const initState = {
	step: 1,
	disabled: false,
	showErrors: false,
	validationErrors: {},
	oldPassword: '',
	newPassword: '',
	confirmPassword: '',
	success: false
};

export default class AccountPasswordField extends React.Component {
	handleTimeout = null;

	constructor(props) {
		super(props);

		this.state = initState;
	}

	clearTimeout() {
		if (this.handleTimeout) {
			window.clearTimeout(this.handleTimeout);
			this.handleTimeout = null;
		}
	}

	resetSuccess() {
		this.setState({ success: false });
	}

	componentWillMount() {
		this.setState({ validationErrors: run(this.state, fieldValidations) });
	}

	componentWillUnmount() {
		this.clearTimeout();
	}

	errorFor(field) {
		return this.state.validationErrors[field] || '';
	}

	handleFieldChanged(field) {
		return e => {
			let newState = update(this.state, {
				[field]: { $set: e.target.value }
			});

			newState.validationErrors = run(newState, fieldValidations);
			this.setState(newState);
		};
	}

	handleSubmit() {
		this.setState({ showErrors: true });

		if (this.state.step === 1 && !this.state.validationErrors['oldPassword']) {
			this.setState({
				step: 2,
				showErrors: false
			});
		} else if (
			this.state.step === 2 &&
			!this.state.validationErrors['oldPassword'] &&
			!this.state.validationErrors['newPassword']
		) {
			this.setState({
				step: 3,
				showErrors: false
			});
		} else if (
			this.state.step === 3 &&
			!this.state.validationErrors['oldPassword'] &&
			!this.state.validationErrors['newPassword'] &&
			!this.state.validationErrors['confirmPassword']
		) {
			if (this.state.newPassword !== this.state.confirmPassword) {
				return this.setState({
					validationErrors: {
						confirmPassword: 'Password does not match the confirm password.'
					}
				});
			}
		}

		if (Object.keys(this.state.validationErrors).length) return;

		const data = {
			oldPassword: this.state.oldPassword,
			newPassword: this.state.newPassword
		};

		this.setState({
			showErrors: false,
			disabled: true
		});

		ApiHelper.updatePassword(data)
			.then(response => {
				if (response.data.success) {
					this.setState({ ...initState, success: true });
					this.handleTimeout = window.setTimeout(this.resetSuccess.bind(this), 3000);
				}
			})
			.catch(error => {
				const state = { ...initState };

				if (error.response.data.message) {
					state.showErrors = true;
					state.validationErrors = {
						oldPassword: error.response.data.message
					};
				}

				this.setState(state);
			});
	}

	render() {
		switch (this.state.step) {
			case 2: {
				return (
					<div className='field'>
						<TextView
							placeholder='Enter New Password'
							showError={this.state.showErrors}
							text={this.state.newPassword}
							type='password'
							onFieldChanged={this.handleFieldChanged('newPassword')}
							errorText={this.errorFor('newPassword')}
							autoComplete='new-password'
						/>
						<button
							onClick={this.handleSubmit.bind(this)}
							className='btn btn-modal'
							disabled={!this.state.newPassword.length}>
							next
						</button>
					</div>
				);
			}
			case 3: {
				return (
					<div className='field'>
						<TextView
							placeholder='Enter Confirm Password'
							showError={this.state.showErrors}
							text={this.state.confirmPassword}
							type='password'
							onFieldChanged={this.handleFieldChanged('confirmPassword')}
							errorText={this.errorFor('confirmPassword')}
							autoComplete='new-password'
						/>
						<button
							onClick={this.handleSubmit.bind(this)}
							className='btn btn-modal'
							disabled={!this.state.confirmPassword.length || this.state.disabled}>
							update
						</button>
					</div>
				);
			}
			default: {
				const btnClassNames = ['btn', 'btn-modal'];

				if (this.state.success) {
					btnClassNames.push('btn-success');
				}

				return (
					<div className='field'>
						<TextView
							placeholder='Enter Current Password'
							showError={this.state.showErrors}
							text={this.state.oldPassword}
							type='password'
							onFieldChanged={this.handleFieldChanged('oldPassword')}
							errorText={this.errorFor('oldPassword')}
							autoComplete='new-password'
						/>
						<button
							onClick={this.handleSubmit.bind(this)}
							className={btnClassNames.join(' ')}
							disabled={this.state.success || !this.state.oldPassword.length}>
							{this.state.success ? 'done' : 'next'}
						</button>
					</div>
				);
			}
		}
	}
}
