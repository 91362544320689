import React from 'react';
import AppHeader from '../Header/AppHeader';
import TopNote from '../TopNote/TopNote';

import MagicWandPopUp from '../App/MagicWandPopUp/MagicWandPopUp';

export class AppLayout extends React.Component {
	render() {
		const classNames = ['site-root', 'app-layout'];

		if (window.isPuppeteer) {
			classNames.push('screenshot');
		}

		return (
			<div className={classNames.join(' ')}>
				<AppHeader />
				<TopNote />
				<MagicWandPopUp />
				<main className='main-content'>{this.props.children}</main>
			</div>
		);
	}
}
