import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import TrialEnd from './TrialEnd';
import PayPerDayEnd from './PayPerDayEnd';
import File from '../../icons/File';

class Details extends React.Component {
	render() {
		const {fileData: {fileName}, user, ppd} = this.props;
		const userRender = user && <span>Hello {user.first_name}</span>;
		return (
			<div className='user-details d-inline-flex align-items-center'>
				{fileName && <TrialEnd text='Trial' />}
				{!!(fileName && ppd.timeLeft !== null) && <PayPerDayEnd text='Pay Per Day' payPerDay={ppd} />}
				{fileName
					? <div className='d-inline-flex align-items-center'><File size={17}/> {fileName}</div>
					: userRender
				}
				{!fileName && <TrialEnd text='Trial' />}
				{!!(!fileName && ppd.timeLeft !== null) && <PayPerDayEnd text='Pay Per Day' payPerDay={ppd} />}
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		user: state.auth.user,
		ppd: state.ppd,
		fileData: state.fileName
	};
}

export default connect(mapStateToProps)(Details);
