import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { helpActivateMenu } from '../../../shared/actions/helpActions';

class ActionMenu extends React.Component {
	render() {
		return (
			<div
				style={{
					top: 10,
					right: 0
				}}
				className='contextual-modal arrow-top-right'>
				<h3 className='title'>Action Menu</h3>
				<div className='content'>
					Here you can import an image,
					<br />
					access Maps, or Clear the drawing
					<br />
					canvas.
				</div>
				<div className='actions'>
					<button
						onClick={() => {
							this.props.helpActivateMenu();
						}}
						className='btn btn-sm btn-modal'>
						ok
					</button>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			helpActivateMenu
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(ActionMenu);
