import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../../Loader/Loader';
import './ItemsListStyles.scss';


class ItemsList extends React.Component {
    render() {
        const { title, items, activeIndex, getLabel, getItemClasses, itemLabelKey = 'name', loading=false, onClick=()=>{} } = this.props;


        return (
            <div className={`list-wrapper ${loading?'no-scroll':''}`}>
                <div className={'list-title px-2'}>{title}</div>
                { loading && <Loader modes={'absolute light-fade backdrop-blur'} color={'#666666'}/> }
                <div className={'list'}>
                    {items.map((item, key) => {
                        let classnames = ['list-item', 'px-2'];

                        if (activeIndex === item.id) {
                            classnames.push('active-item')
                        }

                        if (getItemClasses) {
                            classnames = [...classnames, ...getItemClasses(item)]
                        }

                        return (
                            <div
                                key={key}
                                onClick={() => onClick(item)}
                                className={classnames.join(' ')}
                            >{ getLabel? getLabel(item) : item[itemLabelKey] }</div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

ItemsList.propTypes = {
    title: PropTypes.any.isRequired,
    items: PropTypes.array.isRequired,
    activeIndex: PropTypes.any
}

export default ItemsList