import React from 'react';
import OptionallyDisplayed from './OptionallyDisplayed.js';
import PropTypes from 'prop-types';
import './TextView.scss';

export default class TextField extends React.Component {
	constructor(props) {
		super(props);
		this.shouldDisplayError = this.shouldDisplayError.bind(this);
	}

	shouldDisplayError() {
		return this.props.showError && this.props.errorText !== '';
	}

	render() {
		const { type, className, placeholder, text, onFieldChanged, autoComplete, name, rows, disabled, suffixIcon,
			onSuffixClick, wrapperClass='', onClick=null } = this.props;
		const isTextArea = type === 'textarea';
		const inputProps = {
			className: 'form-control' + (className ? ' ' + className : ''),
			type: type || 'text',
			placeholder,
			value: text,
			onChange: onFieldChanged,
			autoComplete,
			name,
		    ...rows?{ rows }:{},
			disabled
		}

		return (
			<div className={wrapperClass} onClick={onClick? onClick : ()=>{}}>
				{isTextArea ?
					<textarea {...inputProps} />
					:
					<input {...inputProps} />
				}
				<OptionallyDisplayed display={this.shouldDisplayError()}>
					<div className='validation-error'>
						<span className='text'>{this.props.errorText}</span>
					</div>
				</OptionallyDisplayed>
				{
					suffixIcon &&
					<div
						onClick={onSuffixClick}
						className={'suffix-block'}
					>
						{suffixIcon}
					</div>
				}
			</div>
		);
	}
}

TextField.propTypes = {
	showError: PropTypes.bool.isRequired,
	onFieldChanged: PropTypes.func.isRequired
};
