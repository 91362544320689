import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { helpActivateScale2 } from '../../../shared/actions/helpActions';
import { scalePopupEl } from '../../App/ScalePopup/ScalePopup';
import { svgEl } from '../../App/DrawArea/DrawArea';

export let renderSetScale2;

class SetScale2 extends React.Component {
	componentWillMount() {
		renderSetScale2 = this.forceRender.bind(this);
	}

	componentWillUnmount() {
		renderSetScale2 = null;
	}

	forceRender() {
		this.forceUpdate();
	}

	render() {
		if (!scalePopupEl || !svgEl) {
			return null;
		}

		const elBounds = scalePopupEl.getBoundingClientRect(),
			svgBounds = svgEl.getBoundingClientRect(),
			style = { left: elBounds.left + elBounds.width / 2 - 135 };

		let arrow = 'arrow-bottom';

		if (elBounds.top - svgBounds.top < 230) {
			style.top = elBounds.bottom - svgBounds.top + 15;
			arrow = 'arrow-top';
		} else {
			style.top = elBounds.top - svgBounds.top - 230;
		}

		return (
			<div style={style} className={`contextual-modal ${arrow}`}>
				<h3 className='title'>
					Set Scale <span>2/3</span>
				</h3>
				<div className='content'>
					Enter the known length, along with
					<br />
					your chosen measurement system
					<br />
					and submit <span className='icon blue icon-checkmark' />
				</div>
				<div className='actions'>
					<button
						onClick={() => {
							this.props.helpActivateScale2();
						}}
						className='btn btn-sm btn-modal'>
						ok
					</button>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			helpActivateScale2
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(SetScale2);
