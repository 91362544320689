import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

class TrialEnd extends React.Component {
	render() {
		if (!this.props.trial || this.props.hasSubscription) {
			return null;
		}

		return (
			<span className='trial-end'>
				{this.props.text && <span>{this.props.text}</span>}
				{this.props.trial}
			</span>
		);
	}
}

function mapStateToProps(state, ownProps) {
	const {
		trial,
		auth: {
			user
		}
	} = state;
	const subscription = get(user, 'subscription', null);

	return {
		...ownProps,
		trial,
		hasSubscription: subscription && subscription.status === 'Opened'
	};
}

export default connect(mapStateToProps)(TrialEnd);
