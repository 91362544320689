import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clipboardReset, clipboardSetFile, clipboardConfirmLoad } from '../../../shared/reducers/clipboardReducer';
import { modalOpen } from '../../../shared/reducers/ModalReducer';
import { MODAL_CLIPBOARD_CANVAS_RESET } from './ModalClipboardCanvasReset';
import { pdfUpload } from '../../../shared/reducers/PdfReducer';
import { imageAdd } from '../../../shared/actions/ImageActions';
import { layerIsEmptyAllLayers } from '../../../shared/actions/layerActions';

class ClipBoardImport extends React.Component {
	componentDidMount() {
		document.onpaste = this.somethingPasted.bind(this);
	}

	componentWillUnmount() {
		document.onpaste = () => {};
	}

	somethingPasted(event) {
		const items = (event.clipboardData || event.originalEvent.clipboardData).items;
		let file = null,
			isPdf = false;

		for (let index in items) {
			const item = items[index];
			if (item.kind === 'file') {
				event.preventDefault();
				const targetFile = item.getAsFile();

				if (targetFile.type.substring(0, 5) === 'image') {
					file = targetFile;
					break;
				} else if (targetFile.type === 'application/pdf') {
					file = targetFile;
					isPdf = true;
					break;
				}
			}
		}

		if (file) {
			this.confirmCleanCanvas(file, isPdf);
		}
	}

	confirmCleanCanvas(file, isPdf) {
		this.props.layerIsEmptyAllLayers().then(response => {
			if (response) {
				this.onConfirmed(file, isPdf);
			} else {
				this.props.clipboardSetFile(file, isPdf);
				this.props.modalOpen(MODAL_CLIPBOARD_CANVAS_RESET);
			}
		});
	}

	onConfirmed(file, isPdf) {
		if (file instanceof File) {
			if (isPdf) {
				this.props.pdfUpload(file);
				this.props.clipboardReset();
			} else {
				const that = this;
				let img = new Image();

				img.onload = function() {
					that.props.imageAdd(file, {
						width: this.width,
						height: this.height
					});
					that.props.clipboardReset();
				};

				img.src = URL.createObjectURL(file);
			}
		}
	}

	render() {
		const { file, isPdf, confirmed } = this.props.clipboard;

		//Something is pasted
		if (file) {
			if (confirmed) {
				this.onConfirmed(file, isPdf);
			} /*else {
                this.confirmCleanCanvas(file, isPdf);
            }*/
		}

		return null;
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		...ownProps,
		clipboard: state.clipboard
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			clipboardReset,
			clipboardSetFile,
			clipboardConfirmLoad,
			modalOpen,
			imageAdd,
			pdfUpload,
			layerIsEmptyAllLayers
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ClipBoardImport);
