import React from 'react';

const Art7 = ({color="#fff"}) => {

    return (
        <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 200 200">
            <path className="cls-1"
                  style={{ fill: color }}
                  d="M80.41,121.08V91.21H74.92V83h5.49V79q0-6.69,3.88-10.16t11.24-3.47a19.41,19.41,0,0,1,2.74.19c.92.13,2,.31,3.14.55l-.88,8.77c-.66-.12-1.23-.21-1.73-.27s-1.1-.08-1.83-.08a4.73,4.73,0,0,0-3.43,1.14A4.42,4.42,0,0,0,92.36,79v4h7.39v8.24H92.36v29.87Z"/>
            <path className="cls-1"
                  style={{ fill: color }}
                  d="M118.32,73.49V83h6.24v8.24h-6.24v17.44a4.17,4.17,0,0,0,.85,3,3,3,0,0,0,2.32.88,12.61,12.61,0,0,0,1.43-.07,12.86,12.86,0,0,0,1.32-.28l.84,8.63a26,26,0,0,1-3.17.76,21,21,0,0,1-3.41.26q-6.06,0-9.06-3.07t-3-10V91.21h-5V83h5V73.49Z"/>
            <rect className="cls-1" style={{ fill: color }} width="28.5" height="28.5"/>
            <rect className="cls-1" style={{ fill: color }} x="171.5" width="28.5" height="28.5"/>
            <rect className="cls-1" style={{ fill: color }} x="171.5" y="171.5" width="28.5" height="28.5"/>
            <line className="cls-2" style={{ stroke: color, strokeMiterlimit:10, strokeWidth: '5px' }} x1="28.5" y1="14.25" x2="171.5" y2="14.25"/>
            <line className="cls-2" style={{ stroke: color, strokeMiterlimit:10, strokeWidth: '5px' }} x1="185.75" y1="28.5" x2="185.75" y2="171.5"/>
            <line className="cls-2" style={{ stroke: color, strokeMiterlimit:10, strokeWidth: '5px' }} x1="171.5" y1="185.75" x2="28.5" y2="185.75"/>
        </svg>
    )
}

export default Art7