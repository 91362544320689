const MAP_TOGGLE_VISIBILITY = 'MAP_TOGGLE_VISIBILITY';
const MAP_UPDATE_CENTER = 'MAP_UPDATE_CENTER';
const MAP_UPDATE_ZOOM = 'MAP_UPDATE_ZOOM';
const MAP_ZOOM_IN = 'MAP_ZOOM_IN';
const MAP_ZOOM_OUT = 'MAP_ZOOM_OUT';
const MAP_UPDATE_MOVE_POINT = 'MAP_UPDATE_MOVE_POINT';
const MAP_UPDATE_MAX_ZOOM = 'MAP_UPDATE_MAX_ZOOM';
const MAP_UPDATE_PAN = 'MAP_UPDATE_PAN';
const MAP_UPDATE_TYPE = 'MAP_UPDATE_TYPE';
const MAP_UPDATE = 'MAP_UPDATE';
const MAP_RESET = 'MAP_RESET';

const ROADMAP = 'roadmap';
const SATELLITE = 'satellite';
const HYBRID = 'hybrid';

export const mapTypes = { ROADMAP, SATELLITE, HYBRID };

export default {
	MAP_TOGGLE_VISIBILITY,
	MAP_UPDATE_CENTER,
	MAP_UPDATE_ZOOM,
	MAP_ZOOM_IN,
	MAP_ZOOM_OUT,
	MAP_UPDATE_MOVE_POINT,
	MAP_UPDATE_MAX_ZOOM,
	MAP_UPDATE_PAN,
	MAP_UPDATE_TYPE,
	MAP_UPDATE,
	MAP_RESET
};
