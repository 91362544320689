const NOTIFICATION_SHOW = 'NOTIFICATION_SHOW';
const NOTIFICATION_RESET = 'NOTIFICATION_RESET';

export const NOTIFICATION_DELAY = 5000;

const SUCCESS = 'success';
const INFO = 'info';
const WARNING = 'warning';
const DANGER = 'danger';

export const notificationTypes = {
	SUCCESS,
	INFO,
	WARNING,
	DANGER
};

export default {
	NOTIFICATION_SHOW,
	NOTIFICATION_RESET
};
