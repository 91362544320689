import externalConstants from '../constants/externalConstants';

const initState = {
	location: null,
};

export default function external(state = initState, action) {
	switch (action.type) {
		case externalConstants.EXTERNAL_IP_INFO: {
			return {...state, ...action.payload}
		}

		default:
			return state;
	}
}
