const PPD_UPDATE = 'PPD_UPDATE';
const PPD_SET_IGNORE_WELCOME = 'PPD_SET_IGNORE_WELCOME';
const PPD_RESET = 'PPD_RESET';

export function ppdUpdate(value) {
	return {
		type: PPD_UPDATE,
		payload: { value }
	};
}

export function ppdIgnoreWelcome(value) {
	return {
		type: PPD_SET_IGNORE_WELCOME,
		payload: { value }
	};
}

export function ppdReset() {
	return {
		type: PPD_RESET
	};
}

const initState = {
	timeLeft: null,
	ignoreWelcome: false
};

export default function ppdReducer(state = initState, action) {
	switch (action.type) {
		case PPD_UPDATE: {
			return { ...state, timeLeft: action.payload.value };
		}
		case PPD_SET_IGNORE_WELCOME: {
			return { ...state, ignoreWelcome: action.payload.value };
		}
		case PPD_RESET: {
			return { timeLeft: null, ignoreWelcome: false };
		}
		default:
			return state;
	}
}
