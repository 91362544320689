import * as React from 'react';

export class MagicWandMask extends React.Component {
	render() {
		return <g className='magic-wand-mask'>{this.renderMask()}</g>;
	}

	renderMask() {
		return <path className='mask' d={this.props.previewPath} />;
	}
}
