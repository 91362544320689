import React from 'react';
import StripeWidget from "../StripeWidget";

const plans = {
    'Pro Team 5': {
        id:'pro team 5',
        name:'pro team 5',
        perks:[
            'up to 5 logins',
            'annual subscription',
            'cancel anytime'
        ]
    },
    'Pro Team 20': {
        id:'pro team 20',
        name:'pro team 20',
        perks:[
            'up to 20 logins',
            'annual subscription',
            'cancel anytime'
        ]
    },
    'Pro Team Unlimited': {
        id:'pro team unlimited',
        name:'pro team unlimited',
        perks: [
            'unlimited logins',
            'annual subscription',
            'cancel anytime'
        ]
    },
};

class TeamPlans extends React.Component {
    render() {
        const {prices, switchPriceType, handleError, showCheckout} = this.props;

        return (
            <div className={'plans team-plans'}>
                {Object.keys(plans).map(planKey => {
                    const plan = plans[planKey];
                    const planPrice = prices.find(price => price.productName.toLowerCase() === planKey.toLowerCase())
                    let price = null
                    if (planPrice) {
                        const integer = Math.floor(planPrice.price/100);
                        const float = planPrice.price - integer*100;

                        price = {integer, float}
                    }

                    return (
                        <div className={'plan'} key={planKey}>
                            <div className={'plan-name'}>{plan.name}</div>
                            <div className={'plan-body'}>
                                <div className={'plan-price'}>
                                    {
                                        price? (
                                                <div className={'price-block'}>
                                                    <div className={'price'}>
                                                        <div className={'integer'}>{price.integer}</div>
                                                        {price.float>0 && <div className={'float'}>.{price.float}</div>}
                                                    </div>
                                                    <div className={'currency'}>{planPrice.priceCurrency}</div>
                                                </div>
                                            )
                                            :
                                            <div className={'free'}>free</div>
                                    }
                                </div>
                                <div className={'plan-perks'}>
                                    {plan.perks.map(perk => (
                                        <div className={'perk'} key={perk}>{perk}</div>
                                    ))}
                                </div>
                                <StripeWidget
                                    plan={planKey}
                                    onChange={handleError}
                                    btnText={'buy'}
                                    btnClass={'purchase-btn'}
                                    showCheckout={() => showCheckout({...plan,planPrice})}
                                />
                            </div>
                        </div>
                    )
                })}

                <div onClick={switchPriceType} className={'switch-type'}>
                    Individual Plans
                </div>
            </div>
        );
    }
}

export default TeamPlans;
