import SvgPanZoom from 'svg-pan-zoom';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as instrumentsTypes from '../../../shared/classes/Instruments';
import SVGNoteList from '../SVGNoteList/SVGNoteList';
import CanvasImage from '../CanvasImage/CanvasImage';
import CanvasSketch from '../CanvasSketch/CanvasSketch';
import CanvasGrid from '../CanvasGrid/CanvasGrid';

import { noteAdjustZoom } from '../../../shared/actions/NoteActions';
import { imageSetRealSizes } from '../../../shared/actions/ImageActions';
import {
	drawAreaMouseUp,
	drawAreaMouseMove,
	drawAreaMouseDown,
	drawAreaSetSizes,
	drawAreaShiftConstraintEnabled,
	drawAreaShiftConstraintDisabled
} from '../../../shared/actions/drawAreaActions';
import { zoomPanMoved, zoomSetPanBy, zoomWheel } from '../../../shared/actions/zoomActions';
import CanvasLayers from '../CanvasLayers/CanvasLayers';
import CanvasPinnedLayers from '../CanvasLayers/CanvasPinnedLayers';
import { layerAdjustScaleNotes, layerSetLabelsVisible } from '../../../shared/actions/layerActions';
import { offsetUpdate } from '../../../shared/reducers/offsetReducer';
import { sketchResetActivePoint } from '../../../shared/actions/SketchActions';
import { labelReset } from '../../../shared/reducers/LabelReducer';
import { printActivateReady } from '../../../shared/reducers/printReducer';
import isEqual from 'lodash.isequal';
import { SUBSCRIPTION_PLANS } from '../../../shared/constants/plansConstants';
import { instrumentChange } from '../../../shared/reducers/instrumentReducer';
import get from 'lodash/get';

export let svgPan;
export let svgEl;

class DrawArea extends React.Component {
	afterZoom = false;
	handleTimeout = null;

	constructor(props) {
		super(props);

		this.resizeSVGPan = this.resizeSVGPan.bind(this);
		this.resetActiveIndex = this.resetActiveIndex.bind(this);

		this.handleKeyUp = this.handleKeyUp.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.state = {
			lineIsDrawing: false
		};
	}

	render() {
		const showCanvasElements = !this.props.image.dynamicImage && this.props.canvasVisible;
		const shouldShowPinnedLayers = this.props.layersList.some(({ pinned }) => pinned);

		const classNames = ['draw-area'];

		if (
			this.props.dragInstrumentMenu === false &&
			this.props.dragIndex === null &&
			this.props.dragAngleIndex === null &&
			this.props.movableNote === null
		) {
			switch (this.props.instrument) {
				case instrumentsTypes.STRAIGHT_LINE: {
					if (this.props.image.dynamicImage) {
						classNames.push('scale-line');
					} else {
						classNames.push('straight-line');
					}
					break;
				}
				case instrumentsTypes.CONSTRAINED_LINE: {
					classNames.push('constrained-line');
					break;
				}
				case instrumentsTypes.CURVED_LINE: {
					classNames.push('curved-line');
					break;
				}
				case instrumentsTypes.ANGLE_LINE: {
					if (!this.props.sketch.angleClosed) {
						if (this.props.sketch.angleLineExists()) {
							classNames.push('angle-tool-b');
						} else {
							classNames.push('angle-tool-a');
						}
					}
					break;
				}
				case instrumentsTypes.PAN_MOVE: {
					classNames.push('pan');
					break;
				}
				case instrumentsTypes.NOTE_TOOL: {
					classNames.push('note');
					break;
				}
				case instrumentsTypes.RECTANGLE_TOOL: {
					classNames.push('rect');
					break;
				}
				case instrumentsTypes.CIRCLE_TOOL: {
					classNames.push('circle');
					break;
				}
				case instrumentsTypes.MAGIC_WAND: {
					classNames.push('magic-wand');
					break;
				}
				case instrumentsTypes.MAGIC_WAND_ADD: {
					classNames.push('magic-wand-plus');
					break;
				}
				case instrumentsTypes.MAGIC_WAND_SUB: {
					classNames.push('magic-wand-minus');
					break;
				}
				default: {
					classNames.push('select');
				}
			}
		}
		const { drawAreaMouseUp, drawAreaMouseMove, drawAreaMouseDown, readonly } = this.props;


		return (
			<svg
				id='main'
				className={classNames.join(' ')}
				onMouseUp={drawAreaMouseUp}
				onMouseMove={readonly? () => {} :drawAreaMouseMove}
				onMouseDown={drawAreaMouseDown}
				onWheel={this.onWheel.bind(this)}
				ref={svg => {
					svgEl = svg ? svg : svgEl;
				}}>
				<g className='svg-pan-zoom_viewport'>
					{this.props.image.url && this.props.image.display && (
						<CanvasImage
							{...{
								image: this.props.image,
								scaleLineReady: this.props.scaleLineReady,
								scaleLine: this.props.scaleLine,
								zoomValue: this.props.zoomValue,
								preview: this.props.preview,
								strokeWidth: this.props.strokeWidth,
								printActivateReady: this.props.printActivateReady.bind(this)
							}}
						/>
					)}

					{showCanvasElements && this.props.canvasSizes.width && (
						<CanvasGrid
							{...{
								zoomValue: this.props.zoomValue,
								canvasSizes: this.props.canvasSizes,
								minCanvasSizes: this.props.minCanvasSizes,
								gridTransparency: this.props.gridTransparency
							}}
						/>
					)}

					{showCanvasElements && shouldShowPinnedLayers && !this.props.showAllLayers && (
						<CanvasPinnedLayers />
					)}
					{showCanvasElements && !this.props.showAllLayers && <CanvasSketch lineIsDrawing={this.state.lineIsDrawing}/>}
					{showCanvasElements && this.props.showAllLayers && <CanvasLayers />}
					{showCanvasElements && <SVGNoteList />}
				</g>
			</svg>
		);
	}

	resetDrawState = () => {
		this.setState({ lineIsDrawing: false });
	}

	resetActiveIndex() {
		this.setState({ lineIsDrawing: true });
		if (this.props.activeIndex !== null || this.props.activeIndexBeforeClose !== null) {
			this.props.sketchResetActivePoint();
		}

		if (this.props.editableLineIndex !== null) {
			this.props.labelReset();
		}
	}

	clearTimeout() {
		if (this.handleTimeout) {
			window.clearTimeout(this.handleTimeout);
			this.handleTimeout = null;
		}
	}

	onWheel(event) {
		if (this.props.mapVisible) {
			this.clearTimeout();
			this.handleTimeout = window.setTimeout(this.props.zoomWheel.bind(this), 100, event.deltaY);
		} else {
			this.props.zoomWheel(event.deltaY);
		}
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.canvasSizes, this.props.canvasSizes)) {
			this.resizeSVGPan();
		}

		if (get(this, 'props.user.subscription.stripe_plan', '') === SUBSCRIPTION_PLANS.educator &&
			this.props.instrument !== instrumentsTypes.PAN_MOVE) {
			this.props.instrumentChange(instrumentsTypes.PAN_MOVE);
		}

		if (prevProps.displayLineLength !== this.props.displayLineLength) {
			this.props.layerSetLabelsVisible(this.props.displayLineLength);
		}

		if (svgPan && svgPan.getSizes().realZoom.toFixed(1) !== this.props.zoomValue.toFixed(1)) {
			this.afterZoom = true;
			svgPan.zoom(this.props.zoomValue);

			if (!isEqual(prevProps.zoomValue, this.props.zoomValue)) {
				if (this.props.showAllLayers) {
					this.props.layerAdjustScaleNotes();
				} else {
					this.props.noteAdjustZoom();
				}
			}
		}

		if (this.props.zoomPanBy !== null) {
			this.afterZoom = true;
			svgPan.panBy(this.props.zoomPanBy);
			this.props.zoomSetPanBy(null);
		}

		if (!this.props.canvasSizes.width || !this.props.canvasSizes.height) {
			let { width, height } = svgEl.getBoundingClientRect();

			this.props.drawAreaSetSizes({
				width: width,
				height: height
			});
		}

		if (window.isPuppeteer) {
			if (
				(!this.props.image.url || !this.props.image.display) &&
				!this.props.mapVisible &&
				!this.props.print.isExport &&
				this.props.print.isPhantomJs &&
				!this.props.print.isReady
			) {
				this.props.printActivateReady();
			}
		}
	}

	componentDidMount() {
		svgPan = SvgPanZoom(svgEl, {
			zoomEnabled: true,
			panEnabled: true,
			beforePan: this.beforePanMove.bind(this),
			controlIconsEnabled: false,
			fit: false,
			center: false,
			preventMouseEventsDefault: false,
			minZoom: 1,
			zoomScaleSensitivity: 0.2,
			dblClickZoomEnabled: false,
			mouseWheelZoomEnabled: false
		});

		let { width, height } = svgEl.getBoundingClientRect();

		this.props.drawAreaSetSizes({
			width: width,
			height: height
		});

		window.addEventListener('resize', this.resizeSVGPan);
		window.addEventListener('mousedown', this.resetActiveIndex);
		window.addEventListener('keyup', this.handleKeyUp);
		window.addEventListener('keydown', this.handleKeyDown);
		window.addEventListener('mouseup', this.resetDrawState);
	}

	componentWillUnmount() {
		if (svgPan) {
			svgPan.destroy();
		}

		window.removeEventListener('resize', this.resizeSVGPan);
		window.removeEventListener('mousedown', this.resetActiveIndex);
		window.removeEventListener('keyup', this.handleKeyUp);
		window.removeEventListener('keydown', this.handleKeyDown);
		window.removeEventListener('mouseup', this.resetDrawState);
	}

	handleKeyUp(event) {
		const keyCode = event.keyCode || event.which,
			code = `${event.code}`.toLowerCase();

		if (keyCode === 16 || code === 'shiftright' || code === 'shiftleft') {
			this.props.drawAreaShiftConstraintDisabled();
		}
	}

	handleKeyDown(event) {
		const keyCode = event.keyCode || event.which,
			code = `${event.code}`.toLowerCase();

		if (keyCode === 16 || code === 'shiftright' || code === 'shiftleft') {
			this.props.drawAreaShiftConstraintEnabled();
		}
	}

	beforePanMove(oldPan, newPan) {
		if (this.props.shouldDragLayer) {
			return false;
		}

		if (this.props.mapVisible) {
			return { x: 0, y: 0 };
		}

		let dragIndex = this.props.dragIndex,
			dragAngleIndex = this.props.dragAngleIndex,
			minCanvasSizes = this.props.minCanvasSizes,
			dragSupportPoint = this.props.dragSupportPoint,
			{ width, height } = svgEl.getBoundingClientRect(),
			{ realZoom } = svgPan.getSizes(),
			isPanMoveEnabled =
				this.props.movableNote === null &&
				dragIndex === null &&
				dragAngleIndex === null &&
				dragSupportPoint === null &&
				!this.props.image.dynamicImage &&
				[instrumentsTypes.PAN_MOVE, instrumentsTypes.SELECT_TOOL].includes(this.props.instrument);

		if (!isPanMoveEnabled) {
			if (!this.afterZoom) {
				return false;
			}
		}

		if (this.afterZoom) {
			this.afterZoom = false;
		}

		let maxYOffset = height - height * realZoom;
		let maxXOffset = width - width * realZoom;

		if (height < minCanvasSizes.height) {
			maxYOffset += (height - minCanvasSizes.height) * realZoom;
		}

		if (width < minCanvasSizes.width) {
			maxXOffset += (width - minCanvasSizes.width) * realZoom;
		}

		let { x, y } = newPan;

		if (x > 0) {
			x = 0;
		} else if (x < maxXOffset) {
			x = maxXOffset;
		}

		if (y > 0) {
			y = 0;
		} else if (y < maxYOffset) {
			y = maxYOffset;
		}
		this.props.zoomPanMoved({ width, height, x, y, realZoom });
		this.props.offsetUpdate({ x, y });
		return { x, y };
	}

	resizeSVGPan() {
		let { width, height } = svgEl.getBoundingClientRect(),
			{ realZoom } = svgPan.getSizes(),
			{ x, y } = svgPan.getPan();

		this.props.drawAreaSetSizes({ width, height });

		if (this.props.mapVisible) {
			return;
		}

		if (this.props.image.url && this.props.image.dynamicImage) {
			this.props.imageSetRealSizes();
		}
		this.props.zoomPanMoved({ width, height, x, y, realZoom });
	}

	getCursorFromFont(code) {
		let canvas = document.createElement('canvas');
		canvas.width = 24;
		canvas.height = 24;
		//document.body.appendChild(canvas);
		let ctx = canvas.getContext('2d');
		ctx.fillStyle = '#000000';
		ctx.font = '24px sac';
		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';
		ctx.fillText(String.fromCharCode(parseInt(code, 16)), 12, 12);
		let dataURL = canvas.toDataURL('image/png');
		return 'url(' + dataURL + ') auto';
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		dragIndex: state.sketchState.dragIndex,
		dragSupportPoint: state.sketchState.dragSupportPoint,
		activeIndex: state.sketchState.activeIndex,
		activeIndexBeforeClose: state.sketchState.activeIndexBeforeClose,
		dragAngleIndex: state.sketchState.dragAngleIndex,
		sketch: state.sketchState.sketch,
		canvasVisible: state.drawArea.visible,
		canvasSizes: state.drawArea.stageSizes,
		minCanvasSizes: state.drawArea.minStageSizes,
		preview: { ...state.preview },
		zoomValue: state.zoom.value,
		zoomPanBy: state.zoom.panBy,
		instrument: state.instrument,
		dragInstrumentMenu: state.instrumentMenu.mouseDown,
		movableNote: state.notes.movableNote,
		image: state.image,
		scale: state.scale.value,
		scaleLine: state.scale.line,
		scaleLineReady: state.scale.lineReady,
		mapVisible: state.map.visible,
		showAllLayers: state.layers.showAllLayers,
		layersList: state.layers.list,
		strokeWidth: state.settings.lineWeight,
		displayLineLength: state.settings.displayLineLength,
		gridTransparency: state.settings.gridTransparency,
		editableLineIndex: state.label.editableLineIndex,
		print: state.print,
		shouldDragLayer: state.drawArea.shouldDragLayer,
		user: state.auth.user
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			drawAreaMouseUp,
			drawAreaMouseMove,
			drawAreaMouseDown,
			noteAdjustZoom,
			drawAreaSetSizes,
			zoomPanMoved,
			zoomSetPanBy,
			zoomWheel,
			labelReset,
			offsetUpdate,
			imageSetRealSizes,
			layerAdjustScaleNotes,
			layerSetLabelsVisible,
			sketchResetActivePoint,
			printActivateReady,
			instrumentChange,
			drawAreaShiftConstraintDisabled,
			drawAreaShiftConstraintEnabled
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawArea);
