import { Helper } from 'dxf';
import { imageAdd, imageSetRealSizes, imageUpdateUrl } from './ImageActions';
import { dataURItoBlob } from '../reducers/PdfReducer';
import ApiHelper from '../ApiHelper';
import { notificationShow } from './notificationActions';
import { notificationTypes } from '../constants/notificationConstants';
import { canvasReset } from './drawAreaActions';
import { modalClose } from '../reducers/ModalReducer';
import { instrumentChange } from '../reducers/instrumentReducer';
import { PAN_MOVE } from '../classes/Instruments';
import * as types from '../constants/ImageConstants';

export const isDXF = ({ name }) => /^.*\.dxf$/i.test(name);
export const isSVG = ({ name }) => /^.*\.svg$/i.test(name);
export const isDWG = ({ name }) => /^.*\.dwg$/i.test(name);

const readFile = file => {
	return new Promise(resolve => {
		const reader = new FileReader();
		// Read the file
		reader.readAsText(file);
		// Specify the handler for the `load` event
		reader.onload = function(e) {
			return resolve(e.target.result);
		};
	});
};

const loadXHR = url => {
	return new Promise(function(resolve, reject) {
		try {
			const xhr = new XMLHttpRequest();
			xhr.open('GET', url);
			xhr.responseType = 'blob';
			xhr.onerror = function() {
				reject('Network error.');
			};
			xhr.onload = function() {
				if (xhr.status === 200) {
					resolve(xhr.response);
				} else {
					reject('Loading error:' + xhr.statusText);
				}
			};
			xhr.send();
		} catch (err) {
			reject(err.message);
		}
	});
};

const svgToImage = svg => {
	return dispatch => {
		const canvas = document.createElement('canvas'),
			context = canvas.getContext('2d'),
			image = new Image();

		image.src = 'data:image/svg+xml;base64,' + btoa(svg);
		image.onload = function() {
			let { width, height } = image;

			if (width < 600 || height < 600) {
				let k = 600 / width;
				width = k * width;
				height = k * height;
			}

			canvas.setAttribute('width', width);
			canvas.setAttribute('height', height);

			//create a rectangle with the desired color
			context.fillStyle = '#FFFFFF';
			context.fillRect(0, 0, width, height);
			context.drawImage(image, 0, 0);

			const canvasdata = canvas.toDataURL('image/jpeg'),
				blob = dataURItoBlob(canvasdata),
				file = new File([blob], 'image.jpg', { type: blob.type });

			dispatch(
				imageAdd(file, {
					width: canvas.width,
					height: canvas.height
				})
			);
		};
	};
};

export function dxfUpload(dxfFile) {
	return dispatch => {
		readFile(dxfFile).then(file => {
			const helper = new Helper(file);
			const svg = helper.toSVG();
			dispatch(svgToImage(svg));
		});
	};
}

export function svgUpload(svgFile) {
	return dispatch => {
		readFile(svgFile).then(svg => {
			dispatch(svgToImage(svg));
		});
	};
}

//ZamZar
export function upload(file) {
	return dispatch => {
		const formData = new FormData();
		formData.append('file', file);

		dispatch(modalClose());
		dispatch(instrumentChange(PAN_MOVE));
		dispatch(notificationShow('Converting to image. Waiting...', notificationTypes.INFO, 1000 * 60 * 5));

		ApiHelper.upload(formData)
			.then(response => {
				if (response.data.success) {
					const img = document.createElement('img');
					img.src = response.data.path;
					img.onload = async function() {
						let { width, height } = img;

						if (width < 600 || height < 600) {
							let k = 600 / width;
							width = k * width;
							height = k * height;
						}

						const imageBlob = await loadXHR(response.data.path);
						dispatch(notificationShow('Image uploaded successfully', notificationTypes.SUCCESS));
						dispatch({
							type: types.IMAGE_ADD,
							payload: {
								image: imageBlob,
								sizes: {
									width: width,
									height: height
								}
							}
						});
						dispatch(imageUpdateUrl(response.data.path));
						dispatch(imageSetRealSizes());
					};
				}
			})
			.catch(error => {
				let message = 'Unable to upload file, please try again later.';

				if (error.response && error.response.data.message) {
					message = error.response.data.message;
				}

				dispatch(canvasReset());
				dispatch(notificationShow(message, notificationTypes.DANGER));
			});
	};
}
