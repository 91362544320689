import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { modalClose } from '../../../../../shared/reducers/ModalReducer';
import TrialEnd from '../../../../App/User/TrialEnd';

class AccountTrialEndField extends React.Component {
	render() {
		return (
			<div className='field'>
				<TrialEnd text='Trial Ends:' />
				<Link onClick={this.props.modalClose.bind(this)} className='btn btn-modal' to={`/subscription`}>
					Visit Subscription Page
				</Link>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			modalClose
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(AccountTrialEndField);
