import store from '../store';

import Sketch from '../classes/Sketch';
import * as types from '../constants/SketchConstants';
import * as instrumentsTypes from '../classes/Instruments';

const initialState = {
	sketch: new Sketch(),
	activeIndex: null,
	activeIndexBeforeClose: null,
	dragIndex: null,
	dragShapeIndex: null,
	dragAngleIndex: null,
	dragSupportPoint: null,
	willClose: false,
	currentLine: null,
	currentShape: null,
	currentMask: null
};

export function sketchReducer(state = { ...initialState }, action) {
	let instrument;

	if (store) {
		instrument = store.getState().instrument;
	}

	if (
		![types.SKETCH_UPDATE_SKETCH, types.SKETCH_RESET].includes(action.type) &&
		instrument &&
		[instrumentsTypes.PAN_MOVE].includes(instrument) &&
		!state.sketch.closed &&
		!state.sketch.angleClosed
	) {
		return state;
	}

	switch (action.type) {
		case types.SKETCH_UPDATE_SKETCH: {
			return { ...state, sketch: action.payload.sketch };
		}
		case types.SKETCH_SET_CURRENT_LINE: {
			let sketch = Sketch.restoreFromDump(state.sketch.convertForSave());

			sketch.setCurrentLine(action.payload.currentLine);

			return {
				...state,
				sketch: sketch,
				currentLine: action.payload.currentLine
			};
		}
		case types.SKETCH_SET_CURRENT_SHAPE: {
			let sketch = Sketch.restoreFromDump(state.sketch.convertForSave());

			sketch.setCurrentShape(action.payload.currentShape);

			return {
				...state,
				sketch: sketch,
				currentShape: action.payload.currentShape
			};
		}
		case types.SKETCH_RESET_CURRENT_LINE: {
			let sketch = Sketch.restoreFromDump(state.sketch.convertForSave());

			sketch.setCurrentLine(null);

			return {
				...state,
				sketch,
				currentLine: null
			};
		}
		case types.SKETCH_RESET_CURRENT_SHAPE: {
			let sketch = Sketch.restoreFromDump(state.sketch.convertForSave());

			sketch.setCurrentShape(null);

			return {
				...state,
				sketch,
				currentShape: null
			};
		}

		case types.SKETCH_SET_DRAG_INDEX: {
			return { ...state, dragIndex: action.payload.index };
		}

		case types.SKETCH_UPDATE_POINT: {
			let sketch = Sketch.restoreFromDump(state.sketch.convertForSave());

			if (state.dragSupportPoint !== null) {
				sketch.updateSupportPoint(
					state.dragSupportPoint.pointIndex,
					state.dragSupportPoint.supportIndex,
					action.payload.point
				);
			} else if (state.dragIndex !== null) {
				if (instrument === instrumentsTypes.SELECT_TOOL && !sketch.closed) {
					sketch.updateControlPoint(state.dragIndex, action.payload.point, false);
				} else {
					sketch.updateControlPoint(state.dragIndex, action.payload.point);
				}
			} else if (state.dragAngleIndex !== null) {
				sketch.updateAnglePoint(state.dragAngleIndex, action.payload.point);
			}

			return {
				...state,
				sketch
			};
		}
		case types.SKETCH_UPDATE_LAT_LNG: {
			let sketch = Sketch.restoreFromDump(state.sketch.convertForSave());

			if (state.dragIndex !== null) {
				if (instrument === instrumentsTypes.SELECT_TOOL && !sketch.closed) {
					sketch.updateControlLatLngPoint(state.dragIndex, action.payload.latLng, false);
				} else {
					sketch.updateControlLatLngPoint(state.dragIndex, action.payload.latLng);
				}
			} else if (state.dragAngleIndex !== null) {
				sketch.updateAngleLatLngPoint(state.dragAngleIndex, action.payload.latLng);
			}

			return {
				...state,
				sketch
			};
		}
		case types.SKETCH_CURVED_LINE_PUSH_POINT: {
			let { currentLine } = state;

			currentLine.pushPoint(action.payload.point);
			return { ...state, currentLine };
		}

		case types.SKETCH_SET_WILL_CLOSE: {
			return { ...state, willClose: action.payload.value };
		}

		case types.SKETCH_POINT_MOUSE_DOWN: {
			if (!state.sketch.closed && !action.payload.isShape) {
				if (action.payload.ignoreClose) {
					return {
						...state,
						activeIndexBeforeClose: action.payload.index
					};
				} else {
					return state;
				}
			}

			if (action.payload.isShape) {
				return {
					...state,
					dragShapeIndex: action.payload.index,
					activeIndex: null,
					activeIndexBeforeClose: null
				};
			} else {
				return {
					...state,
					dragIndex: action.payload.index,
					activeIndex: action.payload.index
				};
			}
		}

		case types.SKETCH_SUPPORT_POINT_MOUSE_DOWN: {
			return { ...state, dragSupportPoint: action.payload };
		}

		case types.SKETCH_RESET_SUPPORT_POINT: {
			return { ...state, dragSupportPoint: null };
		}

		case types.SKETCH_RESET_ACTIVE_POINT: {
			return { ...state, activeIndex: null, activeIndexBeforeClose: null };
		}

		case types.SKETCH_ANGLE_MOUSE_DOWN: {
			if (state.sketch.closed || !state.sketch.angleClosed) {
				return state;
			}
			return {
				...state,
				dragAngleIndex: [action.payload.index, action.payload.pointIndex]
			};
		}

		case types.SKETCH_STOP_DRAG: {
			return {
				...state,
				dragIndex: null,
				dragAngleIndex: null,
				dragShapeIndex: null
			};
		}

		case types.SKETCH_SET_CURRENT_MASK: {
			return { ...state, currentMask: action.payload.value };
		}

		case types.SKETCH_ADD_TO_MASK: {
			let mask = state.currentMask;

			if (mask) {
				mask.addToArea(action.payload.value);

				return { ...state, currentMask: mask };
			}

			return state;
		}

		case types.SKETCH_SUB_FROM_MASK: {
			let mask = state.currentMask;

			if (mask) {
				mask.substractFromArea(action.payload.value);

				return { ...state, currentMask: mask };
			}

			return state;
		}

		case types.RESET_CURRENT_MASK: {
			return {
				...state,
				currentMask: null
			};
		}

		case types.CONFIRM_CURRENT_MASK: {
			let sketch = Sketch.restoreFromDump(state.sketch.convertForSave());

			let result = state.currentMask.getResultPolygons();

			sketch.addLinesFromPolygon(result[0]);

			return {
				...state,
				sketch,
				currentMask: null
			};
		}

		case types.SKETCH_SET_MAGIC_WAND_TOLERANCE: {
			let mask = state.currentMask;

			mask.setTolerance(action.payload.value);

			return { ...state, currentMask: mask };
		}

		case types.SKETCH_SET_MAGIC_WAND_BLUR: {
			let mask = state.currentMask;

			mask.setBlur(action.payload.value);

			return { ...state, currentMask: mask };
		}

		case types.SKETCH_SET_MAGIC_WAND_COMPOUND: {
			let mask = state.currentMask;

			mask.setCompound(action.payload.value);

			return { ...state, currentMask: mask };
		}

		case types.SKETCH_RESET: {
			return { ...initialState, sketch: new Sketch() };
		}

		default: {
			return state;
		}
	}
}
