import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { helpActivateMap } from '../../../shared/actions/helpActions';

class MapControls extends React.Component {
	render() {
		return (
			<div
				style={{
					top: 60,
					left: 10
				}}
				className='contextual-modal arrow-top'>
				<h3 className='title'>Map Controls</h3>
				<div className='content'>
					Search an address or enter
					<br />
					longitude and latitude coordinates
					<br />
					to find the area before tracing
				</div>
				<div className='actions'>
					<button
						onClick={() => {
							this.props.helpActivateMap();
						}}
						className='btn btn-sm btn-modal'>
						ok
					</button>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			helpActivateMap
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(MapControls);
