import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { settingsAcceptCookies } from '../../shared/actions/settingsActions';
import CookieHelper from '../../shared/CookieHelper';

class CookiesNote extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			message: null
		};
	}

	componentDidMount() {
		if (CookieHelper.cookiesAccepted()) {
			this.props.settingsAcceptCookies();
		}
	}

	render() {
		return (
			<div className='cookies-note-component'>
				<div className='content'>
					<div className='row no-gutters align-items-center app-header'>
						<div className='col col-auto'>
							SketchAndCalc uses cookies to ensure you get the best experience
							<a
								className='content__link'
								href={
									process.env.REACT_APP_MAIN_WP_URL
										? `${process.env.REACT_APP_MAIN_WP_URL}/cookie-policy`
										: '/cookie-policy'
								}>
								more info
							</a>
						</div>
						<div className='col row no-gutters justify-content-end'>
							<button
								className='btn accept'
								onClick={() => {
									this.props.settingsAcceptCookies();
								}}>
								Got it
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapDispatchToPros = dispatch => {
	return bindActionCreators(
		{
			settingsAcceptCookies
		},
		dispatch
	);
};

export default connect(null, mapDispatchToPros)(CookiesNote);
