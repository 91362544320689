import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { printDeactivate } from '../../../shared/reducers/printReducer';
import { modalClose } from '../../../shared/reducers/ModalReducer';

class PrintImage extends React.Component {
	print() {
		window.print();
		this.props.printDeactivate();
		this.props.modalClose();
	}

	render() {
		if (!this.props.image) {
			return null;
		}

		return <img className='print-image' onLoad={this.print.bind(this)} src={this.props.image} alt='Print Screen' />;
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		image: state.print.image
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			printDeactivate,
			modalClose
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintImage);
