import React from 'react';
import DocumentMeta from 'react-document-meta';
import { mobileDetect } from '../../../shared/store';

export default class DownloadPage extends React.Component {
	render() {
		return (
			<section id='download'>
				<DocumentMeta
					title='Download | Area Calculator Software and App for Irregular Shapes'
					description='Download our software or app to start calculating the area to irregular shapes. Our area calculator software can be used one or offline. Try it today!'
				/>
				<div>
					<h2>Download for your device</h2>

					{!mobileDetect.mobile() && (
						<h6>Go ‘browser-less’. Log in to SketchAndCalc using any of the platforms below</h6>
					)}
					{mobileDetect.mobile() && (
						<h6>Create your account using a desktop browser then log-in to your app</h6>
					)}

					<div id='tiles'>
						<figure>
							<div className='card'>
								<a
									target='_blank'
									rel='noopener noreferrer'
									href='https://www.microsoft.com/en-us/p/sketchandcalc-area-calculator/9n40104njj6n'
									className='card'>
									<span className='icon icon-windows' />
									<span>Windows</span>
								</a>
							</div>
						</figure>
						<figure>
							<a
								target='_blank'
								rel='noopener noreferrer'
								href='https://apps.apple.com/app/id1397412180?mt=12'
								className='card'>
								<span className='icon icon-mac_osx' />
								<span>Mac</span>
							</a>
						</figure>
						<figure>
							<a
								target='_blank'
								rel='noopener noreferrer'
								href='https://play.google.com/store/apps/details?id=com.iCalc.SketchAndCalc'
								className='card'>
								<span className='icon icon-android' />
								<span>Android</span>
							</a>
						</figure>
						<figure>
							<a
								target='_blank'
								rel='noopener noreferrer'
								href='https://itunes.apple.com/app/sketchandcalc-area-calculator/id449506634'
								className='card'>
								<span className='icon icon-apple' />
								<span>iOS</span>
							</a>
						</figure>
					</div>
				</div>
			</section>
		);
	}
}
