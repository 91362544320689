import { ANGLE_LINE } from './Instruments';
import LineInterface from './LineInterface';
import MathHelper from '../MathHelper';

export default class AngleLine extends LineInterface {
	constructor(startPoint = null, endPoint = null, startLatLngPoint = null, endLatLngPoint = null) {
		super();

		this.type = ANGLE_LINE;

		this.startPoint = startPoint;
		this.endPoint = endPoint;
		this.startLatLngPoint = startLatLngPoint;
		this.endLatLngPoint = endLatLngPoint;
	}

	start(point) {
		this.startPoint = point;
	}

	end(point) {
		this.endPoint = point;
	}

	startLatLng(latLng) {
		this.startLatLngPoint = latLng;
	}

	endLatLng(latLng) {
		this.endLatLngPoint = latLng;
	}

	getType() {
		return this.type;
	}

	getStartPoint() {
		return this.startPoint;
	}

	getEndPoint() {
		return this.endPoint;
	}

	getStartLatLngPoint() {
		return this.startLatLngPoint;
	}

	getEndLatLngPoint() {
		return this.endLatLngPoint;
	}

	getPoints() {
		return [this.startPoint, this.endPoint];
	}

	getLatLngPoints() {
		return [this.startLatLngPoint, this.endLatLngPoint];
	}

	updatePoint(pointIndex, newPoint) {
		if (pointIndex === 0) {
			this.startPoint = newPoint;
		} else {
			this.endPoint = newPoint;
		}
	}

	updateLatLngPoint(latLngIndex, latLng) {
		if (latLngIndex === 0) {
			this.startLatLngPoint = latLng;
		} else {
			this.endLatLngPoint = latLng;
		}
	}

	isValidLineIfEndAt(endPoint, zoom) {
		let allowance = 10 / zoom;

		return MathHelper.lineLength(this.startPoint, endPoint) > allowance;
	}

	toArray() {
		let startPoint = null,
			endPoint = null;

		if (Array.isArray(this.startPoint)) {
			startPoint = [...this.startPoint];
		}

		if (Array.isArray(this.endPoint)) {
			endPoint = [...this.endPoint];
		}

		return [startPoint, endPoint];
	}

	static fromArray(serializedObject) {
		let startPoint = null,
			endPoint = null;

		if (Array.isArray(serializedObject[0])) {
			startPoint = [...serializedObject[0]];
		}

		if (Array.isArray(serializedObject[1])) {
			endPoint = [...serializedObject[1]];
		}
		return new this(startPoint, endPoint);
	}

	clone() {
		return this.constructor.fromArray(this.toArray());
	}
}
