import React from 'react';
import { bindActionCreators } from 'redux';
import { modalOpen } from '../../../shared/reducers/ModalReducer';
import { connect } from 'react-redux';
import { MODAL_EDUCATOR_DASHBOARD } from '../../ModalContainer/Modals/ModalEducatorDashboard';
import { canvasReset } from '../../../shared/actions/drawAreaActions';

import './ToEducatorDashboard.scss';


class ToEducatorDashboard extends React.Component {

    onClick = () => {
        const { canvasReset, modalOpen } = this.props;
        canvasReset();
        modalOpen(MODAL_EDUCATOR_DASHBOARD)
    }

    render() {
        return (
            <div className={'modal-close'} onClick={this.onClick}>
                <i className='icon icon-close_icn close-note' />
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            modalOpen,
            canvasReset
        },
        dispatch
    );
}

export default connect(null, mapDispatchToProps)(ToEducatorDashboard)