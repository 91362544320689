import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { modalClose } from '../../../../shared/reducers/ModalReducer';
import DocumentsTab from './DocumentsTab/DocumentsTab';
import SessionTab from './SessionTab/SessionTab';
import { getExistingSession } from '../../../../shared/actions/educatorActions';

import './ModalEducatorDashboard.scss';


class ModalEducatorDashboard extends React.Component {
	SESSION_TAB = 'Session';
	DOCUMENTS_TAB = 'Documents';

	constructor(props) {
		super(props);

		this.state = {
			activeTab: this.SESSION_TAB
		};
	}

	async componentWillMount() {
		await this.props.getExistingSession()
	}

	isActiveTab(tab) {
		return this.state.activeTab === tab;
	}

	changeTab(tab) {
		if (!this.isActiveTab(tab)) {
			this.setState({ activeTab: tab });
		}
	}
	render() {
		return (
			<div className='modal-inner-wrapper modal-educator-dashboard'>
				<h2 className='title'>Educator Dashboard</h2>
				<div className='tabs'>
					<div
						className={`tab${this.isActiveTab(this.SESSION_TAB) ? ' active' : ''}`}
						onClick={() => {
							this.changeTab(this.SESSION_TAB);
						}}>
						{this.SESSION_TAB}
					</div>
					<div
						className={`tab${this.isActiveTab(this.DOCUMENTS_TAB) ? ' active' : ''}`}
						onClick={() => {
							this.changeTab(this.DOCUMENTS_TAB);
						}}>
						{this.DOCUMENTS_TAB}
					</div>
				</div>
				{this.isActiveTab(this.SESSION_TAB) && <SessionTab />}
				{this.isActiveTab(this.DOCUMENTS_TAB) && <DocumentsTab />}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		auth: {
			user
		}
	} = state;

	return {
		user
	};
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getExistingSession,
			modalClose
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEducatorDashboard);
