import * as React from 'react';
import { connect } from 'react-redux';
import LayerLabel from '../LayerLabel/LayerLabel';
import MathHelper from '../../../shared/MathHelper';
import isEqual from 'lodash.isequal';

class LayerLabels extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			points: []
		}
	}

	componentWillMount() {
		this.calculatePoints()
	}

	componentDidUpdate(prevProps, prevState) {
		if (!isEqual(this.props.layers, prevProps.layers) || this.props.fileName !== prevProps.fileName) {
			this.setState({points: []})
			this.calculatePoints()
		}
	}

	calculatePoints = () => {
		let points = this.state.points;
		this.props.layers.map((layer) => {
			const sketch = layer.getSketch();
			points = this.getPointsWithoutCollision({sketch, points})
		})
		this.setState({points})
	}

	getPointsWithoutCollision = ({sketch, points}) => {
		const { controlPoints } = sketch
		let maxXPoint = null
		let maxYPoint = null
		let minXPoint = null
		let minYPoint = null
		controlPoints.map(([x,y]) => {
			if (!maxXPoint || maxXPoint < x) {
				maxXPoint = x
			}
			if (!maxYPoint || maxYPoint < y) {
				maxYPoint = y
			}
			if (!minYPoint || minYPoint > y) {
				minYPoint = y
			}
			if (!minXPoint || minXPoint > x) {
				minXPoint = x
			}
		})
		let xOffset = (maxXPoint-minXPoint)/2.5
		let yOffset = (maxYPoint-minYPoint)/2.5
		xOffset = xOffset>maxXPoint? maxXPoint : xOffset
		yOffset = yOffset>maxYPoint? maxYPoint : yOffset
		const initialPoint = sketch.getCenterPoint()
		const [x, y] = initialPoint
		let newX = x
		let newY = y
		let biggestXCollisionPoint = null
		let biggestYCollisionPoint = null
		const antiCollisionPoint = points.map(([pointX, pointY]) => {
			const xDef = parseInt(pointX - x)
			const yDef = parseInt(pointY - y)
			const collisionXLeft = xDef < xOffset
			const collisionXRight = xDef < 0 && xDef > -xOffset
			const collisionYTop = yDef < 0 && yDef > -yOffset
			const collisionYBottom = yDef < yOffset

			if (collisionXLeft) {
				newX = ((biggestXCollisionPoint || x) - xOffset)
				biggestXCollisionPoint = x
			}

			if (collisionXRight) {
				newX = ((biggestXCollisionPoint || x) + xOffset)
				biggestXCollisionPoint = newX
			}

			if (collisionYTop) {
				newY = ((biggestYCollisionPoint || y) + yOffset)
				biggestYCollisionPoint = newY
			}

			if (collisionYBottom) {
				newY = ((biggestYCollisionPoint || y) - yOffset)
				biggestYCollisionPoint = y
			}

			return newX || newY
		})

		const result =  antiCollisionPoint ? [newX, newY] : initialPoint;
		const notIsNan = !isNaN(result[0]) && !isNaN(result[1]);

		return notIsNan? [...points, result] : [...points, []];
	}

	getLabels() {
		const { points } = this.state;
		return this.props.layers.map((layer, index) => {
			const sketch = layer.getSketch();
			const isClosed = sketch.closed;
			const { scale, measure, isFractions } = this.props;
			let text = `  ${sketch.getArea(scale)} ${measure}`;

			if (measure === 'yd' && isFractions) {
				text = MathHelper.yardsFeet(text);
			} else if (measure === 'ft' && isFractions) {
				text = MathHelper.feetInches(text);
			}

			if (!isClosed) return null;

			return (
				<LayerLabel
					key={index}
					zoom={this.props.zoom}
					point={points[index]}
					layer={layer.getId()}
					color={layer.getColor()}
					text={text}
					isPinned={this.props.isPinned}
				/>
			);
		});
	}

	render() {
		return <g className='layer-labels'>{this.getLabels()}</g>;
	}
}

const mapStateToProps = state => ({
	measure: state.scale.measure,
	isFractions: state.settings.imperial === 'fractions',
	fileName: state.fileName.fileName
});

export default connect(mapStateToProps)(LayerLabels);
