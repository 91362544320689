import React from 'react';

const Art6 = ({color="#fff"}) => {

    return (
        <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 200 200">
            <path className="cls-1" style={{ fill: color }} d="M85.7,74.12h-12v-8h12Zm0,47h-12V83h12Z"/>
            <path className="cls-1"
                  style={{ fill: color }}
                  d="M104,83l.45,5.18.11,0A13.29,13.29,0,0,1,109,83.81a12,12,0,0,1,6.11-1.55q5.7,0,8.81,3.56t3.1,11.63v23.63H115.15V97.41c0-2.23-.44-3.79-1.32-4.67a5.5,5.5,0,0,0-4-1.32,7.2,7.2,0,0,0-3,.58,6.24,6.24,0,0,0-2.18,1.68v27.4H92.78V83Z"/>
            <rect className="cls-1" style={{ fill: color }} width="28.5" height="28.5"/>
            <rect className="cls-1" style={{ fill: color }} x="171.5" width="28.5" height="28.5"/>
            <rect className="cls-1" style={{ fill: color }} x="171.5" y="171.5" width="28.5" height="28.5"/>
            <line className="cls-2" style={{ stroke: color, strokeMiterlimit:10, strokeWidth: '5px' }} x1="28.5" y1="14.25" x2="171.5" y2="14.25"/>
            <line className="cls-2" style={{ stroke: color, strokeMiterlimit:10, strokeWidth: '5px' }} x1="185.75" y1="28.5" x2="185.75" y2="171.5"/>
        </svg>
    )
}

export default Art6