import * as React from 'react';
import isEqual from 'lodash.isequal';
import { stopPropagation } from '../../../shared/EventHelper';
import { STRAIGHT_LINE, SELECT_TOOL } from '../../../shared/classes/Instruments';
import { DIAMETER_LINE } from '../../../shared/classes/DiameterLine';
import { renderMoveVertex } from '../../ContextualHelp/Modals/MoveVertex';

export class ControlPoints extends React.Component {
	pointEl = null;

	render() {
		return <g className='control-points'>{this.renderPoints()}</g>;
	}

	renderPoints() {
		let strokeWidth = 1 / (this.props.zoom || 1),
			line = this.props.line,
			pointIndex = this.props.pointIndex;

		return this.props.points.map((point, index) => {
			let classNames = ['point'],
				rectSize = 6 / (this.props.zoom || 1);

			if (line && [STRAIGHT_LINE, DIAMETER_LINE].includes(line.getType()) && pointIndex >= 0) {
				if (isEqual(line.getPoints()[pointIndex], point)) {
					classNames.push('active');
				}
			}

			if (this.props.activeIndex === index && !classNames.includes('active')) {
				classNames.push('active');
			}

			if (classNames.includes('active')) {
				rectSize *= 1.2;
			}

			return (
				<rect
					ref={el => {
						if (index === 0) {
							this.pointEl = el;
						}
					}}
					className={classNames.join(' ')}
					width={rectSize}
					height={rectSize}
					x={point[0] - rectSize / 2}
					y={point[1] - rectSize / 2}
					key={index}
					strokeWidth={strokeWidth}
					onTouchStart={event => {
						if (this.props.closed) {
							stopPropagation(event);
							this.props.mouseDown(index, event, this.props.isShape);
						} else {
							this.props.sketchSetDragIndex(index);
						}
					}}
					onMouseEnter={this.props.onMouseEnter}
					onMouseLeave={this.props.onMouseLeave}
					onMouseDown={event => {
						this.props.onMouseDown(index, event);
						if (this.props.closed) {
							stopPropagation(event);
							this.props.mouseDown(index, event, this.props.isShape);
						} else {
							this.props.sketchSetDragIndex(index);
						}
					}}
					onMouseUp={this.props.onMouseUp}
				/>
			);
		});
	}

	componentDidUpdate() {
		if (this.pointEl && this.props.closed && !this.props.helpMoveVertex && renderMoveVertex) {
			const bounds = this.pointEl.getBoundingClientRect();

			renderMoveVertex({
				top: bounds.top,
				left: bounds.left,
				width: bounds.width,
				height: bounds.height
			});
		}
	}
}
