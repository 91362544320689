import React from 'react';
import ModalContainer from '../ModalContainer/ModalContainer';

import maintenance from '../../assets/img/Maintenance_banner.png';

const locked_for_maintenance = false;

export default class RootLayout extends React.Component {
	render() {
		if (locked_for_maintenance) {
			return (
				<div id='over'>
					<img src={maintenance} />
				</div>
			);
		} else {
			return (
				<div className='site-root'>
					{this.props.children}
					<ModalContainer />
				</div>
			);
		}
	}
}
