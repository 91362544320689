import React, { Component } from 'react';

import LineLabel from '../LineLabel/LineLabel';
import MathHelper from '../../../shared/MathHelper';
import { ANGLE_LINE } from '../../../shared/classes/Instruments';
import { gridSize } from '../CanvasGrid/CanvasGrid';

export default class PreviewLine extends Component {
	render() {
		if (!this.props.preview.display) {
			return null;
		}

		let showLabel = this.props.showLabel,
			fromPoint = this.props.preview.from,
			toPoint = this.props.preview.to,
			lineSize = MathHelper.lineLength(fromPoint, toPoint),
			instrument = this.props.instrument,
			strokeDashArray = 0;

		if (instrument === ANGLE_LINE) {
			showLabel = false;
			strokeDashArray = 5 / this.props.zoom;
		}

		return (
			<g className='preview-line'>
				<line
					strokeDasharray={strokeDashArray}
					strokeWidth={this.props.strokeWidth}
					x1={fromPoint[0]}
					y1={fromPoint[1]}
					x2={toPoint[0]}
					y2={toPoint[1]}
				/>
				{showLabel && (
					<LineLabel
						point={MathHelper.lineMiddlePoint(fromPoint, toPoint)}
						lineSize={lineSize}
						text={MathHelper.round((lineSize * this.props.scale) / gridSize)}
						rotate={MathHelper.lineAngle(fromPoint, toPoint)}
						zoom={this.props.zoom}
					/>
				)}
			</g>
		);
	}
}
