import * as types from '../constants/drawAreaConstants';

const initiateState = {
	mouseDown: false,
	visible: true,
	stageSizes: { width: null, height: null },
	minStageSizes: { width: 0, height: 0 },
	shiftHolded: false,
	shouldDragLayer: false
};

export default function drawAreaReducer(state = initiateState, action) {
	switch (action.type) {
		case types.DRAW_AREA_UPDATE: {
			return { ...state, ...action.payload.state };
		}

		case types.DRAW_AREA_SET_MOUSE_DOWN: {
			return { ...state, mouseDown: action.payload.value };
		}

		case types.DRAW_AREA_TOGGLE_VISIBILITY: {
			return { ...state, visible: !state.visible };
		}

		case types.DRAW_AREA_SET_SIZES: {
			return { ...state, stageSizes: action.payload.sizes };
		}

		case types.DRAW_AREA_SET_MIN_SIZES: {
			return { ...state, minStageSizes: action.payload.sizes };
		}

		case types.DRAW_AREA_RESET: {
			return { ...initiateState };
		}

		case types.DRAW_AREA_SHIFT_HOLD: {
			return { ...state, shiftHolded: true };
		}

		case types.DRAW_AREA_SHIFT_RELEASE: {
			return { ...state, shiftHolded: false };
		}

		case types.SET_SHOULD_DRAG_LAYER: {
			return { ...state, shouldDragLayer: action.payload.value };
		}

		default: {
			return state;
		}
	}
}
