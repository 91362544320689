export const IMAGE_ADD = 'IMAGE_ADD';
export const IMAGE_TOGGLE_VISIBILITY = 'IMAGE_TOGGLE_VISIBILITY';
export const IMAGE_UPDATE_POSITION = 'IMAGE_UPDATE_POSITION';
export const IMAGE_UPDATE_URL = 'IMAGE_UPDATE_URL';
export const IMAGE_ADD_TO_CANVAS = 'IMAGE_ADD_TO_CANVAS';
export const IMAGE_SET_REAL_SIZE = 'IMAGE_SET_REAL_SIZE';
export const IMAGE_SET_DRAG = 'IMAGE_SET_DRAG';
export const IMAGE_UPDATE = 'IMAGE_UPDATE';
export const IMAGE_RESET = 'IMAGE_RESET';
export const IMAGE_SET_DATA = 'IMAGE_SET_DATA';
