import React from 'react';
import ItemsList from '../../../../App/ItemsList';
import { bindActionCreators } from 'redux';
import { stateRestoreFromObject } from '../../../../../shared/actions/stateActions';
import { deleteSession, getSessionDocuments, getSessionsList } from '../../../../../shared/actions/educatorActions';
import { notificationShow } from '../../../../../shared/actions/notificationActions';
import { modalClose } from '../../../../../shared/reducers/ModalReducer';
import { connect } from 'react-redux';
import { authLogout } from '../../../../../shared/actions/authActions';

import './DocumentsTab.scss';


class DocumentsTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sessionsLoading: false,
            documentsLoading: false,
            activeSessionId: null,
            activeDocument: null,
            removeSessionConfirm: false,
            removeAllConfirm: false,
        }
    }

    async componentWillMount() {
        await this.fetchData()
    }

    fetchData = async() => {
        this.setState({ sessionsLoading: true, documentsLoading:true })
        const sessions = await this.props.getSessionsList();
        if (sessions && sessions[0]) {
            this.selectSession(sessions[0])
        } else {
            this.setState({ documentsLoading: false })
        }
        this.setState({ sessionsLoading: false })
    }

    selectSession = session => {
        const { getSessionDocuments } = this.props;
        this.setState({
            activeSessionId: session.id,
            documentsLoading: true,
            activeDocument: null,
            removeSessionConfirm: false,
            removeAllConfirm: false
        });
        getSessionDocuments(session.id).finally(() => {
            this.setState({documentsLoading: false})
        })
    }

    selectDocument = document => this.setState({ activeDocument: document })

    getSessionName = session => {
        const { name, documents_count } = session

        return <div className={'d-flex justify-content-between w-100'}>
            <div>{name}</div>
            <div className={'documents-counter'}>{documents_count}</div>
        </div>
    }

    getSessionTableTitle = () => {
        return <div className={'d-flex justify-content-between'}>
            <div>session</div>
            <div>#files</div>
        </div>
    }

    openDocument = () => {
        const { stateRestoreFromObject, modalClose } = this.props;
        const { activeDocument } = this.state;

        if (activeDocument) {
            stateRestoreFromObject(activeDocument);
            modalClose();
        }
    }

    getSessionItemClasses = (session) => {
        let classes = [];
        const { removeSessionConfirm, activeSessionId, removeAllConfirm } = this.state;
        const isCurrentSession = session.id === activeSessionId;

        if (removeSessionConfirm && isCurrentSession) {
            classes.push('blocked_by_current_device')
        }

        if (removeAllConfirm) {
            classes = ['blocked_by_current_device']
        }

        return classes;
    }

    getDocumentItemClasses = () => {
        const classes = [];
        const { removeSessionConfirm, removeAllConfirm } = this.state;

        if (removeSessionConfirm || removeAllConfirm) {
            classes.push('blocked_by_current_device')
        }

        return classes;
    }

    removeSessionConfirm = e => {
        e.stopPropagation();
        this.setState({ removeSessionConfirm: true, removeAllConfirm: false })
    }

    removeSession = async () => {
        const { activeSessionId } = this.state;
        const { deleteSession } = this.props

        if (activeSessionId) {
            this.setState({sessionsLoading: true});
            await deleteSession(activeSessionId);
        }

        this.setState({removeSessionConfirm: false, sessionsLoading: false})
    }

    removeAllConfirm = e => {
        e.stopPropagation();
        this.setState({ removeAllConfirm: true, removeSessionConfirm: false })
    }

    removeAll = async() => {
        const { sessionsList, deleteSession } = this.props;
        this.setState({sessionsLoading: true});
        await Promise.all(sessionsList.map(async session => {
            await deleteSession(session.id)
        }))
        this.setState({ removeAllConfirm: false, sessionsLoading: false })
    }

    outsideClick = () => {
        this.setState({
            removeSessionConfirm: false,
            removeAllConfirm: false
        })
    }

    render() {
        const { sessionsList, documents, authLogout } = this.props;
        const { sessionsLoading, activeSessionId, documentsLoading, activeDocument, removeSessionConfirm,
            removeAllConfirm } = this.state;

        return(
            <div className={'modal-open-educator modal-inner-wrapper'} onClick={this.outsideClick}>
                <div className={'row lists-row'}>
                    <div className={'col lists'}>
                        <div className={'sessions'}>
                            <ItemsList
                                withLoader
                                loading={sessionsLoading}
                                items={sessionsList}
                                title={this.getSessionTableTitle()}
                                itemLabelKey={'name'}
                                getLabel={this.getSessionName}
                                activeIndex={activeSessionId}
                                onClick={this.selectSession}
                                getItemClasses={this.getSessionItemClasses}
                            />
                        </div>
                    </div>
                    <div className={'col lists'}>
                        <div className={'documents'}>
                            <ItemsList
                                withLoader
                                loading={sessionsLoading || documentsLoading}
                                items={documents[activeSessionId] || []}
                                title={'name'}
                                itemLabelKey={'name'}
                                getLabel={document => document.name}
                                activeIndex={activeDocument? activeDocument.id : null}
                                onClick={this.selectDocument}
                                getItemClasses={this.getDocumentItemClasses}
                            />
                        </div>
                    </div>
                </div>
                <div className={'mb-2 mt-4 d-flex justify-content-between'}>
                    <button
                        disabled={!activeSessionId || sessionsLoading || !activeSessionId}
                        className={`mr-2 ${removeSessionConfirm? 'error-btn' : 'outlined-btn'}`}
                        onClick={removeSessionConfirm? this.removeSession : this.removeSessionConfirm}
                    >
                        { removeSessionConfirm? 'Click again to Delete' : 'Delete session' }
                    </button>
                    <button
                        disabled={!activeDocument}
                        className={'outlined-btn ml-2'}
                        onClick={this.openDocument}
                    >
                        Open
                    </button>
                </div>
                <button
                    disabled={sessionsList.length === 0 || sessionsLoading || documentsLoading}
                    className={`my-2 ${removeAllConfirm? 'error-btn' : 'outlined-btn'}`}
                    onClick={ removeAllConfirm? this.removeAll : this.removeAllConfirm }
                >
                    {removeAllConfirm? 'Click again to delete all files' : 'Delete all files'}
                </button>
                <button
                    className={'secondary-btn my-2'}
                    onClick={authLogout}
                >
                    Log Out
                </button>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        educator: {
            sessionsList,
            documents
        }
    } = state

    return {
        sessionsList,
        documents
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        stateRestoreFromObject,
        getSessionDocuments,
        notificationShow,
        getSessionsList,
        deleteSession,
        authLogout,
        modalClose
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTab)