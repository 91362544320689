import cookie from 'js-cookie';

const INDICATING_COOKIE_NAME = 'cookiesAccepted';
const ADDTHIS_URL = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5304a73729606797';

export default class CookieHelper {
	static init() {
		CookieHelper.disableMarketingCookies();
	}

	static cookiesAccepted() {
		return cookie.get(INDICATING_COOKIE_NAME);
	}

	static acceptCookies(marketingEnabled) {
		cookie.set(INDICATING_COOKIE_NAME, true);
		if (marketingEnabled) {
			CookieHelper.enableMarketingCookies();
			CookieHelper.loadScript(ADDTHIS_URL);
		}
	}

	static loadScript(url) {
		let scriptEl = document.createElement('script');
		scriptEl.setAttribute('src', url);
		document.head.appendChild(scriptEl);
	}

	static enableMarketingCookies() {
		document.addthis_config = {
			data_use_cookies_ondomain: !1,
			data_use_cookies: !1
		};
		window['ga-disable-' + process.env.REACT_APP_GA_ID] = false;
	}

	static disableMarketingCookies() {
		document.addthis_config = {
			data_use_cookies_ondomain: false,
			data_use_cookies: false
		};
		window['ga-disable-' + process.env.REACT_APP_GA_ID] = true;
	}
}
