import { STRAIGHT_LINE } from './Instruments';
import LineInterface from './LineInterface';
import MathHelper from '../MathHelper';
import * as d3 from 'd3-polygon';

export default class Line extends LineInterface {
	constructor(startPoint = null, endPoint = null, startLatLngPoint = null, endLatLngPoint = null) {
		super();

		this.type = STRAIGHT_LINE;

		this.startPoint = startPoint;
		this.endPoint = endPoint;
		this.startLatLngPoint = startLatLngPoint;
		this.endLatLngPoint = endLatLngPoint;

		this.isConstrained = false;
	}

	isConstrainedLine() {
		return this.isConstrained;
	}

	setConstrainedLine(value) {
		this.isConstrained = value;
	}

	start(point) {
		this.startPoint = point;
	}

	end(point) {
		this.endPoint = point;
	}

	startLatLng(latLng) {
		this.startLatLngPoint = latLng;
	}

	endLatLng(latLng) {
		this.endLatLngPoint = latLng;
	}

	toPath(first = false) {
		if (!Array.isArray(this.startPoint) || !Array.isArray(this.endPoint)) {
			return '';
		}

		let pathString = 'L' + this.endPoint[0] + ',' + this.endPoint[1];

		if (first) {
			pathString = 'M' + this.startPoint[0] + ',' + this.startPoint[1] + pathString;
		}

		return pathString;
	}

	getType() {
		return this.type;
	}

	getStartPoint() {
		return this.startPoint;
	}

	getEndPoint() {
		return this.endPoint;
	}

	getStartLatLngPoint() {
		return this.startLatLngPoint;
	}

	getEndLatLngPoint() {
		return this.endLatLngPoint;
	}

	getPoints() {
		return [this.startPoint, this.endPoint];
	}

	getLatLngPoints() {
		return [this.startLatLngPoint, this.endLatLngPoint];
	}

	getLength(scale = 1) {
		return MathHelper.round((d3.polygonLength([this.startPoint, this.endPoint]) / 2) * scale);
	}

	getMiddlePoint() {
		return [(this.startPoint[0] + this.endPoint[0]) / 2, (this.startPoint[1] + this.endPoint[1]) / 2];
	}

	getLabelAngle() {
		let x = this.endPoint[0] - this.startPoint[0];
		let y = this.endPoint[1] - this.startPoint[1];
		let theta = Math.atan2(y, x);
		theta *= 180 / Math.PI;

		return theta;
	}

	updatePoint(pointIndex, newPoint) {
		if (pointIndex === 0) {
			this.startPoint = newPoint;
		} else {
			this.endPoint = newPoint;
		}
	}

	updateLatLngPoint(latLngIndex, latLng) {
		if (latLngIndex === 0) {
			this.startLatLngPoint = latLng;
		} else {
			this.endLatLngPoint = latLng;
		}
	}

	isValidLineIfEndAt(endPoint, zoom) {
		let allowance = 3 / zoom;

		return MathHelper.lineLength(this.startPoint, endPoint) > allowance;
	}

	toArray() {
		let startPoint = null,
			endPoint = null;

		if (Array.isArray(this.startPoint)) {
			startPoint = [...this.startPoint];
		}

		if (Array.isArray(this.endPoint)) {
			endPoint = [...this.endPoint];
		}

		return [startPoint, endPoint];
	}

	static fromArray(serializedObject) {
		let startPoint = null,
			endPoint = null;

		if (Array.isArray(serializedObject[0])) {
			startPoint = [...serializedObject[0]];
		}

		if (Array.isArray(serializedObject[1])) {
			endPoint = [...serializedObject[1]];
		}
		return new this(startPoint, endPoint);
	}

	clone() {
		return this.constructor.fromArray(this.toArray());
	}
}
