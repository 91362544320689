import React from 'react';

const UpDown = ({height= 30, width = 40, color='#fff'}) => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px"
         viewBox="0 0 85.04 50.22"
         height={height}
         width={width}
         className={'up-down-icon'}
    >
        <path fill={color} d="M0,50.22L42.52,0l42.52,50.22H0z"/>
    </svg>
);

export default UpDown;