import Sketch from './Sketch';
import Note from './Note';
import isEmpty from 'lodash.isempty';

export default class Layer {
	constructor(
		sketch = new Sketch(),
		notes = [],
		history = {
			currentPosition: 0,
			history: [{}]
		}
	) {
		this.setId(1);
		this.setSketch(sketch);
		this.setNotes(notes);
		this.setHistory(history);
		this.setColor(null);
		this.setVisibility(true);
		this.setPin(false);
		this.setHidden(false);
	}

	getId() {
		return this.id;
	}

	setId(id) {
		this.id = id;
	}

	getColor() {
		return this.color;
	}

	setColor(color) {
		this.color = color;
	}

	isVisible() {
		return this.visible;
	}

	setVisibility(value) {
		this.visible = value;
	}

	setSketch(sketch) {
		this.sketch = Sketch.restoreFromDump(sketch.convertForSave());
	}

	getSketch() {
		return Sketch.restoreFromDump(this.sketch.convertForSave());
	}

	setNotes(notes) {
		this.notes = notes.map(note => note.clone());
	}

	getNotes() {
		return this.notes.map(note => note.clone());
	}

	setHistory(history) {
		this.history = {
			currentPosition: history.currentPosition,
			history: history.history.map(dump => {
				if (isEmpty(dump)) {
					return {};
				} else {
					return Sketch.restoreFromDump(dump).convertForSave();
				}
			})
		};
	}

	getHistory() {
		return {
			currentPosition: this.history.currentPosition,
			history: this.history.history.map(dump => {
				if (isEmpty(dump)) {
					return {};
				} else {
					return Sketch.restoreFromDump(dump).convertForSave();
				}
			})
		};
	}

	getLastHistory() {
		return {
			currentPosition: 0,
			history: (() => {
				let dump = this.history.history[this.history.currentPosition];
				if (isEmpty(dump)) {
					return [{}];
				} else {
					return [Sketch.restoreFromDump(dump).convertForSave()];
				}
			})()
		};
	}

	toObject() {
		return {
			id: this.getId(),
			sketch: this.sketch.convertForSave(),
			notes: this.notes.map(note => note.toObject()),
			history: this.getHistory(),
			color: this.getColor(),
			visible: this.visible,
			pinned: this.getPin()
		};
	}

	toSaveObject() {
		return {
			id: this.getId(),
			sketch: this.sketch.convertForSave(),
			notes: this.notes.map(note => note.toObject()),
			history: this.getLastHistory(),
			color: this.getColor(),
			visible: this.visible,
			pinned: this.getPin()
		};
	}

	static fromObject(initObject) {
		let layer = new this();
		layer.setId(initObject.id);
		layer.sketch = Sketch.restoreFromDump(initObject.sketch);
		layer.notes = initObject.notes.map(note => Note.fromObject(note));
		layer.setHistory(initObject.history);
		layer.setColor(initObject.color);
		layer.setVisibility(initObject.visible);
		layer.setPin(initObject.pinned);

		return layer;
	}

	clone() {
		return this.constructor.fromObject(this.toObject());
	}

	setPin(value) {
		this.pinned = value;
	}

	getPin() {
		return this.pinned;
	}

	setHidden(value) {
		this.hidden = value;
	}

	getHidden() {
		return this.hidden;
	}
}
