import { UPDATE_FILE_STATE } from '../constants/fileStateConstants';

const initialState = {
    needSave: false
};

export default function fileState(state = initialState, action) {
    switch (action.type) {
        case UPDATE_FILE_STATE: {
            return { needSave: action.payload.needSave };
        }
        default: {
            return state;
        }
    }
}