import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { modalClose, modalOpen } from '../../../../shared/reducers/ModalReducer';
import { canvasReset } from '../../../../shared/actions/drawAreaActions';
import { MODAL_IMAGE_UPLOAD } from './ModalImageUpload';

export const MODAL_IMAGE_UPLOAD_CANVAS_RESET = 'MODAL_IMAGE_UPLOAD_CANVAS_RESET';

class ModalImageUploadCanvasReset extends React.Component {
	render() {
		return (
			<div className='modal-image-upload-canvas-reset modal-inner-wrapper'>
				<h2 className='text-center'>Import Image/PDF</h2>
				<p className='text-center'>all current layers will be deleted</p>
				<div className='text-center'>
					<button className='btn btn-modal' onClick={this.props.modalClose}>
						cancel
					</button>
					<button
						className='btn btn-modal'
						onClick={() => {
							this.props.canvasReset({manualValues: { measure: this.props.measure }});
							this.props.modalClose();
							this.props.modalOpen(MODAL_IMAGE_UPLOAD);
						}}>
						delete
					</button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		measure: state.scale.measure
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			modalClose,
			modalOpen,
			canvasReset
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalImageUploadCanvasReset);
