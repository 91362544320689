import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { availableCustomColors } from '../../../shared/constants/layerConstants';
import { layerSetColor, layerDuplicate, togglePinLayer } from '../../../shared/actions/layerActions';

class LayerColors extends React.Component {
	render() {
		const {
			layers: { list, currentIndex },
			togglePinLayer
		} = this.props;

		const currentLayer = list[currentIndex];

		const pinLayerLabel = currentLayer.pinned ? 'unpin layer' : 'pin layer';
		const pinLayerHandler = () => togglePinLayer(currentLayer.id);

		return (
			<div
				className='layer-colors'
				style={{
					left: `${this.props.left}px`,
					bottom: `${this.props.bottom}px`
				}}>
				<div className='extra' onClick={pinLayerHandler}>
					<svg className='icon icon-svg' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 65.95 99.7'>
						<path
							d='M53.68.23A29.31,29.31,0,0,0,37.1,53.72L17.05,96l8.36,4,
						20-42.25A29.32,29.32,0,1,0,53.68.23Zm0,49.4A20.08,20.08,0,1,1,73.75,29.56,20.09,20.09,0,
						0,1,53.68,49.63Z'
							transform='translate(-17.05 -0.23)'
						/>
					</svg>
					{pinLayerLabel}
				</div>
				<div className='extra' onClick={this.props.layerDuplicate}>
					<span className='icon icon-duplicate' />
					duplicate layer
				</div>
				{availableCustomColors.map((color, index) => {
					const classNames = ['color', color];

					if (
						(currentLayer.getColor() && currentLayer.getColor() === color) ||
						(!currentLayer.getColor() && this.props.drawingColor === color)
					) {
						classNames.push('active');
					}

					return (
						<div
							key={index}
							className={classNames.join(' ')}
							onClick={() => {
								this.props.layerSetColor(color);
							}}
						/>
					);
				})}
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		drawingColor: state.settings.drawingColor,
		layers: state.layers
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			layerSetColor,
			layerDuplicate,
			togglePinLayer
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(LayerColors);
