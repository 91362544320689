import React from 'react';
import MainMenu from '../Menus/MainMenu';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/header/app-logo-2.png';

export class Header extends React.Component {
	render() {
		const { isEducator } = this.props;

		return (
			<header className='row no-gutters align-items-center main-header'>
				<div className='logo col col-auto'>
					<a href={process.env.REACT_APP_MAIN_WP_URL || '/'}>
						<img src={logo} alt='Logo' />
					</a>
				</div>
				<div className='col'>
					<MainMenu isEducator={isEducator}/>
				</div>
			</header>
		);
	}
}
