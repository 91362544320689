import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AccountNameField from './Fields/AccountNameField';
import AccountEmailField from './Fields/AccountEmailField';
import AccountPasswordField from './Fields/AccountPasswordField';
import AccountTrialEndField from './Fields/AccountTrialEndField';
import AccountPaymentField from './Fields/AccountPaymentField';
import AccountCardField from './Fields/AccountCardField';
import { loadUserData } from '../../../../shared/actions/authActions';
import { settingsSetMarketingCookies } from '../../../../shared/actions/settingsActions';
import ApiHelper from '../../../../shared/ApiHelper';
import isEqual from 'lodash.isequal';

const likelyArray = [
	{ scale: 1, label: '1' },
	{ scale: 2, label: '2' },
	{ scale: 3, label: '3' },
	{ scale: 4, label: '4' },
	{ scale: 5, label: '5' },
	{ scale: 6, label: '6' },
	{ scale: 7, label: '7' },
	{ scale: 8, label: '8' },
	{ scale: 9, label: '9' },
	{ scale: 10, label: '10' }
];

class AccountSettings extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			updatePayment: false,
			unSubscribe: false,
			forgetMe: false,
			unSubscribeSuccess: false,
			disabled: false,
			likely: null,
			isSubmitting: false,
			isAnswerRecorded: false
		};
	}

	isTrial() {
		return this.props.user && !this.props.user.subscription && this.props.user.trial_end;
	}

	isSubscription() {
		return this.props.user && this.props.user.subscription && this.props.user.subscription.status === 'Opened';
	}

	isUnSubscribe() {
		return this.props.user && this.props.user.subscription && this.props.user.subscription.unsubscribe;
	}

	unSubscribe() {
		this.setState({ disabled: true });

		ApiHelper.unSubscribe(this.state.forgetMe).then(response => {
			const state = { disabled: false };

			if (response.data.success) {
				state.unSubscribeSuccess = true;
				this.props.loadUserData();
			}

			this.setState(state);
		});
	}

	showUpdatePayment() {
		this.setState({ updatePayment: true });
	}

	hideUpdatePayment() {
		this.setState({ updatePayment: false });
	}

	showUnSubscribe() {
		this.setState({
			unSubscribe: true,
			updatePayment: false
		});
	}

	hideUnSubscribe() {
		this.setState({ unSubscribe: false });
	}

	handleLikelyChange(event) {
		this.setState({ likely: parseInt(event.target.value, 10) });
	}

	handleLikelySubmit() {
		this.setState({ isSubmitting: true });

		ApiHelper.unsubscribeLogger({ likelyScale: this.state.likely })
			.then(response => {
				if (response.data.success) {
					this.setState({ isAnswerRecorded: true });
				}
			})
			.finally(() => {
				this.setState({ isSubmitting: false });
			});
	}

	componentDidUpdate(prevProps) {
		const settings = this.props.settings;

		if (!isEqual(prevProps.settings, settings)) {
			ApiHelper.updateSettings({ settings });
		}
	}

	render() {
		if (this.state.unSubscribeSuccess) {
			return (
				<div className='account-unsubscribe success'>
					<p>Unsubscribe Successful</p>
					<p>
						Continue using SketchAndCalc until the next billing cycle. Resume access at anytime by logging
						in with your email address and password
					</p>
					<p className='likely'>
						One a scale of 1-10 how likely are you to recommended SketchAndCalc to a friend?
					</p>
					<div className='radio-buttons'>
						{likelyArray.map(({ scale, label }, index) => (
							<div key={scale}>
								<input
									type='radio'
									id={`${scale}-${index}`}
									name='scale'
									value={scale}
									disabled={this.state.isAnswerRecorded}
									checked={this.state.likely === scale}
									onChange={this.handleLikelyChange.bind(this)}
								/>
								<label htmlFor={`${scale}-${index}`} className='small'>
									{label}
								</label>
							</div>
						))}
					</div>
					<div className='text-right w-100 most-likely'>
						most
						<br />
						likely
					</div>
					<div className='field'>
						<span />
						<button
							disabled={this.state.isAnswerRecorded || this.state.isSubmitting || !this.state.likely}
							onClick={this.handleLikelySubmit.bind(this)}
							className='btn btn-modal'>
							{this.state.isSubmitting
								? 'sending...'
								: this.state.isAnswerRecorded
								? 'Thank You!'
								: 'submit'}
						</button>
					</div>
				</div>
			);
		}

		if (this.state.unSubscribe) {
			return (
				<div className='account-unsubscribe'>
					<p>Are you sure?</p>
					<div className='actions'>
						<button
							onClick={this.unSubscribe.bind(this)}
							className='btn btn-modal'
							disabled={this.state.disabled}>
							unsubscribe
						</button>
						<button
							onClick={this.hideUnSubscribe.bind(this)}
							className='btn btn-modal'
							disabled={this.state.disabled}>
							cancel
						</button>
					</div>
					<div className='forget-me'>
						<div className='checkbox-custom'>
							<label className='label'>Delete my data after my account expires</label>
							<input
								type='checkbox'
								className='checkbox-field'
								onClick={event => {
									this.setState({
										forgetMe: event.target.checked
									});
								}}
								defaultChecked={this.state.forgetMe}
							/>
							<span className='flag' />
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className='account-settings'>
				<AccountNameField name={`${this.props.user.first_name} ${this.props.user.last_name}`} />
				<AccountEmailField email={this.props.user.email} />
				<AccountPasswordField />

				{this.isTrial() && <AccountTrialEndField />}
				{this.isSubscription() && !this.state.updatePayment && (
					<AccountPaymentField
						onClick={this.showUpdatePayment.bind(this)}
						subscription={this.props.user.subscription}
					/>
				)}
				{this.isSubscription() && !this.isUnSubscribe() && this.state.updatePayment && (
					<AccountCardField onSuccess={this.hideUpdatePayment.bind(this)} />
				)}
				{this.isSubscription() && !this.isUnSubscribe() && (
					<div className='field'>
						<span />
						<button onClick={this.showUnSubscribe.bind(this)} className='btn btn-modal'>
							unsubscribe
						</button>
					</div>
				)}

				<div className='field cookie-settings'>
					<span className='label'>Marketing cookies</span>
					<div className='fields'>
						<span>On</span>
						<label className='switch'>
							<input
								type='checkbox'
								onChange={event => {
									this.props.settingsSetMarketingCookies(event.target.checked);
								}}
								defaultChecked={this.props.settings.marketingCookiesOn}
							/>
							<span className='slider' />
						</label>
						<span>Off</span>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		user: state.auth.user,
		settings: state.settings
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			loadUserData,
			settingsSetMarketingCookies
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
