import React from 'react';
import { NavLink } from 'react-router-dom';
import { mobileDetect } from '../../shared/store';

export default class MainMenu extends React.Component {
	isLogInActive(match, location) {
		if ((!match && location.pathname === '/login') || (match && match.isExact)) {
			return true;
		}

		return false;
	}

	render() {
		const { isEducator=false } = this.props;

		if (isEducator) {
			return (
				<nav className='main-menu row no-gutters justify-content-end'>
					<NavLink to='/help' className='header-link'>
						Help
					</NavLink>
				</nav>
			);
		}

		return (
			<nav className='main-menu row no-gutters justify-content-end'>
				<a exact href={process.env.REACT_APP_MAIN_WP_URL || '/'} className='footer-link'>
					Home
				</a>
				<NavLink to='/contact' className='footer-link'>
					Contact
				</NavLink>
				<NavLink to='/download'>Download</NavLink>
				{/*<NavLink to='/api'>API</NavLink>*/}
				<NavLink to='/help' className='header-link'>
					Help
				</NavLink>
				<NavLink to='/subscription' className='footer-link'>
					Pricing
				</NavLink>
				{!mobileDetect.mobile() && <NavLink to='/register'>Sign up</NavLink>}
				{!mobileDetect.mobile() && (
					<NavLink to='/login' isActive={this.isLogInActive.bind(this)}>
						Log in
					</NavLink>
				)}
				<NavLink to='/help' className='footer-link'>
					Help
				</NavLink>
				<a
					href={
						process.env.REACT_APP_MAIN_WP_URL
							? `${process.env.REACT_APP_MAIN_WP_URL}/cookie-policy`
							: '/cookie-policy'
					}
					className='footer-link'>
					Cookie Policy
				</a>
				<a
					href={
						process.env.REACT_APP_MAIN_WP_URL
							? `${process.env.REACT_APP_MAIN_WP_URL}/privacy-policy`
							: '/privacy-policy'
					}
					className='footer-link'>
					Privacy Policy
				</a>
				<a
					href={
						process.env.REACT_APP_MAIN_WP_URL
							? `${process.env.REACT_APP_MAIN_WP_URL}/terms-and-conditions`
							: '/terms-and-conditions'
					}
					className='footer-link'>
					Terms and Conditions
				</a>
			</nav>
		);
	}
}
