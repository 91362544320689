import React, { Component } from 'react';
import { connect } from 'react-redux';

import update from 'immutability-helper';
import { run, ruleRunner } from '../../Validation/ruleRunner.js';
import { required, isValidEmail, minLength, phone, site } from '../../Validation/rules.js';
import { Link } from 'react-router-dom';
import ApiHelper from '../../../shared/ApiHelper';
import DocumentMeta from 'react-document-meta';
import ReactGA from 'react-ga';
import queryString from 'query-string';
import './_Register.scss';
import UserRegister from '../../../forms/UserRegister';
import AuthPolicyWarn from '../../App/AuthPolicyWarn';
import Switch from '../../inputs/Switch';
import { authTypes } from '../../../shared/constants/authConstants';
import EducatorRegister from '../../../forms/EducatorRegister';
import Loader from '../../Loader/Loader';

const userFieldValidations = [
	ruleRunner('firstName', 'First Name', required),
	ruleRunner('lastName', 'Last Name', required),
	ruleRunner('email', 'Email Address', required, isValidEmail),
	ruleRunner('password', 'Password', required, minLength(6))
];

const educatorFieldValidators = [
	...userFieldValidations,
	ruleRunner('nameOfSchool', 'Name of school', required),
	ruleRunner('city', 'City', required),
	ruleRunner('country', 'Country', required),
	ruleRunner('phoneNumber', 'Phone number', required, phone),
	ruleRunner('usage', 'Usage', required),
	ruleRunner('address', 'Address', required, site),
];

const authVarsEducator = {
	title: 'Create Free Educator Account',
	subtitle: 'Share our Area Calculator with your Students for Free. Once Verified, Login to Create Classroom Sessions',
	policyText: 'By proceeding you affirm you are an educator and represent a legally accredited school. All applications for education accounts will be verified and subject to approval. You agree to our ',
	validators: educatorFieldValidators,
	classes: {
		subtitle: 'px-3',
		switchBlock: 'w-100 flex-wrap',
		form: 'mt-3'
	}
};

const authVarsUser = {
	title: 'Create User Account',
	subtitle: 'Trial with no obligation',
	validators: userFieldValidations,
	policyText: 'By signing up, you agree to our ',
	classes: {
		subtitle: 'text-nowrap',
		switchBlock: '',
		form: ''
	}
};


class RegisterPage extends Component {
	handleTimeout = null;
	handleResend = false;

	constructor(props) {
		super(props);
		this.handleFieldChanged = this.handleFieldChanged.bind(this);
		this.errorFor = this.errorFor.bind(this);
		this.state = {
			authType: authTypes.user,
			authVars: authVarsUser,
			showResend: true,
			showVerify: false,
			showErrors: false,
			validationErrors: {},
			globalError: false,
			disposableEmailError: false,
			registerPending: false
		};
	}

	switchAuthType = () => {
		const { authType } = this.state;

		const common = {
			firstName: null,
			lastName: null,
			email: null,
			password: null,
			nameOfSchool: null,
			country: null,
			city: null,
			phoneNumber: null,
			usage: null,
			address: null,
			showErrors: false
		};

		if (authType === authTypes.user) {
			this.setState({
				...common,
				authType: authTypes.educator,
				authVars: authVarsEducator,
				validationErrors: run(this.state, educatorFieldValidators)
			});
		} else {
			this.setState({
				...common,
				authType: authTypes.user,
				authVars: authVarsUser,
				validationErrors: run(this.state, userFieldValidations)
			});
		}
	}

	resetGlobalError() {
		this.setState({ globalError: false });
	}

	clearTimeout() {
		if (this.handleTimeout) {
			window.clearTimeout(this.handleTimeout);
			this.handleTimeout = null;
		}
	}

	componentWillMount() {
		const { isAuthenticated, history } = this.props;
		const { authType } = this.state;

		if (isAuthenticated) {
			history.push('/');
		}

		const query = new URLSearchParams(history.location.search);
		const type = query.get('type');

		if (type && type !== authType) {
			this.switchAuthType();
		}

		this.setState({ validationErrors: run(this.state, userFieldValidations) });
	}

	setEmailValue = (event, email) => {
		event.preventDefault();

		const newState = update(this.state, {
			email: { $set: email }
		});
		newState.validationErrors = run(newState, this.state.authVars.validators);

		this.validate(newState, 'email');
	};

	errorFor(field) {
		if (field === 'email' && this.state.validationErrors[field]) {
			// Format inner email as clickable link
			const emailRegexp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;

			const string = String(this.state.validationErrors[field]);

			const elements = [];
			let lastIndex = 0;
			let match;

			while ((match = emailRegexp.exec(string)) !== null) {
				if (match.index > lastIndex) {
					elements.push(string.substring(lastIndex, match.index));
				}

				const emailString = match[0];
				const decoratedComponent = (
					<a
						href='javascript:void(0)'
						onClick={e => {
							this.setEmailValue(e, emailString);
						}}>
						{emailString}
					</a>
				);
				elements.push(decoratedComponent);

				lastIndex = emailRegexp.lastIndex;
			}

			// Push remaining text if there is any
			if (string.length > lastIndex) {
				elements.push(string.substring(lastIndex));
			}

			return elements.length === 1 ? elements[0] : elements;
		}

		return this.state.validationErrors[field] || '';
	}

	handleFieldChanged = field => {
		return e => {
			let newState = update(this.state, {
				[field]: { $set: e.target.value }
			});
			newState.validationErrors = run(newState, this.state.authVars.validators);
			this.validate(newState, field);
		};
	}

	validate = (state) => {
		return this.setState(state);
	}

	handleSubmit = event => {
		event.preventDefault();

		this.resetGlobalError();

		this.setState({ showErrors: true });

		if (Object.keys(this.state.validationErrors).length) return;

		ReactGA.ga('send', 'event', 'form', 'click', 'registration');

		this.setState({ showErrors: false, registerPending: true });

		let params = queryString.parse(this.props.location.search);
		const { firstName, lastName, email, password, authType, nameOfSchool, city, country, phoneNumber, usage,
			address } = this.state;

		let user = {
			type: authType,
			firstName: firstName.trim(),
			lastName: lastName.trim(),
			email: email.trim(),
			password: password.trim(),
			app: params.app && params.app === 'true'
		};

		if (authType === authTypes.educator) {
			user = {
				...user,
				nameOfSchool: nameOfSchool.trim(),
				city: city.trim(),
				country: country.trim(),
				phoneNumber: phoneNumber.trim(),
				usage: usage.trim(),
				badge_url: address.trim()
			};
		}

		ApiHelper.register(user)
			.then(response => {
				if (response.data.success) {
					this.setState({ showVerify: true });
				}
			})
			.catch(error => {
				if (error.response && error.response.data && error.response.data.message) {
					this.clearTimeout();

					const didYouMean = /did you mean\:/gi;
					if (didYouMean.test(error.response.data.message)) {
						this.resetGlobalError();
						this.setState({
							showErrors: true,
							validationErrors: {
								email: error.response.data.message
							}
						});
					} else if (error.response.data.message === 'disposable') {
						this.setState({ disposableEmailError: true });
					} else {
						this.setState({
							disposableEmailError: false,
							globalError: error.response.data.message
						});
					}
				}
			})
			.finally(() => {
				this.setState({registerPending: false});
			});
	}

	resend(event) {
		event.preventDefault();

		if (this.handleResend) {
			return;
		}

		this.handleResend = true;

		ApiHelper.resend(this.state.email)
			.then(response => {
				if (response.data.success) {
					this.setState({ showResend: false });
				}

				this.handleResend = false;
			})
			.catch(() => {
				this.setState({ showResend: true });
				this.handleResend = false;
			});
	}

	handleSendDisposableEmail = event => {
		event && event.preventDefault && event.preventDefault();

		const { email } = this.state;

		ApiHelper.sendDisposableEmail(email)
			.then(({ data }) => {
				if (data.success) {
					this.setState({
						disposableEmailError: false,
						globalError: 'Standby… We’ll send you an email shortly. Thank you.'
					});
				}
			})
			.catch(error => {
				if (error.response && error.response.data && error.response.data.message) {
					this.setState({
						disposableEmailError: false,
						globalError: error.response.data.message
					});
				}
			});
	};

	renderVerifyBlock = () => {
		const { showResend } = this.state;
		return (
			<div className='form-wrapper'>
				<DocumentMeta title='Verify Account' />
				<div className='verify'>
					<h2>Are we there yet?</h2>
					<p>Please check your inbox and click the verification link</p>
					{showResend && (
						<div className='alert alert-dark notification' role='alert'>
							No email yet?... follow
							<Link target='_blank' to='/help#cant-find-emails-sketchandcalc'>
								these instructions
							</Link>
							before you
							<Link onClick={this.resend.bind(this)} to='#'>
								re-send email
							</Link>
						</div>
					)}
					{!showResend && (
						<div className='alert alert-dark notification large' role='alert'>
							No email yet?... follow
							<Link target='_blank' to='/help#cant-find-emails-sketchandcalc'>
								these instructions
							</Link>
							before you
							<a href='mailto:support@sketchandcalc.com'>support@sketchandcalc.com</a>
						</div>
					)}
				</div>
			</div>
		);
	}

	render() {
		const { authVars, showVerify, authType, registerPending } = this.state;
		const RegisterForm = authType === authTypes.user ? UserRegister : EducatorRegister;
		
		if (showVerify) {
			return this.renderVerifyBlock();
		}

		return (
			<div className='form-wrapper'>
				<DocumentMeta title='Register' />
				{ registerPending && <Loader size={150} color={'white'} modes={'fixed dark-fade'}/> }
				<form className='register' autoComplete='off'>
					<h2>{authVars.title}</h2>
					<div className={`${authVars.classes.switchBlock} d-flex flex-wrap align-items-center justify-content-between px-5 text-center`}>
						<div className={`${authVars.classes.subtitle} m-0 py-3 mr-2`}>
							{authVars.subtitle}
						</div>
						<div className={'switchbox d-flex justify-content-lg-end justify-content-center align-items-center ml-auto py-3'}>
							<div>User</div>
							<Switch
								onChange={this.switchAuthType}
								defaultChecked={authType===authTypes.user}
							/>
							<div>Educator</div>
						</div>
					</div>
					<div className='field-wrap'>
						{this.state.disposableEmailError && (
							<p className='global-error text-center text-danger'>
								We're having trouble verifying that address.
								<a href='#' onClick={this.handleSendDisposableEmail}>
									{' '}
									Send your address to a human{' '}
								</a>
								and we'll get you registered.
							</p>
						)}
						{this.state.globalError && (
							<p className='global-error text-center text-danger'>{this.state.globalError}</p>
						)}
						<div className={authVars.classes.form}>
							<RegisterForm
								handleFieldChanged={this.handleFieldChanged}
								errorFor={this.errorFor}
								{...this.state}
							/>
						</div>
						<button
							onClick={this.handleSubmit}
							className={'primary-btn mt-4'}
						>
							Send Verification Email
						</button>
						<AuthPolicyWarn text={authVars.policyText}/>
					</div>
				</form>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		isAuthenticated: state.auth.isAuthenticated
	};
}

export default connect(mapStateToProps)(RegisterPage);
