import React from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
	noteDelete,
	noteEndMove,
	noteMouseOut,
	noteOver,
	noteRotateArrow,
	noteStartEdit,
	noteStartMove
} from '../../../shared/actions/NoteActions';
import { stopPropagation } from '../../../shared/EventHelper';
import get from 'lodash/get';
import { SUBSCRIPTION_PLANS } from '../../../shared/constants/plansConstants';

class SVGNoteList extends React.Component {
	render() {
		return (
			<g>
				{!this.props.layers.showAllLayers && this.renderNotes()}
				{this.props.layers.showAllLayers && this.renderLayerNotes()}
			</g>
		);
	}

	renderLayerNotes() {
		const notes = [];

		this.props.layers.list.forEach(layer => {
			if (layer.isVisible() && layer.sketch.closed) {
				notes.push(...layer.getNotes());
			}
		});

		return notes.map((note, index) => {
			return (
				<g className='note' key={index}>
					{this.renderNoteBg(note)}
					{this.renderNoteArrow(note)}
				</g>
			);
		});
	}

	renderNotes() {
		return this.props.notes.map((note, index) => {
			const classNames = ['note'];

			if (index === this.props.editableNote) {
				classNames.push('editable');
			}

			return (
				<g
					className={classNames.join(' ')}
					key={index}
					onMouseDown={stopPropagation}
					onMouseUp={stopPropagation}
					onClick={stopPropagation}
					onMouseOver={() => this.props.isEducator? () => {} : this.props.noteOver(index)}
					onMouseLeave={() => this.props.noteMouseOut(index)}>
					{this.renderNoteBg(note)}
					{this.renderNoteArrow(note)}
					{this.renderNoteControls(note, index)}
				</g>
			);
		});
	}

	renderNoteBg(note) {
		let position = note.position;

		return (
			<rect className='box' x={position[0]} y={position[1]} width={note.getWidth()} height={note.getHeight()} />
		);
	}

	renderNoteArrow(note) {
		const arrow = note.drawArrow();

		return (
			<g transform={arrow.position}>
				<path className='arrow' d={arrow.path} transform={'rotate(' + arrow.rotation + ')'} />
			</g>
		);
	}

	renderNoteControls(note, index) {
		let realZoom = this.props.zoomValue,
			rectSize = 39.3 / realZoom,
			rectWidth = 1 / realZoom,
			notePosition = note.position;

		if (this.props.isEducator) {
			return null;
		}

		return (
			<g
				className='controls'
				transform={
					'translate(' +
					(notePosition[0] + 10 / realZoom) +
					',' +
					(notePosition[1] + (note.getHeight() - rectSize) / 2) +
					')'
				}>
				<g
					className='icon textEdit'
					onClick={() => {
						this.props.noteStartEdit(index);
					}}>
					<rect width={rectSize} height={rectSize} strokeWidth={rectWidth} />
					<g transform={'translate(' + 12 / realZoom + ',' + 8 / realZoom + ')'}>
						<svg width={16 / realZoom} height={23 / realZoom} viewBox='0 0 16 23'>
							<path d='M0.446,0H15.07v1.618H8.707v18.475H6.809V1.618H0.446V0z M0,22.883v-1.395h15.516v1.395H0z' />
						</svg>
					</g>
				</g>

				<g
					className='icon moveNote'
					transform={'translate(' + 45 / realZoom + ',0)'}
					onMouseDown={event => {
						this.props.noteStartMove(event, index);
					}}
					onMouseUp={() => {
						this.props.noteEndMove(index);
					}}>
					<rect width={rectSize} height={rectSize} strokeWidth={rectWidth} />
					<g transform={'translate(' + 8 / realZoom + ',' + 8 / realZoom + ')'}>
						<svg width={24 / realZoom} height={24 / realZoom} viewBox='0 0 24 24'>
							<path d='M8.729,13.082H4.353v2.932L0,11.638l4.353-4.376v2.932h4.376V13.082z M10.172,8.729V4.376H7.286L11.639,0l4.353,4.376  h-2.886v4.353H10.172z M13.105,14.548v4.353h2.886l-4.353,4.376l-4.353-4.376h2.886v-4.353H13.105z M14.548,10.195h4.376V7.263  l4.353,4.376l-4.353,4.376v-2.932h-4.376V10.195z' />
						</svg>
					</g>
				</g>
				<g
					className='icon rotateNoteArrow'
					transform={'translate(' + 90 / realZoom + ',0)'}
					onClick={() => {
						this.props.noteRotateArrow(index);
					}}>
					<rect width={rectSize} height={rectSize} strokeWidth={rectWidth} />
					<g transform={'translate(' + 9 / realZoom + ',' + 8 / realZoom + ')'}>
						<svg width={20 / realZoom} height={24 / realZoom} viewBox='0 0 20 24'>
							<path d='M16.66,13.323h3.337c0,1.82-0.448,3.497-1.342,5.028c-0.894,1.532-2.108,2.746-3.64,3.64  c-1.532,0.894-3.205,1.342-5.017,1.342c-1.812,0-3.485-0.447-5.017-1.342c-1.532-0.894-2.746-2.108-3.64-3.64  C0.447,16.82,0,15.147,0,13.335c0-1.812,0.447-3.484,1.342-5.016c0.894-1.532,2.107-2.746,3.64-3.64  C6.514,3.784,8.19,3.337,10.01,3.337h3.337V0l5.017,4.993l-5.017,5.017V6.697L10.01,6.674c-1.851,0-3.426,0.649-4.725,1.948  s-1.948,2.87-1.948,4.713c0,1.844,0.649,3.415,1.948,4.714c1.299,1.299,2.87,1.948,4.713,1.948s3.414-0.649,4.713-1.948  C16.01,16.75,16.66,15.175,16.66,13.323z' />
						</svg>
					</g>
				</g>
				<g
					className='icon deleteNote'
					transform={'translate(' + 140 / realZoom + ',0)'}
					onClick={() => {
						this.props.noteDelete(index);
					}}>
					<rect width={rectSize} height={rectSize} strokeWidth={rectWidth} />
					<g transform={'translate(' + 8 / realZoom + ',' + 8 / realZoom + ')'}>
						<svg width={23 / realZoom} height={23 / realZoom} viewBox='0 0 23 23'>
							<path d='M13.611,11.086l8.559,8.56l-2.526,2.526l-8.56-8.56l-8.56,8.56L0,19.646l8.559-8.56L0,2.526L2.526,0l8.56,8.56L19.645,0  l2.526,2.526L13.611,11.086z' />
						</svg>
					</g>
				</g>
			</g>
		);
	}
}

function mapStateToProps(state, ownProps) {
	const {
		auth: {
			user
		},
		zoom,
		instrument,
		notes,
		layers
	} = state;

	const isEducator = get(user, 'subscription.stripe_plan', '') === SUBSCRIPTION_PLANS.educator;


	return {
		...ownProps,
		zoomValue: zoom.value,
		instrument,
		notes: notes.list,
		editableNote: notes.editableNote,
		movableNote: notes.movableNote,
		layers,
		isEducator
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			noteOver,
			noteMouseOut,
			noteStartEdit,
			noteStartMove,
			noteEndMove,
			noteRotateArrow,
			noteDelete
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(SVGNoteList);
