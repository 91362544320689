import * as React from 'react';
import { LinesAsSVGElements } from '../LinesAsSVGElements/LinesAsSVGElements';
import { LineLabels } from '../LineLabels/LineLabels';
import PreviewLine from '../PreviewLine/PreviewLine';
import PreviewShape from '../PreviewLine/PreviewShape';
import { AngleLabels } from '../AngleLabels/AngleLabels';
import { SupportPoints } from '../ControlPoints/SupportPoints';
import { ControlPoints } from '../ControlPoints/ControlPoints';
import { ShapeControlPoints } from '../ControlPoints/ShapeControlPoints';
import { MagicWandMask } from '../MagicWandMask/MagicWandMask';
import {
	sketchAngleMouseDown,
	sketchLabelsToggleVisibility,
	sketchPointMouseDown,
	sketchSupportPointMouseDown,
	shapeAddPoint,
	sketchResetActivePoint,
	sketchSetDragIndex
} from '../../../shared/actions/SketchActions';
import { lineAddPoint } from '../../../shared/actions/lineActions';
import * as SketchTypes from '../../../shared/classes/SketchTypes';
import * as instrumentsTypes from '../../../shared/classes/Instruments';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { stopPropagation } from '../../../shared/EventHelper';
import { AngleLines } from '../AngleLines/AngleLines';
import { labelReset } from '../../../shared/reducers/LabelReducer';
import DiameterLine from '../../../shared/classes/DiameterLine';
import { setShouldDragLayer } from '../../../shared/actions/drawAreaActions';
import { historyPush } from '../../../shared/actions/historyActions';
import { instrumentChange } from '../../../shared/reducers/instrumentReducer';

class CanvasSketch extends React.Component {
	state = {
		prevInstrument: null,
		movePointIsProcess: false,
		movePointIsEnter: false,
		movePointIsLeave: false,
		movePointIsMove: false,
		resetStateAfterMouseUp: false
	};

	isShowDiameter = () => {
		const {
			activeIndex,
			label: { editableLineIndex, editablePointIndex }
		} = this.props;
		return !(activeIndex === null && editableLineIndex === null && editablePointIndex === null);
	};

	hasDiameterLine = () => this.props.sketch.getLines().some(line => line instanceof DiameterLine);

	handleToggleLabelsMouseDown = event => {
		stopPropagation(event);
		this.props.setShouldDragLayer(true);
	};

	handleToggleLabelsMouseUp = event => {
		stopPropagation(event);
		this.props.setShouldDragLayer(false);
		this.props.historyPush(this.props.sketch.convertForSave());
	};

	handleToggleLabelsClick = () => {
		if (this.props.activeIndex !== null) {
			this.props.sketchResetActivePoint();
		}

		if (this.props.label.editableLineIndex !== null) {
			this.props.labelReset();
		}

		this.props.sketchLabelsToggleVisibility();
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.activeIndexBeforeClose !== null &&
			this.props.activeIndexBeforeClose === null &&
			this.state.movePointIsEnter &&
			this.state.prevInstrument) {
			this.setState({
				movePointIsEnter: false,
				prevInstrument: null,
			});
			this.props.instrumentChange(this.state.prevInstrument);
		}
	}

	onMouseEnterOnControlPoints = () => {
		const { movePointIsEnter } = this.state;
		const { sketch, lineIsDrawing } = this.props;
		if (!movePointIsEnter && !lineIsDrawing && !sketch.closed) {
			this.setState({
				movePointIsEnter: true,
			});
		}
		this.setState({movePointIsLeave: false});
	}

	onMouseLeaveOnControlPoints = () => {
		const { prevInstrument, movePointIsEnter, movePointIsProcess } = this.state;
		const { instrumentChange } = this.props;
		if (movePointIsEnter && !movePointIsProcess) {
			this.setState({
				movePointIsEnter: false
			});
			if (this.state.prevInstrument) {
				instrumentChange(prevInstrument);
				this.setState({
					prevInstrument: null
				});
			}
		}
		this.setState({movePointIsLeave: true});
	}

	onMouseDownOnControlPoints = (index, event) => {
		const { instrument } = this.props;
		const { movePointIsEnter } = this.state;
		if (movePointIsEnter && instrument === instrumentsTypes.SELECT_TOOL) {
			event.stopPropagation();
			this.props.sketchPointMouseDown(index, event, false, true);
			this.setState({movePointIsProcess: true});
		}
	}

	onMouseUpOnControlPoints = () => {
		const { prevInstrument, movePointIsEnter, movePointIsProcess, movePointIsLeave } = this.state;
		const { instrumentChange } = this.props;
		if (movePointIsEnter && movePointIsProcess && movePointIsLeave) {
			this.setState({
				prevInstrument: null,
				movePointIsEnter: false,
				movePointIsProcess: false
			});
			if (this.state.prevInstrument) {
				instrumentChange(prevInstrument);
			}
		} else if (movePointIsEnter && movePointIsProcess && !movePointIsLeave) {
			this.setState({
				movePointIsProcess: false
			});
		}
	}

	render() {
		const isSketchClosed = this.props.sketch.closed,
			zoom = this.props.zoomValue,
			scale = this.props.scale,
			strokeWidth = this.props.strokeWidth / zoom,
			classNames = ['sketch'],
			sketch = this.props.sketch;

		if (isSketchClosed) {
			classNames.push('closed');
		}

		return (
			<g>
				<g className={classNames.join(' ')}>
					{!isSketchClosed && (
						<path
							d={sketch.toPath(isSketchClosed)}
							className={this.props.willClose ? 'closed' : ''}
							strokeWidth={strokeWidth}
						/>
					)}

					{!isSketchClosed && (
						<AngleLines
							lines={sketch.getAngleLines()}
							strokeWidth={strokeWidth}
							zoom={zoom}
							mouseDown={this.props.sketchAngleMouseDown}
							closed={this.props.sketch.angleClosed}
						/>
					)}

					{isSketchClosed && (
						<LinesAsSVGElements
							isShowDiameter={this.isShowDiameter()}
							lines={sketch.getLines()}
							shapes={sketch.getShapes()}
							doubleClick={this.props.lineAddPoint}
							shapeDoubleClick={this.props.shapeAddPoint}
							strokeWidth={strokeWidth}
						/>
					)}

					{isSketchClosed && this.renderCenterLabel()}

					{(sketch.labelsVisible || this.hasDiameterLine()) && (
						<LineLabels
							isShowDiameter={this.isShowDiameter()}
							lines={this.props.sketch.getLines()}
							visible={isSketchClosed || (!isSketchClosed && sketch.type !== SketchTypes.SHAPE)}
							zoom={zoom}
							scale={scale}
							closed={isSketchClosed}
							helpEditLineLength={this.props.helpEditLineLength}
						/>
					)}

					{!isSketchClosed && sketch.type !== SketchTypes.SHAPE && (
						<PreviewLine
							preview={this.props.preview}
							showLabel={true}
							scale={scale}
							zoom={zoom}
							strokeWidth={strokeWidth}
							instrument={this.props.instrument}
						/>
					)}

					{!isSketchClosed && (
						<PreviewShape
							preview={this.props.preview}
							scale={scale}
							zoom={zoom}
							strokeWidth={strokeWidth}
							instrument={this.props.instrument}
						/>
					)}

					{isSketchClosed && this.props.activeIndex !== null && (
						<AngleLabels
							activeIndex={this.props.activeIndex}
							sketch={this.props.sketch}
							zoom={zoom}
							displayAngles={this.props.displayAngles}
						/>
					)}
					{isSketchClosed && this.props.activeIndex !== null && (
						<SupportPoints
							activeIndex={this.props.activeIndex}
							dragSupportPoint={this.props.dragSupportPoint}
							sketch={this.props.sketch}
							zoom={zoom}
							mouseDown={this.props.sketchSupportPointMouseDown}
						/>
					)}

					{
						<ControlPoints
							isShape={false}
							points={this.props.sketch.getControlPoints()}
							activeIndex={this.props.activeIndex !== null ? this.props.activeIndex : this.props.activeIndexBeforeClose}
							mouseDown={this.props.sketchPointMouseDown}
							zoom={zoom}
							line={this.props.sketch.getLines()[this.props.label.editableLineIndex]}
							pointIndex={this.props.label.editablePointIndex}
							closed={isSketchClosed}
							helpMoveVertex={this.props.helpMoveVertex}
							sketchSetDragIndex={this.props.sketchSetDragIndex}
							onMouseEnter={this.onMouseEnterOnControlPoints}
							onMouseLeave={this.onMouseLeaveOnControlPoints}
							onMouseDown={this.onMouseDownOnControlPoints}
							onMouseUp={this.onMouseUpOnControlPoints}
						/>
					}

					{
						<ShapeControlPoints
							isShape={true}
							points={this.props.sketch.getShapeControls()}
							activeIndex={this.props.activeIndex}
							mouseDown={this.props.sketchPointMouseDown}
							zoom={zoom}
							line={this.props.sketch.getLines()[this.props.label.editableLineIndex]}
							pointIndex={this.props.label.editablePointIndex}
							closed={true}
							helpMoveVertex={this.props.helpMoveVertex}
						/>
					}
					{this.props.magicWandMask && (
						<MagicWandMask previewPath={this.props.magicWandMask.getPreviewPath()} />
					)}
				</g>
			</g>
		);
	}

	renderCenterLabel() {
		let centerPoint = this.props.sketch.getCenterPoint(),
			plusWidth = 10 / this.props.zoomValue,
			plusHeight = 0.5 / this.props.zoomValue,
			plusWidthHalf = plusWidth / 2,
			plusHeightHalf = plusHeight / 2;

		const rectWidth = 25 / this.props.zoomValue;
		const rectHeight = 25 / this.props.zoomValue;
		const rectWidthHalf = rectWidth / 2;
		const rectHeightHalf = rectHeight / 2;
		const circleStrokeWidth = 2 / this.props.zoomValue;

		return (
			<g
				className='toggle-labels'
				onMouseDown={this.handleToggleLabelsMouseDown}
				onMouseUp={this.handleToggleLabelsMouseUp}>
				<rect
					className='toggle-labels__rect'
					x={centerPoint[0] - rectWidthHalf}
					y={centerPoint[1] - rectHeightHalf}
					width={rectWidth}
					height={rectHeight}
				/>
				<circle
					cx={centerPoint[0]}
					cy={centerPoint[1]}
					r={12 / this.props.zoomValue}
					strokeWidth={circleStrokeWidth}
					onClick={this.handleToggleLabelsClick}
				/>
				{!this.props.sketch.labelsVisible && (
					<rect
						x={centerPoint[0] - plusHeightHalf}
						y={centerPoint[1] - plusWidthHalf}
						width={plusHeight}
						height={plusWidth}
					/>
				)}
				<rect
					x={centerPoint[0] - plusWidthHalf}
					y={centerPoint[1] - plusHeightHalf}
					width={plusWidth}
					height={plusHeight}
				/>
			</g>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		sketch: state.sketchState.sketch,
		magicWandMask: state.sketchState.sketch.mask,
		willClose: state.sketchState.willClose,
		zoomValue: state.zoom.value,
		dragIndex: state.sketchState.dragIndex,
		activeIndex: state.sketchState.activeIndex,
		activeIndexBeforeClose: state.sketchState.activeIndexBeforeClose,
		scale: state.scale.value,
		preview: { ...state.preview },
		label: state.label,
		instrument: state.instrument,
		strokeWidth: state.settings.lineWeight,
		displayAngles: state.settings.displayAngles,
		helpEditLineLength: state.help.helpEditLineLength,
		helpMoveVertex: state.help.helpMoveVertex,
		dragSupportPoint: state.sketchState.dragSupportPoint,
		shouldDragLayer: state.drawArea.shouldDragLayer
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			sketchPointMouseDown,
			sketchAngleMouseDown,
			lineAddPoint,
			labelReset,
			sketchLabelsToggleVisibility,
			sketchResetActivePoint,
			shapeAddPoint,
			sketchSupportPointMouseDown,
			setShouldDragLayer,
			historyPush,
			sketchSetDragIndex,
			instrumentChange
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(CanvasSketch);
