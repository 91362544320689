const SOCKET_UPDATED_SESSION_DOCUMENT = 'SOCKET_UPDATED_SESSION_DOCUMENT';
const SOCKET_STUDENT_SESSION_ENDED = 'SOCKET_STUDENT_SESSION_ENDED';
const SOCKET_EDUCATOR_SESSION_ENDED = 'SOCKET_EDUCATOR_SESSION_ENDED';
const SOCKET_EVENT_LOGOUT = 'SOCKET_EVENT_LOGOUT';
const SOCKET_SUBSCRIPTION_PLAN_CHANGED = 'SOCKET_SUBSCRIPTION_PLAN_CHANGED';
const SOCKET_DOCUMENT_LIST_UPDATED = 'SOCKET_DOCUMENT_LIST_UPDATED';
const SOCKET_SESSION_SETTINGS_UPDATED = 'SOCKET_SESSION_SETTINGS_UPDATED';
const SET_WEBSOCKET_STATUS = 'SET_WEBSOCKET_STATUS';
const SOCKET_ONLINE = 'online';
const SOCKET_OFFLINE = 'offline';

export default {
	SOCKET_SESSION_SETTINGS_UPDATED,
	SOCKET_UPDATED_SESSION_DOCUMENT,
	SOCKET_EDUCATOR_SESSION_ENDED,
	SOCKET_EVENT_LOGOUT,
	SOCKET_STUDENT_SESSION_ENDED,
	SOCKET_SUBSCRIPTION_PLAN_CHANGED,
	SOCKET_DOCUMENT_LIST_UPDATED,
	SET_WEBSOCKET_STATUS,
	SOCKET_ONLINE,
	SOCKET_OFFLINE
};
