import React from 'react';

const Art5 = ({color="#fff"}) => {

    return (
        <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 200 200">
            <path className="cls-1"
                  style={{ fill: color }}
                  d="M66.76,105.94H64.51v15.14H52.6V66.13H64.51V97h2l7.71-14H87.86L76.34,100.23,89,121.08H75.36Z"/>
            <path className="cls-1"
                  style={{ fill: color }}
                  d="M103.22,83l.43,4.86.1,0a12.16,12.16,0,0,1,4.42-4.15,12.87,12.87,0,0,1,6.26-1.45,11.72,11.72,0,0,1,6.14,1.52,9.56,9.56,0,0,1,3.86,4.75,12.82,12.82,0,0,1,4.51-4.67,12.6,12.6,0,0,1,6.48-1.6q5.67,0,8.81,3.75t3.14,11.86v23.21h-12V97.87c0-2.51-.37-4.22-1.11-5.11a4.25,4.25,0,0,0-3.47-1.34,7.26,7.26,0,0,0-3.06.6,6.14,6.14,0,0,0-2.22,1.73q0,.6,0,1a8.26,8.26,0,0,1,0,.88v25.43H113.72V97.87c0-2.51-.37-4.22-1.11-5.11a4.3,4.3,0,0,0-3.5-1.34,6.83,6.83,0,0,0-2.94.6,6.47,6.47,0,0,0-2.24,1.73v27.33H92V83Z"/>
            <rect className="cls-1" style={{ fill: color }} width="28.5" height="28.5"/>
            <rect className="cls-1" style={{ fill: color }} x="171.5" width="28.5" height="28.5"/>
            <line className="cls-2" style={{ stroke: color, strokeMiterlimit:10, strokeWidth: '5px' }} x1="28.5" y1="14.25" x2="171.5" y2="14.25"/>
            <line className="cls-2" style={{ stroke: color, strokeMiterlimit:10, strokeWidth: '5px' }} x1="185.75" y1="28.5" x2="185.75" y2="171.5"/>
        </svg>
    )
}

export default Art5