import React from 'react';
import { connect } from 'react-redux';
import ApiHelper from '../../../shared/ApiHelper';
import DocumentMeta from 'react-document-meta';

class UpdateCardPage extends React.Component {
	stripe = null;
	elements = null;
	card = null;
	cardEl = null;
	handleTimeout = null;

	constructor(props) {
		super(props);

		this.state = {
			disabled: false,
			error: null
		};
	}

	clearTimeout() {
		if (this.handleTimeout) {
			window.clearTimeout(this.handleTimeout);
			this.handleTimeout = null;
		}
	}

	resetErrorMessage() {
		this.setState({ error: null });
	}

	handleError(error) {
		this.clearTimeout();
		this.setState({ error: error });
		this.handleTimeout = window.setTimeout(this.resetErrorMessage.bind(this), 3000);
	}

	verificationSubscription() {
		const user = this.props.auth.user;

		if (user) {
			if (!user.subscription || user.subscription.status === 'Opened') {
				this.props.history.push('/');
			}
		}
	}

	componentWillMount() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push('/login');
		} else {
			this.verificationSubscription();
		}
	}

	componentDidUpdate() {
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push('/login');
		} else {
			this.verificationSubscription();
		}
	}

	componentDidMount() {
		this.stripe = window.Stripe(process.env.REACT_APP_STRIPE_KEY);
		this.elements = this.stripe.elements();
		this.card = this.elements.create('card', {
			hidePostalCode: true
		});

		this.card.mount(this.cardEl);
	}

	handleToken() {
		return new Promise((resolve, reject) => {
			this.stripe.createToken(this.card).then(result => {
				if (result.error) {
					reject(result.error);
				} else {
					resolve(result.token);
				}
			});
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		this.setState({ error: null });

		this.handleToken()
			.then(token => {
				this.setState({ disabled: true });

				ApiHelper.updateCard({ token })
					.then(response => {
						if (response.data.success) {
							this.props.history.push('/register-complete');
						} else {
							this.setState({ disabled: false });
						}
					})
					.catch(error => {
						this.setState({ disabled: false });

						if (error.response.data.message) {
							this.handleError(error.response.data.message);
						}
					});
			})
			.catch(error => {
				this.handleError(error);
			});
	}

	render() {
		return (
			<div className='form-wrapper'>
				<DocumentMeta title='Update Credit Card' />
				<form onSubmit={this.handleSubmit.bind(this)}>
					<h2>Update Card</h2>
					<h5>we had difficulty processing your card last time</h5>
					{this.state.error && <p className='text-center text-danger'>{this.state.error}</p>}
					<div className='form-group'>
						<div ref={el => (this.cardEl = el)} />
					</div>
					<button disabled={this.state.disabled} type='submit'>
						Update Card
					</button>
				</form>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		auth: state.auth
	};
}

export default connect(mapStateToProps)(UpdateCardPage);
