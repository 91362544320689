import { svgEl, svgPan } from '../../components/App/DrawArea/DrawArea';

export function getScaledPoint(point) {
	let x = point[0],
		y = point[1],
		currentPan = svgPan.getPan(),
		realZoom = svgPan.getSizes().realZoom;

	x = (x - currentPan.x) / realZoom;
	y = (y - currentPan.y) / realZoom;
	return [x, y];
}

export function isPointValid(point) {
	return Array.isArray(point) && point.length === 2 && !Number.isNaN(point[0]) && !Number.isNaN(point[1]);
}

export function getEventPoint(event) {
	let bounds = svgEl.getBoundingClientRect();
	return getScaledPoint([event.nativeEvent.clientX - bounds.left, event.nativeEvent.clientY - bounds.top]);
}
