import React from 'react';
import { connect } from 'react-redux';
import EducatorRegister from '../../../forms/EducatorRegister';
import { ruleRunner, run } from '../../Validation/ruleRunner';
import { phone, required } from '../../Validation/rules';
import update from 'immutability-helper';
import DocumentMeta from 'react-document-meta';
import './_EducatorUpdatePage.scss';
import AuthPolicyWarn from '../../App/AuthPolicyWarn';
import ThankYou from '../../App/ThankYou';
import { bindActionCreators } from 'redux';
import { enableEducator } from '../../../shared/actions/educatorActions';
import { authLogout } from '../../../shared/actions/authActions';
import Loader from '../../Loader/Loader';

const educatorFieldValidators = [
    ruleRunner('nameOfSchool', 'Name of school', required),
    ruleRunner('city', 'City', required),
    ruleRunner('country', 'Country', required),
    ruleRunner('phoneNumber', 'Phone number', required, phone),
    ruleRunner('usage', 'Usage', required),
    ruleRunner('address', 'Address', required),
];


class EducatorUpdatePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            validationErrors: {},
            done: false
        }
    }

    componentWillMount() {
        const { user, history, pending } = this.props;

        if (!user && !pending) {
            history.push('/login');
        }
    }

    errorFor = field => {
        return this.state.validationErrors[field] || '';
    }

    handleFieldChanged = field => {
        return e => {
            let newState = update(this.state, {
                [field]: { $set: e.target.value }
            });
            newState.validationErrors = run(newState, educatorFieldValidators);
            this.setState(newState)
        };
    }

    handleSubmit = async(e) => {
        e.preventDefault();
        try {
            const {enableEducator, authLogout} = this.props;
            const {nameOfSchool, city, country, phoneNumber, usage, address} = this.state;
            const validationErrors = run(this.state, educatorFieldValidators)
            this.setState({showErrors: true, loading: true})

            if (Object.keys(validationErrors).length) {
                return this.setState({validationErrors})
            }

            const register = await enableEducator({
                phone: parseInt(phoneNumber),
                name: nameOfSchool,
                city, country, usage, badge_url: address
            })

            if (register) {
                this.setState({done: true})
                authLogout()
            }
        } catch (e) {}
        finally {
            this.setState({loading:false})
        }
    }

    render() {
        const { user } = this.props;
        const { done, loading } = this.state;

        if (done) {
            return (<ThankYou />)
        }

        if (!user) {
            return null
        }

        return (
            <div className='form-wrapper educator-update-page'>
                { loading && <Loader size={150} color={'white'} modes={'fixed dark-fade'}/> }
                <DocumentMeta title='Educator' />
                <form className='d-flex flex-column align-items-center' autoComplete='off'>
                    <h2>Thank you {user.first_name}</h2>
                    <div className={'text-center subtitle'}>
                        Convert your User Account {user.email} <br />
                        to a Free Educator Account and Create Classroom Sessions for Students.
                    </div>
                    <EducatorRegister
                        withUserFields={false}
                        handleFieldChanged={this.handleFieldChanged}
                        errorFor={this.errorFor}
                        {...this.state}
                    />
                    <button
                        onClick={this.handleSubmit}
                        className={'primary-btn mt-4'}
                    >
                        Send Badge and Instructions
                    </button>
                    <AuthPolicyWarn text={'By proceeding you affirm you are an educator and represent a legally accredited school. All applications for education accounts will be verified and subject to approval. You agree to our '}/>
                </form>
                </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        auth: {
            user,
            pending
        }
    } = state;


    return {
        user,
        pending
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            enableEducator,
            authLogout
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EducatorUpdatePage);