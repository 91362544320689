import socketConstants from '../constants/socketConstants';
import { authLogout } from './authActions';
import { MODAL_INFO } from '../../components/ModalContainer/Modals/ModalInfo/ModalInfo';
import { modalOpen } from '../reducers/ModalReducer';
import { GET_DOCUMENTS_SUCCESS } from '../constants/documentsConstants';
import { getExistingSession, updateDocument_socket } from './educatorActions';
import { MODAL_EDUCATOR_DASHBOARD } from '../../components/ModalContainer/Modals/ModalEducatorDashboard';
import { settingsUpdate } from './settingsActions';

export const runEvent = ({type, payload, meta}) => async dispatch => {
	switch (type) {
		case socketConstants.SOCKET_EVENT_LOGOUT: {
			await dispatch(authLogout());
			dispatch(modalOpen(MODAL_INFO, {
				title: 'Logout',
				message: 'You have been logged out. The number of devices for your plan has been exceeded.'
			}))
			break;
		}
		case socketConstants.SOCKET_SUBSCRIPTION_PLAN_CHANGED: {
			await dispatch(authLogout());
			dispatch(modalOpen(MODAL_INFO, {
				title: 'Logout',
				message: 'You have been logged out. Subscription plan was successfully changed.'
			}))
			break;
		}
		case socketConstants.SOCKET_DOCUMENT_LIST_UPDATED: {
			const { documentsList } = payload
			dispatch({ type: GET_DOCUMENTS_SUCCESS, payload: documentsList });
			break;
		}
		case socketConstants.SOCKET_STUDENT_SESSION_ENDED: {
			await dispatch(authLogout());
			dispatch(modalOpen(MODAL_INFO, {
				title: 'Logout',
				message: 'You have been logged out. Session was ended.'
			}))
			break;
		}
		case socketConstants.SOCKET_EDUCATOR_SESSION_ENDED: {
			const { name, isEducatorStop } = payload
			await dispatch(getExistingSession());

			if (!isEducatorStop) {
				dispatch(modalOpen(MODAL_INFO, {
					title: 'Session was ended',
					message: `Session ${name} was ended.`,
					onClose: () => dispatch(modalOpen(MODAL_EDUCATOR_DASHBOARD))
				}))
			}

			break;
		}
		case socketConstants.SOCKET_UPDATED_SESSION_DOCUMENT: {
			const { documents } = payload
			await dispatch(updateDocument_socket(documents))
			break;
		}
		case socketConstants.SOCKET_SESSION_SETTINGS_UPDATED: {
			const { settings } = payload
			let settingsObj = JSON.parse(settings.state);
			dispatch(settingsUpdate(settingsObj));
			break;
		}
		default: break;
	}

	return { type, payload, meta };
}

export const setWebsocketStatus = (status) => async(dispatch) => {
	dispatch({type: socketConstants.SET_WEBSOCKET_STATUS, payload: { status }});
};
