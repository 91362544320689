import * as types from '../constants/ImageConstants.js';

const initState = {
	url: null,
	file: null,
	position: [20, 20],
	dragActive: false,
	dynamicImage: false,
	display: true,
	originSizes: { width: null, height: null },
	realSizes: { width: null, height: null },
	data: null,
	ratio: 1,
	scale: 1
};

export function imageReducer(state = { ...initState }, action) {
	switch (action.type) {
		case types.IMAGE_SET_DRAG: {
			return { ...state, dragActive: action.payload.dragFlag };
		}

		case types.IMAGE_TOGGLE_VISIBILITY: {
			return { ...state, display: !state.display };
		}

		case types.IMAGE_SET_REAL_SIZE: {
			return {
				...state,
				realSizes: action.payload.sizes,
				position: action.payload.position
			};
		}

		case types.IMAGE_ADD: {
			const { image, sizes } = action.payload;

			return {
				...state,
				file: image,
				url: URL.createObjectURL(image),
				dynamicImage: true,
				originSizes: { ...sizes },
				realSizes: { ...sizes },
				ratio: sizes.height / sizes.width
			};
		}

		case types.IMAGE_UPDATE_POSITION: {
			return { ...state, position: action.payload.position };
		}

		case types.IMAGE_UPDATE_URL: {
			return { ...state, url: action.payload.value };
		}

		case types.IMAGE_ADD_TO_CANVAS: {
			return {
				...state,
				dynamicImage: false,
				position: action.payload.position
			};
		}

		case types.IMAGE_UPDATE: {
			return { ...initState, ...action.payload.state };
		}

		case types.IMAGE_SET_DATA: {
			return { ...state, data: action.payload.data };
		}

		case types.IMAGE_RESET: {
			return { ...initState };
		}

		default: {
			return state;
		}
	}
}
