import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { layerToggleVisibility } from '../../../shared/actions/layerActions';
import chunk from 'lodash.chunk';

class VisibilityLayers extends React.Component {
	render() {
		const data = [];

		this.props.layers.list.forEach((layer, index) => {
			data.push({
				id: layer.getId(),
				disabled: !layer.isVisible(),
				index
			});
		});

		const rows = chunk(data, 1);

		return (
			<div
				className='visibility-layers'
				style={{
					left: `${this.props.left}px`,
					bottom: `${this.props.bottom}px`
				}}>
				{rows.map((row, index) => {
					return (
						<div key={`row-${index}`} className='row-item'>
							{row.map(item => {
								const classNames = ['item'];

								if (item.disabled) {
									classNames.push('disabled');
								}

								return (
									<div
										key={`item-${item.id}`}
										className={classNames.join(' ')}
										onClick={() => {
											this.props.layerToggleVisibility(item.index);
										}}>
										{item.id}
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		layers: state.layers
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			layerToggleVisibility
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(VisibilityLayers);
