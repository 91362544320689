import React from 'react';
import DocumentMeta from 'react-document-meta';

export default class NotFound extends React.Component {
	render() {
		return (
			<div className='not-found'>
				<DocumentMeta
					title='Not Found'
					meta={{
						name: {
							'prerender-status-code': 404
						}
					}}
				/>
				<div className='page-404'>
					<h2>404</h2>
					<p>Sorry ... But the requested page was not found!</p>
				</div>
			</div>
		);
	}
}
