import MagicWand from '../lib/magic-wand';
import maskDilate from '../lib/mask-dilate';
import store from '../store';
import { notificationShow } from '../actions/notificationActions';
import { notificationTypes } from '../constants/notificationConstants';

const SIMPLIFY_TOLERANT = 2;
const SIMPLIFY_COUNT = 50;
const GAUSSIAN_BLUR = 5;

export default class MagicWandTool {
	tolerance = 15;
	blur = 0;
	compoundShapes = false;
	image = null;
	mask = null;
	outlinedMask = null;
	previewPath = null;

	lastAdded = false;
	lastSubstracted = false;

	constructor(point, doNotUpdateMask) {
		this.setImagePoint(point);

		if (!doNotUpdateMask) {
			this.updateMask();
		}
	}

	/*** MAIN PARAMS ***/

	_getImageScallings(realSizes, originSizes) {
		return {
			x: realSizes.width / originSizes.width,
			y: realSizes.height / originSizes.height
		};
	}

	setImagePoint(point) {
		this.point = this.getRelativePoint(point);
	}

	getRelativePoint(point) {
		let image = store.getState().image;
		let scallings = this._getImageScallings(image.realSizes, image.data);

		return {
			x: Math.ceil((point[0] - image.position[0]) / scallings.x),
			y: Math.ceil((point[1] - image.position[1]) / scallings.y)
		};
	}

	setTolerance(value) {
		this.tolerance = value;
		this.updateMask();
	}

	getTolerance() {
		return this.tolerance;
	}

	setBlur(value) {
		this.blur = value;
		this.outlinedMask = maskDilate(this.mask, this.blur);
		this.updatePreviewPath();
	}

	getBlur() {
		return this.blur;
	}

	setCompound(value) {
		this.compoundShapes = value;
		this.updatePreviewPath();
	}

	getCompound() {
		return this.compoundShapes;
	}

	updateMask() {
		let image = store.getState().image;

		this.mask = MagicWand.floodFill(image.data, this.point.x, this.point.y, this.tolerance);
		this.mask = MagicWand.gaussBlurOnlyBorder(this.mask, GAUSSIAN_BLUR);
		this.outlinedMask = maskDilate(this.mask, this.blur);

		this.lastAdded = false;
		this.lastSubstracted = false;

		this.updatePreviewPath();
	}

	getResultPolygonsCount() {
		let cs = MagicWand.traceContours(this.mask);
		cs = MagicWand.simplifyContours(cs, SIMPLIFY_TOLERANT, SIMPLIFY_COUNT);

		return cs.length;
	}

	getResultPolygons() {
		let image = store.getState().image,
			cs = MagicWand.traceContours(this.outlinedMask),
			result = [],
			offset = image.position,
			scallings = this._getImageScallings(image.realSizes, image.data);

		cs = MagicWand.simplifyContours(cs, SIMPLIFY_TOLERANT, SIMPLIFY_COUNT);

		for (let i = 0; i < cs.length; i++) {
			let poly = [],
				points = cs[i].points;

			if (cs[i].inner && !this.compoundShapes) continue;

			for (let j = 0; j < points.length; j++) {
				poly.push([offset[0] + points[j].x * scallings.x, offset[1] + points[j].y * scallings.y]);
			}

			result.push(poly);
		}

		return result;
	}

	/*** END MAIN PARAMS ***/

	/*** PREVIEW PATH ***/

	getPreviewPath() {
		return this.previewPath;
	}

	updatePreviewPath() {
		let image = store.getState().image,
			cs = MagicWand.traceContours(this.outlinedMask),
			path = '',
			offset = image.position,
			scallings = this._getImageScallings(image.realSizes, image.data);

		cs = MagicWand.simplifyContours(cs, SIMPLIFY_TOLERANT, SIMPLIFY_COUNT);

		for (let i = 0; i < cs.length; i++) {
			if (cs[i].inner && !this.compoundShapes) continue;
			path += this.maskPointsToPath(cs[i].points, offset, scallings);
		}

		path += ' Z';

		this.previewPath = path;
	}

	maskPointsToPath(ps, offset, scallings) {
		let path = '';

		path += ' M' + (ps[0].x * scallings.x + offset[0]) + ',' + (ps[0].y * scallings.y + offset[1]);

		for (let j = 1; j < ps.length; j++) {
			path += ' L' + (ps[j].x * scallings.x + offset[0]) + ',' + (ps[j].y * scallings.y + offset[1]);
		}

		return path;
	}

	/*** END PREVIEW PATH ***/

	/*** EXTRA WAND TOOLS ***/

	addToArea(point) {
		let image = store.getState().image,
			relPoint = this.getRelativePoint(point),
			baseMask = this.outlinedMask,
			newMask = maskDilate(MagicWand.floodFill(image.data, relPoint.x, relPoint.y, this.tolerance), this.blur),
			stacking = false,
			joinedMask = baseMask.data.map((filled, index) => {
				if (!stacking) {
					if (filled === 1) {
						if (!stacking && newMask.data[index] === 1) {
							stacking = true;
						}
					}
				}
				return Math.max(filled, newMask.data[index]);
			});

		if (stacking) {
			this.blur = 0;
			this.mask.data = joinedMask;
			this.mask.bounds.minX = Math.min(this.mask.bounds.minX, newMask.bounds.minX);
			this.mask.bounds.minY = Math.min(this.mask.bounds.minY, newMask.bounds.minY);
			this.mask.bounds.maxX = Math.max(this.mask.bounds.maxX, newMask.bounds.maxX);
			this.mask.bounds.maxY = Math.max(this.mask.bounds.maxY, newMask.bounds.maxY);
			this.outlinedMask = MagicWandTool.cloneMask(this.mask);
			this.updatePreviewPath();
			this.lastAdded = true;
			this.lastSubstracted = false;
		} else {
			this.notifyNotIntersect();
		}
	}

	substractFromArea(point) {
		let image = store.getState().image,
			relPoint = this.getRelativePoint(point),
			baseMask = this.outlinedMask,
			newMask = MagicWand.floodFill(image.data, relPoint.x, relPoint.y, this.tolerance),
			stacking = false,
			joinedMask = baseMask.data.map((filled, index) => {
				if (!stacking && filled === 1 && newMask.data[index] === 1) {
					stacking = true;
				}
				return Math.max(0, filled - newMask.data[index]);
			});
		if (stacking) {
			this.blur = 0;
			this.mask.data = joinedMask;
			this.mask.bounds.minX = Math.min(this.mask.bounds.minX, newMask.bounds.minX);
			this.mask.bounds.minY = Math.min(this.mask.bounds.minY, newMask.bounds.minY);
			this.mask.bounds.maxX = Math.max(this.mask.bounds.maxX, newMask.bounds.maxX);
			this.mask.bounds.maxY = Math.max(this.mask.bounds.maxY, newMask.bounds.maxY);
			this.outlinedMask = MagicWandTool.cloneMask(this.mask);
			this.updatePreviewPath();
			this.lastAdded = false;
			this.lastSubstracted = true;
		} else {
			this.notifyNotIntersect();
		}
	}

	notifyNotIntersect() {
		setTimeout(() => {
			store.dispatch(notificationShow('Selected areas does not intersect', notificationTypes.WARNING, 1500));
		}, 0);
	}

	/** END EXTRA WAND TOOLS ***/

	/*** SAVE LOAD FEATURE ***/

	toObject() {
		return {
			point: this.point,
			tolerance: this.tolerance,
			blur: this.blur,
			compoundShapes: this.compoundShapes,
			mask: MagicWandTool.cloneMask(this.mask),
			outlinedMask: MagicWandTool.cloneMask(this.outlinedMask),
			previewPath: this.previewPath,
			lastAdded: this.lastAdded,
			lastSubstracted: this.lastSubstracted
		};
	}

	static restoreFromObject(object) {
		let restored = new MagicWandTool(object.point, true);

		Object.assign(restored, object);
		restored.mask = MagicWandTool.cloneMask(object.mask);
		restored.outlinedMask = MagicWandTool.cloneMask(object.outlinedMask);

		return restored;
	}

	static cloneMask(mask) {
		return {
			bounds: Object.assign({}, mask.bounds),
			data: mask.data.slice(0),
			height: mask.height,
			width: mask.width
		};
	}

	/*** END SAVE LOAD FEATURE ***/
}
