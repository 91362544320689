import React from 'react';

const HideShowImage = ({height= 30, width = 40, color='#fff'}) => (
    <svg version="1.1" id="Layer_1" x="0px" y="0px"
         viewBox="0 0 85.04 57.3"
         height={height}
         width={width}
         className={'image-icon'}
    >
        <path fill={color} d="M0,57.3l28.05-29.09l9.35,9.2l17.51-24.93L85.04,57.3H0z M10.97,0C4.99,0,0.14,4.85,0.14,10.83s4.85,10.83,10.83,10.83
            s10.83-4.85,10.83-10.83S16.96,0,10.97,0z"
        />
    </svg>
);

export default HideShowImage;