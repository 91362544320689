import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import stripeLogo from '../../../assets/img/stripe.svg';
import DocumentMeta from 'react-document-meta';
import TrustLogo from '../../TrustLogo';
import ApiHelper from "../../../shared/ApiHelper";
import PersonalPlans from "./PersonalPlans/PersonalPlans";
import TeamPlans from "./TeamPlans/TeamPlans";
import Loader from "../../Loader/Loader";
import Select from 'react-select';
import {currencyOptions} from "./currencyOptions";
import {bindActionCreators} from "redux";
import {getLocationInfo} from "../../../shared/actions/externalActions";
import {Elements, StripeProvider} from "react-stripe-elements";
import CheckoutForm from "./CheckoutForm";

const PRICE_TYPES = {
    personal: 'personal',
    team: 'team'
}

class SubscriptionPage extends React.Component {
    handleTimeout = null;

    constructor(props) {
        super(props);
        this.state = {
            plan: null,
            error: null,
            currency: {value:'GBP', label:'GBP'},
            country: 'US',
            loading:true,
            priceType: PRICE_TYPES.personal,
            prices:[],
            isCheckoutOpen: false,
        };
    }

    async componentDidMount() {
        const detectedCurrency = await this.detectCurrency();
        await this.getPrices(detectedCurrency.value);
    }

    detectCurrency = async()  => {
        const {location, getLocationInfo} = this.props;
        const initialCurrency = this.state.currency
        let detectedLocation = location;
        let detectedCurrency = null;

        if (!detectedLocation) {
            detectedLocation = await getLocationInfo();
        }

        detectedCurrency = currencyOptions.find(option => option.value === detectedLocation.currency)

        if (!detectedCurrency) {
            detectedCurrency = initialCurrency;
        }

        this.setState({currency: detectedCurrency, country: detectedLocation.country})

        return detectedCurrency
    }

    getPrices = async (currency='usd') => {
        this.setState({
            loading:true
        })
        const prices = await ApiHelper.getPrices(currency.toLowerCase())
        this.setState({
            prices:prices.data.data,
            loading:false
        })
    }

    clearTimeout() {
        if (this.handleTimeout) {
            window.clearTimeout(this.handleTimeout);
            this.handleTimeout = null;
        }
    }

    resetErrorMessage = () => {
        this.setState({ error: null });
    }

    handleError = (error) => {
        this.clearTimeout();
        this.setState({ error: error });
        this.handleTimeout = window.setTimeout(this.resetErrorMessage, 3000);
    }

    switchPriceType = () => {
        const {priceType} = this.state

        if (priceType === PRICE_TYPES.personal) {
            this.setState({priceType:PRICE_TYPES.team})
        } else {
            this.setState({priceType:PRICE_TYPES.personal})
        }
    }

    renderCurrency = (currency) => {
        return (
                <div className={'currency-item'}>
                    <img src={require(`../../../assets/flags/${currency.value}.png`)} alt={currency.label}/>
                    {currency.label}
                </div>
        )
    }

    setCurrency = (currency) => {
        this.setState({currency})
        this.getPrices(currency.value);
    }

    showCheckout = plan => {
        this.setState({ plan, isCheckoutOpen: true });
    };

    closeCheckout = () => {
        this.setState({ plan:null, isCheckoutOpen: false });
    };

    render() {
        const {priceType, error, prices, loading, currency, country, isCheckoutOpen, plan} = this.state;
        const {auth} = this.props;
        const isPersonal = priceType === PRICE_TYPES.personal;
        const Plans = isPersonal ? PersonalPlans : TeamPlans;

        return (
            <div id='subscription'>
                { loading &&  <Loader modes={'absolute light-fade backdrop-blur'} color={'#666666'}/> }
                <DocumentMeta title='Subscription' />
                <h2>Plans</h2>
                <div className={`plans ${isPersonal? 'personal-plans': 'team-plans'}`}>
                {isPersonal ?
                    <h3>
                        Hi
                        {auth.user && <span>&nbsp;{auth.user.first_name}</span>}, Go Unlimited with a
                        Subscription or Pay As You Go
                    </h3>
                    :
                    <h3>
                        Share a single account across your entire team
                    </h3>
                }
                    <Select
                        value={currency}
                        placeholder={'Currency'}
                        className={'currency-select'}
                        options={currencyOptions}
                        formatOptionLabel={this.renderCurrency}
                        onChange={this.setCurrency}
                    />
                </div>

                <StripeProvider
                    apiKey={process.env.REACT_APP_STRIPE_KEY}
                    apiVersion={process.env.STRIPE_API_VERSION}
                >
                    <div>
                        <Plans
                            handleError={this.handleError}
                            showCheckout={this.showCheckout}
                            switchPriceType={this.switchPriceType}
                            loading={loading}
                            prices={prices}
                        />
                        <Elements>
                            <CheckoutForm
                                countryPreset={country}
                                show={isCheckoutOpen}
                                plan={plan}
                                closeCheckout={this.closeCheckout}
                            />
                        </Elements>
                    </div>
                </StripeProvider>
                {error && (
                    <div className='alert alert-danger' role='alert'>
                        {error}
                    </div>
                )}

                <div className='logo'>
                    <TrustLogo />
                    <ReactGA.OutboundLink
                        className='stripe'
                        eventLabel='Power by stripe'
                        to='https://stripe.com'
                        target='_blank'>
                        <img src={stripeLogo} alt='Power by stripe' />
                    </ReactGA.OutboundLink>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        auth: state.auth,
        location: state.external.location
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getLocationInfo
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);
