import React from 'react';
import PropTypes from 'prop-types';

import './_Switch.scss';


const Switch = props => {
    const { onChange=()=>{}, defaultChecked } = props

    return (
        <label className='switch-input'>
            <input
                type='checkbox'
                onChange={onChange}
                defaultChecked={defaultChecked}
            />
            <span className='slider' />
        </label>
    )
}

Switch.propTypes = {
    onChange: PropTypes.func,
    defaultChecked: PropTypes.bool
}

export default Switch