import types from '../constants/authConstants';
import cookie from 'js-cookie';
import ApiHelper from '../ApiHelper';
import CookieHelper from '../CookieHelper';
import isEmpty from 'lodash.isempty';
import { settingsUpdate } from './settingsActions';
import { helpUpdate } from './helpActions';
import { stateReset } from './stateActions';
import { canvasReset } from './drawAreaActions';
import { scaleSetDefaultMeasure } from './scaleControlActions';
import SocketAPI from '../SocketAPI';

export function authLogin() {
	return { type: types.AUTH_LOGIN };
}

export function authSetUser(user) {
	return { type: types.AUTH_SET_USER, payload: { user } };
}

export function authLogout(email) {
	return dispatch => {
		SocketAPI.disconnect()
		cookie.remove('accessToken');
		return ApiHelper.logout(email).then(() => {
			dispatch(canvasReset());
			dispatch(stateReset());
			dispatch({ type: types.AUTH_LOGOUT });
			window.sessionStorage.removeItem('shouldShowMessage');
		});
	};
}

export const loadUserData = (props = {}) => {
	const { strictMode } = {
		strictMode: false,
		...props
	}

	return dispatch => {
		dispatch({ type: types.AUTH_PENDING, payload:true });

		ApiHelper.getUserData()
			.then(response => {
				if (response.data.success && response.data.user) {
					const { email, device_uuid, is_session_user } = response.data.user
					let user = { email, device_uuid, is_session_user: !!is_session_user };

					if (!isEmpty(response.data.user.subscription) || strictMode) {
						user.subscription = {
							...response.data.user.subscription
						};
					}

					if (!isEmpty(response.data.user.userMeta)) {
						user = { ...user, ...response.data.user.userMeta };
					}

					if (!isEmpty(response.data.user.settings) && response.data.user.settings.state) {
						let settings = JSON.parse(response.data.user.settings.state);

						dispatch(settingsUpdate(settings));
						dispatch(scaleSetDefaultMeasure());

						if (settings.cookiesAccepted) {
							CookieHelper.acceptCookies();
						}
					}

					if (!isEmpty(response.data.user.tours) && response.data.user.tours.state) {
						dispatch(helpUpdate(JSON.parse(response.data.user.tours.state)));
					}

					dispatch(authSetUser(user));
				}
			})
			.catch(() => {
				dispatch(authLogout());
			});
	};
}

export function showForceLoginMessage() {
	return { type: types.SHOW_FORCE_LOGIN_MESSAGE };
}
