import React from 'react';
import { ruleRunner, run } from '../../../../Validation/ruleRunner';
import { required } from '../../../../Validation/rules';
import TextView from '../../../../Util/TextView.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authLogout, loadUserData } from '../../../../../shared/actions/authActions';
import { modalOpen } from '../../../../../shared/reducers/ModalReducer';
import isEqual from 'lodash/isEqual';
import Loader from '../../../../Loader/Loader';
import moment from 'moment';
import { withRouter } from 'react-router';
import { createSession, revokeEducator, stopExistingSession } from '../../../../../shared/actions/educatorActions';
import { MODAL_SETTINGS } from '../../ModalSettings/ModalSettings';
import { MODAL_EDUCATOR_DASHBOARD } from '../index';
import get from 'lodash/get';
import { notificationTypes } from '../../../../../shared/constants/notificationConstants';
import { notificationShow } from '../../../../../shared/actions/notificationActions';

import './SessionTabStyles.scss';

const fieldValidations = [
    ruleRunner('session_name', 'Session name', required)
];

const initialState = {
    session_name: '',
    validationErrors: {},
    submitted: false,
    counter: '12:00',
    session: null,
}


class SessionTab extends React.Component {
    handleInterval = null;

    constructor(props) {
        super(props);

        this.state = {
            ...initialState,
            session: props.session,
            revokeEducatorConfirm: false,
            stopSessionConfirm: false,
            revokeInProgress: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(this.state.session, this.props.session)) {
            this.updateStateSession(this.props.session)
        }
    }

    componentDidMount() {
        this.updateStateSession(this.props.session)
    }

    clearTimeout() {
        if (this.handleInterval) {
            window.clearInterval(this.handleInterval);
            this.handleInterval = null;
        }
    }

    getTimer = (session) => {
        const sessionObj = session || this.state.session;

        if(!sessionObj) {
            return
        }

        const now = moment(new Date());
        const end = moment(sessionObj.ends_at);
        const diff = parseFloat(moment.duration(end.diff(now)).asHours()).toFixed(2);
        const hours = parseInt(diff);
        const minutes = parseInt(parseInt((diff - hours)*100) * 60 / 100);
        const formatted = `${hours}:${("0" + minutes).slice(-2)}`
        this.setState({session, counter: formatted})
    }

    updateStateSession = (session) => {
        if (session) {
            this.getTimer(session);
            this.handleInterval = window.setInterval(this.getTimer.bind(this), 60000);
        }
    }

    getInputProps = fieldName => {
        const { validationErrors } = this.state;

        return {
            showError: !!validationErrors[fieldName],
            errorText: validationErrors[fieldName],
            text: this.state[fieldName],
            onFieldChanged: this.handleFieldChanged(fieldName)
        }
    }

    handleFieldChanged = field => e => this.setState({[field]: e.target.value},
        () => this.state.submitted && this.setState({
            validationErrors: run(this.state, fieldValidations)
        })
    );

    logout = () => {
        const { authLogout, user } = this.props;

        authLogout(user.email)
    }

    componentWillUnmount() {
        this.clearTimeout()
    }

    startSession = async () => {
        const { createSession } = this.props;
        const { session_name } = this.state;
        this.setState({submitted: true});

        const validationErrors = run(this.state, fieldValidations)

        if (Object.keys(validationErrors).length) {
            return this.setState({ validationErrors })
        }

        this.setState({...initialState})

        await createSession({
            name: session_name
        })
    }

    revokeEducatorConfirm = e => {
        e.stopPropagation();
        this.setState({ revokeEducatorConfirm:true, stopSessionConfirm:false })
    }

    revokeEducator = async (e) => {
        e.stopPropagation();
        try {
            const { revokeEducator, loadUserData, history} = this.props;
            this.setState({revokeInProgress: true})
            await revokeEducator()
            await loadUserData({strictMode:true})
            history.push('/subscription')
        } catch (e) {}
        finally {
            this.setState({ revokeEducatorConfirm:false, revokeInProgress:false })
        }
    }

    stopSessionConfirm = e => {
        e.stopPropagation();
        this.setState({ stopSessionConfirm: true, revokeEducatorConfirm:false })
    }

    stopSession = async (e) => {
        e.stopPropagation();
        const { stopExistingSession } = this.props;
        await stopExistingSession();
        this.setState({ stopSessionConfirm: false, ...initialState, session:null });
    }

    toSettings = () => {
        const { modalOpen } = this.props;
        modalOpen(MODAL_SETTINGS, {
            onClose: () => modalOpen(MODAL_EDUCATOR_DASHBOARD)
        });
    }

    outsideClick = () => {
        this.setState({
            revokeEducatorConfirm: false,
            stopSessionConfirm: false
        })
    }

    copyPassword = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const value = get(e, 'target.value', null);

        if (value) {
            try {
                await navigator.clipboard.writeText(value)
                this.props.notificationShow(
                    'Copied!',
                    notificationTypes.SUCCESS,
                    2000
                );
            } catch (e) {}
        }

    }

    render() {
        const { counter, session, revokeEducatorConfirm, revokeInProgress, stopSessionConfirm } = this.state;
        const { pending } = this.props;

        if (pending || revokeInProgress) {
            return <Loader size={150} color={'#666666'}/>
        }

        return(
            <div>
                <div className={'session-tab'} onClick={this.outsideClick}>
                <div className={'creds-block'}>
                    <div className={'cred'}>
                        <div className={'label'}>session name</div>
                        {session ?
                            <TextView
                                onFieldChanged={() => {}}
                                text={session.name}
                                className={'form-input disabled-session-name'}
                                placeholder='<Add session name>'
                                autoComplete='off'
                                type='text'
                                disabled
                            />
                            :
                            <TextView
                                {...this.getInputProps('session_name')}
                                className={'form-input'}
                                placeholder='<Add session name>'
                                autoComplete='off'
                                type='text'
                            />
                        }
                    </div>
                    <div className={'cred'}>
                        <div className={'label'}>password</div>
                        {session ?
                            <TextView
                                onClick={this.copyPassword}
                                onFieldChanged={() => {}}
                                text={session.password}
                                key={session.password}
                                className={'form-input password disabled-session-password'}
                                placeholder='&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;'
                                autoComplete='new-password'
                                type='text'
                                disabled={true}
                            />
                            :
                            <TextView
                                {...this.getInputProps('password')}
                                className={'form-input password'}
                                placeholder='&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;'
                                autoComplete='new-password'
                                type='text'
                                disabled={true}
                            />
                        }
                    </div>
                </div>
                    {session ?
                        <button
                            className={`mt-3 ${stopSessionConfirm? 'error-btn' : 'outlined-btn'}`}
                            onClick={stopSessionConfirm? this.stopSession : this.stopSessionConfirm}
                        >
                            { stopSessionConfirm? 'Click Again to End Session' : 'End Session' }
                        </button>
                        :
                        <button
                            className={'primary-btn'}
                            onClick={this.startSession}
                        >
                            Start session
                        </button>
                    }
                <div className={`counter ${session? 'active-counter' : 'disabled-counter'}`}>
                    { counter }
                </div>
                <button
                    className={'outlined-btn'}
                    onClick={this.toSettings}
                >
                    Session Settings
                </button>
                <button
                    className={`${revokeEducatorConfirm? 'error-btn' : 'outlined-btn'}`}
                    onClick={revokeEducatorConfirm? this.revokeEducator : this.revokeEducatorConfirm}
                >
                    { revokeEducatorConfirm? 'Click Again to Close Educator Account' : 'Close Educator Account' }
                </button>
                <button
                    className={'secondary-btn'}
                    onClick={this.logout}
                >
                    Log Out
                </button>
            </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        auth: {
            user,
        },
        educator: {
            pending,
            session
        }
    } = state;


    return {
        user,
        session,
        pending
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            modalOpen,
            authLogout,
            loadUserData,
            createSession,
            revokeEducator,
            notificationShow,
            stopExistingSession
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(SessionTab))