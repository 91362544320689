export const SCALE_DECREASE = 'SCALE_DECREASE';
export const SCALE_INCREASE = 'SCALE_INCREASE';
export const SCALE_MEASURES_CHANGED = 'SCALE_MEASURES_CHANGED';
export const SCALE_SET_VALUE = 'SCALE_SET_VALUE';
export const SCALE_SET_TEXT_VALUE = 'SCALE_SET_TEXT_VALUE';
export const SCALE_SET_MEASURE = 'SCALE_SET_MEASURE';
export const SCALE_LINE_START = 'SCALE_LINE_START';
export const SCALE_LINE_END = 'SCALE_LINE_END';
export const SCALE_LINE_RESET = 'SCALE_LINE_RESET';
export const SCALE_UPDATE = 'SCALE_UPDATE';
export const SCALE_RESET = 'SCALE_RESET';
