import React from 'react';

import { Router, Route, Switch } from 'react-router';
import createBrowserHistory from 'history/createBrowserHistory';
import { Provider } from 'react-redux';

import HomePage from './components/Pages/Home/HomePage';
import DownloadPage from './components/Pages/Download/DownloadPage';
import ApiPage from './components/Pages/Api/ApiPage';
import LogInPage from './components/Pages/Login/LogInPage';
import RegisterPage from './components/Pages/Register/RegisterPage';
import RegisterCompletePage from './components/Pages/Register/RegisterCompletePage';
import SubscriptionPage from './components/Pages/Subscription/SubscriptionPage';
import PrivacyPolicyPage from './components/Pages/Policy/PrivacyPolicyPage';
import CookiePolicyPage from './components/Pages/Policy/CookiePolicyPage';
import TermsAndConditions from './components/Pages/Policy/TermsAndConditions';
import MainLayout from './components/Layout/MainLayout';
import RootLayout from './components/Layout/RootLayout';
import ContactPage from './components/Pages/Contact/ContactPage';
import UpdateCardPage from './components/Pages/Card/UpdateCardPage';
import Verify from './components/Pages/Verify/Verify';
import NotFound from './components/Pages/NotFound/NotFound';
import Blog from './components/Pages/Blog/Blog';
import Post from './components/Pages/Blog/Post';
import Help from './components/Pages/Help/Help';
import store from './shared/store';
import ReactGA from 'react-ga';
import TestPage from './components/Pages/TestPage';
import AppPageSwitcher from './components/App/AppPageSwitcher';
import EducatorUpdatePage from './components/Pages/EducatorUpdate/EducatorUpdatePage';

export const history = createBrowserHistory({
	basename: process.env.PUBLIC_URL || '/'
});

export let shortHistory = {
	prevPage: null,
	currentPage: null
};

if (!window.isPuppeteer) {
	ReactGA.initialize(process.env.REACT_APP_GA_ID);
	ReactGA.pageview(window.location.pathname);
	shortHistory.currentPage = window.location.pathname;

	window.TrustLogo('none', 'SC5', 'none');
	document.querySelectorAll('a[onmousemove^=\'tLXB(event)\']')[0].style.display = 'none';
	document.getElementById('tl_popupSC5').style.height = 0;

	history.listen(location => {
		shortHistory.prevPage = shortHistory.currentPage;
		shortHistory.currentPage = location.pathname;
		ReactGA.pageview(location.pathname);
	});
}

export default (
	<Provider store={store}>
		<Router history={history}>
			<RootLayout>
				<Switch>
					<Route exact path='/'>
						<AppPageSwitcher />
					</Route>
					<Route path='/'>
						<MainLayout>
							<Switch>
								<Route name='Home' exact path='/' component={HomePage} />
								<Route name='Contact' exact path='/contact' component={ContactPage} />
								{ process.env.REACT_APP_NODE_ENV !== 'production' &&
									<Route name='Test' exact path='/test-badge' component={TestPage}/>
								}
								<Route name='Download' exact path='/download' component={DownloadPage} />
								<Route name='Api' exact path='/api' component={ApiPage} />
								<Route name='Blog' path='/blog'>
									<Switch>
										<Route exact path='/blog' component={Blog} />
										<Route path='/blog/:slug' component={Post} />
									</Switch>
								</Route>
								<Route name='Help' exact path='/help' component={Help} />
								<Route name='LogIn' exact path='/login' component={LogInPage} />
								<Route name='Register' exact path='/register' component={RegisterPage} />
								<Route name='EducatorUpdate' exact path='/educator-update' component={EducatorUpdatePage} />
								<Route
									name='RegisterComplete'
									exact
									path='/register-complete'
									component={RegisterCompletePage}
								/>
								<Route name='Subscription' exact path='/subscription' component={SubscriptionPage} />
								<Route
									name='PrivacyPolicy'
									exact
									path='/privacy-policy'
									component={PrivacyPolicyPage}
								/>
								<Route name='CookiePolicy' exact path='/cookie-policy' component={CookiePolicyPage} />
								<Route
									name='TermsAndConditions'
									exact
									path='/terms-and-conditions'
									component={TermsAndConditions}
								/>
								<Route name='UpdateCard' exact path='/update-card' component={UpdateCardPage} />
								<Route name='Verify' exact path='/verify/:token' component={Verify} />
								<Route component={NotFound} />
							</Switch>
						</MainLayout>
					</Route>
				</Switch>
			</RootLayout>
		</Router>
	</Provider>
);
