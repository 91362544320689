import pdfjs from 'pdfjs-dist/webpack';
import { modalOpen } from './ModalReducer';
import { MODAL_PDF_PREVIEW } from '../../components/ModalContainer/Modals/ModalPDFPreview/ModalPDFPreview';
import { imageAdd } from '../actions/ImageActions';
import { MODAL_LOADER } from '../../components/ModalContainer/Modals/ModalLoader/ModalLoader';

/**
 * Constants
 */

const PDF_ADD = 'PDF_ADD';
const PDF_ADD_PAGES = 'PDF_ADD_PAGES';
const PDF_ADD_PAGE_THUMB = 'PDF_ADD_PAGE_THUMB';
const PDF_RESET = 'PDF_RESET';

export function dataURItoBlob(dataURI) {
	// convert base64/URLEncoded data component to raw binary data held in a string
	let byteString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
	else byteString = decodeURI(dataURI.split(',')[1]);

	// separate out the mime component
	let mimeString = dataURI
		.split(',')[0]
		.split(':')[1]
		.split(';')[0];

	// write the bytes of the string to a typed array
	let ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new Blob([ia], { type: mimeString });
}

/**
 * Actions
 */

export function selectedPdfPage(index) {
	return (dispatch, state) => {
		let pdfPage = state().pdf.pdfPages[index];
		let originViewport = pdfPage.getViewport(3),
			canvas = document.createElement('canvas'),
			context = canvas.getContext('2d');

		canvas.height = originViewport.height;
		canvas.width = originViewport.width;

		dispatch(modalOpen(MODAL_LOADER));

		pdfPage.render({ canvasContext: context, viewport: originViewport }).then(() => {
			dispatch(pdfReset());

			const image = canvas.toDataURL('image/png'),
				blob = dataURItoBlob(image),
				file = new File([blob], 'image.png', { type: blob.type });

			dispatch(
				imageAdd(file, {
					width: canvas.width,
					height: canvas.height
				})
			);
		});
	};
}

export function pdfUpload(pdfFile) {
	return dispatch => {
		let pdfUrl = URL.createObjectURL(pdfFile);

		dispatch(modalOpen(MODAL_LOADER));

		pdfjs
			.getDocument(pdfUrl)
			.then(pdf => {
				let pages = [];

				for (let i = 1; i <= pdf.numPages; i++) {
					pages.push(pdf.getPage(i));
				}

				dispatch(pdfAdd(pdfFile, pdf.numPages));

				return Promise.all(pages);
			})
			.then(pages => {
				dispatch(pdfAddPages(pages));
				dispatch(createThumb(pages));
			});
	};
}

function createThumb(pages) {
	return dispatch => {
		let maxSize = 200;

		Promise.all(
			pages.map(page => {
				// This part hang's up all page
				return new Promise(resolve => {
					let originViewport = page.getViewport(1),
						maxOriginSize = Math.max(originViewport.width, originViewport.height),
						scale = maxSize / maxOriginSize,
						calcViewport = page.getViewport(scale),
						canvas = document.createElement('canvas'),
						context = canvas.getContext('2d');

					canvas.height = calcViewport.height;
					canvas.width = calcViewport.width;

					page.render({
						canvasContext: context,
						viewport: calcViewport
					}).then(() => {
						resolve(canvas.toDataURL('image/png'));
					});
				});
			})
		).then(function(thumbs) {
			dispatch(pdfAddPageThumbs(thumbs));
			dispatch(modalOpen(MODAL_PDF_PREVIEW));
		});
	};
}

export function pdfAdd(pdfFile, numPages) {
	return {
		type: PDF_ADD,
		payload: { pdfFile, numPages }
	};
}
export function pdfAddPages(pages) {
	return {
		type: PDF_ADD_PAGES,
		payload: { pages }
	};
}
export function pdfAddPageThumbs(thumbs) {
	return {
		type: PDF_ADD_PAGE_THUMB,
		payload: { thumbs }
	};
}
export function pdfReset() {
	return {
		type: PDF_RESET
	};
}

/**
 * Reducer
 */

const initialState = {
	pdfFile: null,
	numPages: null,
	pdfPages: null,
	pdfPagesThumbs: null
};

export default function(state = { ...initialState }, action) {
	switch (action.type) {
		case PDF_ADD: {
			return {
				...state,
				pdfFile: action.payload.pdfFile,
				numPages: action.payload.numPages,
				pdfPages: new Array(action.payload.numPages),
				pdfPagesThumbs: new Array(action.payload.numPages)
			};
		}

		case PDF_ADD_PAGES: {
			return {
				...state,
				pdfPages: action.payload.pages
			};
		}

		case PDF_ADD_PAGE_THUMB: {
			return {
				...state,
				pdfPagesThumbs: action.payload.thumbs
			};
		}

		case PDF_RESET: {
			return {
				...initialState
			};
		}

		default: {
			return state;
		}
	}
}
