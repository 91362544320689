import React from 'react';
import ReactGA from 'react-ga';

export default class SocialMenu extends React.Component {
	render() {
		return (
			<nav className='row no-gutters menu-social justify-content-end align-items-center'>
				<ReactGA.OutboundLink
					target='_blank'
					eventLabel='Facebook SketchAndCalc'
					to='https://www.facebook.com/SketchAndCalc-Area-Calculator-361029050630065/'
					className='icon-social-f'>
					<span className='sr-only'>facebook</span>
				</ReactGA.OutboundLink>
				<ReactGA.OutboundLink
					target='_blank'
					to='https://twitter.com/SketchAndCalc'
					eventLabel='Twitter SketchAndCalc'
					className='icon-social-t'>
					<span className='sr-only'>twitter</span>
				</ReactGA.OutboundLink>
			</nav>
		);
	}
}
