const OFFSET_UPDATE = 'OFFSET_UPDATE';
const OFFSET_RESET = 'OFFSET_RESET';

export function offsetUpdate(offset) {
	return {
		type: OFFSET_UPDATE,
		payload: { offset }
	};
}

export function offsetReset() {
	return { type: OFFSET_RESET };
}

const initState = {
	x: 0,
	y: 0
};

export function offsetReducer(state = initState, action) {
	switch (action.type) {
		case OFFSET_UPDATE: {
			return { ...state, ...action.payload.offset };
		}
		case OFFSET_RESET: {
			return { ...initState };
		}
		default: {
			return state;
		}
	}
}
