import * as React from 'react';
import { Text } from '../SVG/Text';
import { displayAngleValues } from '../../../shared/constants/settingsConstants';
import MathHelper from '../../../shared/MathHelper';

export class AngleLabels extends React.Component {
	render() {
		if (this.props.activeIndex === null) {
			return null;
		}

		if (this.props.displayAngles === displayAngleValues.DISPLAY_ANGLE_OFF) {
			return null;
		}

		return <g className='angle-labels'>{this.getLabels()}</g>;
	}

	getPointAngles() {
		const angels = [],
			distance = 16,
			zoom = this.props.zoom || 1,
			sketch = this.props.sketch;

		sketch.getControlPoints().forEach((point, index) => {
			const neighbors = sketch.getPointNeighbors(index);

			angels.push({
				value: MathHelper.getAngleBetweenThreePoints(neighbors.prev, point, neighbors.next) + '°',
				position: MathHelper.getAnglePosition(neighbors.prev, point, neighbors.next, distance / zoom)
			});
		});

		return angels;
	}

	getLabels() {
		let activeIndex = this.props.activeIndex,
			pointsAngels = this.getPointAngles(),
			angleLabels = [],
			zoom = this.props.zoom;

		if (activeIndex !== null) {
			let currentPointNeighborIndexes = this.props.sketch.getPointNeighborsIndexes(activeIndex);

			let prevAngle = pointsAngels[currentPointNeighborIndexes.prevIndex],
				currentAngle = pointsAngels[activeIndex],
				nextAngle = pointsAngels[currentPointNeighborIndexes.nextIndex];

			let fontSize;

			switch (this.props.displayAngles) {
				case displayAngleValues.DISPLAY_ANGLE_SMALL: {
					fontSize = 10;
					break;
				}
				case displayAngleValues.DISPLAY_ANGLE_MED: {
					fontSize = 12;
					break;
				}
				case displayAngleValues.DISPLAY_ANGLE_LARGE: {
					fontSize = 14;
					break;
				}
				default: {
					fontSize = 12;
				}
			}

			angleLabels.push(
				<Text
					key={'angle-prev'}
					x={prevAngle.position[0]}
					y={prevAngle.position[1]}
					text={prevAngle.value}
					fontSize={fontSize}
					zoom={zoom}
				/>
			);
			angleLabels.push(
				<Text
					key={'angle-current'}
					x={currentAngle.position[0]}
					y={currentAngle.position[1]}
					text={currentAngle.value}
					fontSize={fontSize}
					zoom={zoom}
				/>
			);
			angleLabels.push(
				<Text
					key={'angle-next'}
					x={nextAngle.position[0]}
					y={nextAngle.position[1]}
					text={nextAngle.value}
					fontSize={fontSize}
					zoom={zoom}
				/>
			);
		}

		return angleLabels;
	}
}
