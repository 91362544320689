import { stateRestoreFromObject, stateToObject } from '../actions/stateActions';
import { modalClose, modalOpen } from './ModalReducer';
import { MODAL_LOADER } from '../../components/ModalContainer/Modals/ModalLoader/ModalLoader';
import ApiHelper from '../ApiHelper';
import store from '../store';
import { settingsUpdate } from '../actions/settingsActions';
import { dataURItoBlob } from './PdfReducer';
import { notificationShow } from '../actions/notificationActions';
import { notificationTypes } from '../constants/notificationConstants';
import { layerChange, layerRefreshLayers, layerSetShowAllLayers } from '../actions/layerActions';
import download from 'downloadjs';
import blobUtil from 'blob-util';
import { eachOfSeries } from 'async';

window.loadPrintData = data => {
	if (data.settings) {
		store.dispatch(settingsUpdate(data.settings));
	}

	store.dispatch(stateRestoreFromObject({ state: JSON.stringify(data) }));

	store.dispatch(printActivatePhantom());
};

window.changeLayer = (index, showAllLayers = false) => {
	store.dispatch(printActivateExport());

	if (showAllLayers) {
		store.dispatch(layerSetShowAllLayers(true));
	} else {
		store.dispatch(layerChange(index));
	}
};

const PRINT_ACTIVATE = 'PRINT_ACTIVATE';
const PRINT_DEACTIVATE = 'PRINT_DEACTIVATE';
const PRINT_ACTIVATE_PHANTOM = 'PRINT_ACTIVATE_PHANTOM';
const PRINT_ACTIVATE_EXPORT = 'PRINT_ACTIVATE_EXPORT';
const PRINT_ACTIVATE_READY = 'PRINT_ACTIVATE_READY';

export function printActivate(isExport = false) {
	return (dispatch, store) => {
		const { drawArea, settings, layers, map, label } = store();

		dispatch(modalOpen(MODAL_LOADER));

		dispatch(stateToObject()).then(state => {
			if (map.visible && layers.showAllLayers) {
				dispatch(layerRefreshLayers());
			}

			state.drawArea.stageSizes = drawArea.stageSizes;
			state.settings = settings;
			state.label = label;

			if (isExport) {
				ApiHelper.export({ state })
					.then(response => {
						dispatch(modalClose());

						let downloadedFiles = []; // sometimes there's duplicates coming from back end, can't figure out why so there's this temporary frontend fix

						eachOfSeries(response.data.imagesBlob, (image, key, callback) => {
							if (!downloadedFiles.includes(image.name)) {
								downloadedFiles.push(image.name);

								blobUtil.base64StringToBlob(image.base64).then(blob => {
									blobUtil.blobToArrayBuffer(blob).then(arrayBuffer => {
										download(arrayBuffer, image.name);

										setTimeout(() => {
											callback();
										}, 2000);
									});
								});
							} else {
								callback();
							}
						});
					})
					.catch(error => {
						dispatch(modalClose());

						let message = 'An unknown error occurred, please try again later.';

						if (error.response && error.response.data.message) {
							message = error.response.data.message;
						}

						dispatch(notificationShow(message, notificationTypes.DANGER, 3000));
					});
			} else {
				ApiHelper.print({ state })
					.then(response => {
						const blob = dataURItoBlob(response.data);

						dispatch({
							type: PRINT_ACTIVATE,
							payload: {
								image: URL.createObjectURL(blob)
							}
						});
					})
					.catch(error => {
						dispatch(modalClose());

						let message = 'An unknown error occurred, please try again later.';

						if (error.response && error.response.data.message) {
							message = error.response.data.message;
						}

						dispatch(notificationShow(message, notificationTypes.DANGER, 3000));
					});
			}
		});
	};
}

export function printActivatePhantom() {
	return { type: PRINT_ACTIVATE_PHANTOM };
}

export function printActivateExport() {
	return { type: PRINT_ACTIVATE_EXPORT };
}

export function printActivateReady() {
	return { type: PRINT_ACTIVATE_READY };
}

export function printDeactivate() {
	return { type: PRINT_DEACTIVATE };
}

const initState = {
	image: null,
	isPhantomJs: false,
	isExport: false,
	isReady: false
};

export default function printReducer(state = initState, action) {
	switch (action.type) {
		case PRINT_ACTIVATE: {
			return { ...state, image: action.payload.image };
		}
		case PRINT_ACTIVATE_PHANTOM: {
			return { ...state, isPhantomJs: true };
		}
		case PRINT_ACTIVATE_EXPORT: {
			return { ...state, isExport: true };
		}
		case PRINT_ACTIVATE_READY: {
			return { ...state, isReady: true };
		}
		case PRINT_DEACTIVATE: {
			return { ...initState };
		}
		default:
			return state;
	}
}
