import { GET_DOCUMENTS_SUCCESS } from '../constants/documentsConstants';

const initState = {
    documentsList: []
};

export default (state = initState, action) => {
    switch (action.type) {
        case GET_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                documentsList: action.payload
            };
        }

        default:
            return state;
    }
}
