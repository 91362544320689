import React, { Component } from 'react';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import ApiHelper from '../../../shared/ApiHelper';
import { bindActionCreators } from 'redux';
import { ppdIgnoreWelcome } from '../../../shared/reducers/ppdReducer';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import TextView from '../../Util/TextView';
import update from 'immutability-helper';
import { ruleRunner, run } from '../../Validation/ruleRunner';
import { required } from '../../Validation/rules';
import ReactGA from 'react-ga';
import {countryList, SUBSCRIPTION_PLANS_ALIAS} from '../../../shared/constants/plansConstants';
import Dropdown from '../../Util/Dropdown/Dropdown';

const fieldValidations = [
	ruleRunner('postcode', 'Postal code', required),
	ruleRunner('holder', 'Name on Card', required)
];

const styleInput = { base: { fontSize: '16px', lineHeight: '54px', color: '#464a4c' } };

class CheckoutForm extends Component {
	constructor(props) {
		super(props);

		this.handleFieldChanged = this.handleFieldChanged.bind(this);
		this.errorFor = this.errorFor.bind(this);

		this.state = {
			holder: '',
			country: 'US',
			postcode: '',
			validationErrors: {},
			showErrors: false,
			error: null,
			disabled: false,
			processing: false
		};
	}

	presetCountry = (country) => {
		const countryPreset = countryList.find(({value}) => value === country);
		this.setState({country: countryPreset?.value || 'US'})
	}

	componentDidUpdate(prevProps) {
		if(prevProps.countryPreset !== this.props.countryPreset) {
			this.presetCountry(this.props.countryPreset)
		}
	}

	handleSubmit = async ev => {
		ev.preventDefault();

		this.setState({ showErrors: true });

		await new Promise(resolve => {
			this.setState({ validationErrors: run(this.state, fieldValidations) }, resolve);
		});

		if (Object.keys(this.state.validationErrors).length) return;

		this.setState({ showErrors: false });

		ReactGA.ga('send', 'event', 'form', 'click', 'payment');
		let plan = this.props.plan;

		this.setState({
			disabled: true,
			processing: true,
			error: null
		});

		try {
			let setupIntent = await ApiHelper.setup_intents();
			let cardSetup = await this.props.stripe.handleCardSetup(setupIntent.data.client_secret);

			if (cardSetup.error) {
				throw cardSetup.error;
			}

			await ApiHelper.payment_sca({
				token: cardSetup.setupIntent.payment_method,
				plan,
				holder: this.state.holder,
				country: this.state.country,
				postcode: this.state.postcode
			})
				.then(response => {
					if (response.data.success && plan.id === SUBSCRIPTION_PLANS_ALIAS.payPerDay) {
						if (
							this.props.user &&
							this.props.user.trial_end &&
							Date.parse(this.props.user.trial_end) > Date.now()
						) {
							ApiHelper.reportUsage();
							return response;
						} else {
							return ApiHelper.reportUsage();
						}
					} else {
						return response;
					}
				})
				.then(response => {
					if (response.data.success) {
						if (plan.id === SUBSCRIPTION_PLANS_ALIAS.payPerDay) {
							this.props.ppdIgnoreWelcome(true);
						}
						this.props.history.push('/register-complete');
					}
				})
				.then(response => {
					ReactGA.ga('send', 'event', 'form', 'click', 'payment-complete');
				});

			this.setState({
				disabled: false,
				processing: false,
				error: null
			});
		} catch (e) {
			this.setState({
				disabled: false,
				processing: false,
				error: e.message
			});
		}
	};

	close = ({ target, currentTarget }) => {
		let { disabled, processing } = this.state;

		if (target !== currentTarget || disabled === true || processing === true) {
			return;
		}

		this.props.closeCheckout();
	};

	handleFieldChanged(field) {
		return e => {
			let newState = update(this.state, {
				[field]: { $set: e.target.value }
			});
			newState.validationErrors = run(newState, fieldValidations);

			return this.setState(newState);
		};
	}

	errorFor(field) {
		return this.state.validationErrors[field] || '';
	}

	renderForm() {
		return this.props.show && this.props.user ? (
			<div id={'checkout'} onClick={this.close}>
				<form onSubmit={this.handleSubmit}>
					<h6>{this.props.plan.name}</h6>

					<div className='form-group'>
						<label style={{ textAlign: 'center' }}>{this.props.user.email}</label>
					</div>

					<div className='form-group'>
						<label>Card Details</label>
						<div className={'relative'}>
							<CardNumberElement className={'cardNumber'} style={styleInput} />
							<i className={'iconCardnumber icon-cardnumber'}></i>
						</div>
						<div className={'cardExpiryCVC'}>
							<CardExpiryElement className={'cardExpiry'} style={styleInput} />
							<CardCVCElement className={'cardCVC'} style={styleInput} />
							<i className={'iconCardcvv icon-cardcvv'}></i>
						</div>
						{this.state.error && (
							<div className='validation-error'>
								<span className='text'>{this.state.error}</span>
							</div>
						)}
					</div>

					<div className='form-group'>
						<label>Name on Card</label>
						<TextView
							showError={this.state.showErrors}
							type='text'
							text={this.props.holder}
							onFieldChanged={this.handleFieldChanged('holder')}
							errorText={this.errorFor('holder')}
						/>
					</div>

					<div className='form-group'>
						<label>Country or region</label>
						<Dropdown
							onChange={this.handleFieldChanged('country')}
							value={this.state.country}
							options={countryList}
						/>
						<TextView
							className={'postcode'}
							placeholder='Postal code'
							showError={this.state.showErrors}
							type='text'
							text={this.props.postcode}
							onFieldChanged={this.handleFieldChanged('postcode')}
							errorText={this.errorFor('postcode')}
						/>
					</div>

					<div className='form-group'>
						<button type='submit' disabled={this.state.disabled}>
							{this.state.processing ? 'Processing…' : 'Pay'}
						</button>
						<div>
							<h5>
								{' '}
								By clicking "Pay" you agree to our{' '}
								<Link target='_blank' to={'/terms-and-conditions'}>
									Terms of Use
								</Link>
								{', '}
								<Link target='_blank' to={'/cookie-policy'}>
									Cookie
								</Link>{' '}
								and{' '}
								<Link target='_blank' to={'/privacy-policy'}>
									Privacy Policy
								</Link>
								.
							</h5>
						</div>
					</div>
				</form>
			</div>
		) : null;
	}

	render() {
		return this.renderForm();
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		user: state.auth.user
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			ppdIgnoreWelcome
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectStripe(CheckoutForm)));
