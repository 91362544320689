import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { drawAreaToggleVisibility } from '../../../shared/actions/drawAreaActions';
import { imageAddToCanvas, imageToggleVisibility } from '../../../shared/actions/ImageActions';
import HideShowGrid from '../../icons/HideShowGrid';
import HideShowImage from '../../icons/HideShowImage';
import MapControls from '../../MapControls/MapControls';

class CanvasControlsContainer extends React.Component {
	render() {
		const { image, canvasVisible } = this.props;
		return (
			<div className='canvas-controls-container'>
				<div className='canvas-actions-buttons'>
					{this.props.image.dynamicImage && this.props.image.url && (
						<button className='btn btn-success btn-sm' onClick={this.props.imageAddToCanvas}>
							add to canvas
						</button>
					)}

					{!this.props.image.dynamicImage && this.props.image.url && (
						<div className='image-container'>
							<button
								title={`${image.display ? 'hide' : 'show'} image`}
								className={`btn btn-primary btn-sm image${image.display ? '' : ' hide'}`} onClick={this.props.imageToggleVisibility}>
								<HideShowImage height='25px' width='18px'/>
							</button>
							<button
								title={`${canvasVisible ? 'hide' : 'show'} canvas`}
								className={`btn btn-primary btn-sm image${canvasVisible ? '' : ' hide'}`} onClick={this.props.drawAreaToggleVisibility}>
								<HideShowGrid height='20px' width='20px'/>
							</button>
						</div>
					)}
				</div>
				{this.props.mapVisible && <MapControls />}
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		image: state.image,
		scaleManual: state.scale.manual,
		canvasVisible: state.drawArea.visible,
		mapVisible: state.map.visible
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			drawAreaToggleVisibility,
			imageAddToCanvas,
			imageToggleVisibility
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(CanvasControlsContainer);
