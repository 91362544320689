import store from '../store';
import Line from '../classes/Line';
import CurvedLine from '../classes/CurvedLine';
import Sketch from '../classes/Sketch';
import { historyPush } from './historyActions';
import { sketchUpdateSketch } from './SketchActions';
import MapHelper from '../MapHelper';
import { STRAIGHT_LINE } from '../classes/Instruments';
import MathHelper from '../MathHelper';
import { getEventPoint } from '../classes/pointsFunc';
import { notificationShow } from './notificationActions';
import { notificationTypes } from '../constants/notificationConstants';
import { DIAMETER_LINE } from '../classes/DiameterLine';

export function lineAddPoint(event, index, chunkIndex) {
	return dispatch => {
		let sketch = store.getState().sketchState.sketch,
			map = store.getState().map,
			point = getEventPoint(event),
			originalLine = sketch.lines[index],
			newLines;

		if (originalLine instanceof Line) {
			if (map.visible) {
				newLines = [
					new Line(
						...[
							originalLine.startPoint,
							point,
							originalLine.startLatLngPoint,
							MapHelper.point2LatLng(point)
						]
					),
					new Line(
						...[point, originalLine.endPoint, MapHelper.point2LatLng(point), originalLine.endLatLngPoint]
					)
				];
			} else {
				newLines = [new Line(...[originalLine.startPoint, point]), new Line(...[point, originalLine.endPoint])];
			}
		}

		if (originalLine instanceof CurvedLine) {
			let pointIndex = chunkIndex + 1,
				points = originalLine.getPoints(),
				newPoints = [...points.slice(0, pointIndex), point, ...points.slice(pointIndex)];

			if (map.visible) {
				let latLngPoints = originalLine.getLatLngPoints(),
					newLatLngPoints = [
						...latLngPoints.slice(0, pointIndex),
						MapHelper.point2LatLng(point),
						...latLngPoints.slice(pointIndex)
					];

				newLines = [new CurvedLine(newPoints, newLatLngPoints)];
			} else {
				newLines = [new CurvedLine(newPoints)];
			}
		}

		let lines = [...sketch.lines.slice(0, index), ...newLines, ...sketch.lines.slice(index + 1)];

		let newSketch = new Sketch(lines);
		newSketch.labelsVisible = sketch.labelsVisible;
		newSketch.hasCurved = sketch.hasCurved;
		newSketch.closeSketch();

		dispatch(sketchUpdateSketch(newSketch));
		dispatch(historyPush(newSketch.convertForSave()));
	};
}

export function lineChangeLength(lineIndex, pointIndex, length) {
	return (dispatch, store) => {
		if (!(lineIndex >= 0 && pointIndex >= 0 && length > 0)) {
			return;
		}

		let state = store(),
			map = state.map,
			sketch = state.sketchState.sketch,
			line = sketch.getLines()[lineIndex],
			sizes = state.drawArea.stageSizes;

		if (!line) {
			return;
		}

		if (line.type === STRAIGHT_LINE || line.type === DIAMETER_LINE) {
			let start = line.getStartPoint(),
				end = line.getEndPoint(),
				point = [end[0] - start[0], end[1] - start[1]],
				angle = Math.atan2(point[1], point[0]),
				newPoint = [];

			if (pointIndex === 0) {
				newPoint = [
					MathHelper.round(end[0] - Math.cos(angle) * length),
					MathHelper.round(end[1] - Math.sin(angle) * length)
				];
			} else {
				newPoint = [
					MathHelper.round(start[0] + Math.cos(angle) * length),
					MathHelper.round(start[1] + Math.sin(angle) * length)
				];
			}

			if (newPoint[0] > sizes.width || newPoint[1] > sizes.height || newPoint[0] < 0 || newPoint[1] < 0) {
				dispatch(
					notificationShow(
						'You cannot move a vertex off screen by editing the line length!',
						notificationTypes.DANGER,
						3000
					)
				);
			} else {
				let newSketch = Sketch.restoreFromDump(sketch.convertForSave());
				newSketch.updatePoint(lineIndex, pointIndex, newPoint);

				if (MathHelper.isPathIntersect([...newSketch.getControlPoints(), newSketch.getFirstPoint()])) {
					dispatch(notificationShow('Lines cannot intersect!', notificationTypes.DANGER, 1000));
				} else {
					if (map.visible) {
						newSketch.updateLatLngPoint(lineIndex, pointIndex, MapHelper.point2LatLng(newPoint));
					}

					dispatch(sketchUpdateSketch(newSketch));
					dispatch(historyPush(newSketch.convertForSave()));
				}
			}
		}
	};
}
