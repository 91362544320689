import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { modalClose } from '../../../../shared/reducers/ModalReducer';
import { printActivate } from '../../../../shared/reducers/printReducer';

export const MODAL_EXPORT = 'MODAL_EXPORT';

class ModalExport extends React.Component {
	render() {
		return (
			<div className='modal-export modal-inner-wrapper'>
				<h2 className='text-center'>export image or data</h2>
				<div className='text-center'>
					<button className='btn btn-modal' onClick={this.onExportPNG.bind(this)}>
						.png
					</button>
					<button className='btn btn-modal' onClick={this.onExportCSV.bind(this)}>
						.csv
					</button>
				</div>
			</div>
		);
	}

	onExportPNG() {
		this.props.printActivate(true);
	}

	onExportCSV() {
		const rows = this.calculateCSVRows(),
			csvContent = 'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n'),
			encodedUri = encodeURI(csvContent),
			link = document.createElement('a');

		const fileName = this.props.fileName || 'SketchAndCalc';

		link.setAttribute('href', encodedUri);
		link.setAttribute('download', fileName + '.csv');
		document.body.appendChild(link); // Required for FF

		link.click();
		this.props.modalClose();
	}

	calculateCSVRows() {
		let result = [['layer_id', 'perimeter_' + this.props.measure, 'area_' + this.props.measure + '2']];

		this.props.layers
			.map((layer, index) => {
				if (this.props.selectedLayer === index) {
					return this.props.sketch;
				} else {
					return layer.sketch;
				}
			})
			.filter(sketch => sketch.closed)
			.forEach((sketch, index) => {
				let perimeter = sketch.getPerimeter(this.props.scale, false),
					area = sketch.getArea(this.props.scale, false),
					prefix = sketch.hasCurved ? '~' : '';
				result.push([index + 1, prefix + perimeter, prefix + area]);
			});

		return result;
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		sketch: state.sketchState.sketch,
		scale: state.scale.value,
		measure: state.scale.measure,
		layers: state.layers.list,
		selectedLayer: state.layers.currentIndex,
		fileName: state.fileName.fileName
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			printActivate,
			modalClose
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalExport);
