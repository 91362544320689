import React from 'react';
import connect from 'react-redux/es/connect/connect';
import bindActionCreators from 'redux/es/bindActionCreators';
import { selectedPdfPage } from '../../../../shared/reducers/PdfReducer';

export const MODAL_PDF_PREVIEW = 'MODAL_PDF_PREVIEW';

class ModalPDFPreview extends React.Component {
	render() {
		return (
			<div className='modal-pdf-preview modal-inner-wrapper'>
				<p>Select PDF page</p>
				<div className='images modal-content row no-gutters align-items-center'>
					{this.props.thumbnails &&
						this.props.thumbnails.map((image, index) => {
							return (
								<div className='image-wrapper col-3' key={index}>
									<img onClick={() => this.props.selectedPdfPage(index)} src={image} alt='' />
								</div>
							);
						})}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		thumbnails: state.pdf.pdfPagesThumbs
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			selectedPdfPage
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalPDFPreview);
