import ReactGA from 'react-ga';
import { MODAL_LOADER } from '../../components/ModalContainer/Modals/ModalLoader/ModalLoader';

const MODAL_UPDATE_ACTIVE = 'MODAL_UPDATE_ACTIVE';

export function modalOpen(modalId, data={}) {
	return dispatch => {
		if (modalId !== MODAL_LOADER) {
			ReactGA.modalview(modalId);
		}

		dispatch(modalUpdateActive(modalId, data));
	};
}

export function modalClose() {
	return dispatch => {
		dispatch(modalUpdateActive(null));
	};
}

function modalUpdateActive(activeModal, data={}) {
	return {
		type: MODAL_UPDATE_ACTIVE,
		payload: { activeModal, data }
	};
}

export function modalReducer(
	state = {
		activeModal: null,
		data: {}
	},
	action
) {
	switch (action.type) {
		case MODAL_UPDATE_ACTIVE: {
			const { activeModal, data } = action.payload
			return {
				...state,
				activeModal,
				data
			};
		}

		default: {
			return state;
		}
	}
}
