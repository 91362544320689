import Layer from '../classes/Layer';
import layerTypes from '../constants/layerConstants';
import Sketch from '../classes/Sketch';
import store from '../store';

const initState = {
	list: [new Layer()],
	currentIndex: 0,
	showAllLayers: false
};

export default function(state = { ...initState }, action) {
	switch (action.type) {
		case layerTypes.LAYER_ADD: {
			let maxId = Math.max(...state.list.map(layer => layer.getId())),
				newLayer = new Layer();

			newLayer.setId(maxId + 1);

			return { ...state, list: [...state.list, newLayer] };
		}
		case layerTypes.LAYER_DUPLICATE: {
			let maxId = Math.max(...state.list.map(layer => layer.getId())),
				currentLayer = state.list[state.currentIndex],
				globalState = store.getState(),
				currentNotes = globalState.notes.list,
				currentSketchDump = globalState.sketchState.sketch.convertForSave(),
				newHistory = {
					currentPosition: 0,
					history: [currentSketchDump]
				},
				newLayer = new Layer(Sketch.restoreFromDump(currentSketchDump), currentNotes, newHistory);

			newLayer.setId(maxId + 1);
			newLayer.setColor(currentLayer.getColor());

			return { ...state, list: [...state.list, newLayer] };
		}
		case layerTypes.LAYER_UPDATE: {
			let index = action.payload.index;

			return {
				...state,
				list: [...state.list.slice(0, index), action.payload.layer, ...state.list.slice(index + 1)]
			};
		}
		case layerTypes.LAYER_SET_SHOW_ALL_LAYERS: {
			return { ...state, showAllLayers: action.payload.value };
		}
		case layerTypes.LAYER_SET_LIST_LAYERS: {
			return { ...state, list: [...action.payload.list] };
		}
		case layerTypes.LAYER_CHANGE: {
			return { ...state, currentIndex: action.payload.index };
		}
		case layerTypes.LAYER_DELETE: {
			return {
				...state,
				list: [...state.list.slice(0, action.payload.index), ...state.list.slice(action.payload.index + 1)]
			};
		}
		case layerTypes.LAYER_RESET: {
			return { ...initState };
		}

		case layerTypes.TOGGLE_PIN_LAYER: {
			const newList = state.list.map(layer => {
				if (layer.getId() === action.payload.layerId) {
					const newLayer = new Layer();
					newLayer.setId(layer.getId());
					newLayer.setColor(layer.getColor() || 'pink');
					newLayer.setPin(!layer.getPin());

					return newLayer;
				}

				return layer;
			});

			return {
				...state,
				list: [...newList]
			};
		}

		default: {
			return state;
		}
	}
}
