import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { modalClose } from '../../../../shared/reducers/ModalReducer';
import { mapToggleVisibility } from '../../../../shared/actions/mapActions';

export const MODAL_TOGGLE_MAP = 'MODAL_TOGGLE_MAP';

class ModalToggleMap extends React.Component {
	render() {
		return (
			<div className='modal-toggle-map modal-inner-wrapper'>
				<h2 className='text-center'>
					{this.props.mapVisible && 'switching to canvas'}
					{!this.props.mapVisible && 'switching to maps'}
				</h2>
				<p className='text-center'>all current layers will be deleted</p>
				<div className='text-center'>
					<button className='btn btn-modal' onClick={this.props.modalClose}>
						cancel
					</button>
					<button
						className='btn btn-modal'
						onClick={() => {
							this.props.mapToggleVisibility();
							this.props.modalClose();
						}}>
						delete
					</button>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		mapVisible: state.map.visible
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			modalClose,
			mapToggleVisibility
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalToggleMap);
