import io from 'socket.io-client';
import cookie from 'js-cookie';
import { dispatch } from './store';
import { runEvent, setWebsocketStatus } from './actions/socketActions';
import socketConstants from './constants/socketConstants';
import get from 'lodash/get';

export default {
    _socket: null,

    init() {
        const accessToken = cookie.get('accessToken')
        if (accessToken) {
            this.connect(accessToken)
        }
    },

    connect(token) {
        const socketToken = get(this, '_socket.io.opts.query.token', '')

        if (socketToken === token) {
            return
        }

        const options = {
            cors: {
                origin: '*'
            },
            query: {
                token
            }
        }
        this._socket = io.connect(process.env.REACT_APP_API_HOST, options);
        this._socket.on('action', props => dispatch(runEvent(props)));
        this._socket.on('disconnect', () => dispatch(setWebsocketStatus(socketConstants.SOCKET_OFFLINE)));
        this._socket.on('connect', () => dispatch(setWebsocketStatus(socketConstants.SOCKET_ONLINE)));
    },

    disconnect() {
        if (!this._socket) {
            return
        }

        this._socket.disconnect();
        this._socket = null;
    }
}