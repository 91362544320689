import React from 'react';
import { MeasureSelect } from '../MeasureSelect/MeasureSelect';
import * as scaleActions from '../../../shared/actions/scaleControlActions';
import bindActionCreators from 'redux/es/bindActionCreators';
import { connect } from 'react-redux';
import { renderSetScale2 } from '../../ContextualHelp/Modals/SetScale2';
import { helpActivateLengthSubmitted, helpActivateScale2 } from '../../../shared/actions/helpActions';
import { notificationShow } from '../../../shared/actions/notificationActions';
import { notificationTypes } from '../../../shared/constants/notificationConstants';
import InputMeasure, { isInputMeasure } from '../InputMeasure/InputMeasure';

export let scalePopupEl;

class ScalePopup extends React.Component {
	scaleValue = '';

	componentDidUpdate() {
		if (scalePopupEl && renderSetScale2) {
			renderSetScale2();
		}
	}

	render() {
		scalePopupEl = null;

		if (!this.props.scaleLineReady) {
			this.scaleValue = '';
			return null;
		}

		let lineEndPoint = this.props.scaleLine.getEndPoint(),
			point = [
				lineEndPoint[0] * this.props.zoomValue - Math.abs(this.props.offset.x),
				lineEndPoint[1] * this.props.zoomValue - Math.abs(this.props.offset.y)
			];

		if (point[0] + 265 > this.props.sizes.width) {
			point[0] -= point[0] + 265 - this.props.sizes.width;
		}

		if (point[1] + 30 > this.props.sizes.height) {
			point[1] -= point[1] + 30 - this.props.sizes.height;
		}

		if (point[0] < 0) {
			point[0] = 0;
		}
		if (point[1] < 0) {
			point[1] = 0;
		}

		return (
			<div
				ref={el => (scalePopupEl = el)}
				className='scale-popup'
				style={{
					left: `${point[0]}px`,
					top: `${point[1]}px`
				}}>
				<div className='icon icon-annotate_delete' onClick={this.props.scaleLineReset.bind(this)} />
				{isInputMeasure(this.props.measure) && this.props.isFractions ? (
					<InputMeasure
						value={0}
						measure={this.props.measure}
						style={{
							height: 28,
							boxShadow: 'none'
						}}
						afterConvert={value => {
							this.scaleValue = value;
						}}
					/>
				) : (
					<input type='text' onKeyUp={this.scaleValueChanged.bind(this)} placeholder='length' />
				)}
				<MeasureSelect measure={this.props.measure} shortText={true} />

				<div className='icon icon-checkmark' onClick={this.scaleSubmit.bind(this)} />
			</div>
		);
	}

	isNumeric(value) {
		return !isNaN(parseFloat(value)) && isFinite(value);
	}

	scaleValueChanged(event) {
		this.scaleValue = event.target.value;
	}

	scaleSubmit() {
		if (this.isNumeric(this.scaleValue)) {
			this.props.helpActivateScale2();
			this.props.helpActivateLengthSubmitted();
			this.props.scaleCalcFromUserInputValue(this.scaleValue, this.props.measure);
		} else {
			this.props.notificationShow('Numeric values only', notificationTypes.WARNING, 1000);
		}
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		measure: state.scale.measure,
		scaleLine: state.scale.line,
		scaleLineReady: state.scale.lineReady,
		zoomValue: state.zoom.value,
		offset: state.offset,
		sizes: state.drawArea.stageSizes,
		isFractions: state.settings.imperial === 'fractions'
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			...scaleActions,
			notificationShow,
			helpActivateScale2,
			helpActivateLengthSubmitted
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ScalePopup);
