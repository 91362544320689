import * as noteTypes from '../constants/NoteConstants';
import Note from '../classes/Note';
import { getEventPoint } from '../classes/pointsFunc';

const initState = {
	editableNote: null,
	movableNote: null,
	hoveredNote: null,
	startMovePoint: null,
	list: []
};

export function noteReducer(state = initState, action) {
	switch (action.type) {
		case noteTypes.NOTE_ADD: {
			return {
				...state,
				list: [...state.list, new Note(action.payload.point, action.payload.latLng)]
			};
		}
		case noteTypes.NOTE_START_EDIT: {
			return { ...state, editableNote: action.payload.index };
		}

		case noteTypes.NOTE_OVER: {
			return { ...state, hoveredNote: action.payload.index };
		}

		case noteTypes.NOTE_MOUSE_OUT: {
			return { ...state, hoveredNote: null };
		}

		case noteTypes.NOTE_ADJUST_ZOOM: {
			let notes = state.list.map(note => {
				let newNote = note.clone();
				newNote.point = newNote.adjustScale();
				return newNote;
			});

			return { ...state, list: notes };
		}

		case noteTypes.NOTE_UPDATE: {
			let index = action.payload.index;

			return {
				...state,
				list: [...state.list.slice(0, index), action.payload.note, ...state.list.slice(index + 1)]
			};
		}

		case noteTypes.NOTE_UPDATE_LIST: {
			return { ...state, list: action.payload.list };
		}

		case noteTypes.NOTE_END_EDIT: {
			return { ...state, editableNote: null };
		}

		case noteTypes.NOTE_START_MOVE: {
			return {
				...state,
				movableNote: action.payload.index,
				startMovePoint: getEventPoint(action.payload.event)
			};
		}

		case noteTypes.NOTE_MOVE: {
			return {
				...state,
				startMovePoint: action.payload.newMovePoint
			};
		}

		case noteTypes.NOTE_END_MOVE: {
			if (state.movableNote === null) {
				return state;
			}

			return { ...state, movableNote: null };
		}
		case noteTypes.NOTE_DELETE: {
			return {
				...state,
				list: [...state.list.slice(0, action.payload.index), ...state.list.slice(action.payload.index + 1)]
			};
		}
		case noteTypes.NOTE_RESET: {
			return { ...initState };
		}
		default: {
			return state;
		}
	}
}
