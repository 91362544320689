import './style.css';
import './assets/fonts/sac.css';

import ReactDOM from 'react-dom';
import routes from './Routes';
import ApiHelper from './shared/ApiHelper';
import CookieHelper from './shared/CookieHelper';
import SocketAPI from './shared/SocketAPI';


import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

ApiHelper.init();
CookieHelper.init();
SocketAPI.init();

ReactDOM.render(routes, document.getElementById('root'));
