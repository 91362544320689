import { svgEl } from '../../components/App/DrawArea/DrawArea';

const INSTRUMENT_MENU_TOGGLE_EXPAND = 'INSTRUMENT_MENU_TOGGLE_EXPAND';
const INSTRUMENT_MENU_SET_MOUSE_DOWN = 'INSTRUMENT_MENU_SET_MOUSE_DOWN';
const INSTRUMENT_MENU_SET_POSITION = 'INSTRUMENT_MENU_SET_POSITION';
const INSTRUMENT_MENU_SET_OFFSETS = 'INSTRUMENT_MENU_SET_OFFSETS';
const INSTRUMENT_MENU_RESET = 'INSTRUMENT_MENU_RESET';

export const instrumentMenuTypes = {
	INSTRUMENT_MENU_TOGGLE_EXPAND,
	INSTRUMENT_MENU_SET_MOUSE_DOWN,
	INSTRUMENT_MENU_SET_POSITION,
	INSTRUMENT_MENU_SET_OFFSETS,
	INSTRUMENT_MENU_RESET
};

export function instrumentMenuToggleExpand() {
	return { type: instrumentMenuTypes.INSTRUMENT_MENU_TOGGLE_EXPAND };
}

export function instrumentMenuSetMouseDown(value) {
	return {
		type: instrumentMenuTypes.INSTRUMENT_MENU_SET_MOUSE_DOWN,
		payload: { value }
	};
}

export function instrumentMenuMouseMove(event) {
	return (dispatch, store) => {
		let { instrumentMenu } = store();

		if (instrumentMenu.mouseDown) {
			let bounds = svgEl.getBoundingClientRect(),
				position = {
					x: event.nativeEvent.clientX - bounds.left - instrumentMenu.offsets.x,
					y: event.nativeEvent.clientY - bounds.top - instrumentMenu.offsets.y
				};

			dispatch(instrumentMenuSetPosition(position));
		}
	};
}

export function instrumentMenuMouseUp() {
	return (dispatch, store) => {
		let { instrumentMenu } = store();

		if (instrumentMenu.mouseDown) {
			dispatch(instrumentMenuSetMouseDown(false));
			dispatch(instrumentMenuSetOffsets(initState.offsets));
		}
	};
}

export function instrumentMenuSetPosition(position) {
	return {
		type: instrumentMenuTypes.INSTRUMENT_MENU_SET_POSITION,
		payload: { position }
	};
}

export function instrumentMenuSetOffsets(offsets) {
	return {
		type: instrumentMenuTypes.INSTRUMENT_MENU_SET_OFFSETS,
		payload: { offsets }
	};
}

export function instrumentMenuReset() {
	return { type: instrumentMenuTypes.INSTRUMENT_MENU_RESET };
}

const initState = {
	expand: true,
	mouseDown: false,
	position: null,
	offsets: { x: 0, y: 0 }
};

export default function instrumentMenuReducer(state = initState, action) {
	switch (action.type) {
		case instrumentMenuTypes.INSTRUMENT_MENU_TOGGLE_EXPAND: {
			return { ...state, expand: !state.expand };
		}
		case instrumentMenuTypes.INSTRUMENT_MENU_SET_MOUSE_DOWN: {
			return { ...state, mouseDown: action.payload.value };
		}
		case instrumentMenuTypes.INSTRUMENT_MENU_SET_POSITION: {
			return { ...state, position: action.payload.position };
		}
		case instrumentMenuTypes.INSTRUMENT_MENU_SET_OFFSETS: {
			return { ...state, offsets: action.payload.offsets };
		}
		case instrumentMenuTypes.INSTRUMENT_MENU_RESET: {
			return { ...initState };
		}
		default: {
			return state;
		}
	}
}
