import React from 'react';
import StripeWidget from "../StripeWidget";

const plans = {
    'educator': {
        id:'educator',
        name:'educator',
        perks: [
            'classroom sessions',
            'teacher dashboard',
            'requires verification'
        ]
    },
    'payg': {
        id:'payg',
        name:'pay as you go',
        perks:[
            'per 24h session',
            'sessions billed monthly',
            'no subscription'
        ]
    },
    'project': {
        id:'project',
        name:'project',
        perks:[
            'monthly subscription',
            'cancel anytime'
        ]
    },
    'pro': {
        id:'pro',
        name: 'pro',
        perks: [
            'annual subscription',
            'cancel anytime',
            'best value'
        ]
    }
};

class PersonalPlans extends React.Component {
    render() {
        const {prices, switchPriceType, handleError, showCheckout} = this.props;

        return (
            <div className={'plans personal-plans'}>
                {Object.keys(plans).map(planKey => {
                    const plan = plans[planKey];
                    const planPrice = prices.find(price => price.productName.toLowerCase() === planKey.toLowerCase())
                    let price = null
                    if (planPrice) {
                        const integer = Math.floor(planPrice.price/100);
                        const float = planPrice.price - integer*100;

                        price = {integer, float}
                    }

                    return (
                        <div className={'plan'} key={planKey}>
                            <div className={'plan-name'}>{plan.name}</div>
                            <div className={'plan-body'}>
                            <div className={'plan-price'}>
                                {
                                    price? (
                                        <div className={'price-block'}>
                                            <div className={'price'}>
                                                <div className={'integer'}>{price.integer}</div>
                                                {price.float>0 && <div className={'float'}>.{price.float}</div>}
                                            </div>
                                            <div className={'currency'}>{planPrice.priceCurrency}</div>
                                        </div>
                                    )
                                        :
                                    <div className={'free'}>free</div>
                                }
                            </div>
                            <div className={'plan-perks'}>
                                {plan.perks.map(perk => (
                                    <div className={'perk'} key={perk}>{perk}</div>
                                ))}
                            </div>
                                <StripeWidget
                                    plan={planKey}
                                    onChange={handleError}
                                    btnText={planPrice?'buy':'register'}
                                    btnClass={'purchase-btn'}
                                    showCheckout={() => showCheckout({...plan,planPrice})}
                                />
                            </div>
                        </div>
                    )
                })}

                <div onClick={switchPriceType} className={'switch-type'}>
                    Team Plans
                </div>
            </div>
        );
    }
}

export default PersonalPlans;
