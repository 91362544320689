import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { modalClose, modalOpen } from '../../../shared/reducers/ModalReducer';
import { canvasReset } from '../../../shared/actions/drawAreaActions';
import { clipboardReset, clipboardConfirmLoad } from '../../../shared/reducers/clipboardReducer';

export const MODAL_CLIPBOARD_CANVAS_RESET = 'MODAL_CLIPBOARD_CANVAS_RESET';

class ModalClipboardCanvasReset extends React.Component {
	render() {
		return (
			<div className='modal-image-upload-canvas-reset modal-inner-wrapper'>
				<h2 className='text-center'>Import Image/PDF from clipboard</h2>
				<p className='text-center'>all current layers will be deleted</p>
				<div className='text-center'>
					<button
						className='btn btn-modal'
						onClick={() => {
							this.props.clipboardReset();
							this.props.modalClose();
						}}>
						cancel
					</button>
					<button
						className='btn btn-modal'
						onClick={() => {
							this.props.canvasReset();
							this.props.modalClose();
							this.props.clipboardConfirmLoad();
						}}>
						delete
					</button>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			modalClose,
			modalOpen,
			canvasReset,
			clipboardReset,
			clipboardConfirmLoad
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(ModalClipboardCanvasReset);
